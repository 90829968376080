import DefaultLayout from 'App/layouts/DefaultLayout/DefaultLayout';
import Authenticated from 'middleware/authenticated';
import ServiceHome from 'pages/service/ServiceHome';
import Agenda from 'pages/service/Schedule/Schedule';
import ListaDeEspera from 'pages/service/ListaDeEspera/ListaDeEspera';
import SolicitacoesInsumos from 'pages/service/SolicitacoesInsumos/SolicitacoesInsumos';
import Prontuario from 'pages/service/Prontuario/Prontuario';
import FormPaciente from 'pages/shared/Pacientes/FormPaciente/FormPaciente';
import Pacientes from 'pages/shared/Pacientes/Pacientes';
import PerfilPaciente from 'pages/shared/Pacientes/PerfilPaciente/PerfilPaciente';

export const routes = [
  {
    key: '/atendimento/home',
    path: '/atendimento/home',
    element: (
      <Authenticated>
        <DefaultLayout nav="service" tab="">
          <ServiceHome />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/atendimento/agenda',
    path: '/atendimento/agenda',
    element: (
      <Authenticated>
        <DefaultLayout nav="service" tab="/agenda">
          <Agenda />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/atendimento/pacientes',
    path: '/atendimento/pacientes',
    element: (
      <Authenticated>
        <DefaultLayout nav="service" tab="/pacientes">
          <Pacientes path="atendimento" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/atendimento/perfil-paciente/:id',
    path: '/atendimento/perfil-paciente/:id',
    element: (
      <Authenticated>
        <DefaultLayout nav="service" tab="/perfil-paciente">
          <PerfilPaciente path="atendimento" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/atendimento/pacientes/novo',
    path: '/atendimento/pacientes/novo',
    element: (
      <Authenticated>
        <DefaultLayout nav="service" tab="/pacientes">
          <FormPaciente path="atendimento" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/atendimento/pacientes/editar/:id',
    path: '/atendimento/pacientes/editar/:id',
    element: (
      <Authenticated>
        <DefaultLayout nav="service" tab="/pacientes">
          <FormPaciente path="atendimento" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/atendimento/lista-de-espera',
    path: '/atendimento/lista-de-espera',
    element: (
      <Authenticated>
        <DefaultLayout nav="service" tab="/lista-de-espera">
          <ListaDeEspera />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/atendimento/solicitacao-insumos',
    path: '/atendimento/solicitacao-insumos',
    element: (
      <Authenticated>
        <DefaultLayout nav="service" tab="/solicitacao-insumos">
          <SolicitacoesInsumos props={{ nav: 'service', tab: 'lista_solicitacoes' }} />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/atendimento/prontuario/:patientId/consulta/:scheduleId',
    path: '/atendimento/prontuario/:patientId/consulta/:scheduleId',
    element: (
      <Authenticated>
        <DefaultLayout nav="service" tab="prontuario">
          <Prontuario />
        </DefaultLayout>
      </Authenticated>
    ),
  },
];
