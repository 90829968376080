import React from 'react';
import styles from './Header.module.scss';
import User from '../User/User';
import { FinanceiroHeader, RecepcaoHeader, MedicoHeader } from './HeaderItems/HeaderItems';
import { AiOutlineHome } from 'react-icons/ai';
import Enums from 'transformers/enums';
import { useNavigate } from 'react-router-dom';
import ConsultorioSelectGlobal from '../ConsultorioSelectGlobal/ConsultorioSelectGlobal';

function Header({ nav }) {
  const navigate = useNavigate();
  const showSecondaryButtons = ['finance', 'concierge', 'stock', 'service'].includes(nav);

  return (
    <>
      <div className={styles.container}>
        {nav !== 'default' && (
          <div className={`${styles.headerLeft} ${styles.visible}`}>
            <div
              className={styles.header_home_container}
              onClick={() => navigate(`/${Enums.navTabToLabelUrl[nav]}/home`)}
            >
              <AiOutlineHome className={styles.header_home_icon} />
              <span className={styles.header_home_module}>{Enums.navTabNameToLabel[nav]}</span>
            </div>
          </div>
        )}
        <div className={styles.headerCenter}>{showSecondaryButtons && <ConsultorioSelectGlobal />}</div>
        <div className={styles.headerRight}>
          {showSecondaryButtons && (
            <div className={styles.calendarAndNotification}>
              {/* <Notification className={styles.notification} /> */}
              {nav === 'finance' && <FinanceiroHeader />}
              {nav === 'concierge' && <RecepcaoHeader />}
              {nav === 'services' && <MedicoHeader />}
            </div>
          )}
          <div className={styles.user}>
            <User />
          </div>
        </div>
      </div>
    </>
  );
}
export default Header;
