import React, { useContext } from 'react';
import styles from './Home.module.scss';
import CardHome from './CardHome/CardHome';
import { AuthContext } from 'contexts/auth';

function Home() {
  const { loadingUser, getHomeMenuItems } = useContext(AuthContext);
  if (loadingUser) {
    return '';
  }

  const cards = getHomeMenuItems();
  if (cards.length === 1) {
    window.location.href = cards[0].link;
    return '';
  }

  return (
    <div className={styles.container}>
      <div className={styles.conteudo}>
        {cards.map((item, index )=> (
          <div className={styles.card} key={index}>
            <CardHome
              icon={item.icon}
              title={item.title}
              buttons={[
                {
                  label: 'Acessar',
                  link: item.link,
                  variant: 'contained',
                  color: 'secondary',
                },
              ]}
            >
              {item.text}
            </CardHome>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Home;
