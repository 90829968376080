import React from 'react'
import styles from './PlanoAtual.module.scss'
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import Button from 'components/Button/Button.js'
import { useState } from 'react';
import Modal from 'components/Modal/Modal';
import cartaoImg from 'assets/cartao.jpeg';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';

function PlanoAtual() {
    const [openModal, setOpenModal] = useState(false)
    const [openCartaoModal, setOpenCartaoModal] = useState(false)
    const [showSecondModal, setShowSecondModal] = useState(false);
    const [showLastModal, setShowLastModal] = useState(false);

    const handleOpenSecondModal = () => {
        setOpenCartaoModal(false);
        setShowSecondModal(true);
    };

    const handleBackModal = () => {
        setOpenCartaoModal(true);
        setShowSecondModal(false);
    };

    const handleOpenLastModal = () => {
        setShowSecondModal(false);
        setShowLastModal(true)
    };

    return (
        <div>
            <div className={styles.card}>
                <div className={styles.name}>
                    <div className={styles.titulo}>
                        <h1>Plano Anual</h1>
                        <p>Plano pago</p>
                    </div>
                    <div className={styles.price}>
                        <p>R$2.999,00/<span>ano</span></p>
                    </div>
                </div>
                <div>
                    <div className={styles.plano}>
                        <div className={styles.renovacao}>
                            <p>O plano será renovado automaticamente em:</p>
                            <span>19/05/2024</span>
                        </div>
                        <div className={styles.cancel}>
                            <span onClick={() => { setOpenModal(true) }}>Cancelar o plano</span>
                            <Modal
                                isOpen={openModal}
                                setModalOpen={() => setOpenModal(!openModal)}
                            >
                                <div className={styles.cancelModal}>
                                    <h2>Cancelamento do plano</h2>
                                    <p>Ao cancelar seu plano ele será desativado
                                        após a data de competência do útimo lano contratado.
                                        Para confirmar o cancelamento clique no botão abaixo.</p>
                                    <div onClick={() => { setOpenModal(false) }}>
                                        <Button label="Confirmar cancelamento"  color='secondary' />
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                    <div className={styles.cartao}>
                        <div className={styles.dados}>
                            <p>Cartão Cadastrado</p>
                            <span>*******4988</span>
                        </div>
                        <div className={styles.change}>
                            <span onClick={() => { setOpenCartaoModal(true) }}>Trocar cartão</span>
                            <Modal
                                isOpen={openCartaoModal}
                                setModalOpen={() => setOpenCartaoModal(!openCartaoModal)}
                            >
                                <div className={styles.firstModal}>
                                    <h2>Confirmar mudança de plano</h2>
                                    <p>Ao confirmar esta ação, os dados de cobrança serão alterados no cartão de crédito já cadastrado.</p>
                                    <div onClick={handleOpenSecondModal}>
                                        <Button label="Confirmar"  color='secondary' />
                                    </div>
                                </div>
                            </Modal>
                            <Modal
                                isOpen={showSecondModal}
                                setModalOpen={() => setShowSecondModal(!showSecondModal)}
                            >
                                <div className={styles.content}>
                                    <img className={styles.credit} src={cartaoImg} alt="Cartao"></img>
                                    <div className={styles.titular}>
                                        <div>
                                            <p>Numero do cartao</p>
                                            <input className={styles.input}></input>
                                        </div>
                                        <div>
                                            <p>Nome do titular do cartao</p>
                                            <input className={styles.input}></input>
                                        </div>
                                    </div>
                                    <div className={styles.codigo}>
                                        <div className={styles.validade}>
                                            <p>Validade</p>
                                            <input></input>
                                        </div>
                                        <div className={styles.cvv}>
                                            <p>CVV</p>
                                            <input></input>
                                        </div>
                                    </div>
                                    <div className={styles.button}>
                                        <button className={styles.btnBack} onClick={handleBackModal}><MdKeyboardDoubleArrowRight/>Voltar</button>
                                        <Button label="Salvar" color='secondary' onClick={handleOpenLastModal} />
                                    </div>
                                </div>
                            </Modal>
                            <Modal
                                isOpen={showLastModal}
                                setModalOpen={() => setShowLastModal(!showLastModal)}
                            >
                                <div className={styles.lastModal}>
                                    <p>Sua próxima cobrança será feita no seu novo cartão cadastrado.</p>
                                    <div onClick={() => { setShowLastModal(false) }}>
                                        <Button label="Confirmar" color='secondary'/>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.buttons}>
                <ButtonVoltar />
                <Button label="Alterar meu plano"  color='secondary' link="/planos/alterar" />
            </div>
        </div>
    )
}

export default PlanoAtual