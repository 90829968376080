import yup from 'shared/validator.js';

let estoqueSchema = yup.object({
  batch: yup.string().required(),
  expiration_date: yup.string().required(),
  entry_quantity: yup.string().required(),
  balance: yup.string().required(),
  clinic: yup.number().required(),
  item: yup.number().nullable(),
  purchase_order: yup.number().nullable(),
});

export const validateEntradaEstoque = async data => {
  return await estoqueSchema.validate(data);
};
