// hooks
import { useState } from 'react';

// scss
import styles from './modal.module.scss';

// components
import Modal from 'components/Modal/Modal';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import { Checkbox } from '@mui/material';
import { Grid } from '@mui/material';

// other
import { validateParcels } from '../hooks/validator';

export default function ModalAdicionarParcelas({ modalGetter, modalSetter, form, setForm }) {
  const [validateError, setValidateError] = useState('');
  const [parcels, setParcels] = useState({
    parcels: '',
    tax_per_month: '',
    number_release_days: '',
    is_anticipant: false,
  });

  const handleChange = event => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === 'tax_per_month') {
      value = value.replace('.', ',');
      value = value.replace(/[^0-9,]/g,'');
      value = value.slice(0, 5);
      if (parseFloat(value.replace(',', '.')) > 100) {
        value = '100';
      }
    } else {
      value = value.replace(/\D/g, '');
      value = value.slice(0, 2);
    }

    setParcels(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  const createParcelsArray = async () => {
    let hasParcel = false;
    form.types.forEach(item => {
      if (item.parcels === parseInt(parcels.parcels)) {
        hasParcel = true;
      }
    });

    try {
      await validateParcels({
        parcels: parseInt(parcels.parcels),
        tax_per_month: parseFloat(parcels.tax_per_month.replace(',', '.')),
        number_release_days: parseInt(parcels.number_release_days),
      });

      if (!hasParcel) {
        setForm(prevState => ({
          ...prevState,
          types: [
            ...prevState.types,
            {
              parcels: parseInt(parcels.parcels),
              tax_per_month: parseFloat(parcels.tax_per_month.replace(',', '.')),
              number_release_days: parseInt(parcels.number_release_days),
              is_anticipant: parcels.is_anticipant,
            },
          ],
        }));
        modalSetter({ open: false });
        setParcels({ parcels: '', tax_per_month: '', number_release_days: '', is_anticipant: false });
      } else {
        setValidateError('Este tipo de parcela já existe.');
        setTimeout(() => setValidateError(''), 2000);
      }
    } catch (err) {
      setValidateError('Todos os campos devem ser preenchidos.');
      setTimeout(() => setValidateError(''), 2000);
      return;
    }
  };

  return (
    <Modal
      className={styles.modal_adicionar_parcela}
      isOpen={modalGetter.open}
      setModalOpen={() => {
        setParcels({ parcels: '', tax_per_month: '', number_release_days: '' });
        modalSetter({ open: false });
      }}
    >
      <h2>Adicionar Parcela(s)</h2>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input
            name="parcels"
            label="Quantidade de Parcelas"
            type="text"
            value={parcels.parcels}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            name="tax_per_month"
            label="Taxa (%)"
            type="text"
            value={parcels.tax_per_month}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            name="number_release_days"
            label="Dias para a liberação"
            type="text"
            value={parcels.number_release_days}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            className={styles.modal_adicionar_parcela_button}
            label="Adicionar"
            color="secondary"
            onClick={() => {
              createParcelsArray();
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <span>
            <Checkbox
              color="secondary"
              checked={parcels.is_anticipant}
              onClick={() =>
                setParcels(prevState => ({ ...prevState, is_anticipant: prevState.is_anticipant ? false : true }))
              }
            />
            Com adiantamento
          </span>
        </Grid>
      </Grid>
      <span className={styles.validation_error}>{validateError}</span>
    </Modal>
  );
}
