import DefaultLayout from 'App/layouts/DefaultLayout/DefaultLayout';
import Authenticated from 'middleware/authenticated';
import DRE from 'pages/finance/DRE/DRE';
import DadosBancarios from 'pages/finance/DadosBancarios/DadosBancarios';
import DadosBancariosForm from 'pages/finance/DadosBancarios/DadosBancariosForm/DadosBancariosForm';
import AddTransacao from 'pages/finance/FluxoCaixa/AddTransacao/AddTransacao';
import FluxoCaixa from 'pages/finance/FluxoCaixa/FluxoCaixa';
import PlanoContas from 'pages/finance/PlanoContas/PlanoContas';
import PlanoContasForm from 'pages/finance/PlanoContas/PlanoContasForm/PlanoContasForm';
import PrevisãoReceita from 'pages/finance/PrevisaoReceita/PrevisãoReceita';
import RelatorioComissao from 'pages/finance/RelatorioComissao/RelatorioComissao';
import Pedidos from 'pages/finance/Pedidos/Pedidos';
import FinanceHome from 'pages/finance/FinanceHome';
import ListaProtocolos from 'pages/finance/Protocolos/ListaProtocolos/ListaProtocolos';
import AddProtocolos from 'pages/finance/Protocolos/AddProtocolos/AddProtocolos';
import FormasCobranca from 'pages/finance/FormasCobranca/FormasCobranca';
import SolicitacaoInsumos from 'pages/finance/SolicitacoesInsumos/SolicitacoesInsumos';
import SolicitacaoCompras from 'pages/finance/SolicitacaoCompras/SolicitacaoCompras';
import AdicionarCobranca from 'pages/finance/FormasCobranca/AdicionarCobranca/AdicionarCobranca';
import MovimentacaoInterna from 'pages/finance/FluxoCaixa/MovimentacaoInterna/MovimentacaoInterna';
import CaixaReembolso from 'pages/finance/CaixaReembolso/CaixaReembolso';

export const routes = [
  {
    key: '/financeiro/home',
    path: '/financeiro/home',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="">
          <FinanceHome />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/financeiro/dados-bancarios',
    path: '/financeiro/dados-bancarios',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="/config">
          <DadosBancarios />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/financeiro/dados-bancarios/novo',
    path: '/financeiro/dados-bancarios/novo',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="/config">
          <DadosBancariosForm />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/financeiro/dados-bancarios/editar/:id',
    path: '/financeiro/dados-bancarios/editar/:id',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="/config">
          <DadosBancariosForm />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/financeiro/plano-contas',
    path: '/financeiro/plano-contas',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="/config">
          <PlanoContas />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/financeiro/plano-contas/novo',
    path: '/financeiro/plano-contas/novo',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="/config">
          <PlanoContasForm />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/financeiro/plano-contas/editar/:id',
    path: '/financeiro/plano-contas/editar/:id',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="/config">
          <PlanoContasForm />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/financeiro/fluxo-caixa',
    path: '/financeiro/fluxo-caixa',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="/fluxo-caixa">
          <FluxoCaixa />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/financeiro/fluxo-caixa/add-transacao',
    path: '/financeiro/fluxo-caixa/add-transacao',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="/fluxo-caixa">
          <AddTransacao />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/financeiro/fluxo-caixa/movimentacao-interna',
    path: '/financeiro/fluxo-caixa/movimentacao-interna',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="/fluxo-caixa">
          <MovimentacaoInterna />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/financeiro/previsao-receita',
    path: '/financeiro/previsao-receita',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="/previsao-receita">
          <PrevisãoReceita />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/financeiro/protocolos',
    path: '/financeiro/protocolos',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="/protocolos">
          <ListaProtocolos />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/financeiro/protocolos/novo',
    path: '/financeiro/protocolos/novo',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="/protocolos">
          <AddProtocolos />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/financeiro/protocolos/editar/:id',
    path: '/financeiro/protocolos/editar/:id',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="/protocolos">
          <AddProtocolos />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/financeiro/dre',
    path: '/financeiro/dre',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="/relatorio">
          <DRE />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/financeiro/relatorio-comissao',
    path: '/financeiro/relatorio-comissao',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="/relatorio">
          <RelatorioComissao />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/financeiro/pedidos',
    path: '/financeiro/pedidos',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="/pedidos">
          <Pedidos />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/financeiro/formas-cobrancas',
    path: '/financeiro/formas-cobrancas',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="/pedidos">
          <FormasCobranca />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/financeiro/formas-cobrancas/novo',
    path: '/financeiro/formas-cobrancas/novo',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="/pedidos">
          <AdicionarCobranca />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/financeiro/formas-cobrancas/:id/editar',
    path: '/financeiro/formas-cobrancas/:id/editar',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="/pedidos">
          <AdicionarCobranca />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/financeiro/solicitacao-insumos',
    path: '/financeiro/solicitacao-insumos',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="/solicitacao-insumos">
          <SolicitacaoInsumos props={{ nav: 'finance', tab: 'lista_solicitacoes' }} />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/financeiro/solicitacao-compras',
    path: '/financeiro/solicitacao-compras',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="/solicitacao-compras">
          <SolicitacaoCompras
            props={{
              nav: 'finance',
              tab: 'lista_solicitacoes',
              headers: ['Código', 'Data', 'Fornecedor', 'Valor', 'Status', 'Ações'],
            }}
          />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/financeiro/caixa',
    path: '/financeiro/caixa',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="/caixa">
          <CaixaReembolso tab="caixa" user_type="finance" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/financeiro/reembolso',
    path: '/financeiro/reembolso',
    element: (
      <Authenticated>
        <DefaultLayout nav="finance" tab="/caixa">
          <CaixaReembolso tab="reembolso" user_type="finance" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
];
