import React, { useState, useEffect, useContext } from 'react';
import styles from './AddProtocolos.module.scss';
import Input from 'components/Input/Input';
import { Checkbox } from '@mui/material';
import { Grid } from '@mui/material';
import { LoaderContext } from 'contexts/loader';
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import { useToast } from 'hooks/useToast';
import Select from 'components/Select/Select';
import { useNavigate, useParams } from 'react-router-dom';
import { SpecialtiesRepository } from 'data/specialties';
import { ProceduresRepository } from 'data/procedures';
import { ClinicIdContext } from 'contexts/clinicId';
import { ServiceNamesRepository } from 'data/professionals';
import { ProtocolsRepository } from 'data/protocols';
import { validateProcedures, validateProtocol } from './validator';
import PageTitle from 'components/PageTitle/PageTitle';
import Container from 'components/Container/Container';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { GiMedicalDrip } from 'react-icons/gi';
import InsumosModal, { initialInsumosModal } from './InsumosModal';

const initialFormData = { name: '', value: null, profile: null, items: [], specialtie_type: null, service_type: null, protocol: '' };

const profileSelect = [
  { name: 'Qualquer', value: null },
  { name: 'Médico', value: 'doctor' },
  { name: 'Outro', value: 'other' },
];

function AddProtocolos() {
  let navigate = useNavigate();
  let { id } = useParams();
  const { setLoading } = useContext(LoaderContext);
  const { clinicId } = useContext(ClinicIdContext);
  const toast = useToast();
  const [procedimentosList, setProcedimentosList] = useState([initialFormData]);
  const [specialtieList, setSpecialtieList] = useState([]);
  const [professionalList, setProfessionalsList] = useState([]);
  const [itemToRemove, setItemToRemove] = useState([]);
  const [protocol, setProtocol] = useState({ protocols_name: '', clinic: null, only_doctor: false });
  const [insumosModal, setInsumosModal] = useState(initialInsumosModal);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const responseSpeciality = await SpecialtiesRepository.list();
      setSpecialtieList(responseSpeciality.results);
      setLoading(false);
    };
    run();
  }, []);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const response = await ServiceNamesRepository.list();
      setProfessionalsList(response.results);
      setLoading(false);
    };
    run();
  }, []);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const response = await ProtocolsRepository.get(id);
      setProtocol(response);
      setLoading(false);
    };
    if (id) run();
  }, [id]);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const response = await ProceduresRepository.list(id);
      setProcedimentosList(response.results);
      setLoading(false);
    };
    if (id) run();
  }, [id]);

  const handleChangeValue = (index, event, value) => {
    const { name } = event.target;
    const updatedForms = [...procedimentosList];
    updatedForms[index][name] = value;
    setProcedimentosList(updatedForms);
  };

  const handleAddItem = () => {
    setProcedimentosList([...procedimentosList, { ...initialFormData, protocol: id ? parseInt(id) : '' }]);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedForms = [...procedimentosList];
    updatedForms[index][name] = value;
    setProcedimentosList(updatedForms);
  };

  const removeForm = (index, id) => {
    const updatedForms = [...procedimentosList];
    updatedForms.splice(index, 1);
    setItemToRemove([...itemToRemove, id]);
    setProcedimentosList(updatedForms);
  };

  const handleSubmit = async () => {
    let selectedProtocol;
    try {
      await validateProtocol({ ...protocol });
    } catch (err) {
      toast.error(err.message);
      return;
    }
    try {
      for (const item of procedimentosList) {
        await validateProcedures({ ...item });
      }
    } catch (err) {
      toast.error(err.message);
      return;
    }
    setLoading(true);
    if (id) {
      await ProtocolsRepository.update(id, protocol);
    } else {
      await ProtocolsRepository.post(protocol);
    }
    const response = await ProtocolsRepository.list();
    const protocolos = response?.results ?? [];
    setLoading(false);
    protocolos.forEach(item => {
      if (item?.protocols_name === protocol.protocols_name) {
        selectedProtocol = item.id;
      }
    });
    try {
      const newArrList = procedimentosList.map(item => ({
        ...item,
        protocol: selectedProtocol,
      }));
      saveData(selectedProtocol, newArrList);
    } catch (error) {
      toast.error(error);
      return;
    }
  };

  const saveData = async (selectedProtocol, list) => {
    setLoading(true);
    if (id && itemToRemove)
      itemToRemove.forEach(item => {
        ProceduresRepository.delete(selectedProtocol, item);
      });
    if (id) {
      procedimentosList.forEach(item => {
        if (item.id) {
          ProceduresRepository.update(selectedProtocol, item.id, item);
        } else {
          ProceduresRepository.post(selectedProtocol, { ...item, protocol: selectedProtocol });
        }
      });
      setLoading(false);
    } else {
      const response = await ProceduresRepository.post(selectedProtocol, list);
      setLoading(false);
      if (response?.error) toast.error(response.error);
    }
    setItemToRemove([]);
    return navigate('/financeiro/protocolos');
  };

  return (
    <Container className={styles.container}>
      <PageTitle>Adicionar Precificação de Protocolo</PageTitle>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Input
              label="Nome Protocolo"
              value={protocol.protocols_name}
              className={styles.input}
              onChange={e => setProtocol({ ...protocol, protocols_name: e.target.value, clinic: parseInt(clinicId) })}
            />
          </Grid>
          <Grid item xs={6}>
            <span>
              <Checkbox
                id="only_doctor"
                color="secondary"
                checked={protocol.only_doctor}
                onChange={e => setProtocol({ ...protocol, only_doctor: e.target.checked })}
              />
              Apenas médico pode atribuir ao paciente
            </span>
          </Grid>
          <Grid item xs={6} className={styles.subtext}>
            <p>Procedimentos</p>
          </Grid>
          <Grid item xs={6} className={styles.menuButton}>
            <Button label="+" color="secondary" onClick={handleAddItem} />
          </Grid>
        </Grid>
      </div>
      {!!procedimentosList.length ? (
        <Card className={styles.card}>
          {procedimentosList.map((form, index) => (
            <Grid container spacing={2} className={styles.procedimentosGrid}>
              <Grid item xs={3}>
                <Input label="Procedimento" name="name" value={form.name} onChange={e => handleInputChange(index, e)} />
              </Grid>
              <Grid item xs={2}>
                <Input
                  numericInput
                  label="Valor"
                  name="value"
                  value={form.value}
                  onChange={(e, value) => handleChangeValue(index, e, value)}
                />
              </Grid>
              <Grid item xs={form.profile === '' ? 3 : 2}>
                <Input
                  numericInput
                  label="Custo"
                  name="cost"
                  value={form.cost}
                  onChange={(e, value) => handleChangeValue(index, e, value)}
                />
              </Grid>
              <Grid item xs={form.profile === '' ? 3 : 2}>
                <Select
                  label="Perfil (opcional)"
                  name="profile"
                  value={form.profile}
                  options={profileSelect.map(item => ({ value: item.value, name: item.name }))}
                  onChange={e => handleInputChange(index, e)}
                />
              </Grid>
              {form.profile === 'doctor' ? (
                <Grid item xs={2}>
                  <Select
                    label="Especialidade"
                    name="specialtie_type"
                    value={form.specialtie_type}
                    options={specialtieList.map(item => ({ value: item.id, name: item.name }))}
                    onChange={e => handleInputChange(index, e)}
                  />
                </Grid>
              ) : (
                <></>
              )}
              {form.profile === 'other' ? (
                <Grid item xs={2}>
                  <Select
                    label="Profissionais"
                    name="service_type"
                    value={form.service_type}
                    options={professionalList.map(item => ({ value: item.id, name: item.name }))}
                    onChange={e => handleInputChange(index, e)}
                  />
                </Grid>
              ) : (
                <></>
              )}
              <Grid item xs={1} className={styles.buttons}>
                <GiMedicalDrip 
                  color="#C5A500" 
                  title="Insumos do Procedimento"
                  onClick={() => setInsumosModal({ isOpen: true, formData: procedimentosList[index] })}
                />
                <MdOutlineDeleteOutline
                  color="#A31912"
                  title="Remover Procedimento"
                  onClick={() => removeForm(index, form?.id)}
                />
              </Grid>
            </Grid>
          ))}
        </Card>
      ) : (
        <div className={styles.empty_container}>
          Não há procedimentos para exibir
        </div>
      )}

      <div className={styles.btnSalvar}>
        <Button label="Salvar" color="secondary" className={styles.menuButton} onClick={handleSubmit} />
      </div>
      <InsumosModal
        insumosModal={insumosModal} 
        setInsumosModal={setInsumosModal}
        setFormData={() => setProcedimentosList([...procedimentosList])} 
      />
    </Container>
  );
}

export default AddProtocolos;
