// hooks
import { useState } from 'react';

// other
import { buildInitialScheduleStructure } from 'transformers/userForm';

export default function useForm() {
  const [form, setForm] = useState({
    data: {
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      photo: null,
      reimbursement_right: false,
      is_partner: false,
    },
    profile: {
      doctor: {
        crm: null,
      },
      finance: {
        approver: true,
      },
      services: {
        service_type: null,
      },
    },
    clinics: [],
    specialties: [],
    user_types: [],
    schedule: buildInitialScheduleStructure(),
  });

  return {
    // getters
    form,

    // setters
    setForm,
  };
}
