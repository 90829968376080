import React from 'react';
import styles from './StockHome.module.scss';
import CardHome from 'pages/shared/Home/CardHome/CardHome';
import { HiPlus, HiOutlineMenu } from 'react-icons/hi';
import { BiSolidPurchaseTagAlt } from 'react-icons/bi';
import { BsBuildingFillUp } from 'react-icons/bs';
import { FaWarehouse } from 'react-icons/fa';
import { GiPriceTag, GiMedicalDrip } from 'react-icons/gi';
function StockHome() {

  return (
    <div className={styles.container}>
      <div className={styles.conteudo}>
        <CardHome
          icon={<BsBuildingFillUp />}
          title="Fornecedores"
          buttons={[
            {
              label: 'Adicionar',
              icon: <HiPlus />,
              link: '/estoque/fornecedores/novo',
              variant: 'contained',
              color: 'secondary',
            },
            {
              label: 'Acessar',
              icon: <HiOutlineMenu />,
              link: '/estoque/fornecedores',
              variant: 'outlined',
              color: 'secondary',
            },
          ]}
        ></CardHome>
        <CardHome
          icon={<GiMedicalDrip />}
          title="Insumos"
          buttons={[
            {
              label: 'Adicionar',
              icon: <HiPlus />,
              link: '/estoque/insumos/novo',
              variant: 'contained',
              color: 'secondary',
            },
            {
              label: 'Acessar',
              icon: <HiOutlineMenu />,
              link: '/estoque/insumos',
              variant: 'outlined',
              color: 'secondary',
            },
          ]}
        ></CardHome>
        <CardHome
          icon={<FaWarehouse />}
          title="Estoque"
          buttons={[
            {
              label: 'Adicionar',
              icon: <HiPlus />,
              link: '/estoque/estoque',
              variant: 'contained',
              color: 'secondary',
            },
            {
              label: 'Acessar',
              icon: <HiOutlineMenu />,
              link: '/estoque/estoque',
              variant: 'outlined',
              color: 'secondary',
            },
          ]}
        ></CardHome>
        <CardHome
          icon={<BiSolidPurchaseTagAlt />}
          title="Solicitação de Insumos"
          buttons={[
            {
              label: 'Adicionar',
              icon: <HiPlus />,
              link: '/estoque/solicitacao-insumos',
              variant: 'contained',
              color: 'secondary',
            },
            {
              label: 'Acessar',
              icon: <HiOutlineMenu />,
              link: '/estoque/solicitacao-insumos',
              variant: 'outlined',
              color: 'secondary',
            },
          ]}
        ></CardHome>
        <CardHome
          icon={<GiPriceTag />}
          title="Solicitação de compras"
          buttons={[
            {
              label: 'Adicionar',
              icon: <HiPlus />,
              link: '/estoque/solicitacoes-compras',
              variant: 'contained',
              color: 'secondary',
            },
            {
              label: 'Acessar',
              icon: <HiOutlineMenu />,
              link: '/estoque/solicitacoes-compras',
              variant: 'outlined',
              color: 'secondary',
            },
          ]}
        ></CardHome>
        {/* <CardHome
          icon={<BiSolidReport />}
          title="Relatório"
          buttons={[
            {
              label: 'Adicionar',
              icon: <HiPlus />,
              link: '/relatorio',
              variant: 'contained',
              color: 'secondary',
            },
            {
              label: 'Acessar',
              icon: <HiOutlineMenu />,
              link: '/relatorio',
              variant: 'outlined',
              color: 'secondary',
            },
          ]}
        ></CardHome> */}
      </div>
    </div>
  );
}

export default StockHome;
