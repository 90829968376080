import yup from 'shared/validator.js';

let PaymentMethodSchema = yup.object({
  description: yup.string().required(),
  bank_account: yup.number().required(),
  chart_of_account: yup.number().required(),
});

let ParcelsSchema = yup.object({
  parcels: yup.number().required(),
  tax_per_month: yup.number().required(),
  number_release_days: yup.number().required(),
});

export const validatePaymentMethod = async data => {
  return await PaymentMethodSchema.validate(data);
};

export const validateParcels = async data => {
  return await ParcelsSchema.validate(data);
};
