import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import Table from 'components/Table/Table';
import styles from './Insumos.module.scss';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'components/Button/Button';
import { ItemsRepository } from 'data/stockitems';
import { LoaderContext } from 'contexts/loader';
import { ClinicIdContext } from 'contexts/clinicId';
import { TbEdit } from 'react-icons/tb';
import errorIcon from 'assets/ico/error_icon.svg';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import Modal from 'components/Modal/Modal.js';
import confirmedIcon from 'assets/ico/confirmedIcon.svg';

const initialModalState = {
  isOpen: false,
  success: false,
  pending: false,
  error: false,
  msg: '',
  id: '',
};

const initialInsumosFilter = {
  limit: 30,
  offset: 0,
};
let insumosHasNext = false;
function Insumos() {
  const { clinicId } = useContext(ClinicIdContext);
  const { setLoading } = useContext(LoaderContext);
  const [list, setList] = useState([]);
  const [modalState, setModalState] = useState(initialModalState);
  const [insumosFilter, setInsumosFilter] = useState(initialInsumosFilter);

  const loadInsumos = async (filter) => {
    setLoading(true);
    const response = await ItemsRepository.list({ ...filter, clinic: clinicId });
    insumosHasNext = response.next != null;
    setLoading(false);
    if (response?.error == null) {
      if (filter === initialInsumosFilter) {
        setList(response.results);
      } else {
        setList([ ...list, ...response.results ]);
      }
    }
  };

  useEffect(() => {
    if (clinicId != null) {
      loadInsumos(initialInsumosFilter);
    }
  }, [clinicId]);

  useEffect(() => {
    if (insumosFilter !== initialInsumosFilter) {
      loadInsumos(insumosFilter);
    }
  }, [insumosFilter]);

  const deleteRecord = async id => {
    setLoading(true);
    const response = await ItemsRepository.delete(id);
    setLoading(false);
    if (response?.error) {
      setModalState({
        isOpen: true,
        error: true,
        msg: response.error,
      });
    } else {
      setList(list.filter(item => item.id !== id));
      setModalState({
        isOpen: true,
        success: true,
      });
    }
  };

  const handleDelete = id => {
    deleteRecord(id);
  };

  const onClose = () => {
    setModalState(initialModalState);
  };

  const editButton = id => (
    <div className={styles.editButton}>
      <Link to={`/estoque/insumos/editar/${id}`}>
        <TbEdit className={styles.pen} color="#C5A500" title="Editar Insumo" size={20} />
      </Link>
      <MdOutlineDeleteOutline
        color="#A31912"
        title="Excluir Insumo"
        onClick={() => {
          setModalState({ isOpen: true, id: id, pending: true });
        }}
      />
    </div>
  );
  const botaoVoltar = <ButtonVoltar link="/estoque/home" />;

  return (
    <Container>
      <div className={styles.header}>
        <PageTitle>Lista de Insumos</PageTitle>
        <Button label="Adicionar Insumo" link="/estoque/insumos/novo" color="secondary" />
      </div>
      <Table
        columns={['Insumo', 'Tipo', 'Código do Insumo', 'Ações']}
        lines={list.map(item => [item.name, item.unit_type, item.item_code, editButton(item.id)])}
        onScrollEnd={() => {
          if (insumosHasNext) {
            setInsumosFilter({
              ...insumosFilter,
              offset: insumosFilter.offset + insumosFilter.limit,
            });
          }
        }}
      />
      {botaoVoltar}
      <Modal isOpen={modalState.isOpen && modalState.pending} setModalOpen={onClose} className={styles.modal_container}>
        {modalState.pending ? (
          <div className={styles.modal_inner_container}>
            <p>Tem certeza que deseja excluir este insumo?</p>
            <Button color="secondary" label="Confirmar" onClick={() => handleDelete(modalState.id)} />
          </div>
        ) : (
          <></>
        )}
      </Modal>
      <Modal isOpen={modalState.isOpen && modalState.success} setModalOpen={onClose} className={styles.modal_container}>
        {modalState.success ? (
          <div className={styles.modal_inner_container}>
            <img src={confirmedIcon} alt=""></img>
            <span>O Insumo foi excluido</span>
          </div>
        ) : (
          <></>
        )}
      </Modal>
      <Modal isOpen={modalState.isOpen && modalState.error} setModalOpen={onClose} className={styles.modal_container}>
        {modalState.error ? (
          <div className={styles.modal_inner_container}>
            <img src={errorIcon} alt=""></img>
            <span>{modalState.msg}</span>
          </div>
        ) : (
          <></>
        )}
      </Modal>
    </Container>
  );
}

export default Insumos;
