// hooks
import { useContext } from 'react';
import { useCobrancas } from './hooks/useCobrancas';
import { useCobrancasForm } from './hooks/useCobrancasForm';
import { useNavigate } from 'react-router-dom';

// scss
import styles from './FormasCobranca.module.scss';

// contexts
import { LoaderContext } from 'contexts/loader.js';

// components
import Table from 'components/Table/Table.js';
import Modal from 'components/Modal/Modal';
import TableFooter from 'components/TableFooter/TableFooter';
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import Button from 'components/Button/Button';
import { FaEdit } from 'react-icons/fa';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import errorIcon from 'assets/ico/error_icon.svg';
import confirmedIcon from 'assets/ico/confirmedIcon.svg';

const initialModalState = {
  isOpen: false,
  success: false,
  error: false,
  pending: false,
  id: '',
  msg: '',
};

function FormasCobranca() {
  const { setLoading } = useContext(LoaderContext);
  const { list, handleDelete, modalState, setModalState } = useCobrancas(setLoading);
  const { banks } = useCobrancasForm(setLoading);

  const navigate = useNavigate();

  let daysArrayIndex = 0;
  let taxArrayIndex = 0;

  function getPropFromList(key, value) {
    if (banks.length === 0 || list.length === 0) {
      return null;
    }

    if (key === 'bank') {
      const filter = banks.find(filter => filter.id === value);
      return filter ? filter.banks_name : '';
    }

    const propArray = list.map(item => {
      if (item.types && item.types.length > 0) {
        const firstType = item.types[0];
        if (key === 'days') {
          return `${firstType.number_release_days} dias`;
        } else if (key === 'tax') {
          return `${firstType.tax}%`;
        }
      }
      return key === 'days' ? 'Não definido' : 'Não definido';
    });

    const currentIndex = key === 'days' ? daysArrayIndex++ : taxArrayIndex++;
    return propArray[currentIndex % propArray.length];
  }

  const onClose = () => {
    setModalState(initialModalState);
  };

  return (
    <Container className={styles.container}>
      <PageTitle>Formas de Cobrança</PageTitle>
      <div className={styles.table}>
        <Table
          columns={['Banco', 'Descrição', 'Parcelas (total)', '']}
          lines={
            list.length > 0
              ? list.map(item => [
                  getPropFromList('bank', item.bank_account),
                  item.description,
                  item.types.length > 0 ? `${item.types.length} parcela(s)` : 'Não definido',
                  <div className={styles.formas_cobranca_action_icons}>
                    <FaEdit
                      className={styles.formas_cobranca_edit_icon}
                      color="#C5A500"
                      title="Editar Forma de Cobrança"
                      onClick={() => navigate(`/financeiro/formas-cobrancas/${item.id}/editar`)}
                    />
                    <MdOutlineDeleteOutline
                      className={styles.formas_cobranca_delete_icon}
                      color="#A31912"
                      title="Excluir Forma de Cobrança"
                      onClick={() => {
                        setModalState({ isOpen: true, id: item.id, pending: true });
                      }}
                    />
                  </div>,
                ])
              : [[<div>Não há informações para exibir</div>]]
          }
        />
      </div>
      <TableFooter>
        <ButtonVoltar link="/financeiro/home" />
        <Button label="Adicionar +" color="secondary" link="/financeiro/formas-cobrancas/novo" />
      </TableFooter>
      <Modal isOpen={modalState.isOpen && modalState.pending} setModalOpen={onClose} className={styles.modal_container}>
        {modalState.pending ? (
          <div className={styles.modal_inner_container}>
            <p>Tem certeza que deseja excluir esta forma de cobrança ?</p>
            <Button color="secondary" label="Confirmar" onClick={() => handleDelete(modalState.id)} />
          </div>
        ) : (
          <></>
        )}
      </Modal>
      <Modal isOpen={modalState.isOpen && modalState.success} setModalOpen={onClose} className={styles.modal_container}>
        {modalState.success ? (
          <div className={styles.modal_inner_container}>
            <img src={confirmedIcon} alt=""></img>
            <span>A forma de cobrança foi excluida</span>
          </div>
        ) : (
          <></>
        )}
      </Modal>
      <Modal isOpen={modalState.isOpen && modalState.error} setModalOpen={onClose} className={styles.modal_container}>
        {modalState.error ? (
          <div className={styles.modal_inner_container}>
            <img src={errorIcon} alt=""></img>
            <span>{modalState.msg}</span>
          </div>
        ) : (
          <></>
        )}
      </Modal>
    </Container>
  );
}

export default FormasCobranca;
