import * as React from 'react';
import TextField from '@mui/material/TextField';
import MuiAutocomplete from '@mui/material/Autocomplete';
import styles from './Autocomplete.module.scss';

export default function Autocomplete({
  name,
  className,
  label,
  onChange,
  options,
  value,
  error,
  helper = '',
  ...props
}) {
  const selectedOption = React.useMemo(
    () => options.find(option => option.value === (value != null ? value.value : null)),
    [options, value]
  );

  const customStyles = [styles.autocomplete];
  if (className) customStyles.push(className);

  return (
    <MuiAutocomplete
      className={customStyles.join(' ')}
      disablePortal
      value={selectedOption || null}
      id={name}
      onChange={onChange}
      options={options}
      getOptionLabel={option => option.label}
      renderInput={params => (
        <TextField
          className={styles.textfield}
          {...params}
          label={label}
          name={name}
          error={!!error}
          helperText={error ?? helper}
          variant="outlined"
          sx={{
            fontSize: 16,
            backgroundColor: 'white',
          }}
          {...props}
        />
      )}
    />
  );
}
