import DefaultLayout from 'App/layouts/DefaultLayout/DefaultLayout';
import Authenticated from 'middleware/authenticated';
import StockHome from 'pages/stock/StockHome';
import Fornecedores from 'pages/stock/Fornecedores/Fornecedores';
import Insumos from 'pages/stock/Insumos/Insumos';
import Estoque from 'pages/stock/Estoque/Estoque';
import SolicitacoesInsumos from 'pages/stock/SolicitacoesInsumos/SolicitacoesInsumos';
import SolicitacoesCompras from 'pages/stock/SolicitacoesCompras/SolicitacoesCompras';
// import Relatorio from 'pages/stock/Relatorio/Relatorio';
import AddFornecedor from 'pages/stock/Fornecedores/AddFornecedor/AddFornecedor';
import ViewFornecedor from 'pages/stock/Fornecedores/ViewFornecedor/ViewFornecedor';
import AddInsumo from 'pages/stock/Insumos/AddInsumo/AddInsumo';

export const routes = [
  {
    key: '/estoque/home',
    path: '/estoque/home',
    element: (
      <Authenticated>
        <DefaultLayout nav="stock" tab="">
          <StockHome />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/estoque/fornecedores',
    path: '/estoque/fornecedores',
    element: (
      <Authenticated>
        <DefaultLayout nav="stock" tab="/fornecedores">
          <Fornecedores />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/estoque/fornecedores/novo',
    path: '/estoque/fornecedores/novo',
    element: (
      <Authenticated>
        <DefaultLayout nav="stock" tab="/fornecedores">
          <AddFornecedor />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/estoque/fornecedores/:id/editar',
    path: '/estoque/fornecedores/:id/editar',
    element: (
      <Authenticated>
        <DefaultLayout nav="stock" tab="/fornecedores">
          <AddFornecedor />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/estoque/fornecedores/:id/visualizar',
    path: '/estoque/fornecedores/:id/visualizar',
    element: (
      <Authenticated>
        <DefaultLayout nav="stock" tab="/fornecedores">
          <ViewFornecedor />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/estoque/insumos',
    path: '/estoque/insumos',
    element: (
      <Authenticated>
        <DefaultLayout nav="stock" tab="/insumos">
          <Insumos />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/estoque/insumos/novo',
    path: '/estoque/insumos/novo',
    element: (
      <Authenticated>
        <DefaultLayout nav="stock" tab="/insumos">
          <AddInsumo />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/estoque/insumos/editar/:id',
    path: '/estoque/insumos/editar/:id',
    element: (
      <Authenticated>
        <DefaultLayout nav="stock" tab="/insumos">
          <AddInsumo />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/estoque/estoque',
    path: '/estoque/estoque',
    element: (
      <Authenticated>
        <DefaultLayout nav="stock" tab="/estoque">
          <Estoque />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/estoque/solicitacao-insumos',
    path: '/estoque/solicitacao-insumos',
    element: (
      <Authenticated>
        <DefaultLayout nav="stock" tab="/solicitacao-insumos">
          <SolicitacoesInsumos props={{ nav: 'stock', tab: 'lista_solicitacoes' }} />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/estoque/solicitacoes-compras',
    path: '/estoque/solicitacoes-compras',
    element: (
      <Authenticated>
        <DefaultLayout nav="stock" tab="/solicitacoes-compras">
          <SolicitacoesCompras
            props={{
              nav: 'stock',
              tab: 'lista_solicitacoes',
              headers: ['Código', 'Data', 'Fornecedor', 'Valor', 'Status', ''],
            }}
          />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  // {
  //   key: '/estoque/relatorio',
  //   path: '/estoque/relatorio',
  //   element: (
  //     <Authenticated>
  //       <DefaultLayout nav="stock" tab="/relatorio">
  //         <Relatorio />
  //       </DefaultLayout>
  //     </Authenticated>
  //   ),
  // },
];
