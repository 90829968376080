// scss
import styles from './ListaSolicitacoes.module.scss';

// components
import Table from 'components/Table/Table';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import { FaEdit, FaSearch } from 'react-icons/fa';
import { MdOutlineDeleteOutline } from 'react-icons/md';

export default function ListaSolicitacoes({ props }) {
  const { setTabInfo, setModalState, setOrderState, tableData, handleDeleteOrder, modalStatus, setModalStatus } = props;

  return (
    <div className={styles.lista}>
      <div>
        {tableData.body.length < 1 ? (
          <div className={styles.lista_solicitacoes_no_orders}>Não há pedidos para exibir</div>
        ) : (
          <Table
            columns={tableData.headers}
            lines={tableData.body.map((data, index) => {
              data.style = styles[data.status];
              return [
                ...Object.values(data).slice(4),
                <div key={index}>
                  {data.status === 'Aguardando' && (
                    <div className={styles.solicitacoes_icons_container}>
                      <FaSearch
                        className={styles.solicitacoes_icon_view}
                        onClick={() => {
                          setTabInfo('ver_solicitacao');
                          setOrderState(data);
                        }}
                      />
                      <FaEdit
                        className={styles.solicitacoes_icon_edit}
                        onClick={() => {
                          setModalState({ open: true, is_edit: true });
                          setOrderState(data);
                        }}
                      />
                      <MdOutlineDeleteOutline
                        color="#A31912"
                        className={styles.solicitacoes_icon_delete}
                        onClick={() => setModalStatus(prevState => ({ ...prevState, open: true, id: data.order_id }))}
                      />
                    </div>
                  )}
                  {data.status !== 'Aguardando' && (
                    <FaSearch
                      className={styles.solicitacoes_icon_view}
                      onClick={() => {
                        setTabInfo('ver_solicitacao');
                        setOrderState(data);
                      }}
                    />
                  )}
                </div>,
              ];
            })}
          />
        )}
      </div>
      <Modal
        className={styles.modalStatus}
        isOpen={modalStatus.open}
        setModalOpen={() => setModalStatus({ open: false, id: '', success: '', confirmed: false })}
      >
        <div className={styles.modal_inner_container}>
          {modalStatus.confirmed ? (
            <>
              {modalStatus.success ? (
                <p className={styles.modal_inner_container_success}>{modalStatus.status}</p>
              ) : (
                <p className={styles.modal_inner_container_fail}>{modalStatus.status}</p>
              )}
            </>
          ) : (
            <>
              <h3>Deseja mesmo cancelar o pedido?</h3>
              <Button
                label="Confirmar"
                onClick={() => {
                  handleDeleteOrder(modalStatus.id);
                  setTimeout(() => {
                    setModalStatus(prevState => ({ ...prevState, confirmed: true }));
                  }, 1000);
                  setTimeout(() => {
                    window.location.reload();
                  }, 3000);
                }}
              />
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}
