import React from 'react';
import styles from './Modal.module.scss';
// import Button from '../Button/Button'
import { GrFormClose } from 'react-icons/gr';

function Modal({ isOpen, setModalOpen, className, children }) {
  const modalStyles = [styles.container];
  if (className) modalStyles.push(className);

  if (isOpen) {
    return (
      <div className={styles.background}>
        <div className={modalStyles.join(' ') || styles.container}>
          <div className={styles.button_container}>
            <button className={styles.button} onClick={setModalOpen}>
              <GrFormClose />
            </button>
          </div>
          <div className={styles.body}>{children}</div>
        </div>
      </div>
    );
  }

  return null;
}

export default Modal;
