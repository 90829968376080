// hooks
import { useState, useEffect } from 'react';

// other
import { validateUserData, validateUserProfile, validateUserClinics } from './validator';

export default function useHandlers(tab, edit, id, form) {
  const [error, setError] = useState({});
  const [controlTabs, setControlTabs] = useState(tab);
  const [unlockedTabs, setUnlockedTabs] = useState(['data']);

  async function validateOnBlur() {
    try {
      await validateUserData(form.data);
      setUnlockedTabs(['data', 'profile']);
      setError({});
    } catch (error) {
      let errorsByPath = {};
      error.inner.forEach(err => {
        const translationHash = {
          first_name: 'Nome é um campo obrigatório',
          last_name: 'Sobrenome é um campo obrigatório',
          email: 'Email é um campo obrigatório',
        };
        errorsByPath = {
          data: { [err.path]: err.errors[0].includes(err.path) ? translationHash[err.path] : err.errors },
        };
      });
      setUnlockedTabs(['data']);
      setError(errorsByPath);
    }
  }

  async function handleToggleTabEdit() {
    setUnlockedTabs(
      form.user_types.includes(1)
        ? ['data', 'profile', 'specialties', 'clinic', 'schedule']
        : ['data', 'profile', 'clinic']
    );
    switch (tab) {
      case 'data':
        try {
          await validateUserData(form.data);
          setError({});
        } catch (error) { }
        break;
      case 'profile':
        try {
          setError({});
        } catch (error) { }
        break;
      case 'specialties':
        try {
          setError({});
        } catch (error) { }
        break;
      case 'clinic':
        try {
          if (form.profile.user_types.includes(1)) {
          }
          setError({});
        } catch (error) { }
        break;
      case 'schedule':
        try {
        } catch (error) { }
        break;
      default:
        break;
    }
  }

  async function handleToggleTabAdd() {
    switch (tab) {
      case 'profile':
        try {
          await validateUserProfile({ ...form.profile, user_types: form.user_types });
          if (form.user_types.includes(1)) {
            setUnlockedTabs(['data', 'profile', 'specialties']);
          } else {
            setUnlockedTabs(['data', 'profile', 'clinic']);
          }
          setError({});
        } catch (error) {
          let errorsByPath = {};
          error.inner.forEach(err => {
            const translationHash = {
              user_types: 'Selecione um ou mais perfis.',
            };
            errorsByPath = {
              profile: { [err.path]: err.errors[0].includes(err.path) ? translationHash[err.path] : err.errors },
            };
          });
          setUnlockedTabs(['data', 'profile']);
          setError(errorsByPath);
        }
        break;
      case 'specialties':
        try {
          // await validateUserData(form.specialties);
          setUnlockedTabs(['data', 'profile', 'specialties', 'clinic']);
          setError({});
        } catch (error) { }
        break;
      case 'clinic':
        try {
          await validateUserClinics({ clinics: form.clinics });
          if (form.user_types.includes(1)) {
            setUnlockedTabs(['data', 'profile', 'specialties', 'clinic', 'schedule']);
          }
          setError({});
        } catch (error) {
          let errorsByPath = {};
          error.inner.forEach(err => {
            const translationHash = {
              clinics: 'Selecione um ou mais consultórios.',
            };
            errorsByPath = {
              clinic: { [err.path]: err.errors[0].includes(err.path) ? translationHash[err.path] : err.errors },
            };
          });
          if (form.user_types.includes(1)) {
            setUnlockedTabs(['data', 'profile', 'specialties', 'clinic']);
          } else {
            setUnlockedTabs(['data', 'profile', 'clinic']);
          }
          setError(errorsByPath);
        }
        break;
      case 'schedule':
        try {
          // await validateUserData(form.schedule);
        } catch (error) { }
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (edit) {
      handleToggleTabEdit();
    } else {
      handleToggleTabAdd();
    }
  }, [tab, form]);

  return {
    error,
    controlTabs,
    setControlTabs,
    unlockedTabs,
    validateOnBlur
  };
}
