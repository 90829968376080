import { useState, useEffect, createContext } from 'react';
import { storageUser, storageAccessToken, storageRefreshToken } from '../shared/storage';
import Enums from 'transformers/enums';
import jwtDecode from 'jwt-decode';
import { RiAdminFill } from 'react-icons/ri';
import { BiDollar, BiSolidUserBadge } from 'react-icons/bi';
import { FaWarehouse, FaUserNurse } from 'react-icons/fa';
import moment from 'moment';
import { clearAccessToken, clearClinic, clearClinical, clearRefreshToken, clearUser } from 'shared/storage';
import Endpoints from 'config/endpoints';
import http from 'config/httpClient';
import { UserRepository } from 'data/auth';

export const AuthContext = createContext();
let isRunningTimeOutRefreshToken = false;

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);

  useEffect(() => {
    const recoveredUser = JSON.parse(storageUser());
    if (recoveredUser?.id) {
      UserRepository.get(recoveredUser.id).then(user => {
        setUser(user);
        setLoadingUser(false);
      });
    } else {
      setLoadingUser(false);
    }
  }, []);

  const getAccessToken = () => {
    return storageAccessToken();
  };

  const getRefreshToken = () => {
    return storageRefreshToken();
  };

  const logout = () => {
    clearUser();
    clearAccessToken();
    clearRefreshToken();
    clearClinic();
    clearClinical();

    setUser(null);
    setLoadingUser(false);
  };

  useEffect(() => {
    if (user != null) {
      const resetTimeOut = () => {
        if (!isRunningTimeOutRefreshToken) {
          const accessToken = getAccessToken();
          const { exp } = jwtDecode(accessToken);
          const now = moment();
          const expiration = moment.unix(exp);
          const expirationInMilliseconds = expiration.diff(now);
          setTimeout(async () => {
            isRunningTimeOutRefreshToken = false;
            try {
              const refresh = getRefreshToken();
              if (refresh) {
                const { data } = await http.post(Endpoints.refreshToken, { refresh });
                storageAccessToken(data.access);
                storageRefreshToken(data.refresh);
                resetTimeOut();
              } else {
                logout();
              }
            } catch (err) {
              logout();
            }
          }, expirationInMilliseconds);
          isRunningTimeOutRefreshToken = true;
        }
      };
      resetTimeOut();
    }
  }, [user]);

  const getHomeMenuItems = () => {
    const cards = [];
    (user?.user_types ?? []).forEach(userType => {
      switch (userType) {
        case Enums.profileNameToId.ADMIN:
          cards.push({
            title: 'Admin',
            icon: <RiAdminFill />,
            // text: 'Módulo responsável por gerenciamento dos dados administrativos e conta',
            link: '/admin/home',
          });
          break;
        case Enums.profileNameToId.FINANCE:
          cards.push({
            title: 'Financeiro',
            icon: <BiDollar />,
            // text: 'Registre e consulte operações financeiras, gere relatórios e previsibilidade de receita',
            link: '/financeiro/home',
          });
          break;
        case Enums.profileNameToId.CONCIERGE:
          cards.push({
            title: 'Concierge',
            icon: <BiSolidUserBadge />,
            // text: 'Registre os clientes que estão chegando na clínica dentro do sistema',
            link: '/concierge/home',
          });
          break;
        case Enums.profileNameToId.STOCK:
          cards.push({
            title: 'Estoque',
            icon: <FaWarehouse />,
            // text: 'Registre os clientes que estão chegando na clínica dentro do sistema',
            link: '/estoque/home',
          });
          break;
        case Enums.profileNameToId.DOCTOR:
          cards.push({
            title: 'Atendimento',
            icon: <FaUserNurse />,
            // text: 'Registre os clientes que estão chegando na clínica dentro do sistema',
            link: '/atendimento/home',
          });
          break;
        case Enums.profileNameToId.SERVICE:
          if (!user.user_types.includes(Enums.profileNameToId.DOCTOR)) {
            cards.push({
              title: 'Atendimento',
              icon: <FaUserNurse />,
              // text: 'Registre os clientes que estão chegando na clínica dentro do sistema',
              link: '/atendimento/home',
            });
          }
          break;
        default:
          break;
      }
    });
    cards.sort((a, b) => a.title.localeCompare(b.title));
    return cards;
  };

  return (
    <AuthContext.Provider
      value={{ loadingUser, setLoadingUser, user, setUser, logout, getAccessToken, getRefreshToken, getHomeMenuItems }}
    >
      {children}
    </AuthContext.Provider>
  );
};
