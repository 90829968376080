import yup from 'shared/validator.js';

let protocolSchema = yup.object({
  protocols_name: yup.string().required('Nome de protoolo é um campo obrigatório'),
  clinic: yup.number().required('Clinica é um campo obrigatório'),
  only_doctor: yup.bool().required(),
});

let procedureSchema = yup.object({
  name: yup.string().required('Nome é um campo obrigatório'),
  cost: yup.string().required('Custo é um campo obrigatório'),
  value: yup.number().required('Valor é um campo obrigatório'),
});

export const validateProcedures = async data => {
  return await procedureSchema.validate(data);
};

export const validateProtocol = async data => {
  return await protocolSchema.validate(data);
};
