import React, { useState, useEffect, useContext } from 'react';
import Input from 'components/Input/Input';
import { Grid } from '@mui/material';
import Select from 'components/Select/Select';
import styles from './Formulario.module.scss';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import { ChartOfAccountsRepository } from 'data/chartofaccounts';
import { BankAccountsRepository } from 'data/bankaccounts';
import { LoaderContext } from 'contexts/loader';
import { ClinicIdContext } from 'contexts/clinicId';

const typeSelect = [
  { id: '1', value: 'income', name: 'Receita' },
  { id: '2', value: 'expense', name: 'Despesa' },
];

function Formulario({ handleChange, formData, handleCategory, type }) {
  const { clinicId } = useContext(ClinicIdContext);
  const { setLoading } = useContext(LoaderContext);
  const [bank_account, setBankAccount] = useState([]);
  const [chartAccount, setChartAccount] = useState([]);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const response = await ChartOfAccountsRepository.list({ type: type, clinic: clinicId });
      setChartAccount(response.results);
      setLoading(false);
    };
    if (clinicId != null) {
      run();
    }
  }, [type, clinicId]);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const response = await BankAccountsRepository.list({ transaction: 1, clinic: clinicId });
      setBankAccount(response.results);
      setLoading(false);
    };
    if (clinicId != null) {
      run();
    }
  }, [clinicId]);

  return (
    <Grid container>
      <Grid container spacing={1} className={styles.supGrid}>
        <Grid item xs={3}>
          <Select
            name="bank_account"
            label="Origem"
            value={formData.bank_account}
            options={bank_account.map(item => ({ value: item.id, name: item.banks_name }))}
            onChange={e => handleChange({ name: e.target.name, value: e.target.value })}
          />
        </Grid>
        <Grid item xs={3}>
          <Input
            name="description"
            label="Descrição"
            value={formData.description}
            onChange={e => handleChange({ name: e.target.name, value: e.target.value })}
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            name="type"
            label="Categoria"
            value={type}
            options={typeSelect}
            onChange={e => handleCategory({ name: e.target.name, value: e.target.value })}
          />
        </Grid>
        <Grid item xs={3}>
          <div className={styles.fluxo_caixa_autocomplete_container}>
            <Autocomplete
              name="chart_of_accounts"
              label="Plano de Contas"
              value={chartAccount
                .map(item => ({ value: item.id, label: item.name }))
                .find(item => item.value === formData.chart_of_accounts)}
              options={chartAccount.map(item => ({ value: item.id, label: item.name }))}
              onChange={(_, item) => handleChange({ name: 'chart_of_accounts', value: item.value })}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Formulario;
