import React from 'react';
import styles from './AdminHome.module.scss';
import CardHome from 'pages/shared/Home/CardHome/CardHome';
import { ReactComponent as ColaboradoresSVG } from 'assets/ico/colaboradores.svg';
import { ReactComponent as ConsultorioSVG } from 'assets/ico/consultorio.svg';
// import { ReactComponent as DocumentosSVG } from '../../assets/ico/documentos.svg';
import { ReactComponent as MedicamentosSVG } from 'assets/ico/medicamentos.svg';
import { HiPlus, HiOutlineMenu } from 'react-icons/hi';
import { PiScroll } from "react-icons/pi";

function AdminHome() {

  return (
    <div className={styles.container}>
      <div className={styles.conteudo}>
        <CardHome
          icon={<ColaboradoresSVG />}
          title="Colaboradores"
          buttons={[
            {
              label: 'Adicionar',
              icon: <HiPlus />,
              link: '/admin/colaboradores/novo',
              variant: 'contained',
              color: 'secondary',
            },
            {
              label: 'Acessar',
              icon: <HiOutlineMenu />,
              link: '/admin/colaboradores',
              variant: 'outlined',
              color: 'secondary',
            },
          ]}
        ></CardHome>
        <CardHome
          icon={<ConsultorioSVG />}
          title="Consultórios"
          buttons={[
            {
              label: 'Adicionar',
              icon: <HiPlus />,
              link: '/admin/consultorio/novo',
              variant: 'contained',
              color: 'secondary',
            },
            {
              label: 'Acessar',
              icon: <HiOutlineMenu />,
              link: '/admin/consultorio',
              variant: 'outlined',
              color: 'secondary',
            },
          ]}
        ></CardHome>
        {/* <div className={styles.card}>
          <CardHome
            icon={<DocumentosSVG />}
            title="Documentos"
            buttons={[
              {
                label: 'Acessar',
                link: '/admin/documentos',
                variant: 'contained',
                color: 'secondary',
              },
            ]}
          >
          </CardHome>
        </div> */}
        <CardHome
          icon={<MedicamentosSVG />}
          title="Medicamentos"
          buttons={[
            {
              label: 'Acessar',
              link: '/admin/medicamentos',
              variant: 'contained',
              color: 'secondary',
            },
          ]}
        ></CardHome>
        <CardHome
          icon={<ColaboradoresSVG />}
          title="Profissionais"
          buttons={[
            {
              label: 'Adicionar',
              icon: <HiPlus />,
              link: '/admin/profissionais/novo',
              variant: 'contained',
              color: 'secondary',
            },
            {
              label: 'Acessar',
              icon: <HiOutlineMenu />,
              link: '/admin/profissionais',
              variant: 'outlined',
              color: 'secondary',
            },
          ]}
        ></CardHome>
        <CardHome
          icon={<PiScroll />}
          title="Templates Atendimento"
          buttons={[
            {
              label: 'Adicionar',
              icon: <HiPlus />,
              link: '/admin/templates/novo',
              variant: 'contained',
              color: 'secondary',
            },
            {
              label: 'Acessar',
              icon: <HiOutlineMenu />,
              link: '/admin/templates',
              variant: 'outlined',
              color: 'secondary',
            },
          ]}
        ></CardHome>
      </div>
    </div>
  );
}

export default AdminHome;
