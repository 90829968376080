import Endpoints from '../config/endpoints';
import http from '../config/httpClient';
import { stringifyFilters } from '../shared/utility';
import { parseError } from './base';

export const MedicinesRepository = {
  baseUrl: Endpoints.medicines,

  post: async function (payload) {
    try {
      const { data } = await http.post(`${this.baseUrl}`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  list: async function (filters) {
    try {
      const queryString = stringifyFilters({ ...filters });
      const { data } = await http.get(`${this.baseUrl}${queryString}`);

      return data;
    } catch (err) {
      return parseError(err);
    }
  },
};
