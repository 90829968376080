import * as yup from 'yup';

const userDataValidation = yup.object({
  first_name: yup
    .string()
    .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]{3,}$/, 'Nome deve conter 3 ou mais letras e não pode conter números.')
    .required(),
  last_name: yup
    .string()
    .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]{3,}$/, 'Sobrenome deve conter 3 ou mais letras e não pode conter números.')
    .required(),
  email: yup.string().email('Formato de e-mail inválido!').required(),
  phone_number: yup.string(),
  reimbursement_right: yup.boolean(),
  is_partner: yup.boolean(),
  photo: yup.mixed().nullable(),
});

const userProfileValidation = yup.object({
  user_types: yup.array().min(1).required('Selecione um ou mais perfis.'),
  doctor: yup.object().when('user_types', {
    is: userTypes => Array.isArray(userTypes) && userTypes.includes(1),
    then: () =>
      yup.object({
        crm: yup.string().matches(/^\d{6}$/, 'CRM deve ter 6 dígitos.'),
      }),
    otherwise: () => yup.object().nullable(),
  }),
  // finance: yup.boolean().required(),
  services: yup.object().when('user_types', {
    is: userTypes => Array.isArray(userTypes) && userTypes.includes(7),
    then: () =>
      yup.object({
        service_type: yup.number().required('Selecione um tipo de serviço.'),
      }),
    otherwise: () => yup.object().nullable(),
  }),
});

const userClinicValidation = yup.object({
  clinics: yup.array().min(1).required('Selecione um ou mais consultórios.'),
});

export const validateUserData = async data => {
  return await userDataValidation.validate(data, { abortEarly: false });
};

export const validateUserProfile = async data => {
  return await userProfileValidation.validate(data, { abortEarly: false });
};

export const validateUserClinics = async data => {
  return await userClinicValidation.validate(data, { abortEarly: false });
};
