// scss
import styles from './schedule.module.scss';

// components
import WorkingSchedule from './WorkingSchedule/WorkingSchedule';

export default function AddUserSchedule({ props }) {
  const { form, setForm, clinics } = props;

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Horários</h2>
      <div className={styles.days}>
        <WorkingSchedule
          clinicsFromFormUser={clinics
            .filter(clinic => form.clinics.includes(clinic.id))
            .map(({ id, name }) => ({ id, name }))}
          defaultWorkingHours={form.schedule}
          onChange={hours =>
            setForm(prevState => ({
              ...prevState,
              schedule: hours,
            }))
          }
        />
      </div>
    </div>
  );
}
