import React, { useContext } from 'react';
import styles from './NavigationBar.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';
import { NavBarContext } from 'contexts/navBar';
import HomeModulesButton from './common/HomeModulesButton';
import { AuthContext } from 'contexts/auth';
import { navItems } from './NavigationBarItems';
import NotificationCount from './NotificationCount';

function NavigationBar({ navTab }) {
  const { getHomeMenuItems } = useContext(AuthContext);
  const { navOpen, setNavOpen } = useContext(NavBarContext);
  const navigate = useNavigate();
  const menuItems = getHomeMenuItems();

  const combinedItems = { ...navItems, default: menuItems };
  const selectedTab = combinedItems[navTab] || [];

  return (
    <div className={`${styles.container} ${navOpen === true ? styles.containerAlt : ''}`}>
      {navTab !== 'login' && <HomeModulesButton navTab={navTab} navOpen={navOpen} />}
      <div className={styles.items}>
        {/* {navTab === 'login' && loginCaseNavigationBar()} */}
        {selectedTab.map((object, index) => {
          const hasChildren = object['children'] != null;

          return (
            <React.Fragment key={`itemFragment-${object.title}-${index}`}>
              <div
                key={`itemChild-${object.title}-${index}`}
                className={object.disabled ? styles.disabled : styles.itemChild}
              >
                <div
                  key={`child-div-${object.title}-${index}`}
                  className={styles.links}
                  onClick={() => object.link && navigate(object.link)}
                >
                  <div key={`child-icon-${object.title}-${index}`}>{object.icon}</div>
                  <span>{object.title}</span>
                  <NotificationCount object={object}/>
                </div>
                {hasChildren &&
                  object.children?.map((childrenObject, childrenIndex) => {
                    return (
                      <Link
                        key={`child-link-${childrenObject.title}-${childrenIndex}`}
                        className={styles.childItems}
                        to={childrenObject.link}
                      >
                        <div key={`child-icon-${childrenObject.title}-${childrenIndex}`}>{childrenObject.icon}</div>
                        <span>{childrenObject.title}</span>
                      </Link>
                    );
                  })}
              </div>
            </React.Fragment>
          );
        })}
      </div>
      {navTab !== 'login' && (
        <div className={styles.arrow}>
          <button className={styles.links} onClick={() => setNavOpen(!navOpen)}>
            <MdKeyboardDoubleArrowRight />
          </button>
        </div>
      )}
    </div>
  );
}

export default NavigationBar;
