import yup from 'shared/validator.js';

let planAccountSchema = yup.object({
  clinic: yup.number().required(),
  type: yup.string().required(),
  parent: yup.number().nullable(),
  name: yup.string().max(100).required(),
  isForSchedule: yup.bool(),
  pastOrFutureLaunch: yup.bool(),
});

export const validatePlanAccountList = async data => {
  return await planAccountSchema.validate(data);
};
