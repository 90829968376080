import React from "react";
import styles from './Pastas.module.scss'

function Pastas() {

    return (
        <div className={styles.container}>
            <div className={styles.conteudo}>
                Pastas
            </div>
        </div>
    )

}

export default Pastas