import { useHandlers } from '../hooks/handlers';

// scss
import styles from './Modal.module.scss';

// components
import PageTitle from 'components/PageTitle/PageTitle';
import Table from 'components/Table/Table';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import Input from 'components/Input/Input';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import { formatCurrency } from 'shared/utility';
import { MdOutlineDeleteOutline } from 'react-icons/md';

export default function ModalSolicitarInsumo({
  modalState,
  setModalState,
  orderState,
  setOrderState,
  fornecedores,
  catalogo,
  user,
  clinic,
  loading,
  setLoading,
}) {
  const {
    selectedInsumo,
    setSelectedInsumo,
    selectedBudget,
    setSelectedBudget,
    statusModal,
    setStatusModal,
    listInsumos,
    listBudgets,
    handleOrderRequest,
    handleAddItem,
    handleAddSupplier,
    handleDeleteItem,
    handleDeleteSupplier,
    errorMessageInsumo,
    errorMessageBudget,
  } = useHandlers(modalState, orderState, catalogo, fornecedores, user, clinic, setLoading);

  // const handleFileChange = e => {
  //   const file = e.target.files[0];
  //   setSelectedBudget(prevState => ({ ...prevState, budget_file: file }));
  // };

  return (
    <Modal
      isOpen={modalState.open}
      setModalOpen={() => {
        setOrderState({ id: null, items: [] });
        setModalState({ open: false, is_edit: false });
      }}
    >
      <PageTitle>{modalState.is_edit ? 'Editar Solicitação de Compra' : 'Solicitar Compra'}</PageTitle>
      <div className={styles.modalContainer}>
        <div>
          <div align="center"><b>Insumos necessários</b></div>
          <div className={styles.solicitar_compra_modal_row_container}>
            <div className={styles.solicitar_compra_modal_autocomplete_container}>
              <Autocomplete
                name="items"
                label="Insumo"
                value={catalogo
                  .map(item => ({ value: item.id, label: item.name }))
                  .find(item => item.value === selectedInsumo.id)}
                options={catalogo.sort((a, b) => a.name - b.name).map(item => ({ value: item.id, label: item.name }))}
                onChange={(_, item) => {
                  setSelectedInsumo(prevState => ({ ...prevState, id: item != null ? item.value : '' }));
                }}
              />
            </div>
            <Input
              className={styles.solicitar_insumo_modal_input}
              label="Quantidade"
              min={0}
              max={999}
              value={selectedInsumo.amount || 0}
              onChange={event => {
                const inputValue = event.target.value;
                const numericValue = parseInt(inputValue, 10);
                const limitedValue = Math.min(numericValue, 999);

                setSelectedInsumo(prevState => ({
                  ...prevState,
                  amount: limitedValue,
                }));
              }}
            />
            <Button
              label="+"
              className={styles.solicitar_compra_modal_add_button}
              onClick={handleAddItem}
              disabled={!selectedInsumo.id || !selectedInsumo.amount}
            />
          </div>
          <div className={styles.errorMessage}>
            <p>{errorMessageInsumo}</p>
          </div>
          <Table
            columns={['Insumos', 'Quantidade', '']}
            lines={listInsumos.map(data => [
              data.name,
              data.amount,
              <MdOutlineDeleteOutline
                color="#A31912" 
                style={{ cursor: 'pointer' }} 
                onClick={() => handleDeleteItem(data.item_id)} 
              />,
            ])}
          />
        </div>
        {listInsumos.length > 0 && (
          <div>
            <div align="center"><b>Orçamentos dos fornecedores</b></div>
            <div className={styles.solicitar_compra_modal_row_container}>
              <div className={styles.solicitar_compra_modal_autocomplete_container}>
                <Autocomplete
                  name="suppliers"
                  label="Fornecedores"
                  value={fornecedores
                    .map(item => ({ value: item.id, label: item.name }))
                    .find(item => item.value === selectedBudget.id)}
                  options={fornecedores
                    .sort((a, b) => a.company_name - b.company_name)
                    .map(item => ({ value: item.id, label: item.company_name }))}
                  onChange={(_, item) =>
                    setSelectedBudget(prevState => ({ ...prevState, id: item != null ? item.value : '' }))
                  }
                />
              </div>
              <Input
                numericInput
                name="value"
                value={selectedBudget.value || 0}
                className={styles.solicitar_insumo_modal_input}
                label="Valor"
                onChange={(_, value) => {
                  setSelectedBudget(prevState => ({
                    ...prevState,
                    value: value,
                  }));
                }}
              />
              {/* <Input className={styles.solicitar_insumo_modal_file_input} type="file" onChange={handleFileChange} /> */}
              <Button
                label="+"
                className={styles.solicitar_compra_modal_add_button}
                onClick={handleAddSupplier}
                disabled={!selectedBudget.id || !selectedBudget.value}
              />
            </div>
            <div className={styles.errorMessage}>
              <p>{errorMessageBudget}</p>
            </div>
            <Table
              columns={['Fornecedor', 'Valor', '']}
              lines={listBudgets.map(data => [
                data.company_name,
                formatCurrency(data.value),
                <MdOutlineDeleteOutline
                  color="#A31912"
                  style={{ cursor: 'pointer' }} 
                  onClick={() => handleDeleteSupplier(data.id)} 
                />,
              ])}
            />
          </div>
        )}
      </div>
      <div className={styles.solicitar_insumo_footer_buttons}>
        <ButtonVoltar
          onClick={() => {
            setOrderState({ id: null, items: [] });
            setModalState({ open: false, is_edit: false });
          }}
        />
        <Button
          label={modalState.is_edit ? 'Salvar' : 'Solicitar'}
          onClick={handleOrderRequest}
          disabled={listInsumos.length === 0 || listBudgets.length === 0 || loading}
        />
      </div>
      <Modal
        isOpen={statusModal.open}
        setModalOpen={() => {
          setStatusModal({ ...statusModal, open: false });
          window.location.reload();
        }}
        className={styles.modal_container}
      >
        {statusModal.error && !statusModal.success && (
          <div className={styles.modal_inner_container_error}>
            <h1>Erro!</h1>
            <span>{statusModal.error}</span>
          </div>
        )}
      </Modal>
    </Modal>
  );
}
