import { stringifyFilters } from 'shared/utility';
import Endpoints from '../config/endpoints';
import http from '../config/httpClient';
import { parseError } from './base';

export const ProceduresConciergeRepository = {
  baseUrl: Endpoints.procedures_concierge,

  list: async function (filters = {}) {
    try {
      const queryString = stringifyFilters(filters);
      const { data } = await http.get(`${this.baseUrl}${queryString}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
};
