// hooks
import { useState, useEffect, useContext } from 'react';

// contexts
import { ClinicIdContext } from 'contexts/clinicId';
import { LoaderContext } from 'contexts/loader';

// other
import { SchedulesRepository } from 'data/schedules';
import { AuthContext } from 'contexts/auth';

export default function useApi() {
  const { user } = useContext(AuthContext);
  const { clinicId } = useContext(ClinicIdContext);
  const { setLoading } = useContext(LoaderContext);

  // "events" representa todos os horários agendados. se existe algo
  // na agenda, chamamos de "event".
  const [defaultEvents, setDefaultEvents] = useState([]);

  async function fetchData() {
    setLoading(true);
    const schedulesResponse = await SchedulesRepository.list({ 
      clinic: clinicId, 
      user: user.id,
      status_not: 'canceled',
    });
    if (schedulesResponse && schedulesResponse.results) {
      setDefaultEvents(schedulesResponse.results);
    }
    setLoading(false);
  }

  // chama a função "fetchData" todas as vezes que o estado de clinicId
  // for alterado.
  useEffect(() => {
    if (clinicId != null && user.id != null) {
      fetchData();
    }
  }, [clinicId, user]);

  return {
    defaultEvents,
  };
}
