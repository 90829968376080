import Endpoints from '../config/endpoints';
import http from '../config/httpClient';
import { parseError } from './base';

export const ProceduresRepository = {
  baseUrl: Endpoints.protocolsProcedures,

  list: async function (id) {
    try {
      const { data } = await http.get(`${this.baseUrl(id)}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  post: async function (protocol, payload) {
    try {
      const { data } = await http.post(`${this.baseUrl(protocol)}`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  update: async function (protocol, procedure, payload) {
    try {
      const { data } = await http.patch(`${this.baseUrl(protocol)}/${procedure}`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  delete: async function (protocol, procedure) {
    try {
      const { data } = await http.delete(`${this.baseUrl(protocol)}/${procedure}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  
  getCatalog: async function () {
    try {
      const { data } = await http.get(`/v1/stock-items/`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
};
