import React, { useState, useEffect, useContext } from 'react';
import styles from './ListaProtocolos.module.scss';
import Button from 'components/Button/Button.js';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import { ProtocolsRepository } from 'data/protocols';
import { LoaderContext } from 'contexts/loader';
import { useToast } from 'hooks/useToast';
import { ClinicIdContext } from 'contexts/clinicId';
import { Link } from 'react-router-dom';
import Table from 'components/Table/Table';
import Modal from 'components/Modal/Modal';
import { TbEdit } from 'react-icons/tb';
import errorIcon from 'assets/ico/error_icon.svg';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import confirmedIcon from 'assets/ico/confirmedIcon.svg';
import PageTitle from 'components/PageTitle/PageTitle';
import Container from 'components/Container/Container';
import TableFooter from 'components/TableFooter/TableFooter';

const initialModalState = {
  isOpen: false,
  success: false,
  pending: false,
  error: false,
  id: '',
  msg: '',
};

export default function ListaProtocolos() {
  const toast = useToast();
  const { clinicId } = useContext(ClinicIdContext);
  const { setLoading } = useContext(LoaderContext);
  const [protocolsList, setProtocolsList] = useState([]);
  const [modalState, setModalState] = useState(initialModalState);

  const loadProtocols = async () => {
    setLoading(true);
    const response = await ProtocolsRepository.list(clinicId);
    if (response?.error) {
      toast.error(response.error);
    } else {
      setProtocolsList(response.results);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (clinicId != null) {
      loadProtocols();
    }
  }, [clinicId]); //eslint-disable-line

  const deleteRecord = async id => {
    setLoading(true);
    const response = await ProtocolsRepository.delete(id);
    setLoading(false);
    if (response?.error) {
      setModalState({
        isOpen: true,
        error: true,
        msg: response.error,
      });
    } else {
      setProtocolsList(protocolsList.filter(item => item.id !== id));
      setModalState({
        isOpen: true,
        success: true,
      });
    }
  };

  const handleDelete = id => {
    deleteRecord(id);
  };

  const editButton = item => (
    <div className={styles.editButton}>
      <div>
        <Link to={`/financeiro/protocolos/editar/${item.id}`}>
          <TbEdit className={styles.pen} color="#C5A500" title="Editar Protocolo" />
        </Link>
      </div>
      <div>
        <MdOutlineDeleteOutline
          color="#A31912"
          size="22px"
          onClick={() => {
            setModalState({ isOpen: true, id: item.id, pending: true });
          }}
        />
      </div>
    </div>
  );

  const onClose = () => {
    setModalState(initialModalState);
  };

  return (
    <Container className={styles.container}>
      <PageTitle>Precificação de Protocolos</PageTitle>
      <div className={styles.lista}>
        <div className={styles.table}>
          <Table
            tableHeight="40vh"
            columns={['Protocolos', 'Ações']}
            lines={protocolsList.map(item => [item.protocols_name, editButton(item)])}
          />
        </div>
        <TableFooter>
          <ButtonVoltar link="/financeiro/home" />
          <Button label="Adicionar Protocolo" link="/financeiro/protocolos/novo" color="secondary" />
        </TableFooter>
        <Modal isOpen={modalState.isOpen && modalState.pending} setModalOpen={onClose} className={styles.modal_container}>
          {modalState.pending ? (
            <div className={styles.modal_inner_container}>
              <p>Tem certeza que deseja excluir este protocolo?</p>
              <Button color="secondary" label="Confirmar" onClick={() => handleDelete(modalState.id)} />
            </div>
          ) : (
            <></>
          )}
        </Modal>
        <Modal isOpen={modalState.isOpen && modalState.success} setModalOpen={onClose} className={styles.modal_container}>
          {modalState.success ? (
            <div className={styles.modal_inner_container}>
              <img src={confirmedIcon} alt=""></img>
              <span>O protocolo foi excluido</span>
            </div>
          ) : (
            <></>
          )}
        </Modal>
        <Modal isOpen={modalState.isOpen && modalState.error} setModalOpen={onClose} className={styles.modal_container}>
          {modalState.error ? (
            <div className={styles.modal_inner_container}>
              <img src={errorIcon} alt=""></img>
              <span>{modalState.msg}</span>
            </div>
          ) : (
            <></>
          )}
        </Modal>
      </div>
    </Container>
  );
}
