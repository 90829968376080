import * as React from 'react';
import styles from './TextArea.module.scss';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';

export default function TextArea({ minRows, placeholder, onChange, value, ...props }) {
  return (
    <TextareaAutosize
      className={styles.TextArea}
      minRows={minRows}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      {...props}
    />
  );
}
