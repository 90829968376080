// hooks
import { useState, useEffect, useContext } from 'react';
import { useToast } from 'hooks/useToast';

// contexts
import { ClinicIdContext } from 'contexts/clinicId';

// other
import { BankTransactionsRepository } from 'data/banktransactions';
import { clinicalStorage } from 'shared/storage';
import { BankAccountsRepository } from 'data/bankaccounts';
import moment from 'moment';

export default function useFluxoCaixa(setLoading, filters) {
  const [consultorioList, setConsultorioList] = useState([]);
  const [dataBanks, setDataBanks] = useState([]);
  const [totalTransacoes, setTotalTransacoes] = useState({});
  const [transactionsList, setTransactionsList] = useState([]);
  const [modal, setModal] = useState({ open: false, id: '' });

  const toast = useToast();
  const { clinicId } = useContext(ClinicIdContext);

  const fetchData = async () => {
    setLoading(true);
    const banksResponse = await BankAccountsRepository.list({ clinic: clinicId });

    if (banksResponse && banksResponse.results) {
      setDataBanks(banksResponse.results);
      setLoading(false);
    } else setLoading(false);
  };

  const fetchDataOnScroll = async filters => {
    setLoading(true);
    const banktransactionsResponse = await BankTransactionsRepository.list({
      ...filters,
      transaction_date_range_after: moment(filters.transaction_date_range_after).format('YYYY-MM-DD HH:mm'),
      transaction_date_range_before: moment(filters.transaction_date_range_before).format('YYYY-MM-DD HH:mm'),
    });

    if (banktransactionsResponse && banktransactionsResponse.results) {
      const newTransactionList = filters.offset > 0 ? transactionsList : [];
      setTransactionsList([...newTransactionList, ...banktransactionsResponse.results]);
      setTotalTransacoes(banktransactionsResponse);

      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleDelete = async id => {
    setLoading(true);
    const response = await BankTransactionsRepository.delete(id);
    setLoading(false);
    if (response?.error) {
      toast.error(response.error);
    } else {
      setModal({ open: false, id: '' });
      fetchDataOnScroll(filters);
    }
  };

  const handleExportButtonClick = async () => {
    try {
      const bankId = filters.banks ?? '';
      const exportedData = await BankTransactionsRepository.export(bankId);

      const blob = new Blob([exportedData], { type: 'application/csv' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'exported_data.csv';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

  useEffect(() => {
    fetchDataOnScroll(filters);
  }, [filters]);

  useEffect(() => {
    if (clinicId != null) {
      fetchData();
    }
    setConsultorioList(JSON.parse(clinicalStorage()));
  }, [clinicId]);

  return {
    // getters
    consultorioList,
    dataBanks,
    totalTransacoes,
    transactionsList,
    modal,

    // setters
    setModal,
    setTotalTransacoes,

    // handlers
    handleDelete,
    handleExportButtonClick,

    // onSetFilterList,
  };
}
