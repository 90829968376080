import React from 'react';
import styles from './Documentos.module.scss';
import { Link } from 'react-router-dom';
import Pastas from './Pastas/Pastas';
import Notas from './Notas/Notas';
import PageTitle from 'components/PageTitle/PageTitle';
import Container from 'components/Container/Container';

function Documentos({ tab }) {
  const contents = {
    pastas: <Pastas />,
    notas: <Notas />,
  };

  return (
    <Container>
      <PageTitle>Documentos</PageTitle>
      <div className={styles.tab}>
        <Link to="/admin/documentos">
          <span className={tab === 'pastas' ? styles.elementActive : styles.element}>Pastas</span>
        </Link>
        <Link to="/admin/documentos/notas">
          <span className={tab === 'notas' ? styles.elementActive : styles.element}>Notas Fiscais</span>
        </Link>
      </div>
      <div>{contents[tab]}</div>
    </Container>
  );
}

export default Documentos;
