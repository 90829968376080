import React, { useState, useEffect, useContext } from 'react';
import Table from 'components/Table/Table';
import Select from 'components/Select/Select';
import { LoaderContext } from 'contexts/loader';
import styles from './Reembolso.module.scss';
import { CashierRepository } from 'data/cashier';
import { Grid } from '@mui/material';
import { useToast } from 'hooks/useToast';
import { UserRepository } from 'data/users';
import Input from 'components/Input/Input';
import { ClinicIdContext } from 'contexts/clinicId';
import moment from 'moment';
import InputDatePickerRange from 'components/InputDatePickerRange/InputDatePickerRange';
import Modal from 'components/Modal/Modal';
import { handleSelectOneFile } from 'shared/utility';
import { apiBadRequestError } from 'exceptions/api';
import { IoMdDownload } from 'react-icons/io';
import Button from 'components/Button/Button';
import TableFooter from 'components/TableFooter/TableFooter';
import { validateCaixa } from '../validator';
import { MdPaid } from 'react-icons/md';
import { MdFileUpload } from 'react-icons/md';
import { Button as MuiButton } from '@mui/material';

const initialFormData = {
  description: '',
  user: '',
  value: '',
};

function Reembolso({ user_type }) {
  const { setLoading } = useContext(LoaderContext);
  const { clinicId } = useContext(ClinicIdContext);
  const [openModal, setOpenModal] = useState(false);
  const toast = useToast();
  const [reimbursementList, setReimbursementList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [formData, setFormData] = useState(initialFormData);
  const [inputValue, setInputValue] = useState('');
  const [fileUploaded, setFileUploaded] = useState('');
  
  const now = new Date();
  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const lastDayofMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  const [filters, setFilters] = useState({
    period_after: moment(firstDayOfMonth).format('YYYY-MM-DD'), 
    period_before: moment(lastDayofMonth).format('YYYY-MM-DD'),
    user: '',
  });

  const loadReimbursement = async () => {
    setLoading(true);
    const response = await CashierRepository.list({ 
      ...filters, 
      period_before: moment(filters.period_before).add(1, 'days').format('YYYY-MM-DD'),
      type: 'reimbursement', 
      clinic: clinicId 
    });
    const responseUser = await UserRepository.list({ limit: 999 });
    setLoading(false);
    const listReimbur = response.results ?? [];
    const listUser = responseUser.results ?? [];
    listReimbur.forEach(item => {
      listUser.forEach(user => {
        if (item.user === user.id) {
          item.name_user = user.first_name;
        }
      });
    });
    setReimbursementList(listReimbur);
    setOpenModal(false);
    setInputValue('');
    setFormData(initialFormData);
  };

  useEffect(() => {
    if (clinicId != null) {
      loadReimbursement();
    }
  }, [filters, clinicId]);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const response = await UserRepository.list({ limit: 999 });
      const listUser = response.results;
      let list = [];
      listUser.forEach(user => {
        if (user.reimbursement_right) {
          list.push(user);
        }
      });
      setUserList(list);
      setLoading(false);
    };
    run();
  }, []);

  const handleChangeFilter = event => {
    setFilters({ ...filters, user: event.value });
  };

  const handleApprove = async (id, typeApp) => {
    setLoading(true);
    const response = await CashierRepository.update(id, { status: typeApp });
    setLoading(false);
    if (response?.error) throw new apiBadRequestError(response.error, response.debug);
    loadReimbursement();
  };

  const editButton = item => (
    <>
      <div className={styles.editButton}>
        <IoMdDownload
          className={styles.approve}
          color="#15ab72"
          title=""
          onClick={() => handleDownloadClick(item.id)}
        />
        {user_type === 'finance' && item.status === 'waiting' && (
          <div className={styles.approvedEdit}>
            <MdPaid
              color="#A31912"
              className={styles.approve}
              title="Aprovar Entrada"
              onClick={() => {
                handleApprove(item.id, 'approved');
              }}
            />
            Não Pago
          </div>
        )}
        {user_type === 'finance' && item.status === 'approved' && (
          <div className={styles.approvedEdit}>
            <MdPaid color="3CB043" />
            Pago
          </div>
        )}
      </div>
    </>
  );

  const handleChange = event => {
    let value = event.value;
    setFormData({
      ...formData,
      [event.name]: value,
      type: 'reimbursement',
    });
  };

  const handleChangeValue = (event, value, maskedValue) => {
    setFormData({ ...formData, value: value });
    setInputValue(maskedValue);
  };

  const handleSubmit = () => {
    try {
      validateCaixa(formData);
    } catch (err) {
      toast.error(err);
    }
    try {
      saveData();
    } catch (err) {
      toast.error(err);
    }
  };

  const handleDateChange = (clearField, newDate) => {
    if (clearField) {
      setFilters(prevState => ({
        ...prevState,
        period_after: '',
        period_before: '',
        offset: 0,
      }));
    } else if (newDate.length >= 2 && newDate[0] !== null && newDate[1] !== null) {
      const [startDate, endDate] = newDate;
      setFilters(prevState => ({
        ...prevState,
        period_after: moment(startDate).format('YYYY-MM-DD'),
        period_before: moment(endDate).format('YYYY-MM-DD'),
        offset: 0,
      }));
    }
  };

  const saveData = async () => {
    setLoading(true);
    const response = await CashierRepository.create({ ...formData, clinic: clinicId, status: 'waiting' });
    setLoading(false);
    if (response?.error) throw new apiBadRequestError(response.error, response.debug);
    return loadReimbursement();
  };

  const handleDownloadClick = async id => {
    const exportedData = await CashierRepository.get(id);
    const urlFile = exportedData.invoice_file;
    if (urlFile === null) {
      toast.error('Não tem um arquivo!');
    } else {
      window.open(urlFile, "_blank")
      // const imageDownload = await fetch(urlFile)
      //   .then(response => response.arrayBuffer())
      //   .then(buffer => new Blob([buffer], { type: 'image/*' }));

      // const link = document.createElement('a');
      // link.href = URL.createObjectURL(imageDownload);
      // link.download = 'downloaded_image.jpg';

      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
    }
  };

  const handleUpload = (fieldName, event) => {
    setFormData({ ...formData, [fieldName]: event });
    setFileUploaded(event.name);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputDatePickerRange
            label="Período"
            value={[
              new Date(filters.period_after+'T12:00:00Z'), 
              new Date(filters.period_before+'T12:00:00Z')
            ]}
            onChange={newDate => handleDateChange(false, newDate)}
            className={styles.dateInput}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Colaborador"
            value={filters.user}
            options={userList ? userList.map(item => ({ value: item.id, name: item.first_name })) : []}
            onChange={e => handleChangeFilter({ name: e.target.name, value: e.target.value })}
          />
        </Grid>
      </Grid>
      <div>
        <Table
          columns={['Data', 'Descrição', 'Valor', 'Reembolso', 'Recibo']}
          lines={reimbursementList.map(item => [
            moment(item.created_at).format('DD/MM/YYYY'),
            item.description,
            item.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
            item.name_user,
            editButton(item),
          ])}
        />
      </div>
      {user_type === 'concierge' && (
        <TableFooter>
          <Button color="secondary" label="Lançar solicitação de reembolso" onClick={() => setOpenModal(true)} />
        </TableFooter>
      )}
      <Modal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)} className={styles.modal}>
        <div className={styles.modal}>
          <h1>Solicitação de Reembolso</h1>
          <Grid container spacing={2} className={styles.grid}>
            <Grid item xs={4}>
              <Select
                label="Colaborador"
                options={userList.map(item => ({ name: item.first_name, value: item.id }))}
                value={formData.user}
                onChange={e => handleChange({ name: 'user', value: e.target.value })}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="Descricao"
                value={formData.description}
                onChange={e => handleChange({ name: 'description', value: e.target.value })}
              />
            </Grid>
            <Grid item xs={4}>
              <Input numericInput name="value" label="Valor" value={inputValue} onChange={handleChangeValue} />
            </Grid>
            <Grid item xs={12} className={styles.gridUpload}>
              <MuiButton variant="contained" component="label">
                <MdFileUpload />
                Upload File
                <input
                  hidden
                  onClick={() => {
                    handleSelectOneFile('.jpg, .jpeg, .png', (invoice_file, invoice_file64) => {
                      handleUpload('invoice_file', invoice_file);
                    });
                  }}
                />
              </MuiButton>
              {fileUploaded ? <p>{fileUploaded}</p> : <p>Nenhum arquivo selecionado</p>}
            </Grid>
          </Grid>
          <div className={styles.btnAdd}>
            <Button label="Salvar" onClick={handleSubmit} />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Reembolso;
