import React, { useState } from 'react';
import styles from './RedefinirSenha.module.scss';
import Button from 'components/Button/Button';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import envelope from 'assets/ico/envelope.svg';
import yup from 'shared/validator.js';
import Input from 'components/Input/Input';

function RedefinirSenha() {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const schema = yup.string().email().required();

  const validateEmail = async data => {
    return await schema.validate(data);
  };

  return !success ? (
    <div className={styles.container}>
      <div className={styles.conteudo}>
        <h1>Recuperar Senha</h1>
        <p>Enviaremos uma mensagem para o seu email com as informações necessárias para mudar sua na na plataforma.</p>
        <div className={styles.input}>
          <img src={envelope} alt="" />
          <Input
            type="email"
            placeholder="Email"
            id="email"
            value={email}
            error={error && 'Por favor insira um e-mail válido'}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className={styles.buttons}>
          <ButtonVoltar />
          <Button
            onClick={async () => {
              try {
                await validateEmail(email);
                setError(null);
                setSuccess(true);
              } catch (err) {
                setError(err);
              }
            }}
            label="Recuperar Senha"
            background="#15AB72"
            borderColor="#15AB72"
            fontColor="white"
            widthButton="170px"
          />
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.conteudoModal}>
        <div className={styles.ico}>
          <img src={envelope} alt="" />
        </div>
        <h1>Verifique seu email</h1>
        <p>As orientações foram enviadas para {email}</p>
        <div className={styles.btn}>
          <Button
            label="Ir para login"
            background="#15AB72"
            borderColor="#15AB72"
            fontColor="white"
            widthButton="170px"
            addLink={true}
            link="/login"
          />
        </div>
      </div>
    </div>
  );
}

export default RedefinirSenha;
