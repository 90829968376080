// hooks
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from 'hooks/useToast';

// scss
import styles from './MovimentacaoInterna.module.scss';

// contexts
import { AuthContext } from 'contexts/auth';
import { LoaderContext } from 'contexts/loader';

// components
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import { FaArrowRight } from 'react-icons/fa6';
import { Grid } from '@mui/material';

// other
import { ChartOfAccountsRepository } from 'data/chartofaccounts';
import { BankAccountsRepository } from 'data/bankaccounts';
import { BankTransactionsRepository } from 'data/banktransactions';
import { ClinicsRepository } from 'data/clinics';

const initialForm = {
  origin: {
    bank: '',
    clinic: '',
    chartOfAccounts: '',
  },
  destiny: {
    bank: '',
    clinic: '',
    chartOfAccounts: '',
  },
  date: '',
  value: '',
  description: '',
};

function MovimentacaoInterna() {
  const [chartOfAccountsExpense, setChartOfAccountsExpense] = useState([]);
  const [chartOfAccountsIncome, setChartOfAccountsIncome] = useState([]);
  const [formData, setFormData] = useState(initialForm);
  const [clinicsList, setClinicsList] = useState([]);
  const { setLoading } = useContext(LoaderContext);
  const [banksList, setBanksList] = useState([]);
  const { user } = useContext(AuthContext);
  const [inputValue, setInputValue] = useState('');
  let navigate = useNavigate();
  const toast = useToast();

  // Clinics
  useEffect(() => {
    const userClinics = user?.clinics;
    let ignore = false;
    async function run() {
      setLoading(true);
      const response = await ClinicsRepository.list();
      const clinics = response.results;
      if (ignore) return;
      setLoading(false);
      if (response?.error) {
        toast.error(response.error);
        setClinicsList([]);
      } else
        setClinicsList(
          clinics
            ?.filter(clinic => userClinics?.includes(clinic.id))
            .map(clinic => ({ value: clinic.id, label: clinic.name }))
        );
    }
    run();
    return () => {
      ignore = true;
    };
  }, []);

  // ChartOfAccounts
  useEffect(() => {
    let ignore = false;
    async function run() {
      setLoading(true);
      const response = await ChartOfAccountsRepository.list();
      const chart = response.results;
      const incomes = chart
        ?.filter(chart => chart.type === 'income')
        .map(chart => ({ value: chart.id, label: chart.name, type: chart.type, clinic: chart.clinic }));
      const expenses = chart
        ?.filter(chart => chart.type === 'expense')
        .map(chart => ({ value: chart.id, label: chart.name, type: chart.type, clinic: chart.clinic }));
      if (ignore) return;
      setLoading(false);
      if (response?.error) {
        toast.error(response.error);
      } else {
        setChartOfAccountsExpense(expenses || []);
        setChartOfAccountsIncome(incomes || []);
      }
    }
    run();
    return () => {
      ignore = true;
    };
  }, []);

  // Banks
  useEffect(() => {
    const run = async () => {
      try {
        const response = await BankAccountsRepository.list({ transaction: 1 });
        const banks = response.results;
        if (response?.error == null) {
          const updatedBanksList = banks.map(item => ({
            value: item.id,
            label: item.banks_name,
            clinic: item.clinic,
          }));
          setBanksList(updatedBanksList);
        }
      } catch (error) {
        toast.error(error);
      }
    };
    run();
  }, []);

  // Clears when banks don't belong to the clinic
  useEffect(() => {
    const filterOriginBank = banksList.find(item => item.value === formData?.origin?.bank);
    const filterDestinyBank = banksList.find(item => item.value === formData?.destiny?.bank);
    const clearDifferentClinic = () => {
      if (formData?.origin?.clinic !== filterOriginBank?.clinic) {
        setFormData(prevFormData => ({
          ...prevFormData,
          origin: {
            ...prevFormData.origin,
            bank: '',
          },
        }));
      } else if (formData?.destiny?.clinic !== filterDestinyBank?.clinic) {
        setFormData(prevFormData => ({
          ...prevFormData,
          destiny: {
            ...prevFormData.destiny,
            bank: '',
          },
        }));
      }
    };
    clearDifferentClinic();
  }, [banksList, formData?.origin?.bank, formData?.origin?.clinic, formData?.destiny?.clinic, formData?.destiny?.bank]);

  const handleFormChange = (event, fieldName, selectedItem) => {
    const { name, value } = event.target;
    if (name === 'description') {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [fieldName]: {
          ...prevFormData[fieldName],
          [selectedItem.type]: selectedItem && selectedItem.value,
        },
      }));
    }
  };

  const destinyOptions = banksList.filter(item => item.clinic === formData.destiny?.clinic);
  const originOptions = banksList.filter(item => item.clinic === formData.origin?.clinic);

  const handleDateChange = event => {
    setFormData({ ...formData, date: event.target.value });
  };

  const handleChangeValue = (event, value, maskedValue) => {
    setFormData({ ...formData, value: value });
    setInputValue(maskedValue);
  };

  const saveData = async () => {
    setLoading(true);
    const response = await BankTransactionsRepository.create(formData);
    setLoading(false);
    if (response?.error) {
      toast.error(response.error);
    } else {
      return navigate('/financeiro/fluxo-caixa');
    }
  };

  return (
    <Container>
      <PageTitle>Movimentação Interna</PageTitle>
      <Grid container className={styles.container}>
        <Grid item xs={5.5} className={styles.selectContainer}>
          <Grid container className={styles.selectGroup} spacing={1.5}>
            <Grid item xs={12} textAlign="center">
              <b>Origem</b>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                label="Consultório"
                value={clinicsList.find(bank => bank.value === formData.origin?.clinic)}
                options={clinicsList}
                onChange={(event, item) => handleFormChange(event, 'origin', { value: item?.value, type: 'clinic' })}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                label="Origem"
                name="origin"
                value={originOptions.find(bank => bank.value === formData.origin?.bank)}
                options={
                  formData.destiny.clinic !== formData.origin.clinic
                    ? originOptions
                    : originOptions.filter(item => item.value !== formData.destiny?.bank)
                }
                onChange={(event, item) => handleFormChange(event, 'origin', { value: item?.value, type: 'bank' })}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                label="Plano de Contas"
                value={chartOfAccountsExpense.find(bank => bank.value === formData.origin?.chartOfAccounts)}
                options={chartOfAccountsExpense.filter(ca => ca.clinic === formData.origin?.clinic)}
                onChange={(event, item) =>
                  handleFormChange(event, 'origin', { value: item?.value, type: 'chartOfAccounts' })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <Grid container className={styles.selectGroup} spacing={1.5}>
            <Grid item xs={12} textAlign="center">
              <FaArrowRight />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5.5} className={styles.selectContainer}>
          <Grid container className={styles.selectGroup} spacing={1.5}>
            <Grid item xs={12} textAlign="center">
              <b>Destino</b>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                label="Consultório"
                value={clinicsList.find(bank => bank.value === formData.destiny?.clinic)}
                options={clinicsList}
                onChange={(event, item) => handleFormChange(event, 'destiny', { value: item?.value, type: 'clinic' })}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                label="Destino"
                name="destiny"
                value={destinyOptions.find(bank => bank.value === formData.destiny?.bank)}
                options={
                  formData.destiny.clinic !== formData.origin.clinic
                    ? destinyOptions
                    : destinyOptions.filter(item => item.value !== formData.origin?.bank)
                }
                onChange={(event, item) => handleFormChange(event, 'destiny', { value: item?.value, type: 'bank' })}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                label="Plano de Contas"
                value={chartOfAccountsIncome.find(bank => bank.value === formData.destiny?.chartOfAccounts)}
                options={chartOfAccountsIncome.filter(ca => ca.clinic === formData.destiny?.clinic)}
                onChange={(event, item) =>
                  handleFormChange(event, 'destiny', { value: item?.value, type: 'chartOfAccounts' })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={3}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Input type="date" value={formData.date} onChange={handleDateChange} />
            </Grid>
            <Grid item xs={3}>
              <Input
                label="Valor"
                className={styles.inputValue}
                name="value"
                value={inputValue}
                onChange={handleChangeValue}
                numericInput
                prefix="R$"
              />
            </Grid>
            <Grid item xs={5}>
              <Input label="Descrição" name="description" value={formData.description} onChange={handleFormChange} />
            </Grid>
            <Grid item xs={1}>
              <Button label="Salvar" style={{ width: '100%' }} color="secondary" onClick={saveData} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={2}>
        <ButtonVoltar link="/financeiro/fluxo-caixa" />
      </Grid>
    </Container>
  );
}
export default MovimentacaoInterna;
