// hooks
import { useContext } from 'react';
import useFluxoCaixa from './hooks/useFluxoCaixa';
import useFilters from './hooks/useFilters';

// scss
import styles from './FluxoCaixa.module.scss';

// contexts
import { LoaderContext } from 'contexts/loader';

// components
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import Table from 'components/Table/Table';
import TableFooter from 'components/TableFooter/TableFooter';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import InputDatePickerRange from 'components/InputDatePickerRange/InputDatePickerRange';
import { CiExport } from 'react-icons/ci';
import { MdOutlineDeleteOutline } from 'react-icons/md';

// other
import moment from 'moment';

function FluxoCaixa() {
  const { setLoading } = useContext(LoaderContext);
  const { filters, setFilters, handleDateChange, handleDeleteFilters, onSetFilterList } = useFilters();
  const {
    // getters
    consultorioList,
    dataBanks,
    totalTransacoes,
    transactionsList,
    modal,

    // setters
    setModal,

    // handlers
    handleDelete,
    handleExportButtonClick,
  } = useFluxoCaixa(setLoading, filters);

  return (
    <Container className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          <PageTitle>Fluxo de Caixa</PageTitle>
        </div>
        <div className={styles.calendar}>
          <div className={styles.inputDatePicker}>
            <InputDatePickerRange
              label="Período"
              value={[filters.transaction_date_range_after, filters.transaction_date_range_before]}
              onChange={newDate => handleDateChange(false, newDate)}
              className={styles.dateInput}
            />
            <Button label="Apagar Filtros" color="secondary" onClick={handleDeleteFilters} />
          </div>
          <div className={styles.btn}>
            <Button
              label="Adicionar transações"
              link="/financeiro/fluxo-caixa/add-transacao/"
              color="secondary"
              addLink={true}
            />
            <Button
              label="Adiconar Movimento Interno"
              link="/financeiro/fluxo-caixa/movimentacao-interna"
              color="secondary"
              addLink={true}
            />
          </div>
        </div>
      </div>
      <div className={styles.table}>
        <Table
          tableHeight="40vh"
          columns={[
            'Data',
            {
              title: 'Consultorio',
              type: 'checkbox',
              allowMultipleSelected: false,
              options: consultorioList.map(item => ({ value: item.id, label: item.name })),
              onClick: selectedValue => {
                onSetFilterList('clinic', selectedValue);
              },
            },
            {
              title: 'Origem',
              type: 'checkbox',
              allowMultipleSelected: false,
              options: dataBanks?.map(item => ({
                value: item.id,
                label: (
                  <div className={styles.fluxo_caixa_bank_info}>
                    <p className={styles.fluxo_caixa_bank_name}>{item.banks_name}</p>
                    {item.banks !== 999 && <p className={styles.fluxo_caixa_bank_agency}> Agência: {item.agency}</p>}
                  </div>
                ),
              })),
              onClick: selectedValue => {
                const [value] = selectedValue;
                onSetFilterList('bank_account', value);
              },
            },
            'Descrição',
            'Valor',
            'Ações',
          ]}
          lines={transactionsList?.map(item => [
            moment(item.transaction_date).format('DD/MM/YYYY'),
            item.clinic_name,
            <div className={styles.fluxo_caixa_dados_bancarios}>
              <span>{item.bank_account_info.bank_name}</span>
              <p>Ag - {item.bank_account_info.agency}</p>
              <p>CC - {item.bank_account_info.account}</p>
            </div>,
            item.description,
            item.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
            <MdOutlineDeleteOutline
              className={styles.fluxo_caixa_delete_icon}
              color="#A31912"
              title="Excluir Transação"
              onClick={() => {
                setModal({ open: true, id: item.id });
              }}
            />,
          ])}
          onScrollEnd={() => {
            if (totalTransacoes.next !== null) {
              setFilters(prevState => ({
                ...prevState,
                offset: prevState.offset + prevState.limit,
              }));
            }
          }}
        />
      </div>
      <div className={styles.total}>
        <div className={styles.fluxo_caixa_income_container}>
          <p className={styles.income}>
            Receita:
            <span>{totalTransacoes?.income?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
          </p>
          <p className={styles.expense}>
            Despesa:
            <span>{totalTransacoes?.expense?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
          </p>
          <p className={styles.balance}>
            Saldo:
            <span>{totalTransacoes?.balance?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
          </p>
        </div>
      </div>
      <TableFooter className={styles.footer}>
        <ButtonVoltar link="/financeiro/home" />
        <div className={styles.btnExport}>
          <Button
            label="Exportar"
            icon={<CiExport />}
            color="secondary"
            disabled={transactionsList.length === 0}
            onClick={handleExportButtonClick}
          ></Button>
        </div>
      </TableFooter>
      <Modal isOpen={modal.open} setModalOpen={() => setModal(!modal.open)}>
        <div className={styles.modalDelete}>
          <p>Você tem certeza que deseja apagar essa transação bancária ?</p>
          <Button
            label="Sim, quero apagar"
            color="secondary"
            onClick={() => handleDelete(modal.id)}
            style={{ width: '50%' }}
          />
        </div>
      </Modal>
    </Container>
  );
}

export default FluxoCaixa;
