import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './App/AppRoutes.js';
import { AuthProvider } from 'contexts/auth';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
