import React, { useEffect, useState, useContext } from 'react';
import styles from './ViewFornecedor.module.scss';
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import Card from 'components/Card/Card';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import Table from 'components/Table/Table';
import TableFooter from 'components/TableFooter/TableFooter';
import { useParams } from 'react-router-dom';
import { LoaderContext } from 'contexts/loader';
import Select from 'components/Select/Select';
import { SupplierRepository } from 'data/supplier';
import { cnpjMask } from 'shared/utility';
import { RepositoryPurchaseOrders } from 'data/purchaseorders';
import moment from 'moment';

const initialFornecedor = {
  company_name: '',
  cnpj: '',
  name: '',
  phone_number: '',
  email: '',
};

function ViewFornecedor() {
  const { id } = useParams();
  const { setLoading } = useContext(LoaderContext);
  const [fornecedor, setFornecedor] = useState(initialFornecedor);
  const [list, setList] = useState([]);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const response = await SupplierRepository.get(id);
      setFornecedor(response);
      setLoading(false);
    };
    run();
  }, [id]);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const response = await RepositoryPurchaseOrders.list({ supplier: id, status: 'approved' });
      setList(response.results);
      setLoading(false);
    };
    run();
  }, [id]);

  const botaoVoltar = <ButtonVoltar link="/estoque/fornecedores" />;

  return (
    <Container>
      <PageTitle>Fornecedor</PageTitle>
      <Card className={styles.card}>
        <div>
          <p>Razão Social: {fornecedor.company_name} </p>
          <p>CNPJ: {cnpjMask(fornecedor.cnpj)} </p>
          <p>Nome Contato: {fornecedor.name} </p>
        </div>
        <div>
          <p>Telefone: {fornecedor.phone_number}</p>
          <p>Email: {fornecedor.email}</p>
        </div>
      </Card>
      <div className={styles.opa}>
        <div className={styles.containerFiltro}>
          <p>Histórico de compras</p>
        </div>
        <div className={styles.selectContainer}>
          <Select
            label="Filtro"
            options={[]}
            value={''}
            className={styles.select}
            // onChange={handleChange}
          />
        </div>
      </div>
      <Table
        columns={['Data do pedido', 'Solicitante', 'Valor']}
        lines={list.map(item => [moment(item.request_date).format('DD/MM/YYYY'), item.requester, item.value])}
      />
      <TableFooter>{botaoVoltar}</TableFooter>
    </Container>
  );
}

export default ViewFornecedor;
