// hooks
import { useState, useEffect, useContext } from 'react';

// repositories
import { UserRepository } from 'data/users';
import { ServiceNamesRepository } from 'data/professionals';
import { SpecialtiesRepository } from 'data/specialties';
import { AdminClinicsRepository } from 'data/admin-clinics';
import { UserHoursRepository } from 'data/usersHour';

// contexts
import { LoaderContext } from 'contexts/loader';

// other
import {
  parseUserHoursToFormData,
  buildInitialScheduleStructure,
  transformUserHoursFormToApi,
} from 'transformers/userForm';

export default function useApi(tab, edit, id, form, setForm, setFeedbackModal) {
  const { loading, setLoading } = useContext(LoaderContext);
  const [userResponse, setUserResponse] = useState({});
  const [serviceNames, setServiceNames] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [clinics, setClinics] = useState([]);

  async function fetchData() {
    const serviceNamesResponse = await ServiceNamesRepository.list();
    const specialtiesResponse = await SpecialtiesRepository.list();
    const clinicsResponse = await AdminClinicsRepository.list();

    if (serviceNamesResponse && serviceNamesResponse.results) {
      setServiceNames(serviceNamesResponse.results);
    }

    if (specialtiesResponse && specialtiesResponse.results) {
      setSpecialties(specialtiesResponse.results);
    }

    if (clinicsResponse && clinicsResponse.results) {
      setClinics(clinicsResponse.results);
    }
  }

  async function setUserInfo(userData, userSpecialties, userHours) {
    const updatedForm = {
      data: {
        first_name: userData.first_name || '',
        last_name: userData.last_name || '',
        email: userData.email || '',
        phone_number: userData.phone_number || '',
        reimbursement_right: userData.reimbursement_right ?? false,
        is_partner: userData.is_partner ?? false,
        photo: userData.photo ?? null,
      },
      profile: {
        doctor: userData.doctor ?? null,
        finance: userData.finance || { approver: true },
        services: userData.services ?? null,
      },
      clinics: userData.clinics || [],
      user_types: userData.user_types || [],
      specialties: userSpecialties || [],
      schedule: parseUserHoursToFormData(userHours, buildInitialScheduleStructure()),
    };
    setUserResponse(userData);
    setForm(updatedForm);
  }

  async function loadUserInfo(id) {
    setLoading(true);
    try {
      const response = await UserRepository.getUser(id);
      const userSpecialtiesResponse = await UserRepository.listSpecialties(id);
      const userHoursResponse = await UserHoursRepository.list(id);

      if (
        response &&
        !response.error &&
        userSpecialtiesResponse &&
        userSpecialtiesResponse.results &&
        userHoursResponse &&
        userHoursResponse.results
      ) {
        setUserInfo(response, userSpecialtiesResponse.results, userHoursResponse.results);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  async function saveOnEdit(currentTab) {
    setLoading(true);

    switch (currentTab) {
      case 'data':
        const patchDataResponse = await UserRepository.updateUserData(id, {
          first_name: form.data.first_name ?? '',
          last_name: form.data.last_name ?? '',
          email: form.data.email,
          phone_number: form.data.phone_number ?? '',
          photo: form.data.photo ?? null,
          reimbursement_right: form.data.reimbursement_right,
          is_partner: form.data.is_partner,
        });

        if (patchDataResponse && !patchDataResponse.error) {
          setLoading(false);
          setFeedbackModal(prevState => ({
            ...prevState,
            open: true,
            type: 'success',
            message: 'Dados salvos com sucesso!',
          }));
        } else {
          setLoading(false);
          setFeedbackModal(prevState => ({
            ...prevState,
            open: true,
            type: 'error',
            message: `[${Object.keys(patchDataResponse.debug)[0].toUpperCase()}] ${patchDataResponse.error}`,
          }));
        }
        break;
      case 'profile':
        const patchProfileResponse = await UserRepository.updateUserData(id, {
          user_types: form.user_types,
          service: {
            service_type: form.profile.services.service_type,
          }
        });

        if (patchProfileResponse && !patchProfileResponse.error) {
          setLoading(false);
          setFeedbackModal(prevState => ({
            ...prevState,
            open: true,
            type: 'success',
            message: 'Dados salvos com sucesso!',
          }));
        } else {
          setLoading(false);
          setFeedbackModal(prevState => ({
            ...prevState,
            open: true,
            type: 'error',
            message: `[${Object.keys(patchProfileResponse.debug)[0].toUpperCase()}] ${patchProfileResponse.error}`,
          }));
        }
        break;
      case 'specialties':
        const patchSpecialtiesResponse = await UserRepository.updateDoctorSpecialties(
          id,
          form.specialties
            .filter(item => item.doctor == null)
            .map(item => ({
              doctor: userResponse.doctor.id,
              specialties_type: item.specialties_type,
              rqe: item.rqe,
            }))
        );

        if (patchSpecialtiesResponse && !patchSpecialtiesResponse.error) {
          setLoading(false);
          setFeedbackModal(prevState => ({
            ...prevState,
            open: true,
            type: 'success',
            message: 'Dados salvos com sucesso!',
          }));
        } else {
          setFeedbackModal(prevState => ({
            ...prevState,
            open: true,
            type: 'error',
            message: `[${Object.keys(patchSpecialtiesResponse.debug)[0].toUpperCase()}] ${patchSpecialtiesResponse.error
              }`,
          }));
        }
        break;
      case 'clinic':
        const patchClinicsResponse = await UserRepository.updateUserData(id, { clinics: form.clinics });

        if (patchClinicsResponse && !patchClinicsResponse.error) {
          setLoading(false);
          setFeedbackModal(prevState => ({
            ...prevState,
            open: true,
            type: 'success',
            message: 'Dados salvos com sucesso!',
          }));
        } else {
          setFeedbackModal(prevState => ({
            ...prevState,
            open: true,
            type: 'error',
            message: `[${Object.keys(patchClinicsResponse.debug)[0].toUpperCase()}] ${patchClinicsResponse.error}`,
          }));
        }

        break;
      case 'schedule':
        try {
          const responses = await Promise.all(
            transformUserHoursFormToApi(id, form.schedule).map(async item => {
              if (item.id != null) {
                return UserHoursRepository.editUserWorkingHours(id, {
                  id: item.id,
                  start_time: item.start_time,
                  end_time: item.end_time,
                });
              } else {
                return UserHoursRepository.addUserWorkingHours(id, {
                  day: item.day,
                  start_time: item.start_time,
                  end_time: item.end_time,
                  clinic: item.clinic,
                });
              }
            })
          );

          let responseError = null;
          for (const response of responses) {
            if (response.error) {
              responseError = response.error;
              break;
            }
          }        

          setLoading(false);
          if (responseError != null) {
            setFeedbackModal(prevState => ({
              ...prevState,
              open: true,
              type: 'error',
              message: responseError,
            }));
          } else {
            setFeedbackModal(prevState => ({
              ...prevState,
              open: true,
              type: 'success',
              message: 'Dados salvos com sucesso!',
            }));
          }
        } catch (error) {
          setLoading(false);
        }
        break;
      default:
        break;
    }
  }

  async function submitData() {
    setLoading(true);

    try {
      // post initial user data
      const userDataResponse = await UserRepository.addUserData({
        first_name: form.data.first_name,
        last_name: form.data.last_name,
        email: form.data.email,
        phone_number: form.data.phone_number ?? '',
        photo: form.data.photo ?? null,
        doctor:
          form.profile.doctor != null
            ? {
              crm: form.profile.doctor.crm,
            }
            : null,
        finance: form.user_types.includes(3)
          ? {
            approver: true,
          }
          : null,
        clinics: form.clinics,
        user_types: form.user_types,
        service: form.user_types.includes(7) ? { service_type: form.profile.services.service_type } : null,
        reimbursement_right: form.data.reimbursement_right ?? false,
        is_partner: form.data.is_partner ?? false,
      });

      // if the user_type contains doctor (1), then post all specialties if there was any selected
      if (userDataResponse && !userDataResponse.error) {
        if (!userDataResponse.doctor) {
          setLoading(false);
          setFeedbackModal(prevState => ({
            ...prevState,
            open: true,
            type: 'success',
            message: 'Dados salvos com sucesso!',
          }));
        } else if (userDataResponse.doctor && form.specialties.length > 0) {
          await UserRepository.updateDoctorSpecialties(
            userDataResponse.id,
            form.specialties.map(item => ({
              doctor: userDataResponse.doctor.id,
              specialties_type: item.specialties_type,
              rqe: item.rqe,
            }))
          );
        }
      } else {
        setFeedbackModal(prevState => ({
          ...prevState,
          open: true,
          type: 'error',
          message: `[${Object.keys(userDataResponse.debug)[0].toUpperCase()}] ${userDataResponse.error}`,
        }));
      }

      if (userDataResponse.doctor.id) {
        transformUserHoursFormToApi(userDataResponse.id, form.schedule).forEach(async item => {
          await UserHoursRepository.addUserWorkingHours(userDataResponse.id, {
            day: item.day,
            start_time: item.start_time,
            end_time: item.end_time,
            clinic: item.clinic,
          });
        });

        setLoading(false);
        setFeedbackModal(prevState => ({
          ...prevState,
          open: true,
          type: 'success',
          message: 'Dados salvos com sucesso!',
        }));
      }
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (edit) {
      loadUserInfo(id);
    }

    fetchData();
  }, [tab]);

  return {
    // getters
    serviceNames,
    specialties,
    clinics,
    loading,

    // handlers
    submitData,
    saveOnEdit,
  };
}
