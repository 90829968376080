import yup from 'shared/validator.js';

let medicamentoSchema = yup.object({
  name: yup.string().max(100).required('Defina um nome ao medicamento'),
});

let restrictionsSchema = yup.object({
  description: yup.string().max(100).required('Defina um texto para a contraindicação'),
  medicines: yup.string().required('Você deve selecionar um medicamento para criar a contraindicação'),
});

export const validateMedicamentoData = async data => {
  return await medicamentoSchema.validate(data);
};

export const validateRestrictionData = async data => {
  return await restrictionsSchema.validate(data);
};
