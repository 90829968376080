import React, { useState, useContext } from 'react';
import styles from './LoginPage.module.scss';
import { AuthContext } from 'contexts/auth';
import Button from 'components/Button/Button';
import lockSVG from 'assets/ico/lock.svg';
import envelope from 'assets/ico/envelope.svg';
import { Checkbox } from '@mui/material';
import { LoaderContext } from 'contexts/loader';
import { UserRepository } from 'data/auth';
import {
  clinicalStorage,
  storageAccessToken,
  storageClinic,
  storageRefreshToken,
  clearRefreshToken,
  storageUser,
} from 'shared/storage';
import { useToast } from 'hooks/useToast';
import { Navigate } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';
import { FaEyeSlash } from 'react-icons/fa';

function LoginPage() {
  const { setLoading } = useContext(LoaderContext);
  const { setUser, setLoadingUser, user, getHomeMenuItems } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState('password');
  const [saveRefreshToken, setSaveRefreshToken] = useState(true);
  const toast = useToast();

  const login = async (email, password, saveRefreshToken) => {
    setLoading(true);
    const response = await UserRepository.login({ email, password });
    setLoading(false);
    if (response?.error) {
      toast.error(response.error ?? 'Não foi possivel efetuar o login');
      return;
    }
    const { access, refresh, user, clinics } = response;

    storageUser(JSON.stringify(user));
    storageAccessToken(access);
    if (saveRefreshToken) {
      storageRefreshToken(refresh);
    } else {
      clearRefreshToken();
    }
    clinicalStorage(JSON.stringify(clinics));
    setUser(user);
    const clinic = storageClinic();
    if (!clinic) storageClinic(clinics[0]?.id);
    setLoadingUser(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    login(e.target.email.value, e.target.password.value, saveRefreshToken);
  };

  if (user) {
    const menuItems = getHomeMenuItems();
    if (menuItems.length === 1) {
      return <Navigate to={menuItems[0].link} />;
    } else {
      return <Navigate to={'/home'} />;
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.conteudo}>
        <p>Acessar Conta</p>
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className={styles.inputs}>
            <div className={styles.input}>
              <img src={envelope} alt="" />
              <input type="email" placeholder="Email" id="email" name="email" autoComplete="off" />
            </div>
            <div className={styles.input}>
              <img src={lockSVG} alt="" />
              <input type={showPassword} placeholder="Senha" id="password" name="password" autoComplete="off" />
              {showPassword === 'password' ? (
                <FaEyeSlash
                  className={styles.eye_password}
                  style={{ opacity: 0.7 }}
                  alt="Mostrar senha"
                  onClick={() => {
                    setShowPassword('text');
                  }}
                />
              ) : (
                <FaEye
                  className={styles.eye_password}
                  style={{ opacity: 0.7 }}
                  alt="Ocultar senha"
                  onClick={() => {
                    setShowPassword('password');
                  }}
                />
              )}
            </div>
          </div>
          <div className={styles.spans}>
            {/* <Link to="/login/redefinir-senha">
              Esqueci minha senha
            </Link> */}
            <span>
              <Checkbox
                checked={saveRefreshToken}
                onChange={e => setSaveRefreshToken(e.target.checked)}
                sx={{
                  '&.Mui-checked': {
                    color: '#15AB72',
                  },
                }}
              />
              Mantenha-me conectado
            </span>
          </div>
          <Button className={styles.loginButton} label="Entrar" type="submit" color="secondary" size="large" />
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
