import yup from 'shared/validator.js';

let PacienteSchema = yup.object({
  name: yup.string().required('O campo nome é obrigatório'),
  birthdate: yup.string().required('O campo data de nascimento é obrigatório'),
  gender: yup.string().required('O campo gênero é obrigatório'),
  cpf: yup.string().required('O campo cpf é obrigatório'),
  phone: yup.string().required('O campo telefone é obrigatório'),
  // address: yup.string().required('O campo endereço é obrigatório'),
  // affiliation: yup.string().required('O campo filiação é obrigatório'),
  email: yup.string().required('O campo email é obrigatório'),
  // clinic: yup.number().required(),
});

export const validatePaciente = async data => {
  return await PacienteSchema.validate(data);
};
