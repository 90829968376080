import yup from 'shared/validator.js';

let templateSchema = yup.object({
  text: yup.string().required('Texto é um campo obrigatório'),
  name: yup.string().max(255).required('Nome é um campo obrigatório'),
  text_type: yup.string().max(10).required('Tipo é um campo obrigatório'),
  clinic: yup.number().required('Selecione uma clinica.'),
});

export const validateTemplate = async data => {
  return await templateSchema.validate(data);
};
