import { stringifyFilters } from 'shared/utility';
import Endpoints from '../config/endpoints';
import http from '../config/httpClient';
import { parseError } from './base';

export const UserHoursRepository = {
  baseUrl: Endpoints.userHours,

  list: async function (user, clinic) {
    try {
      const queryString = clinic ? stringifyFilters({ clinic }) : '';
      const { data } = await http.get(`${this.baseUrl(user)}${queryString}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  addUserWorkingHours: async function (user, clinic) {
    try {
      const { data } = await http.post(`${this.baseUrl(user)}`, clinic);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  editUserWorkingHours: async function (user, body) {
    try {
      const { data } = await http.put(`${this.baseUrl(user)}/${body.id}`, body);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
};
