import React, { useState, useEffect, useContext } from 'react';
import Table from 'components/Table/Table';
import styles from './Caixa.module.scss';
import { Grid } from '@mui/material';
import Button from 'components/Button/Button';
import { CashierRepository } from 'data/cashier';
import { useToast } from 'hooks/useToast';
import Modal from 'components/Modal/Modal';
import { IoMdDownload } from 'react-icons/io';
import { ClinicIdContext } from 'contexts/clinicId';
import { FaRegThumbsUp } from 'react-icons/fa6';
import { apiBadRequestError } from 'exceptions/api';
import { handleSelectOneFile } from 'shared/utility';
import { FaRegThumbsDown } from 'react-icons/fa6';
import { LoaderContext } from 'contexts/loader';
import TableFooter from 'components/TableFooter/TableFooter';
import Input from 'components/Input/Input';
import moment from 'moment';
import InputDatePickerRange from 'components/InputDatePickerRange/InputDatePickerRange';
import { validateCaixa } from '../validator';
import { MdFileUpload } from 'react-icons/md';
import { Button as MuiButton } from '@mui/material';
import { formatCurrency } from 'shared/utility';

const initialFormData = {
  invoice_file: null,
  description: '',
  value: null,
  status: '',
  type: '',
  clinic: null,
  created_at: '',
};

function Caixa({ user_type }) {
  const toast = useToast();
  const { clinicId } = useContext(ClinicIdContext);
  const { setLoading } = useContext(LoaderContext);
  const [cashierList, setCashierList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [inputValue, setInputValue] = useState('');
  const [saldoTotal, setSaldoTotal] = useState('');
  const [fileUploaded, setFileUploaded] = useState('');
  
  const now = new Date();
  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const lastDayofMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  const [filters, setFilters] = useState({ 
    period_after: moment(firstDayOfMonth).format('YYYY-MM-DD'), 
    period_before: moment(lastDayofMonth).format('YYYY-MM-DD') 
  });

  useEffect(() => {
    if (clinicId != null) {
      loadCashier();
    }
  }, [filters, clinicId]);

  const loadCashier = async () => {
    setLoading(true);
    const response = await CashierRepository.list({ 
      ...filters, 
      period_before: moment(filters.period_before).add(1, 'days').format('YYYY-MM-DD'),
      type: 'cashier', 
      clinic: clinicId 
    });
    setLoading(false);
    if (response.results) {
      setCashierList(response.results);
      setSaldoTotal(response.balance_cashier);
    }
    setOpenModal(false);
    setInputValue('');
    setFormData(initialFormData);
  };

  const handleApprove = async (id, typeApp) => {
    setLoading(true);
    const response = await CashierRepository.update(id, { status: typeApp });
    setLoading(false);
    if (response?.error) throw new apiBadRequestError(response.error, response.debug);
    loadCashier();
  };

  const handleSubmit = async event => {
    try {
      validateCaixa(formData);
    } catch (err) {
      toast.error(err);
    }
    try {
      saveData();
    } catch (err) {
      toast.error(err);
    }
  };

  const saveData = async () => {
    setLoading(true);
    const response =
      user_type === 'finance'
        ? await CashierRepository.create({ ...formData, status: 'approved' })
        : await CashierRepository.create({ ...formData, value: formData.value * -1, status: 'waiting' });
    setLoading(false);
    if (response?.error) throw new apiBadRequestError(response.error, response.debug);
    return loadCashier();
  };

  const handleChange = event => {
    let value = event.value;
    setFormData({
      ...formData,
      [event.name]: value,
      type: 'cashier',
      clinic: clinicId,
    });
  };

  const handleChangeValue = (event, value, maskedValue) => {
    setFormData({ ...formData, value: value });
    setInputValue(maskedValue);
  };

  const handleDateChange = (clearField, newDate) => {
    if (clearField) {
      setFilters(prevState => ({
        ...prevState,
        period_after: '',
        period_before: '',
        offset: 0,
      }));
    } else if (newDate.length >= 2 && newDate[0] !== null && newDate[1] !== null) {
      const [startDate, endDate] = newDate;
      setFilters(prevState => ({
        ...prevState,
        period_after: moment(startDate).format('YYYY-MM-DD'),
        period_before: moment(endDate).format('YYYY-MM-DD'),
        offset: 0,
      }));
    }
  };

  const handleUpload = (fieldName, event) => {
    setFormData({ ...formData, [fieldName]: event });
    setFileUploaded(event.name);
  };

  const handleDownloadClick = async id => {
    try {
      const exportedData = await CashierRepository.get(id);
      const urlFile = exportedData.invoice_file;
      if (urlFile === null) {
        toast.error('Não tem um arquivo!');
      } else {
        window.open(urlFile, "_blank")
        // const imageDownload = await fetch(urlFile)
        //   .then(response => response.arrayBuffer())
        //   .then(buffer => new Blob([buffer], { type: 'image/*' }));

        // const link = document.createElement('a');
        // link.href = URL.createObjectURL(imageDownload);
        // link.download = 'downloaded_image.jpg';

        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
      }
    } catch (error) {
      toast.error('Erro no download da imagem');
    }
  };
  
  const reciboButton = item => {
    if (item.value >= 0) {
      return '-';
    }

    if (item.invoice_file == null) {
      return 'Sem recibo';
    }

    return <div className={styles.appovedBtn}>
      <IoMdDownload
        color="#15ab72"
        className={styles.download}
        title=""
        onClick={() => handleDownloadClick(item.id)}
      />
    </div>
  }

  const editButton = item => {
    if (item.value >= 0) {
      return '-';
    }

    let thumbs_icon = '';
    if (item.status === 'waiting') {
      if (user_type === 'finance') {
        thumbs_icon = <>
          <div className={styles.approve}>
            <FaRegThumbsUp
              color="3CB043"
              title="Aprovar"
              onClick={() => {
                handleApprove(item.id, 'approved');
              }}
            />
            |
            <FaRegThumbsDown
              color="#A31912"
              title="Reprovar"
              onClick={() => {
                handleApprove(item.id, 'disapproved');
              }}
            />
          </div>
        </>
      } else {
        thumbs_icon = 'Aguardando';
      }
    } else {
      if (item.status === 'approved') {
        thumbs_icon = (
          <div>
            <FaRegThumbsUp color="3CB043" />
          </div>
        )
      } else {
        thumbs_icon = (
          <div>
            <FaRegThumbsDown color="#A31912" />
          </div>
        )
      }
    }
    return (
      <div className={item.status === 'waiting' ? styles.editButton : styles.appovedBtn}>
        {thumbs_icon}
      </div>
    )
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <InputDatePickerRange
            label="Período"
            value={[
              new Date(filters.period_after+'T12:00:00Z'), 
              new Date(filters.period_before+'T12:00:00Z')
            ]}
            onChange={newDate => handleDateChange(false, newDate)}
            className={styles.dateInput}
          />
        </Grid>
      </Grid>
      <div>
        <Table
          tableHeight="35vh"
          columns={['Data', 'Descrição', 'Valor', user_type === 'finance' ? 'Aprovar Saída' : 'Saída Aprovada', 'Recibo Saída']}
          lines={cashierList.map(item => [
            moment(item.created_at).format('DD/MM/YYYY'),
            item.description,
            <span className={item.value > 0 ? styles.positiveValue : styles.negativeValue}>
              {formatCurrency(item.value)}
            </span>,
            editButton(item),
            reciboButton(item),
          ])}
        />
      </div>
      <TableFooter>
        <Button
          color="secondary"
          label={user_type === 'finance' ? 'Adicionar entrada' : 'Adicionar saida'}
          onClick={() => setOpenModal(true)}
        />
        <div>
          <p>Saldo Atual: {formatCurrency(saldoTotal)} </p>
        </div>
      </TableFooter>
      <Modal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)} className={styles.modal}>
        <div className={styles.modal}>
          <h1>{user_type === 'finance' ? 'Adicionar Entrada' : 'Adicionar Saida'}</h1>
          <Grid container spacing={2} className={styles.grid}>
            <Grid item xs={4}>
              <Input
                type="date"
                name="date"
                value={formData.created_at}
                onChange={e => handleChange({ name: 'created_at', value: e.target.value })}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="Descricao"
                value={formData.description}
                onChange={e => handleChange({ name: 'description', value: e.target.value })}
              />
            </Grid>
            <Grid item xs={4}>
              <Input numericInput name="value" label="Valor" value={inputValue} onChange={handleChangeValue} />
            </Grid>
            {user_type === 'concierge' ? (
              <Grid item xs={12} className={styles.gridUpload}>
                <MuiButton variant="contained" component="label">
                  <MdFileUpload />
                  Upload File
                  <input
                    hidden
                    onClick={() => {
                      handleSelectOneFile('.jpg, .jpeg, .png', (invoice_file, invoice_file64) => {
                        handleUpload('invoice_file', invoice_file);
                      });
                    }}
                  />
                </MuiButton>
                {fileUploaded ? <p>{fileUploaded}</p> : <p>Nenhum arquivo selecionado</p>}
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          <div className={styles.btnAdd}>
            <Button label="Salvar" onClick={handleSubmit} />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Caixa;
