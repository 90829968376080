import yup from 'shared/validator.js';

const daySlotSchema = yup.object().shape(
  {
    clinic: yup.number().nullable(),
    start_time: yup
      .string()
      .nullable()
      .when('end_time', {
        is: field => !!field,
        then: () =>
          yup
            .string()
            .required('Obrigatório')
            .test('valid', 'Inválido', (value, testContext) => {
              return !value ? true : /[0-9:]+$/.test(value);
            }),
      }),
    end_time: yup
      .string()
      .nullable()
      .when('start_time', {
        is: field => !!field,
        then: () =>
          yup
            .string()
            .required('Obrigatório')
            .test('valid', 'Inválido', (value, testContext) => {
              return !value ? true : /[0-9:]+$/.test(value);
            }),
      }),
    id: yup.number().nullable(),
  },
  ['start_time', 'end_time']
);

const daySchema = yup.object({
  morning: daySlotSchema,
  afternoon: daySlotSchema,
});

const userScheduleSchema = yup.object({
  seg: daySchema,
  ter: daySchema,
  qua: daySchema,
  qui: daySchema,
  sex: daySchema,
  sab: daySchema,
  dom: daySchema,
});

export const validateUserScheduleData = async data => {
  return await userScheduleSchema.validate(data, { abortEarly: false });
};
