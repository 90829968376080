import React from 'react';
import styles from './LoginSideBar.module.scss';
import Button from 'components/Button/Button';

function LoginSideBar({ button = false }) {
  return (
    <div className={styles.container}>
      <div></div>
      <div className={styles.conteudo}>
        <h1>Bem-vindo de volta!</h1>
        <p>acesse sua conta agora mesmo.</p>
        {button ? (
          <div className={styles.button}>
            <Button
              label="Entrar"
              fontColor="white"
              background="#C5AA71"
              borderColor="white"
              widthButton="170px"
              addLink={true}
              link="/login"
              type="submit"
            >
              Entrar
            </Button>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default LoginSideBar;
