import React, { useContext, useState, useRef } from 'react';
import styles from './User.module.scss';
import { AuthContext } from 'contexts/auth';
import { Navigate } from 'react-router-dom';
import { BiLogOut } from 'react-icons/bi';
import Button from 'components/Button/Button';
import UserAvatar from 'components/UserAvatar/UserAvatar';

export default function User() {
  const { user, logout } = useContext(AuthContext);
  const [showMenu, setShowMenu] = useState(false);
  
  const userButtonRef = useRef(null);
  const menuRef = useRef(null);
  document.addEventListener(
    'mousedown', 
    e => {
      if (showMenu) {
        const clickInUserButton = userButtonRef.current && userButtonRef.current.contains(e.target);
        const clickInMenu = menuRef.current && menuRef.current.contains(e.target);
        if (!clickInUserButton && !clickInMenu) {
          setShowMenu(false);
        }
      }
    }
  )

  return !user ? (
    <Navigate to={'/login'} />
  ) : (
    <>
      <div 
        className={styles.user} 
        ref={userButtonRef}
        onClick={() => setShowMenu(!showMenu)}
      >
        <div className={styles.content}>
          <UserAvatar imageUrl={user.photo ?? ''} />
          <p>Olá, {user.first_name ?? 'Desconhecido'}</p>
        </div>
      </div>
      <div 
        ref={menuRef}
        className={styles.menu} 
        style={{ visibility: showMenu ? 'visible' : 'hidden' }}
      >
        {/* <Button
          label="Meu Perfil"
          color="light"
          icon={<BiUserCircle />} 
          className={styles.menuButton}
        /> */}
        <Button
          label="Sair"
          color="light"
          icon={<BiLogOut />} 
          className={styles.menuButton}
          onClick={logout}
        />
      </div>
    </>
  );
}
