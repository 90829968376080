import { useState, useEffect } from 'react';
import styles from './Checkbox.module.scss';
import { AiOutlineClose } from 'react-icons/ai';
import { PiArrowElbowDownRightLight } from 'react-icons/pi';

function Checkbox({
  single,
  multiple,
  allowMultipleSelected,
  options,
  defaultValue,
  onClick,
  selected,
  label,
  className,
  children,
}) {
  const [selectedValues, setselectedValues] = useState([]);

  const defaultStyle = [styles.checkbox_container];
  if (className) defaultStyle.push(className);

  useEffect(() => {
    // Set the initial state when the component mounts
    setselectedValues(defaultValue || []);
  }, [defaultValue]);

  function handleToggleMultiple(id) {
    const hasValue = selectedValues.includes(id) || defaultValue?.includes(id);

    let newArray;

    if (allowMultipleSelected) {
      newArray = hasValue ? selectedValues.filter(item => item !== id) : [...selectedValues, id];
    } else {
      newArray = hasValue ? [] : [id];
    }

    setselectedValues(newArray);
    handleClick(newArray);
  }

  function handleClick(updatedValues) {
    onClick && onClick(updatedValues);
  }

  if (single) {
    return (
      <div className={styles.checkbox_single_container}>
        <div className={styles.checkbox_container}>
          <div className={styles.checkbox_icon_container} onClick={onClick}>
            <div className={selected ? styles.checkbox_selectable_active : styles.checkbox_selectable}>
              {<AiOutlineClose />}
            </div>
          </div>
          <div className={styles.checkbox_label_container}>
            <span className={styles.checkbox_label}>{label}</span>
          </div>
        </div>
      </div>
    );
  }

  if (multiple) {
    return (
      <div className={defaultStyle.join(' ')}>
        {options.map((item, index) => {
          const isSelected = selectedValues.includes(item.value) || defaultValue?.includes(item.value);
          return (
            <div key={`checkbox_inner_container-${index}`} className={styles.checkbox_inner_container}>
              <div className={styles.checkbox_container} onClick={() => handleToggleMultiple(item.value)}>
                <div className={styles.checkbox_icon_container}>
                  <span className={isSelected ? styles.checkbox_selectable_active : styles.checkbox_selectable}>
                    <AiOutlineClose />
                  </span>
                </div>
                <div className={styles.checkbox_label_container}>
                  <span className={styles.checkbox_label}>{item.label}</span>
                </div>
              </div>
              <div className={styles.checkbox_children_container}>
                {children &&
                  children
                    .filter(child => selectedValues.includes(child.value) && item.value === child.value)
                    .map(child => {
                      const show = defaultValue.includes(child.value) || selectedValues.includes(child.value);
                      return (
                        <div key={child.value} className={styles.checkbox_child_container}>
                          {show && (
                            <>
                              <PiArrowElbowDownRightLight className={styles.checkbox_child_arrow} />
                              <div className={styles.checkbox_child}>{child.element}</div>
                            </>
                          )}
                        </div>
                      );
                    })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Checkbox;
