import { useState } from 'react';

export default function useModalController() {
  const [modalController, setModalController] = useState({
    open: false,
    event: 'view',
  });

  function onClose() {
    setModalController({
      open: false,
      event: 'view',
    });
  }

  return {
    modalController,
    setModalController,
    onClose,
  };
}
