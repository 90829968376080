import React, { useContext } from 'react';
import styles from './Dados.module.scss';
import Input from 'components/Input/Input';
// import { validateClinicData } from '../validator';
import { Grid } from '@mui/material';
import { FormClinicContext } from 'contexts/formClinic';
import Card from 'components/Card/Card';

function Dados({ error }) {
  const { dados, setDados } = useContext(FormClinicContext);

  const handleChange = event => {
    setDados({ ...dados, clinicData: { ...dados.clinicData, [event.target.name]: event.target.value } });
  };

  return (
    <div className={styles.container}>
      <Card title="Dados Consultório" className={styles.card}>
        <Grid container spacing={2} mt={0.5}>
          <Grid item xs={12}>
            <Input
              label="CNPJ"
              name="cnpj"
              mask="99.999.999/9999-99"
              value={dados.clinicData.cnpj}
              onChange={handleChange}
              error={error?.cnpj}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="Nome do consultório"
              name="name"
              value={dados.clinicData.name}
              onChange={handleChange}
              error={error?.name}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              label="Email"
              name="email"
              value={dados.clinicData.email}
              onChange={handleChange}
              error={error?.email}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              mask="(99)99999-9999"
              addMask={true}
              label="Telefone (Whatsapp)"
              name="phone_number"
              value={dados.clinicData.phone_number}
              onChange={handleChange}
              error={error?.phone_number}
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default Dados;
