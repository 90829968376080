// hooks
import { useState, useEffect } from 'react';

// scss
import styles from './Profiles.module.scss';

// components
import TextArea from 'components/TextArea/TextArea';
import Select from 'components/Select/Select';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button.js';
import Modal from 'components/Modal/Modal';
import Table from 'components/Table/Table';
import { formatCurrency } from 'shared/utility';

export const Estoque = ({ props }) => {
  const { setTabInfo, orderState } = props;

  return (
    <>
      <div className={styles.ver_solicitacao_main_container}>
        <div className={styles.ver_solicitacao_top_card}>
          <div className={styles.ver_solicitacao_top_card_left}>
            <span>Data</span>
            <span>Solicitante</span>
            <span>Status</span>
            {orderState.status === 'Reprovado' && <span>Motivo</span>}
          </div>
          <div className={styles.ver_solicitacao_top_card_right}>
            <span>{orderState.data}</span>
            <span>{orderState.solicitante}</span>
            <span>{orderState.status}</span>
            {orderState.status === 'Reprovado' && <span>{orderState.motivo !== '' ? orderState.motivo : '-'}</span>}
          </div>
        </div>
        <div className={styles.ver_solicitacao_table_container}>
          <Table
            className={styles.ver_solicitacao_compras_table}
            lines={orderState.items.map(item => [item.name, `${item.quantity} unid.`])}
            columns={['Insumos', 'Quantidade']}
          />
        </div>
        <div className={styles.ver_solicitacao_orcamentos_container}>
          {orderState.budget.length > 0 && (
            <>
              <h1>Orçamentos</h1>
              {orderState.budget.map(budget => (
                <>
                  <div className={styles.ver_solicitacao_orcamentos_inner_container}>
                    <div className={styles.ver_solicitacao_orcamentos_inner_container_left}>
                      <span>Fornecedor</span>
                      <span>Valor total</span>
                    </div>
                    <div className={styles.ver_solicitacao_orcamentos_inner_container_middle}>
                      <span>{budget.name}</span>
                      <span>R$ {budget.proposal_value},00</span>
                    </div>
                    <div className={styles.ver_solicitacao_orcamentos_inner_container_right}>
                      <Button
                        label="Baixar orçamento"
                        onClick={() => window.alert('Arquivo indisponível no momento')}
                        disabled={true}
                      />
                    </div>
                  </div>
                </>
              ))}
            </>
          )}
          <span className={styles.ver_solicitacao_orcamentos_status_span}>
            <b>Status:</b> {orderState.status}
          </span>
        </div>
        <div className={styles.ver_solicitacao_buttons_container}>
          <div className={styles.ver_solicitacao_buttons_inner_container}>
            <Button
              label="Fechar"
              className={styles.ver_solicitacao_button_fechar}
              onClick={() => setTabInfo('lista_solicitacoes')}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const Financeiro = ({ props }) => {
  const { setTabInfo, orderState, requestApprover } = props;
  const [selectedInfo, setSelectedInfo] = useState({
    supplier_id: '',
    order_id: orderState.order_id,
    menor_valor: '',
    date: '',
    file: null,
  });
  const [approverModal, setApproverModal] = useState({
    id: '',
    open: false,
  });

  const valuePerBudget = orderState.budget.filter(budget => budget.supplier === selectedInfo.supplier_id);

  useEffect(() => {
    if (valuePerBudget.length > 0) {
      setSelectedInfo(prevState => ({ ...prevState, menor_valor: valuePerBudget[0].proposal_value }));
    }
  }, [selectedInfo.supplier_id]);

  // const handleFileChange = e => {
  //   const file = e.target.files[0];
  //   setSelectedInfo(prevState => ({ ...prevState, file: file }));
  // };

  return (
    <>
      <div className={styles.ver_solicitacao_main_container}>
        <div className={styles.ver_solicitacao_top_card}>
          <div className={styles.ver_solicitacao_top_card_left}>
            {orderState.status === 'Aprovado' && <span>Data</span>}
            <span>Solicitante</span>
            <span>Fornecedor</span>
            <span>Valor</span>
            <span>Status</span>
            {orderState.status === 'Reprovado' && <span>Motivo</span>}
            {orderState.status === 'Aprovado' && (
              <Button
                size="sm"
                label="Download NF"
                onClick={() => window.alert('Arquivo indisponível')}
                disabled={true}
              />
            )}
          </div>
          <div className={styles.ver_solicitacao_top_card_right}>
            {orderState.status === 'Aprovado' && <span>{orderState.delivery_date ?? '-'}</span>}
            <span>{orderState.solicitante ?? '-'}</span>
            <span>{orderState.fornecedor ?? '-'}</span>
            <span>{orderState.menor_valor}</span>
            <span>{orderState.status ?? '-'}</span>
            {orderState.status === 'Reprovado' && <span>{orderState.motivo !== '' ? orderState.motivo : '-'}</span>}
          </div>
        </div>
        <div className={styles.ver_solicitacao_table_container}>
          <Table
            className={styles.ver_solicitacao_compras_table}
            lines={orderState.items.map(item => [item.name, item.quantity])}
            columns={['Insumos', 'Quantidade']}
          />
        </div>
        <div className={styles.ver_solicitacao_orcamentos_container}>
          {orderState.budget.length > 0 && (
            <>
              <h1>Orçamentos</h1>
              {orderState.budget.map((budget, index) => (
                <>
                  <div key={`first-div-${index}`} className={styles.ver_solicitacao_orcamentos_inner_container}>
                    <div key={`second-div-${index}`} className={styles.ver_solicitacao_orcamentos_inner_container_left}>
                      <span>Fornecedor</span>
                      <span>Valor total</span>
                    </div>
                    <div className={styles.ver_solicitacao_orcamentos_inner_container_middle}>
                      <span>{budget.name}</span>
                      <span>{formatCurrency(budget.proposal_value)}</span>
                    </div>
                    <div className={styles.ver_solicitacao_orcamentos_inner_container_right}>
                      <Button
                        label="Baixar orçamento"
                        color="secondary"
                        onClick={() => window.alert('Arquivo indisponível no momento')}
                        disabled={true}
                      />
                    </div>
                  </div>
                </>
              ))}
            </>
          )}
          <span className={styles.ver_solicitacao_orcamentos_status_span}>
            <b>Status:</b> {orderState.status}
          </span>
        </div>
        <div className={styles.ver_solicitacao_buttons_container}>
          <div className={styles.ver_solicitacao_buttons_inner_container}>
            <Button
              label="Fechar"
              className={styles.ver_solicitacao_button_fechar}
              onClick={() => setTabInfo('lista_solicitacoes')}
            />
            {orderState.status === 'Aguardando' && (
              <>
                <Button
                  label="Reprovar"
                  className={styles.ver_solicitacao_button_reprovar}
                  onClick={() => setApproverModal(prevState => ({ ...prevState, open: true, id: 'reprovar' }))}
                />
                <Button
                  label="Aprovar"
                  className={styles.ver_solicitacao_button_aprovar}
                  onClick={() => setApproverModal(prevState => ({ ...prevState, open: true, id: 'aprovar' }))}
                />
              </>
            )}
          </div>
          <Modal
            className={styles.ver_solicitacao_modal}
            isOpen={approverModal.open}
            setModalOpen={() => setApproverModal({ open: false })}
          >
            {approverModal.id === 'reprovar' && (
              <div className={styles.ver_solicitacao_modal_reprovar_body}>
                <h2>Reprovar Pedido</h2>
                <TextArea
                  minRows={3}
                  placeholder="Motivo..."
                  value={selectedInfo.rejection_reason}
                  onChange={e => setSelectedInfo({ ...selectedInfo, rejection_reason: e.target.value })}
                />
                <Button
                  label="Confirmar"
                  onClick={() => {
                    requestApprover(selectedInfo, false);
                  }}
                />
              </div>
            )}
            {approverModal.id === 'aprovar' && (
              <div className={styles.ver_solicitacao_modal_aprovar_body}>
                <h3>Aprovar pedido</h3>
                <Select
                  label="Fornecedor"
                  options={orderState.budget.map(item => ({ name: item.name, value: item.supplier }))}
                  value={selectedInfo.supplier_id}
                  onChange={e => setSelectedInfo(prevState => ({ ...prevState, supplier_id: e.target.value }))}
                />
                <Input
                  type="date"
                  value={selectedInfo.date}
                  onChange={e => setSelectedInfo(prevState => ({ ...prevState, date: e.target.value }))}
                  helper="Data prevista de entrega"
                  className={styles.input}
                />
                {/* <Input helper="Nota Fiscal" type="file" onChange={handleFileChange} className={styles.input} /> */}
                <Button
                  label="Confirmar"
                  onClick={() => {
                    requestApprover(selectedInfo, true);
                  }}
                />
              </div>
            )}
          </Modal>
        </div>
      </div>
    </>
  );
};
