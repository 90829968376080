import React from 'react';
import styles from './ButtonVoltar.module.scss';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';
import Button from 'components/Button/Button';

function ButtonVoltar({ onClick, MarginTop, link = '' }) {
  const estilo = {
    marginTop: MarginTop,
  };

  return (
    <div className={styles.container} style={estilo}>
      <Button
        link={link}
        variant="text"
        color="secondary"
        className={styles.button}
        icon={<MdKeyboardDoubleArrowRight />}
        label="Voltar"
        onClick={onClick}
      />
    </div>
  );
}

export default ButtonVoltar;
