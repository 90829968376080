import React, { useEffect, useState, useContext } from 'react';
import Table from 'components/Table/Table.js';
import TableFooter from 'components/TableFooter/TableFooter';
import PageTitle from 'components/PageTitle/PageTitle';
import Container from 'components/Container/Container';
import Button from 'components/Button/Button.js';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import { TbEdit } from 'react-icons/tb';
import { LoaderContext } from 'contexts/loader';
import styles from './PlanoContas.module.scss';
import { Link } from 'react-router-dom';
import { ChartOfAccountsRepository } from 'data/chartofaccounts';
import Enums from 'transformers/enums';
import { ClinicIdContext } from 'contexts/clinicId';

function PlanoContas() {
  const { clinicId } = useContext(ClinicIdContext);
  const { setLoading } = useContext(LoaderContext);
  const [list, setList] = useState([]);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const response = await ChartOfAccountsRepository.list({ clinic: clinicId });
      setLoading(false);
      if (response?.error == null) {
        setList(response.results);
      }
    };
    if (clinicId != null) {
      run();
    }
  }, [clinicId]);

  const editButton = item => (
    <div>
      <Link to={`/financeiro/plano-contas/editar/${item.id}`}>
        <TbEdit title="Editar" color="#C5A500" style={{ width: '30px', height: '20px' }} />
      </Link>
    </div>
  );
  const botaoVoltar = <ButtonVoltar link="/financeiro/home" />;
  const botaoAdd = (
    <Button label="Adicionar plano de contas" link="/financeiro/plano-contas/novo" color="secondary" addLink={true} />
  );

  return (
    <Container>
      <PageTitle>Plano de Contas</PageTitle>
      <div>
        <Table
          columns={['Item Pai', 'Nome', 'Categoria', 'Ações']}
          lines={list.map(item => [
            item.parent === null ? (
              ''
            ) : (
              <div title={item.parent_name} className={styles.reducedWord} width={'600px'}>
                {item.parent_name}
              </div>
            ),
            item.name,
            `${Enums.statusChartOfAccounts[item.type]}`,
            editButton(item),
          ])}
        />
        <TableFooter>
          {botaoVoltar}
          {botaoAdd}
        </TableFooter>
      </div>
    </Container>
  );
}

export default PlanoContas;
