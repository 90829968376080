import React, { useState, useEffect, useContext } from 'react';
import { ProceduresRepository } from 'data/procedures';
import Modal from 'components/Modal/Modal';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import { LoaderContext } from 'contexts/loader';
import { Grid } from '@mui/material';
import styles from './InsumosModal.module.scss';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button.js'

export const initialInsumosModal = {
  isOpen: false,
  formData: null,
}

function InsumosModal({ insumosModal, setInsumosModal, setFormData }) {
  const { setLoading } = useContext(LoaderContext);
  const [catalog, setCatalog] = useState([]);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const response = await ProceduresRepository.getCatalog();
      setCatalog(response.results);
      setLoading(false);
    };
    run();
  }, []);

  let content = '';
  if (insumosModal?.formData?.items != null) {
    const options = [];
    const formDataItems = insumosModal.formData.items;
    catalog.sort((a, b) => a.name - b.name).forEach(item => {
      if (!formDataItems.find(e => e.item === item.id)) {
        options.push({ 
          value: item.id, 
          label: item.name, 
          item: {
            item: item.id,
            item_name: item.name,
            quantity: 1,
          }, 
        });
      }
    });
    content = <>
      <h4>{insumosModal.formData.name}</h4>
      <Grid container>
        <Grid item xs={12}>
          <Autocomplete
            name="items"
            label="Insumos"
            value={null}
            options={options}
            onChange={(_, option) => {
              formDataItems.push(option.item);
              setFormData();
              document.activeElement.blur();
            }}
          />
        </Grid>
      </Grid>
      {formDataItems.map(item => (
        <Grid container>
          <Grid item xs={9}>
            {item.item_name}
          </Grid>
          <Grid item xs={3}>
              <Input
                label="Quantidade"
                value={item.quantity}
                onChange={e => {
                  item.quantity = e.target.value;
                  setFormData();
                }}
              />
          </Grid>
        </Grid>
      ))}
      <Button 
        label="Fechar Edição dos Insumos" 
        color='primary' 
        onClick={() => setInsumosModal(initialInsumosModal)} 
      />
    </>;
  }

  return (
    <Modal
        isOpen={insumosModal.isOpen}
        setModalOpen={() => setInsumosModal(initialInsumosModal)}
        className={styles.modal}
    >
      {content}
    </Modal>
  );
}

export default InsumosModal;
