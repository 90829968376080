import React, { useState } from 'react';
import styles from './Pedidos.module.scss';
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import { Grid } from '@mui/material';
import { CiFilter } from 'react-icons/ci';
import Table from 'components/Table/Table';
import InputIcon from 'components/InputIcon/InputIcon';
import eye from 'assets/ico/eye.svg';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';

const initialModalState = {
  open: false,
  id: null,
};

function Pedidos() {
  const [modalState, setModalState] = useState(initialModalState);

  const tableMockData = [];
  const modalTableMockData = [];

  for (let i = 1; i <= 5; i++) {
    tableMockData.push([
      `${i}`,
      'Lucas',
      '23/07/2023 13:33',
      'Aguardando',
      <img src={eye} alt="Visualizar" onClick={() => setModalState({ ...modalState, open: true, id: i })} />,
      '',
    ]);
  }

  for (let i = 1; i <= 3; i++) {
    modalTableMockData.push(['', '']);
  }

  function onClose() {
    setModalState(initialModalState);
  }

  return (
    <Container className={styles.container}>
      <Grid container>
        <Grid item xs={6}>
          <PageTitle>Pedidos</PageTitle>
        </Grid>
        <Grid item xs={6}>
          <InputIcon
            inputClassName={styles.filter_input}
            placeholder="Todos"
            icon={<CiFilter />}
            iconPosition="right"
          />
        </Grid>
      </Grid>
      <div className={styles.header}>
        <Table
          className={styles.pedidos_table}
          lines={tableMockData}
          columns={['Código', 'Solicitante', 'Data do pedido', 'Status', '', '']}
        />
      </div>
      <Modal className={styles.modal} isOpen={modalState.open} setModalOpen={onClose}>
        <div className={styles.modal_main_container}>
          <div className={styles.modal_top_card}>
            <div className={styles.modal_top_card_left}>
              <span>Código do pedido</span>
              <span>Solicitante</span>
              <span>Data da solicitação</span>
            </div>
            <div className={styles.modal_top_card_right}>
              <span>1</span>
              <span>Lucas</span>
              <span>23/07/2023 13:33</span>
            </div>
          </div>
          <div className={styles.modal_table_container}>
            <Table className={styles.modal_pedidos_table} lines={modalTableMockData} columns={['Item', 'Quantidade']} />
          </div>
          <div className={styles.modal_orcamentos_container}>
            <h1>Orçamentos</h1>
            {[1, 2, 3].map(() => (
              <>
                <div className={styles.modal_orcamentos_inner_container}>
                  <div className={styles.modal_orcamentos_inner_container_left}>
                    <span>Fornecedor</span>
                    <span>Valor total</span>
                  </div>
                  <div className={styles.modal_orcamentos_inner_container_middle}>
                    <span>Lorem</span>
                    <span>R$1.500,00</span>
                  </div>
                  <div className={styles.modal_orcamentos_inner_container_right}>
                    <Button label="Baixar orçamento" />
                  </div>
                </div>
              </>
            ))}
            <span className={styles.modal_orcamentos_status_span}>
              <b>Status:</b> Reprovado por
            </span>
          </div>
          <div className={styles.modal_buttons_container}>
            <div className={styles.modal_button_voltar_container}>
              <ButtonVoltar onClick={onClose} />
            </div>
            <div className={styles.modal_buttons_inner_container}>
              <Button label="Reprovar" />
              <Button label="Aprovar" disabled={true} />
            </div>
          </div>
        </div>
      </Modal>
    </Container>
  );
}

export default Pedidos;
