import React, { useState } from 'react';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import styles from './ModalContraindicacao.module.scss';
import Input from 'components/Input/Input';
import { FormControl } from '@mui/material';
import { validateRestrictionData } from './validator';
import { useToast } from 'hooks/useToast';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';

const ModalContraindicacao = ({isOpen, medicine, onClose, onCreate}) => {
  const [description, setDescription] = useState('');
  const toast = useToast();

  if (medicine == null) {
    return '';
  }

  if (!isOpen && description.length > 0) {
    setDescription('');
  }
  
  return <Modal isOpen={isOpen} setModalOpen={onClose}>
    <div className={styles.modalContr}>
      <h1>Nova Contraindicação</h1>
      <FormControl fullWidth>
        <Input value={medicine.name} label="Medicamento" disabled />
      </FormControl>
      <div className={styles.divInput}>
        <textarea
          cols="30"
          rows="5"
          placeholder="Contraindicação"
          onChange={e => setDescription(e.target.value)}
        ></textarea>
      </div>
      <div className={styles.button}>
        <button className={styles.btn} onClick={onClose}>
          <MdKeyboardDoubleArrowRight />
          Voltar
        </button>
        <Button 
          onClick={async () => {
            try {
              const postData = {
                medicines: medicine.id,
                description
              }
              await validateRestrictionData(postData);
              await onCreate(postData);
            } catch (err) {
              toast.error(err.errors?.[0] || err.message);
            }
          }} 
          label="Salvar" 
          color="secondary"
        />
      </div>
    </div>
  </Modal>

}

export default ModalContraindicacao