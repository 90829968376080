import React from 'react';
import styles from './Input.module.scss';
import InputMask from 'react-input-mask';
import { TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import IntlCurrencyInput from 'react-intl-currency-input';

const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

function Input({
  name = '',
  label = '',
  placeholder = '',
  type = '',
  id = '',
  value = '',
  onChange,
  onFocus,
  onBlur,
  style,
  className,
  mask,
  numericInput,
  allowNegative,
  error,
  helper = '',
  disabled = false,
  prefix,
  ...props
}) {
  return (
    <div className={styles.container} style={style}>
      {mask && (
        <InputMask
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          mask={mask}
          disabled={disabled}
        >
          {() => (
            <TextField
              type={type}
              disabled={disabled}
              placeholder={placeholder}
              fullWidth={true}
              label={label}
              name={name}
              id={id}
              // InputLabelProps={{ shrink: !!value }}
              className={[styles.input, className].join(' ')}
              error={!!error}
              helperText={[true, false].includes(error) ? helper : error ?? helper}
            />
          )}
        </InputMask>
      )}
      {numericInput && (
        <FormControl fullWidth={true}>
          <InputLabel>{label}</InputLabel>
          <IntlCurrencyInput
            component={OutlinedInput}
            label={label}
            id={id}
            error={!!error}
            disabled={disabled}
            className={[styles.input, className].join(' ')}
            value={value}
            name={name}
            currency="BRL"
            config={currencyConfig}
            onChange={onChange}
            max={1000000}
          />
        </FormControl>
      )}
      {!numericInput && !mask && (
        <TextField
          disabled={disabled}
          fullWidth={true}
          label={label}
          // InputLabelProps={{ shrink: !!value }}
          className={[styles.input, className].join(' ')}
          error={!!error}
          helperText={error ?? helper}
          type={type}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          id={id}
          value={value}
          inputProps={{ maxLength: 50 }}
          {...props}
        />
      )}
    </div>
  );
}

export default Input;
