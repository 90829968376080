import ErrorMessages from '../shared/errorMessages';

export const parseSuccess = data => {
  return { data };
};
export const parseError = (err, customMessage) => {
  const { response } = err;
  if (customMessage)
    return {
      status: response?.status,
      debug: response?.data,
      error: customMessage,
    };
  if (response?.status === 400) {
    let error = response?.data?.detail;
    if (error == null) {
      error = Object.values(response?.data)?.[0];
      if (!(typeof error === 'string' || error instanceof String)) {
        error = error[0];
      }
    }
    
    return {
      status: response?.status,
      debug: response?.data,
      error: error,
    };
  }
  if (response?.status === 401)
    return {
      status: response?.status,
      debug: response?.data,
      error: ErrorMessages.credentialsError,
    };
  if (response?.status === 403)
    return {
      status: response?.status,
      debug: response?.data,
      error: ErrorMessages.noPermissionError,
    };
  if (response?.status === 422)
    return {
      status: response?.status,
      debug: response?.data,
      error: response?.data?.detail ?? (response.data ?
        Object.values(response?.data)?.[0]?.[0]
        : ErrorMessages.paramsError
      ),
    };
  return {
    status: response?.status,
    debug: response?.data ?? err?.message,
    error: ErrorMessages.serviceUnavailable,
  };
};
