import yup from 'shared/validator.js';

let InsumoSchema = yup.object({
  unit_type: yup.string().required('Unidade é um campo obrigatório'),
  name: yup.string().max(100).required('Insumo é um campo obrigatorio'),
  // item_code: yup.string().required('Código do Insumo é um campo obrigatorio'),
  // clinic: yup.number().required('Consultório é um campo obrigatorio'),
});

export const validateInsumo = async data => {
  return await InsumoSchema.validate(data);
};
