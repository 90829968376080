import LoaderScreen from 'components/LoaderScreen/LoaderScreen';
import { useState, createContext } from 'react';

export const LoaderContext = createContext();

export const LoaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  return (
    <LoaderContext.Provider
      value={{ loading, setLoading }}
    >
      <LoaderScreen show={loading}></LoaderScreen>
      {children}
    </LoaderContext.Provider>
  );
};
