import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './AddFornecedor.module.scss';
import { LoaderContext } from 'contexts/loader';
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import Card from 'components/Card/Card';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import { useToast } from 'hooks/useToast';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import { Grid } from '@mui/material';
import { validateFornecedor } from './validator';
import { SupplierRepository } from 'data/supplier';
import { transformApiToSupplier } from 'transformers/userForm';
import { cnpjMask } from 'shared/utility';

const initialSupplierContact = {
  name: '',
  phone_number: '',
  phone_number2: '',
  email: '',
};

const initialForm = {
  company_name: '',
  cnpj: '',
  supplier_contacts: [ { ...initialSupplierContact }, { ...initialSupplierContact }, { ...initialSupplierContact } ],
};

function AddFornecedor() {
  let navigate = useNavigate();
  let { id } = useParams();
  const { setLoading } = useContext(LoaderContext);
  const toast = useToast();
  const [formData, setFormData] = useState(initialForm);

  useEffect(() => {
    let ignore = false;
    async function run() {
      setLoading(true);
      const response = await SupplierRepository.get(id);
      if (ignore) return;
      const data = await transformApiToSupplier(response);
      if (response?.error) {
        toast.error(response.error);
        setFormData({});
      } else {
        while (data.supplier_contacts.length < 3) {
          data.supplier_contacts.push({ ...initialSupplierContact });
        }
        setFormData(data);
      }
      setLoading(false);
    }
    if (id) {
      run();
    }
    return () => (ignore = true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleValidateForm = event => {
    let value = event.value;
    if (event.name === 'cnpj') {
      if (value.length > 18) {
        value = value.replace(/\D/g, '').slice(0, -1);
      } else {
        value = value.replace(/\D/g, '');
      }
    }
    setFormData({ ...formData, [event.name]: value });
  };
  
  const handleValidateFormContact = (index, event) => {
    let value = event.value;
    if (event.name === 'phone_number') {
      value = value.replace(/\D/g, '');
    }
    if (event.name === 'phone_number2') {
      value = value.replace(/\D/g, '');
    }
    const newFormData = { ...formData, supplier_contacts: [...formData.supplier_contacts] };
    newFormData.supplier_contacts[index][event.name] = value;
    setFormData(newFormData);
  };

  const handleSubmit = async event => {
    try {
      await validateFornecedor(formData);
    } catch (err) {
      toast.error('Preencha todos o campos corretamente');
      return;
    }
    try {
      await saveData();
    } catch (err) {
      toast.error(err.errors?.[0] || err.message);
    }
  };

  const saveData = async () => {
    setLoading(true);
    const payload = { ...formData, supplier_contacts: [ ...formData.supplier_contacts ] };
    for (let i=2; i>=0; i--) {
      if (payload.supplier_contacts[i].name === '' || payload.supplier_contacts[i].email === '') {
        payload.supplier_contacts.splice(i, 1);
      } else {
        if (payload.supplier_contacts[i].phone_number === '') {
          payload.supplier_contacts[i].phone_number = null;
        }
        if (payload.supplier_contacts[i].phone_number2 === '') {
          payload.supplier_contacts[i].phone_number2 = null;
        }
      }
    }
    const response = id ? await SupplierRepository.update(id, payload) : await SupplierRepository.create(payload);
    setLoading(false);
    if (response?.error) {
      toast.error(response.error);
    } else {
      return navigate('/estoque/fornecedores');
    }
  };

  const botaoVoltar = <ButtonVoltar link="/estoque/fornecedores" />;
  const botaoAdd = <Button color="secondary" label="Cadastrar" onClick={handleSubmit} />;

  return (
    <Container className={styles.container}>
      <PageTitle>Cadastro de Fornecedores</PageTitle>
      <Card className={styles.card}>
        <div className={styles.dados}>
          <p>Dados Cadastrais</p>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Input
                type="text"
                label="Razão Social"
                name="company_name"
                value={formData.company_name}
                onChange={e => handleValidateForm({ name: e.target.name, value: e.target.value })}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                type="text"
                label="CNPJ"
                name="cnpj"
                value={cnpjMask(formData.cnpj)}
                onChange={e => handleValidateForm({ name: e.target.name, value: e.target.value })}
              />
            </Grid>
          </Grid>
        </div>
        <div className={styles.info}>
          <p>Informaçoes do Contato (1)</p>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Input
                type="text"
                label="Nome Contato"
                name="name"
                value={formData.supplier_contacts[0].name}
                onChange={e => handleValidateFormContact(0, { name: e.target.name, value: e.target.value })}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                name="phone_number"
                label="Telefone 1"
                mask="(99)99999-9999"
                addMask={true}
                lower={true}
                value={formData.supplier_contacts[0].phone_number}
                onChange={e => handleValidateFormContact(0, { name: e.target.name, value: e.target.value })}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                name="phone_number2"
                label="Telefone 2"
                mask="(99)99999-9999"
                addMask={true}
                lower={true}
                value={formData.supplier_contacts[0].phone_number2}
                onChange={e => handleValidateFormContact(0, { name: e.target.name, value: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                type="text"
                label="Email"
                name="email"
                value={formData.supplier_contacts[0].email}
                onChange={e => handleValidateFormContact(0, { name: e.target.name, value: e.target.value })}
              />
            </Grid>
          </Grid>
        </div>
        <div className={styles.info}>
          <p>Informaçoes do Contato (2)</p>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Input
                type="text"
                label="Nome Contato"
                name="name"
                value={formData.supplier_contacts[1].name}
                onChange={e => handleValidateFormContact(1, { name: e.target.name, value: e.target.value })}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                name="phone_number"
                label="Telefone 1"
                mask="(99)99999-9999"
                addMask={true}
                lower={true}
                value={formData.supplier_contacts[1].phone_number}
                onChange={e => handleValidateFormContact(1, { name: e.target.name, value: e.target.value })}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                name="phone_number2"
                label="Telefone 2"
                mask="(99)99999-9999"
                addMask={true}
                lower={true}
                value={formData.supplier_contacts[1].phone_number2}
                onChange={e => handleValidateFormContact(1, { name: e.target.name, value: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                type="text"
                label="Email"
                name="email"
                value={formData.supplier_contacts[1].email}
                onChange={e => handleValidateFormContact(1, { name: e.target.name, value: e.target.value })}
              />
            </Grid>
          </Grid>
        </div>
        <div className={styles.info}>
          <p>Informaçoes do Contato (3)</p>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Input
                type="text"
                label="Nome Contato"
                name="name"
                value={formData.supplier_contacts[2].name}
                onChange={e => handleValidateFormContact(2, { name: e.target.name, value: e.target.value })}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                name="phone_number"
                label="Telefone 1"
                mask="(99)99999-9999"
                addMask={true}
                lower={true}
                value={formData.supplier_contacts[2].phone_number}
                onChange={e => handleValidateFormContact(2, { name: e.target.name, value: e.target.value })}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                name="phone_number2"
                label="Telefone 2"
                mask="(99)99999-9999"
                addMask={true}
                lower={true}
                value={formData.supplier_contacts[2].phone_number2}
                onChange={e => handleValidateFormContact(2, { name: e.target.name, value: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                type="text"
                label="Email"
                name="email"
                value={formData.supplier_contacts[2].email}
                onChange={e => handleValidateFormContact(2, { name: e.target.name, value: e.target.value })}
              />
            </Grid>
          </Grid>
        </div>
      </Card>
      <div className={styles.btn}>
        {botaoVoltar}
        {botaoAdd}
      </div>
    </Container>
  );
}

export default AddFornecedor;
