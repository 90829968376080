import Endpoints from '../config/endpoints';
import http from '../config/httpClient';
import { parseError } from './base';

export const SpecialtiesRepository = {
  baseURL: Endpoints.specialties,

  list: async function () {
    try {
      const { data } = await http.get(`${this.baseURL}?limit=9999`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
};
