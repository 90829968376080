import React, { useEffect, useState } from 'react';
import styles from './WorkingSchedule.module.scss';
import Input from 'components/Input/Input';
import Select from 'components/Select/Select';
import FormControl from 'components/FormControl/FormControl';
import { validateUserScheduleData } from './validator';
import { Typography } from '@mui/material';

function WorkingSchedule({ defaultWorkingHours, clinicsFromFormUser, onChange = () => {} }) {
  const weekDays = {
    seg: 'Segunda',
    ter: 'Terça',
    qua: 'Quarta',
    qui: 'Quinta',
    sex: 'Sexta',
    sab: 'Sábado',
    dom: 'Domingo',
  };
  const initialForm = Object.keys(weekDays).reduce(
    (acc, item) => ({
      ...acc,
      [item]: {
        morning: {
          id: null,
          start_time: null,
          end_time: null,
          clinic: null,
        },
        afternoon: {
          id: null,
          start_time: null,
          end_time: null,
          clinic: null,
        },
      },
    }),
    {}
  );

  const [error, setError] = useState({});
  const [formData, setFormData] = useState(defaultWorkingHours ?? initialForm);
  const [currentClinic, setCurrentClinic] = useState(null);
  const [willValidate, setWillValidate] = useState(false);

  const handleClinicChange = e => {
    setCurrentClinic(parseInt(e.target.value));
  };

  useEffect(() => {
    setWillValidate(true);
  }, [currentClinic]);

  async function validateFormData() {
    setWillValidate(true);
    // setFormData(formData);
  }

  function handleChange(day, data) {
    const morning = { ...formData[day].morning, ...(data.morning || {}) };
    const afternoon = { ...formData[day].afternoon, ...(data.afternoon || {}) };
    if (!morning.start_time && !morning.end_time) morning.clinic = null;
    if (!afternoon.start_time && !afternoon.end_time) afternoon.clinic = null;
    setFormData({
      ...formData,
      [day]: {
        morning,
        afternoon,
      },
    });
  }
  useEffect(() => {
    let ignore = false;
    async function run() {
      try {
        await validateUserScheduleData(formData);
        if (ignore) return;
        setError(null);
        onChange(formData);
      } catch (err) {
        if (ignore) return;
        if (err.inner)
          setError(
            err.inner.reduce((acc, error) => {
              return { ...acc, [error.path]: error.message };
            }, {})
          );
        else setError(err.type ? { [err.path]: err.message } : { message: err.message });
      }
      setWillValidate(false);
    }
    if (willValidate) run();
    return () => {
      ignore = true;
    };
  }, [formData, willValidate]);

  return (
    <div className={styles.container}>
      <div className={styles.topBar}>
        <Select
          label="Consultório"
          options={clinicsFromFormUser.map(item => ({ value: item.id.toString(), name: item.name }))}
          value={currentClinic}
          onChange={handleClinicChange}
          style={{ width: '300px' }}
        ></Select>
      </div>
      <div className={styles.timeContainer}>
        {Object.keys(weekDays).map((day, index) => {
          const disableMorning =
            ![currentClinic, null].includes(formData[day]?.morning?.clinic) &&
            !!(formData[day]?.morning.start_time || formData[day]?.morning.end_time);

          const disableAfternoon =
            ![currentClinic, null].includes(formData[day]?.afternoon?.clinic) &&
            !!(formData[day]?.afternoon.start_time || formData[day]?.afternoon.end_time);

          return (
            <div key={`${index}-${currentClinic}`} className={styles.timeCol}>
              <p className={styles.dayText}>{weekDays[day]}</p>
              <FormControl>
                <Input
                  label=""
                  mask="99:99"
                  value={
                    [currentClinic, null].includes(formData[day]?.morning?.clinic)
                      ? formData[day]?.morning?.start_time ?? ''
                      : ''
                  }
                  disabled={disableMorning}
                  onBlur={validateFormData}
                  onChange={e =>
                    handleChange(day, {
                      morning: {
                        start_time: e.target.value,
                        clinic: currentClinic,
                      },
                    })
                  }
                  error={!!error?.[`${day}.morning.start_time`]}
                  css={styles.timeInput}
                />
              </FormControl>
              <FormControl>
                <Input
                  label=""
                  mask="99:99"
                  value={
                    [currentClinic, null].includes(formData[day]?.morning?.clinic)
                      ? formData[day]?.morning?.end_time ?? ''
                      : ''
                  }
                  disabled={disableMorning}
                  onBlur={validateFormData}
                  onChange={e =>
                    handleChange(day, {
                      morning: {
                        end_time: e.target.value,
                        clinic: currentClinic,
                      },
                    })
                  }
                  error={!!error?.[`${day}.morning.end_time`]}
                />
              </FormControl>

              {/* <p className={styles.stopText}> */}
              <hr className={styles.stopText} />
              {/* </p> */}

              <FormControl>
                <Input
                  label=""
                  mask="99:99"
                  value={
                    [currentClinic, null].includes(formData[day]?.afternoon?.clinic)
                      ? formData[day]?.afternoon?.start_time ?? ''
                      : ''
                  }
                  disabled={disableAfternoon}
                  onBlur={validateFormData}
                  onChange={e =>
                    handleChange(day, {
                      afternoon: {
                        start_time: e.target.value,
                        clinic: currentClinic,
                      },
                    })
                  }
                  error={!!error?.[`${day}.afternoon.start_time`]}
                />
              </FormControl>
              <FormControl>
                <Input
                  label=""
                  mask="99:99"
                  value={
                    [currentClinic, null].includes(formData[day]?.afternoon?.clinic)
                      ? formData[day]?.afternoon?.end_time ?? ''
                      : ''
                  }
                  disabled={disableAfternoon}
                  onBlur={validateFormData}
                  onChange={e =>
                    handleChange(day, {
                      afternoon: {
                        end_time: e.target.value,
                        clinic: currentClinic,
                      },
                    })
                  }
                  error={!!error?.[`${day}.afternoon.end_time`]}
                />
              </FormControl>
            </div>
          );
        })}
      </div>
      <Typography color={'error'}>
        {error?.[Object.keys(error)?.filter(item => !item.includes('start_time') && !item.includes('end_time'))?.[0]]}
      </Typography>
    </div>
  );
}

export default WorkingSchedule;
