import yup from 'shared/validator.js';

let clinicDataSchema = yup.object({
  cnpj: yup.string().required('cnpj é um campo obrigatório'),
  name: yup.string().required('nome é um campo obrigatório'),
  email: yup.string().email().required(),
  phone_number: yup.string().required('telefone é um campo obrigatório'),
});

let clinicAdressSchema = yup.object({
  state: yup.string().required('estado é um campo obrigatório'),
  city: yup.string().required('cidade é um campo obrigatório'),
  district: yup.string().required('bairro é um campo obrigatório'),
  address: yup.string().required('endereço é um campo obrigatório'),
  cep: yup.string().required(),
  complement: yup.string(),
  number: yup.string().required('número é um campo obrigatório'),
});

let clinicTimeSchema = yup.object({
  opening: yup.string().required('hora de abertura é um campo obrigatório'),
  closure: yup.string().required('hora de fechamento é um campo obrigatório'),
});

let clinicSchema = yup.object({
  dados: clinicDataSchema,
  endereco: clinicAdressSchema,
  horario: clinicTimeSchema,
});

export const validateClinicData = async data => {
  return await clinicDataSchema.validate(data, { abortEarly: false });
};

export const validateClinicAdress = async data => {
  return await clinicAdressSchema.validate(data, { abortEarly: false });
};

export const validateClinicTime = async data => {
  return await clinicTimeSchema.validate(data, { abortEarly: false });
};

export const validateData = async data => {
  return await clinicSchema.validate(data);
};
