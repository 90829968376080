import React, { useEffect, useContext, useState } from 'react';
import styles from './Professionals.module.scss';
import Container from 'components/Container/Container';
import { TbEdit } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import PageTitle from 'components/PageTitle/PageTitle';
import { LoaderContext } from 'contexts/loader';
import { useToast } from 'hooks/useToast';
import Table from 'components/Table/Table';
import Button from 'components/Button/Button';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import { ServiceNamesRepository } from 'data/professionals';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import errorIcon from 'assets/ico/error_icon.svg';
import Modal from 'components/Modal/Modal.js';
import confirmedIcon from 'assets/ico/confirmedIcon.svg';

const initialModalState = {
  isOpen: false,
  success: false,
  pending: false,
  error: false,
  msg: '',
  id: '',
};

function Professionals() {
  const toast = useToast();
  const { setLoading } = useContext(LoaderContext);
  const [list, setList] = useState([]);
  const [modalState, setModalState] = useState(initialModalState);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const response = await ServiceNamesRepository.list();
      setLoading(false);
      if (response?.error) {
        toast.error(response.error);
      } else {
        setList(response.results);
      }
    };
    run();
  }, []);

  const deleteRecord = async id => {
    setLoading(true);
    const response = await ServiceNamesRepository.delete(id);
    setLoading(false);
    if (response?.error) {
      setModalState({
        isOpen: true,
        error: true,
        msg: response.error,
      });
    } else {
      setList(list.filter(item => item.id !== id));
      setModalState({
        isOpen: true,
        success: true,
      });
    }
  };

  const handleDelete = id => {
    deleteRecord(id);
  };

  const editButton = itemId => (
    <div className={styles.editButton}>
      <Link to={`/admin/profissionais/editar/${itemId}`}>
        <TbEdit className={styles.pen} color="#C5A500" title="Editar Profissional" />
      </Link>
      <MdOutlineDeleteOutline
        color="#A31912"
        title="Excluir Profissional"
        onClick={() => {
          setModalState({ isOpen: true, id: itemId, pending: true });
        }}
      />
    </div>
  );

  const onClose = () => {
    setModalState(initialModalState);
  };

  return (
    <Container className={styles.container}>
      <div className={styles.header}>
        <PageTitle>Profissionais</PageTitle>
        <Button label="Adicionar Profissional" link="/admin/profissionais/novo" color="secondary" />
      </div>
      <Table
        tableHeight="50vh"
        columns={['Nome da Profissão', 'Editar']}
        lines={list.map(item => [item.name, editButton(item.id)])}
      />
      <ButtonVoltar link="/admin/home" />
      <Modal isOpen={modalState.isOpen && modalState.pending} setModalOpen={onClose} className={styles.modal_container}>
        {modalState.pending ? (
          <div className={styles.modal_inner_container}>
            <p>Tem certeza que deseja excluir esta profissão?</p>
            <Button color="secondary" label="Confirmar" onClick={() => handleDelete(modalState.id)} />
          </div>
        ) : (
          <></>
        )}
      </Modal>
      <Modal isOpen={modalState.isOpen && modalState.success} setModalOpen={onClose} className={styles.modal_container}>
        {modalState.success ? (
          <div className={styles.modal_inner_container}>
            <img src={confirmedIcon} alt=""></img>
            <span>A profissão foi excluida</span>
          </div>
        ) : (
          <></>
        )}
      </Modal>
      <Modal isOpen={modalState.isOpen && modalState.error} setModalOpen={onClose} className={styles.modal_container}>
        {modalState.error ? (
          <div className={styles.modal_inner_container}>
            <img src={errorIcon} alt=""></img>
            <span>{modalState.msg}</span>
          </div>
        ) : (
          <></>
        )}
      </Modal>
    </Container>
  );
}

export default Professionals;
