import React from 'react'
import styles from './Plans.module.scss'
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import Button from 'components/Button/Button';
import noImg from 'assets/noImage.png';
import { AiFillCheckCircle } from 'react-icons/ai';

function Plans() {

  return (
    <div>
    <div className={styles.cards}>
      <div className={styles.card}>
        <div className={styles.header}>
          <div className={styles.titulo}>
            <p>Plano Anual</p>
            <span>plano atual</span>
          </div>
          <div className={styles.price}>
            <p>R$ 2.999</p>
          </div>
        </div>
        <div className={styles.table}>
          <img src={noImg} alt=''></img>
          <table className={styles.tabela}>
            <tr><AiFillCheckCircle className={styles.ico}/>Condicao xxxxx</tr>
            <tr><AiFillCheckCircle className={styles.ico}/>Condicao xxxxx</tr>
            <tr><AiFillCheckCircle className={styles.ico}/>Condicao xxxxx</tr>
          </table>
          <Button type="submit" label="Meu plano"  color='secondary'/>
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.header}>
          <div className={styles.titulo}>
            <p>Plano Mensal</p>
            <span>parcelas 12x</span>
          </div>
          <div className={styles.price}>
            <p>R$ 399</p>
          </div>
        </div>
        <div className={styles.table}>
          <img src={noImg} alt=''></img>
          <table className={styles.tabela}>
            <tr><AiFillCheckCircle className={styles.ico}/>Condicao xxxxx</tr>
            <tr> <AiFillCheckCircle className={styles.ico}/>Condicao xxxxx</tr>
            <tr> <AiFillCheckCircle className={styles.ico}/>Condicao xxxxx</tr>
          </table>
          <Button on type="submit" label="Quero esse plano"  color='secondary'/>
        </div>
      </div>
    </div>
      <ButtonVoltar link='/plano-contas' />
    </div>
  )
}

export default Plans