import Endpoints from '../config/endpoints';
import http from '../config/httpClient';
import { parseError } from './base';
import { stringifyFilters } from '../shared/utility';

export const CashierRepository = {
  baseUrl: Endpoints.cashier,

  list: async function (filters = {}) {
    try {
      const queryString = stringifyFilters({ ...filters });
      const { data } = await http.get(`${this.baseUrl}${queryString}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  get: async function (id) {
    try {
      const { data } = await http.get(`${this.baseUrl}/${id}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  updateFile: async function (id, file) {
    try {
      const blob = await fetch(file).then(res => res.blob());
      const formData = new FormData();
      formData.append('invoice_file', blob, 'invoice_file.png');
      formData.append('invoice_file', file);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      return await http.patch(`${this.baseUrl}/${id}`, formData, config);
    } catch (err) {
      return parseError(err);
    }
  },

  create: async function (payload) {
    try {
      const file = payload.invoice_file;
      delete payload.invoice_file;
      const { data } = await http.post(`${this.baseUrl}`, payload);
      if (file) {
        await CashierRepository.updateFile(data.id, file);
      }
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  update: async function (id, payload) {
    try {
      const { data } = await http.patch(`${this.baseUrl}/${id}`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
};
