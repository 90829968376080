// scss
import styles from './ConciergeHome.module.scss';

// components
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import ConciergeSchedule from './Schedule/Schedule';

function ConciergeHome() {
  return (
    <Container className={styles.container}>
      <PageTitle className={styles.title}>Agenda</PageTitle>
      <ConciergeSchedule />
    </Container>
  );
}
export default ConciergeHome;
