import { AuthContext } from "contexts/auth";
import { useContext } from "react";
import { Navigate } from "react-router-dom";

const Authenticated = ({ children }) => {
  const { user, loadingUser } = useContext(AuthContext);

  if (loadingUser) {
    return '';
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return children;
};
export default Authenticated;