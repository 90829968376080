import { useState, useEffect } from 'react';

import { RepositoryPurchaseOrders } from 'data/purchaseorders';

export function useHandlers(modalState, orderState, catalogo, fornecedores, user, clinic, setLoading) {
  const [selectedInsumo, setSelectedInsumo] = useState({ id: '', amount: '' });
  const [selectedBudget, setSelectedBudget] = useState({ id: '', value: '' });
  const [errorMessageInsumo, setErrorMessageInsumo] = useState('');
  const [errorMessageBudget, setErrorMessageBudget] = useState('');
  const [listInsumos, setListInsumos] = useState([]);
  const [listBudgets, setListBudgets] = useState([]);
  const [statusModal, setStatusModal] = useState({
    open: false,
    error: '',
    success: '',
  });

  useEffect(() => {
    if (modalState.is_edit) {
      const catalogItems = catalogo
        .map(catalogItem => {
          let customObject;

          orderState.items.some(orderItem => {
            if (orderItem.item === catalogItem.id) {
              customObject = {
                is_edit: true,
                item_id: orderItem.id,
                catalog_id: catalogItem.id,
                name: catalogItem.name,
                amount: orderItem.quantity,
              };
              return true;
            }
            return false;
          });

          return customObject;
        })
        .filter(orderItem => orderItem !== undefined);

      const supplierBudgets = fornecedores
        .map(supplier => {
          let customObject;

          orderState.budget.some(budgetItem => {
            if (budgetItem.supplier === supplier.id) {
              customObject = {
                is_edit: true,
                id: budgetItem.id,
                supplier: budgetItem.supplier,
                company_name: supplier.company_name,
                name: supplier.name,
                value: budgetItem.proposal_value,
              };
              return true;
            }
            return false;
          });

          return customObject;
        })
        .filter(budgetItem => budgetItem !== undefined);

      if (catalogItems.length > 0) setListInsumos(catalogItems);
      if (supplierBudgets.length > 0) setListBudgets(supplierBudgets);
    }
  }, [modalState.open]);

  async function handleOrderRequest() {
    setLoading(true);
    if (modalState.is_edit) {
      try {
        await Promise.all(
          listInsumos
            .filter(item => item.is_edit == null)
            .map(async item => {
              await RepositoryPurchaseOrders.postItem(orderState.order_id, {
                item: item.id,
                quantity: item.amount,
              });
            })
        );

        await Promise.all(
          listBudgets
            .filter(item => item.is_edit == null)
            .map(async item => {
              await RepositoryPurchaseOrders.postBudget(orderState.order_id, {
                proposal_value: item.value,
                supplier: item.id,
              });
            })
        );
        setLoading(false);
        window.location.reload();
      } catch (error) {
        setStatusModal(prevState => ({
          ...prevState,
          open: true,
          error: error.message,
        }));
        setLoading(false);
      }
    } else {
      try {
        const orderResponse = await RepositoryPurchaseOrders.postOrder({ requester: user.id, clinic: clinic });

        if (orderResponse.error) {
          setStatusModal(prevState => ({
            ...prevState,
            open: true,
            error: orderResponse.error,
          }));
          setLoading(false);
        } else {
          const itemsResponse = await Promise.all(
            listInsumos.map(async item =>
              RepositoryPurchaseOrders.postItem(orderResponse.id, { item: item.id, quantity: item.amount })
            )
          );

          const budgetsResponse = await Promise.all(
            listBudgets.map(async item =>
              RepositoryPurchaseOrders.postBudget(orderResponse.id, {
                supplier: item.id,
                proposal_value: item.value,
                budget_file: item.budget_file,
              })
            )
          );

          const allItemsSuccessful = itemsResponse.every(item => !item.error);
          const allIBudgetsSuccessful = budgetsResponse.every(item => !item.error);

          if (allItemsSuccessful && allIBudgetsSuccessful) {
            setLoading(false);
            window.location.reload();
          } else {
            const firstError = itemsResponse.find(item => item.error);

            setStatusModal(prevState => ({
              ...prevState,
              open: true,
              error: firstError.error,
            }));
            setLoading(false);
          }
        }
      } catch (error) {
        setStatusModal(prevState => ({
          ...prevState,
          open: true,
          error: error.message,
        }));
        setLoading(false);
      }
    }
  }

  function handleAddItem() {
    const itemExists = listInsumos.find(item =>
      modalState.is_edit && item.is_edit ? item.catalog_id === selectedInsumo.id : item.id === selectedInsumo.id
    );
    const amountChecker = selectedInsumo.amount < 1 ? 1 : selectedInsumo.amount;

    catalogo.forEach(item => {
      if (item.id === selectedInsumo.id) {
        if (!itemExists) {
          // adiciona um item na lista caso não exista nenhum item com o mesmo id
          setListInsumos(prevList => [...prevList, ...[{ ...item, amount: amountChecker }]]);
        } else {
          // atualiza um item da lista caso ele exista, e (&&) se sua quantidade for alterada
          setErrorMessageInsumo('Este item já foi adicionado!');
          setTimeout(() => setErrorMessageInsumo(''), 2000);
          setSelectedInsumo({ id: '', amount: '' });
        }
      }
    });
  }

  function handleAddSupplier() {
    const supplierExists = listBudgets.find(item =>
      modalState.is_edit && item.is_edit ? item.supplier === selectedBudget.id : item.id === selectedBudget.id
    );
    const amountChecker = selectedBudget.value < 1 ? 1 : selectedBudget.value;

    fornecedores.forEach(item => {
      if (item.id === selectedBudget.id) {
        if (!supplierExists) {
          // adiciona um item na lista caso não exista nenhum item com o mesmo id
          setListBudgets(prevList => [...prevList, ...[{ ...item, value: amountChecker }]]);
        } else {
          setErrorMessageBudget('Este fornecedor já foi adicionado!');
          setTimeout(() => setErrorMessageBudget(''), 2000);
          setSelectedBudget({ id: '', value: '' });
        }
      }
    });
  }

  async function handleDeleteItem(item) {
    try {
      await RepositoryPurchaseOrders.deleteItem(orderState.order_id, item);
      setListInsumos(prevList => prevList.filter(prevItem => prevItem.item_id !== item));
    } catch (err) {
      setStatusModal(prevState => ({
        ...prevState,
        open: true,
        error: err.message,
      }));
    }
  }

  async function handleDeleteSupplier(supplier) {
    try {
      await RepositoryPurchaseOrders.deleteSupplier(orderState.order_id, supplier);
      setListBudgets(prevList => prevList.filter(prevItem => prevItem.id !== supplier));
    } catch (err) {
      setStatusModal(prevState => ({
        ...prevState,
        open: true,
        error: err.message,
      }));
    }
  }

  return {
    selectedInsumo,
    setSelectedInsumo,
    selectedBudget,
    setSelectedBudget,
    statusModal,
    setStatusModal,
    listInsumos,
    listBudgets,
    handleOrderRequest,
    handleAddItem,
    handleAddSupplier,
    handleDeleteItem,
    handleDeleteSupplier,
    errorMessageInsumo,
    errorMessageBudget,
  };
}
