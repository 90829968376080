import React from 'react';
import styles from './CardHome.module.scss';
import Button from 'components/Button/Button';

export default function CardHome({
  icon,
  title = '',
  buttons = [
    // {
    //     label: 'Acessar',
    //     icon: <HiOutlineMenu />,
    //     link: '/admin/consultorio/novo',
    //     variant: 'contained',
    //     color: 'secondary'
    // }
  ],
  children,
}) {
  return (
    <div className={styles.card}>
      <div className={styles.conteudo}>
        {icon}
        <p>{title}</p>
      </div>
      <div className={styles.botoes}>
        <React.Fragment>
          {buttons.map((button, index) => (
            <Button
              key={index}
              label={button.label}
              icon={button.icon}
              variant={button.variant ?? 'contained'}
              color={button.color ?? 'secondary'}
              link={button.link}
              className={styles.botao}
            />
          ))}
        </React.Fragment>
      </div>
      <div className={styles.bottom}>{children}</div>
    </div>
  );
}
