import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './DadosBancariosForm.module.scss';
import PageTitle from 'components/PageTitle/PageTitle';
import Container from 'components/Container/Container';
import TableFooter from 'components/TableFooter/TableFooter';
import Button from 'components/Button/Button.js';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import Input from 'components/Input/Input';
import Card from 'components/Card/Card';
import { LoaderContext } from 'contexts/loader';
import { BankAccountsRepository } from 'data/bankaccounts';
import { apiBadRequestError } from 'exceptions/api';
import { BanksRepository } from 'data/banks';
import { useToast } from 'hooks/useToast';
import { validateDadosBancarios } from './validator.js';
import { Grid } from '@mui/material';
import { transformApiToDadosBancarios } from 'transformers/userForm';
import { ClinicIdContext } from 'contexts/clinicId';

const initialForm = {
  account: '',
  agency: '',
  banks: '',
  origin: 'Internal',
};

function DadosBancariosForm() {
  let navigate = useNavigate();
  let { id } = useParams();
  const { setLoading } = useContext(LoaderContext);
  const [banks, setBanks] = useState([]);
  const [formData, setFormData] = useState(initialForm);
  const { clinicId } = useContext(ClinicIdContext);
  const toast = useToast();

  useEffect(() => {
    const run = async () => {
      const response = await BanksRepository.list();
      const newBanks = response.results;
      newBanks.forEach(bank => (bank.name = `${bank.code?.toString().padStart(3, '0')} - ${bank.name}`));
      newBanks.sort((a, b) => a.code - b.code);
      setBanks(newBanks.map(item => ({ value: item.id, label: item.name })));
    };
    run();
  }, []);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const response = await BankAccountsRepository.get(id);
      setLoading(false);
      if (response?.error) {
        toast.error(response.error);
        setFormData({});
      } else setFormData(transformApiToDadosBancarios(response));
    };
    if (id) {
      run();
    }
  }, []);

  const handleBankChange = (event, item) => {
    setFormData({ ...formData, banks: item.value });
  };

  const handleChange = event => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === 'agency') {
      value = value.replace(/\D/g, '');
      value = value.slice(0, 5);
    }

    if (name === 'account') {
      value = value.replace(/\D/g, '');
      value = value.replace(/^(\d{11})(\d{1,2})$/, '$1-$2');
      value = value.slice(0, 13);
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async event => {
    try {
      await validateDadosBancarios({ ...formData, account: formData.account.replace('-', '') });
    } catch (err) {
      toast.error(err.message);
      return;
    }

    try {
      await saveData();
    } catch (err) {
      toast.error(err.errors?.[0] || err.message);
    }
  };

  const saveData = async () => {
    setLoading(true);
    const response = id
      ? await BankAccountsRepository.update(id, formData)
      : await BankAccountsRepository.create(parseInt(clinicId), formData);
    setLoading(false);
    if (response?.error) throw new apiBadRequestError(response.error, response.debug);
    return navigate('/financeiro/dados-bancarios');
  };

  const botaoVoltar = <ButtonVoltar link="/financeiro/dados-bancarios" />;
  const botaoAdd = <Button label="Salvar" color="secondary" onClick={handleSubmit} size="large" />;

  return (
    <Container className={styles.container}>
      <PageTitle>{!!id ? 'Editar Dados Bancários' : 'Adicionar Dados Bancários'}</PageTitle>
      <Card className={styles.card} title="Dados Bancários">
        <div className={styles.formulario}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                name="banks"
                label="Banco"
                value={banks.find(bank => bank.value === formData.banks)}
                options={banks}
                onChange={handleBankChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Input name="agency" label="Agência" value={formData.agency} onChange={handleChange} />
            </Grid>
            <Grid item xs={6}>
              <Input name="account" label="Conta Corrente" value={formData.account} onChange={handleChange} />
            </Grid>
          </Grid>
        </div>
      </Card>
      <TableFooter>
        {botaoVoltar}
        {botaoAdd}
      </TableFooter>
    </Container>
  );
}

export default DadosBancariosForm;
