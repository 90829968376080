import { useState } from 'react';
import { ListItemButton } from '@mui/material';
import BasicPopover from 'components/Popover/Popover';
import styles from './TableHeaderFilter.module.scss';

function TableHeaderFilter({ icon, label, placement, children }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <ListItemButton className={styles.ListItemButton} onClick={handleClick}>
        {icon}
        {label}
      </ListItemButton>
      <BasicPopover open={open} onClose={onClose} anchorEl={anchorEl} placement={placement}>
        {children}
      </BasicPopover>
    </>
  );
}

export default TableHeaderFilter;
