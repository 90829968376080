// scss
import styles from './ServiceHome.module.scss';

// components
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import ServiceSchedule from './Schedule/Schedule';

function ServiceHome() {
  return (
    <Container className={styles.container}>
      <PageTitle className={styles.title}>Agenda</PageTitle>
      <ServiceSchedule />
    </Container>
  );
}

export default ServiceHome;
