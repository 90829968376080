import React from 'react';
import NavigationBar from './NavigationBar/NavigationBar.js';
import styles from './DefaultLayout.module.scss';
import PropTypes from 'prop-types';
import Header from './Header/Header.js';
import { ThemeProvider, createTheme } from '@mui/material';
import LoginSideBar from './LoginSideBar/LoginSideBar.js';

const theme = createTheme({
  palette: {
    primary: {
      light: '#e3cc9c',
      main: '#c5aa71',
      dark: '#a18549',
      contrastText: '#fff',
    },
    secondary: {
      light: '#4dc598',
      main: '#15ab72',
      dark: '#11875b',
      contrastText: '#FFF',
    },
    light: {
      light: '#fff',
      main: '#fff',
      dark: '#f2f2f2',
      contrastText: '#c5aa71',
    },
    dark: {
      light: '#C0C0C0',
      main: '#969696',
      dark: '#4b4b4d',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            padding: '0px 18px',
            height: '28px',
          },
        },
        {
          props: { size: 'medium' },
          style: {
            padding: '11px 18px',
            height: '48px',
          },
        },
        {
          props: { size: 'large' },
          style: {
            padding: '11px 50px',
            height: '48px',
          },
        },
      ],
    },
  },
});

function DefaultLayout({ children, nav = 'default', header = true }) {
  const contentClasses = [styles.content];
  if (!header) contentClasses.push(styles.fullHeight);

  return (
    <div className={styles.container}>
      <ThemeProvider theme={theme}>
        <div className={styles.nav}>{nav === 'login' ? <LoginSideBar /> : <NavigationBar navTab={nav} />}</div>
        <div className={styles.contentWrapper}>
          {header && (
            <div className={styles.header}>
              <Header nav={nav}></Header>
            </div>
          )}
          <div className={contentClasses.join(' ')}>{children}</div>
        </div>
      </ThemeProvider>
    </div>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DefaultLayout;
