// scss
import styles from './data.module.scss';

// components
import Input from 'components/Input/Input';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import Checkbox from 'components/Checkbox/Checkbox';
import { Grid } from '@mui/material';

// other
import { handleSelectOneFile } from 'shared/utility';

export default function AddUserData({ props }) {
  const { error, form, setForm, validateOnBlur } = props;

  function handleInputChange(fieldName, event) {
    setForm(prevState => ({
      ...prevState,
      data: { ...prevState.data, [fieldName]: event.target.value },
    }));
  }

  function handlePhotoChange(fieldName, event) {
    setForm(prevState => ({
      ...prevState,
      data: { ...prevState.data, [fieldName]: event },
    }));
  }

  return (
    <div className={styles.container}>
      <div className={styles.form_container}>
        <h2 className={styles.title}>Dados Básicos</h2>
        <Grid container spacing={2}>
          <Grid item xs={13}>
            <Input
              label="Nome"
              value={form.data.first_name}
              onChange={event => handleInputChange('first_name', event)}
              error={error?.data?.first_name}
            />
          </Grid>
          <Grid item xs={13}>
            <Input
              label="Sobrenome"
              value={form.data.last_name}
              onChange={event => handleInputChange('last_name', event)}
              error={error?.data?.last_name}
            />
          </Grid>
          <Grid item xs={13}>
            <Input
              label="Email"
              value={form.data.email}
              onChange={event => handleInputChange('email', event)}
              error={error?.data?.email}
              onBlur={() => validateOnBlur('email')}
            />
          </Grid>
          <Grid item xs={7}>
            <Input
              label="Telefone (Whatsapp)"
              mask="(99)99999-9999"
              addMask
              lower
              value={form.data.phone_number}
              onChange={event => handleInputChange('phone_number', event)}
            />
          </Grid>
          <Grid item xs={7}>
            <Checkbox
              label="Solicitação de Reembolso"
              single
              selected={form.data.reimbursement_right}
              onClick={() => {
                setForm(prevState => ({
                  ...prevState,
                  data: {
                    ...prevState.data,
                    reimbursement_right: !prevState.data.reimbursement_right,
                  },
                }));
              }}
            />
          </Grid>
          <Grid item xs={7}>
            <Checkbox
              label="Parceiro"
              single
              selected={form.data.is_partner}
              onClick={() => {
                setForm(prevState => ({
                  ...prevState,
                  data: {
                    ...prevState.data,
                    is_partner: !prevState.data.is_partner,
                  },
                }));
              }}
            />
          </Grid>
        </Grid>
      </div>
      <div className={styles.right_container}>
        <div
          className={styles.profile_picture_container}
          onClick={() => {
            handleSelectOneFile('.jpg, .jpeg, .png', (photo, photo64) => {
              handlePhotoChange('photo', photo);
              handlePhotoChange('photo64', photo64);
            });
          }}
        >
          <UserAvatar size={100} imageUrl={form.data.photo64 ?? form.data.photo} className={styles.picture} />
          <span>{form.data.photo != null ? 'Editar imagem' : 'Adicionar imagem'}</span>
        </div>
      </div>
    </div>
  );
}
