export default class Enums {
  static profileNameToId = {
    DOCTOR: 1,
    FINANCE: 3,
    CONCIERGE: 4,
    STOCK: 5,
    ADMIN: 6,
    SERVICE: 7,
  };

  static profileNameToLabel = {
    DOCTOR: 'Médico',
    FINANCE: 'Financeiro',
    CONCIERGE: 'Concierge',
    STOCK: 'Estoque',
    ADMIN: 'Administrador do sistema',
    SERVICE: 'Outro perfil',
  };
  
  static indicationToLabel = {
    google: 'Google',
    patient: 'Indicação de outro paciente',
    instagram: 'Instagram',
    site: 'Site',
  }; 

  static navTabNameToLabel = {
    default: 'Módulos',
    doctor: 'Doutor',
    finance: 'Financeiro',
    stock: 'Estoque',
    concierge: 'Concierge',
    admin: 'Administrador',
    service: 'Atendimento',
  };

  static navTabToLabelUrl = {
    default: '',
    finance: 'financeiro',
    stock: 'estoque',
    service: 'atendimento',
    concierge: 'concierge',
    admin: 'admin',
  };

  static profileIdToLabel = {
    1: 'Médico',
    3: 'Financeiro',
    4: 'Concierge',
    5: 'Estoque',
    6: 'Administrador do sistema',
    7: 'Atendimento',
  };

  static statusLabels = {
    waiting: 'Aguardando',
    approved: 'Aprovado',
    disapproved: 'Reprovado',
  };

  static errorsToPortuguese = {
    'email: This field may not be blank.': 'O campo Email não pode estar vazio.',
    'password: This field may not be blank.': 'O campo Senha não pode estar vazio.',
  };

  static statusChartOfAccounts = {
    income: 'Receita',
    expense: 'Despesa',
    passive: 'Passivo',
    active: 'Ativo',
  };

  static statusSchedule = {
    didnotattend: 'Não Compareceu',
    scheduled: 'Agendado',
    attended: 'Atendido',
    waiting: 'Aguardando Atendimento',
    canceled: 'Cancelado',
  };

  static tabsToHeader = {
    data: 'Dados',
    profile: 'Perfil',
    specialties: 'Especialidades',
    clinic: 'Consultórios',
    schedule: 'Horários',
  };

  static patientGender = {
    male: 'Masculino',
    female: 'Feminino',
  };
}
