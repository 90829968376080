import { useEffect, useState } from 'react';
import { RepositoryMaterialOrders } from 'data/materialorders';

export const useInsumos = clinic => {
  const [pedidos, setPedidos] = useState([]);
  const [catalogo, setCatalogo] = useState([]);
  const [itens, setItens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasClinicSelected, setHasClinicSelected] = useState(false);
  const [modalStatus, setModalStatus] = useState({
    open: false,
    success: false,
    status: '',
    confirmed: false,
  });

  const fetchData = async () => {
    setLoading(true);
    const pedidosResponse = await RepositoryMaterialOrders.getOrders(clinic);
    const catalogoResponse = await RepositoryMaterialOrders.getCatalog(clinic);

    if (pedidosResponse && pedidosResponse.results) {
      setPedidos(pedidosResponse.results);
      const itens = pedidosResponse?.results.map(item => {
        const respositoryResponse = RepositoryMaterialOrders.getItems(item.id);
        return respositoryResponse;
      });

      Promise.all(itens).then(res => {
        const mappedResponse = res.map(item => item.results);
        setItens(mappedResponse);
      });

      setLoading(false);
    } else setLoading(false);
    if (catalogoResponse && catalogoResponse.results) {
      setCatalogo(catalogoResponse.results);
      setLoading(false);
    } else setLoading(false);
  };

  const requestApprover = async (data, approver) => {
    if (approver) {
      setLoading(true);
      await RepositoryMaterialOrders.patchOrder(data.order_id, { status: 'approved', stocks: data.stocks });

      setLoading(false);
      window.location.reload();
    } else {
      setLoading(true);
      await RepositoryMaterialOrders.patchOrder(data.order_id, {
        status: 'disapproved',
        rejection_reason: data.rejection_reason,
      });
      setLoading(false);
      window.location.reload();
    }
  };

  const handleDeleteOrder = async id => {
    try {
      const response = await RepositoryMaterialOrders.deleteOrder(id);

      if (!response.error) {
        setModalStatus({ open: true, success: true, status: 'Pedido cancelado com sucesso!' });
      }
    } catch (err) {
      setModalStatus({ open: true, success: false, status: `Erro ao cancelar pedido: ${err}` });
    }
  };

  useEffect(() => {
    if (clinic != null) {
      fetchData();
      setHasClinicSelected(true);
    } else setHasClinicSelected(false);
  }, [clinic]);

  return {
    pedidos,
    catalogo,
    itens,
    loading,
    setLoading,
    requestApprover,
    hasClinicSelected,
    handleDeleteOrder,
    modalStatus,
    setModalStatus,
  };
};
