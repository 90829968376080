import Endpoints from '../config/endpoints';
import http from '../config/httpClient';
import { parseError } from './base';

export const PaymentSchedulesRepository = {
  baseUrl: Endpoints.payment_schedules,

  create: async function (payload) {
    try {
      const { data } = await http.post(`${this.baseUrl}`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
};
