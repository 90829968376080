import React from 'react';
import styles from './Select.module.scss';
import { MenuItem, TextField, Checkbox, ListItemText } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { BsChevronDown } from 'react-icons/bs';

function Select({
  name,
  label,
  onChange,
  options,
  value,
  style = {},
  error,
  helper = '',
  multiple = false,
  className,
  disabled,
}) {
  const uuid = uuidv4();
  const shrink = !!value;

  const custom_class = [styles.input];
  if (className) custom_class.push(className);

  return (
    <div className={styles.container}>
      <TextField
        select
        disabled={disabled}
        multiple={multiple}
        error={!!error}
        helperText={error ?? helper}
        labelid={`select-label-${uuid}`}
        sx={{
          fontSize: 16,
          ...style,
        }}
        variant="outlined"
        value={value == null ? '' : value}
        name={name}
        label={label}
        onChange={onChange}
        // input={<OutlinedInput label="Tag" />}
        // renderValue={selected => selected.join(', ')}
        className={custom_class.join(' ')}
        shrink={shrink.toString()}
        SelectProps={{
          IconComponent: () => (
            <BsChevronDown
              style={{
                marginRight: '10px',
                cursor: 'pointer',
                position: 'absolute',
                pointerEvents: 'none',
                right: '7px',
              }}
            />
          ),
        }}
      >
        {options.map((item, index) => {
          const selected = item.value === value;
          return (
            <MenuItem
              key={index}
              sx={{
                borderBottom: `${selected ? '2' : '1'}px solid #C5AA71`,
                fontWeight: selected ? '600' : '400',
              }}
              value={item.value}
              name={item.name}
            >
              {multiple ? (
                <>
                  <Checkbox checked={selected} />
                  <ListItemText primary={item.name} />
                </>
              ) : (
                <>{item.name}</>
              )}
            </MenuItem>
          );
        })}
      </TextField>
    </div>
  );
}

export default Select;
