import React from 'react';
import { FormClinicProvider } from 'contexts/formClinic';
import ConsultorioForm from './addClinica/ConsultorioForm';

export default function FormClinic({ tab }) {
  return (
    <FormClinicProvider>
      <ConsultorioForm tab={tab} />
    </FormClinicProvider>
  );
}
