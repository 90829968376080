import React from 'react';
import styles from './HeaderItems.module.scss';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { BiDollar } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Calendar } from 'assets/ico/calendar.svg';

export const FinanceiroHeader = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.header_icons_container}>
      <BiDollar className={styles.header_icon_dollar} onClick={() => navigate('/financeiro/solicitacao-compras')} />
      <AiOutlineShoppingCart
        className={styles.header_icon_cart}
        onClick={() => navigate('/financeiro/solicitacao-insumos')}
      />
    </div>
  );
};

export const RecepcaoHeader = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.header_icons_container}>
      <Calendar className={styles.header_icon_calendar} onClick={() => navigate('/concierge/home')} />
      <AiOutlineShoppingCart
        className={styles.header_icon_cart}
        onClick={() => navigate('/concierge/solicitacao-insumos')}
      />
    </div>
  );
};

export const MedicoHeader = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.header_icons_container}>
      <Calendar className={styles.header_icon_calendar} onClick={() => navigate('/atendimento/home')} />
      <AiOutlineShoppingCart className={styles.header_icon_cart} onClick={() => navigate('/atendimento/home')} />
    </div>
  );
};
