import yup from 'shared/validator.js';

let caixaSchema = yup.object({
  description: yup.string().max(100).required(),
  value: yup.number().required(),
  type: yup.string().required(),
  //   created_at: yup.string().required(),
});

export const validateCaixa = async data => {
  return await caixaSchema.validate(data);
};
