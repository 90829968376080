// hooks
import { useContext } from 'react';
import useApi from './hooks/useApi';
import useScheduleHanlders from './hooks/useScheduleHanlders';
import useModalController from './hooks/useModalController';
import { clinicalStorage } from 'shared/storage';
import { ClinicIdContext } from 'contexts/clinicId';
import { getWeekdayFromDate } from 'shared/utility';
import { useToast } from 'hooks/useToast';

// scss
import styles from './Schedule.module.scss';

// components
import Container from 'components/Container/Container';
import EventModal from './EventModal/EventModal';
import { Calendar } from 'components/Calendar/Calender';

export default function ServiceSchedule() {
  const { clinicId } = useContext(ClinicIdContext);
  const clinicsList = JSON.parse(clinicalStorage());
  const toast = useToast();
  const clinicData = clinicsList.find(c => c.id.toString() === clinicId?.toString());
  const { modalController, setModalController, onClose } = useModalController();
  const { defaultEvents } = useApi();
  const { events, professionalWorkingHours, onDateClick, onEventClick, addEvent } = useScheduleHanlders(defaultEvents, setModalController);

  let slotMinTime;
  let slotMaxTime;
  if (professionalWorkingHours.length > 0) {
    slotMinTime = professionalWorkingHours[0].start_time;
    slotMaxTime = professionalWorkingHours[0].end_time;
    professionalWorkingHours.forEach(workHours => {
      if (workHours.start_time < slotMinTime) {
        slotMinTime = workHours.start_time;
      }
      if (workHours.end_time > slotMaxTime) {
        slotMaxTime = workHours.end_time;
      }
    });
  }
  slotMinTime = slotMinTime ?? clinicData?.opening;
  slotMaxTime = slotMaxTime ?? clinicData?.closure;

  return (
    <Container className={styles.container}>
      <div className={styles.calendar_container}>
        <Calendar
          onDateClick={arg => {
            const weekday = getWeekdayFromDate(arg.date);
            const professionalDayHours = professionalWorkingHours.filter(hours => hours.day === weekday);
            const ignore = professionalWorkingHours.length === 0; //ignora pois não tem definição, fica a critério do usuário controlar a agenda
            if (ignore || professionalDayHours.length > 0) {
              arg.professionalDayHours = ignore ? null : professionalDayHours;
              onDateClick(arg);
            } else {
              toast.warning('Você não trabalha no consultório neste dia');
            }
          }}
          events={events}
          onEventClick={onEventClick}
          customHeaderToolbar={{
            left: 'today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
          }}
          customFooterToolbar={{
            left: '',
            center: '',
            right: 'prev next',
          }}
          slotMinTime={slotMinTime}
          slotMaxTime={slotMaxTime}
        />
      </div>
      <EventModal props={{ modalController, setModalController, onClose, addEvent }} />
    </Container>
  );
}
