// hooks
import { useHandlers } from '../hooks/handlers';

// scss
import styles from './Modal.module.scss';

// components
import PageTitle from 'components/PageTitle/PageTitle';
import Table from 'components/Table/Table';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import Input from 'components/InputIcon/InputIcon';
import { MdOutlineDeleteOutline } from 'react-icons/md';

export default function ModalSolicitarCompra({
  modalState,
  setModalState,
  orderState,
  setOrderState,
  catalogo,
  user,
  clinic,
  loading,
  setLoading,
}) {
  const {
    selectedInsumo,
    setSelectedInsumo,
    statusModal,
    setStatusModal,
    listInsumos,
    handleOrderRequest,
    handleAddItem,
    handleDeleteItem,
    errorMessage,
  } = useHandlers(modalState, orderState, catalogo, user, clinic, setLoading);

  return (
    <Modal
      isOpen={modalState.open}
      className={styles.solicitar_insumo_modal}
      setModalOpen={() => {
        setOrderState({ id: null, items: [] });
        setModalState({ open: false, is_edit: false });
      }}
    >
      <PageTitle>{modalState.is_edit ? 'Editar Solicitação de Insumo' : 'Solicitar Insumo'}</PageTitle>
      <div className={styles.solicitar_insumo_modal_top_container}>
        <div className={styles.solicitar_insumo_modal_autocomplete_container}>
          <Autocomplete
            name="items"
            label="Insumos"
            value={catalogo
              .map(item => ({ value: item.id, label: item.name }))
              .find(item => item.value === selectedInsumo.id)}
            options={catalogo.sort((a, b) => a.name - b.name).map(item => ({ value: item.id, label: item.name }))}
            onChange={(_, item) =>
              setSelectedInsumo(prevState => ({ ...prevState, id: item != null ? item.value : '' }))
            }
          />
        </div>
        <Input
          inputClassName={styles.solicitar_insumo_modal_input}
          type="number"
          min={0}
          max={999}
          value={selectedInsumo.amount}
          placeholder="Quantidade"
          onChange={event => {
            const inputValue = event.target.value;
            const numericValue = parseInt(inputValue, 10);
            const limitedValue = Math.min(numericValue, 999);

            setSelectedInsumo(prevState => ({
              ...prevState,
              amount: limitedValue,
            }));
          }}
        />
        <Button
          label="+"
          className={styles.solicitar_insumo_modal_add_button}
          onClick={handleAddItem}
          disabled={!selectedInsumo.id || !selectedInsumo.amount}
        />
      </div>
      <div className={styles.errorMessage}>
        <p>{errorMessage}</p>
      </div>
      <Table
        columns={['Insumos', 'Quantidade', '']}
        lines={listInsumos.map(data => [
          data.name,
          data.amount,
          <MdOutlineDeleteOutline
            color="#A31912" 
            style={{ cursor: 'pointer' }} 
            onClick={() => handleDeleteItem(data.id)} 
          />,
        ])}
      />
      <div className={styles.solicitar_insumo_footer_buttons}>
        <ButtonVoltar
          onClick={() => {
            setOrderState({ id: null, items: [] });
            setModalState({ open: false, is_edit: false });
          }}
        />
        <Button
          label={modalState.is_edit ? 'Salvar' : 'Solicitar'}
          onClick={handleOrderRequest}
          disabled={listInsumos.length === 0 || loading}
        />
      </div>
      <Modal
        isOpen={statusModal.open}
        setModalOpen={() => {
          setStatusModal({ ...statusModal, open: false });
          window.location.reload();
        }}
        className={styles.modal_container}
      >
        {statusModal.error && !statusModal.success && (
          <div className={styles.modal_inner_container_error}>
            <h1>Erro!</h1>
            <span>{statusModal.error}</span>
          </div>
        )}
      </Modal>
    </Modal>
  );
}
