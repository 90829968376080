import React from 'react';
import styles from './FormControl.module.scss';

function FormControl({ fullWidth = true, width, children }) {
  return (
    <div
      className={styles.container}
      style={{
        ...(width ? { width: width } : {}),
      }}
    >
      {children}
    </div>
  );
}

export default FormControl;
