import React, { useState, useEffect, useContext } from 'react';
import styles from './AddProfessionals.module.scss';
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import Card from 'components/Card/Card';
import { useToast } from 'hooks/useToast';
import Input from 'components/Input/Input';
import { LoaderContext } from 'contexts/loader';
import Button from 'components/Button/Button';
import TableFooter from 'components/TableFooter/TableFooter';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import { validateProfessional } from './validator';
import { apiBadRequestError } from 'exceptions/api';
import { useNavigate, useParams } from 'react-router-dom';
import { ServiceNamesRepository } from 'data/professionals';

function AddProfessionals() {
  const toast = useToast();
  let navigate = useNavigate();
  const { id } = useParams();
  const { setLoading } = useContext(LoaderContext);
  const [formData, setFormData] = useState({ name: '' });

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const response = await ServiceNamesRepository.get(id);
      setLoading(false);
      if (response?.error) {
        toast.error(response.error);
      } else {
        setFormData(response);
      }
    };
    if (id) run();
  }, []);

  const handleChange = event => {
    const value = event.target.value;
    setFormData({ ...formData, [event.target.name]: value });
  };

  const handleSubmit = () => {
    try {
      validateProfessional(formData);
    } catch (error) {}
    try {
      saveData();
    } catch (err) {}
  };

  const saveData = async () => {
    setLoading(true);
    const response = id
      ? await ServiceNamesRepository.update(id, formData)
      : await ServiceNamesRepository.create(formData);
    setLoading(false);
    if (response?.error) throw new apiBadRequestError(response.error, response.debug);
    return navigate('/admin/profissionais');
  };

  return (
    <Container className={styles.container}>
      <PageTitle>Adicionar um profissional</PageTitle>
      <Card className={styles.card}>
        <Input label="Profissão" name="name" value={formData.name} onChange={handleChange} />
      </Card>
      <TableFooter className={styles.footer}>
        <ButtonVoltar link="/admin/profissionais" />
        <Button label="Salvar" onClick={handleSubmit} />
      </TableFooter>
    </Container>
  );
}

export default AddProfessionals;
