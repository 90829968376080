// hooks
import { useContext, useEffect, useState } from 'react';

// scss
import styles from './Profiles.module.scss';

// components
import Button from 'components/Button/Button.js';
import Modal from 'components/Modal/Modal';
import Table from 'components/Table/Table';
import TextArea from 'components/TextArea/TextArea';
import { CircularProgress, Grid } from '@mui/material';
import { StockRepository } from 'data/stock';
import { ClinicIdContext } from 'contexts/clinicId';
import Input from 'components/InputIcon/InputIcon';
import Autocomplete from 'components/Autocomplete/Autocomplete';

export const ProfileDefault = ({ props }) => {
  const { setTabInfo, orderState } = props;

  return (
    <>
      <div className={styles.ver_solicitacao_main_container}>
        <div className={styles.ver_solicitacao_top_card}>
          <div className={styles.ver_solicitacao_top_card_left}>
            <span>Data</span>
            <span>Solicitante</span>
            <span>Status</span>
            {orderState.status === 'Reprovado' && <span>Motivo</span>}
          </div>
          <div className={styles.ver_solicitacao_top_card_right}>
            <span>{orderState.data}</span>
            <span>{orderState.solicitante}</span>
            <span>{orderState.status}</span>
            {orderState.status === 'Reprovado' && <span>{orderState.motivo !== '' ? orderState.motivo : '-'}</span>}
          </div>
        </div>
        <div className={styles.ver_solicitacao_table_container}>
          <Table
            className={styles.ver_solicitacao_compras_table}
            lines={orderState.items.map(item => [item.name, `${item.quantity} unid.`])}
            columns={['Insumos', 'Quantidade']}
          />
        </div>
        <div className={styles.ver_solicitacao_buttons_container}>
          <div className={styles.ver_solicitacao_buttons_inner_container}>
            <Button
              label="Fechar"
              className={styles.ver_solicitacao_button_fechar}
              onClick={() => setTabInfo('lista_solicitacoes')}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const Estoque = ({ props }) => {
  const { setTabInfo, orderState, requestApprover } = props;
  const [selectedInfo, setSelectedInfo] = useState({
    order_id: orderState.order_id,
    file: null,
    rejection_reason: '',
  });
  const [approverModal, setApproverModal] = useState({
    id: '',
    open: false,
  });

  return (
    <>
      <div className={styles.ver_solicitacao_main_container}>
        <div className={styles.ver_solicitacao_top_card}>
          <div className={styles.ver_solicitacao_top_card_left}>
            <span>Data</span>
            <span>Solicitante</span>
            <span>Status</span>
            {orderState.status === 'Reprovado' && <span>Motivo</span>}
          </div>
          <div className={styles.ver_solicitacao_top_card_right}>
            <span>{orderState.data ?? '-'}</span>
            <span>{orderState.solicitante ?? '-'}</span>
            <span>{orderState.status ?? '-'}</span>
            {orderState.status === 'Reprovado' && <span>{orderState.motivo !== '' ? orderState.motivo : '-'}</span>}
          </div>
        </div>
        <div className={styles.ver_solicitacao_table_container}>
          <Table
            className={styles.ver_solicitacao_compras_table}
            lines={orderState.items.map(item => [item.name, item.quantity])}
            columns={['Insumos', 'Quantidade']}
          />
        </div>
        <div className={styles.ver_solicitacao_buttons_container}>
          <div className={styles.ver_solicitacao_buttons_inner_container}>
            <Button
              label="Fechar"
              className={styles.ver_solicitacao_button_fechar}
              onClick={() => setTabInfo('lista_solicitacoes')}
            />
            {orderState.status === 'Aguardando' && (
              <>
                <Button
                  label="Reprovar"
                  className={styles.ver_solicitacao_button_reprovar}
                  onClick={() => setApproverModal(prevState => ({ ...prevState, open: true, id: 'reprovar' }))}
                />
                <Button
                  label="Aprovar"
                  className={styles.ver_solicitacao_button_aprovar}
                  onClick={() => setApproverModal(prevState => ({ ...prevState, open: true, id: 'aprovar' }))}
                />
              </>
            )}
          </div>
          <Modal
            className={styles.ver_solicitacao_modal}
            isOpen={approverModal.open}
            setModalOpen={() => setApproverModal({ open: false })}
          >
            {approverModal.id === 'reprovar' && (
              <div className={styles.ver_solicitacao_modal_reprovar_body}>
                <h3>Reprovar Pedido</h3>
                <TextArea
                  minRows={3}
                  placeholder="Motivo..."
                  value={selectedInfo.rejection_reason}
                  onChange={e => setSelectedInfo({ ...selectedInfo, rejection_reason: e.target.value })}
                />
                <Button
                  label="Confirmar"
                  onClick={() => {
                    requestApprover(selectedInfo, false);
                  }}
                />
              </div>
            )}
            {approverModal.id === 'aprovar' && (
              <EstoqueAprovarModal requestApprover={requestApprover} orderState={orderState} />
            )}
          </Modal>
        </div>
      </div>
    </>
  );
};

const EstoqueAprovarModal = ({ orderState, requestApprover }) => {

  const { clinicId } = useContext(ClinicIdContext);
  const [selectedInfo, setSelectedInfo] = useState({
    order_id: orderState.order_id,
    stocks: [],
  });
  const hasItemsInOrder = orderState.items.length > 0;
  const [tabInfo, setTabInfo] = useState(hasItemsInOrder ? orderState.items[0].id : null);
  const [qtdValue, setQtdValue] = useState('');
  const [selectedStock, setSelectedStock] = useState(null);
  const [stock, setStock] = useState(null);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    const run = async () => {
      setStock(null);
      setSelectedStock(null);
      setQtdValue('');
      const orderItem = orderState.items.find(oi => oi.id === tabInfo);
      const response = await StockRepository.list({ clinic: clinicId, item: orderItem.item });
      setStock(response?.results ?? []);
    };
    if (tabInfo != null && clinicId != null) {
      run();
    }
  }, [tabInfo]);

  const stockBatchQtd = {};
  const stockOptions = [];
  if (stock != null && tabInfo != null) {
    stock.forEach(stockItem => {
      let qtd = Math.trunc(stockItem.balance);
      selectedInfo.stocks.filter(e => e.stock === stockItem.id).forEach(e => qtd -= e.quantity);
      stockBatchQtd[stockItem.id] = qtd;
      stockOptions.push({
        label: `Lote: ${stockItem.batch}, Qtd: ${qtd}`,
        value: stockItem.id,
      });
    });
  }

  let remainingQtd = 0;
  if (hasItemsInOrder && tabInfo != null) {
    const item = orderState.items.find(e => e.id === tabInfo);
    remainingQtd = item.quantity;
    selectedInfo.stocks.filter(e => e.item_order === tabInfo).forEach(e => remainingQtd -= e.quantity);
  }

  return (
    <div className={styles.ver_solicitacao_modal_aprovar_body}>
      <h3>{hasItemsInOrder ? 'Selecionar lotes do estoque' : 'Aprovar pedido'}</h3>
      {hasItemsInOrder ? (
        <>
          <div className={styles.tab}>
            {orderState.items.map(item => (
              <span
                className={tabInfo === item.id ? styles.elementActive : styles.element}
                onClick={() => setTabInfo(item.id)}
              >
                {item.item_name}
              </span>
            ))}
          </div>
          {stock == null ? (
            <div align="center" style={{marginBottom: '30px'}}>
              <CircularProgress color="success" size={40} />
            </div>
          ) : (
            <>
              <div align="right">Ainda faltam informar a origem de: {remainingQtd} unidades.</div>
              <Grid container spacing={1} className={styles.inputs}>
                <Grid item xs={8}>
                  <Autocomplete
                    label="Lote do Estoque"
                    value={selectedStock}
                    options={stockOptions.sort((a, b) => a.label - b.label)}
                    onChange={(_, item) => setSelectedStock(item)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Input
                    name="quantity"
                    value={qtdValue}
                    placeholder="Qtd p/ usar do lote"
                    inputClassName={styles.input_qtd}
                    onChange={e => setQtdValue(e.target.value)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button
                    label="+"
                    disabled={
                      selectedStock == null || 
                      qtdValue === '' || 
                      qtdValue > remainingQtd ||
                      qtdValue > stockBatchQtd[selectedStock.value]
                    }
                    onClick={() => {
                      setSelectedInfo({
                        ...selectedInfo,
                        stocks: [
                          ...selectedInfo.stocks,
                          {
                            item_order: tabInfo,
                            stock: selectedStock.value,
                            quantity: parseInt(qtdValue),
                          }
                        ],
                      });
                      setQtdValue('');
                      setSelectedStock(null);
                    }}
                  />
                </Grid>
              </Grid>
              <Table
                columns={['Lote do Estoque', 'Quantidade', 'Remover']}
                tableHeight="250px"
                lines={selectedInfo.stocks.filter(e => e.item_order === tabInfo).map((e, index) => [
                  `Lote ${stock.find(stock => stock.id === e.stock)?.batch}`,
                  e.quantity,
                  <Button
                    label="-"
                    size="sm"
                    onClick={() => {
                      selectedInfo.stocks.splice(index+1, 1);
                      setSelectedInfo({
                        ...selectedInfo,
                        stocks: [
                          ...selectedInfo.stocks,
                        ],
                      });
                    }}
                  />
                ])}
              />
            </>
          )}
        </>
      ) : (
        <p>Tem certeza que deseja aprovar este pedido?</p>
      )}
      <Button
        label="Confirmar"
        onClick={() => {
          if (hasItemsInOrder) {
            for (const item of orderState.items) {
              let remainingQtd = item.quantity;
              selectedInfo.stocks.filter(e => e.item_order === item.id).forEach(e => remainingQtd -= e.quantity);
              if (remainingQtd > 0) {
                setErrorText(`Ainda faltam informar ${remainingQtd} unidades de ${item.item_name}`);
                setTimeout(() => {
                  setErrorText('');
                }, 5000);
                return;
              }
            }
          }  
          requestApprover(selectedInfo, true);
        }}
      />
      <div className={styles.error}>{errorText}</div>
    </div>
  );
}
