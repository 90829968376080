import React, { useContext } from 'react';
import styles from './HomeModulesButton.module.scss';
import { useNavigate } from 'react-router-dom';
import { FaAngleLeft } from 'react-icons/fa6';
import Enums from 'transformers/enums';
import { AuthContext } from 'contexts/auth';

function HomeModulesButton({ navTab, navOpen }) {
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  let currentPathname = window.location.pathname;
  if (currentPathname.charAt(currentPathname.length - 1) === '/') {
    currentPathname = currentPathname.substring(0, currentPathname.length - 1);
  }

  if (currentPathname === '/home') {
    //Se já está na tela de exibição de módulos, não precisamos exibir esse botão
    return '';
  }

  let currentModuleHome = `/${Enums.navTabToLabelUrl[navTab]}/home`;
  if (currentPathname === currentModuleHome) {
    //Significa que já está na tela inicial do módulo, então o "botão de voltar" deve ir para a tela de seleção dos módulos
    // navTab = 'default';
    currentModuleHome = `/${Enums.navTabToLabelUrl[navTab]}/home`;
  }

  const hasMultiplesModules = (user?.user_types ?? []).length > 1;
  if (navTab === 'default' && !hasMultiplesModules) {
    return '';
  }

  return (
    <div className={styles.homemodules}>
      <div className={styles.home}>
        <div
          className={`${styles.links} ${navTab === '/home' ? styles.linkActive : ''}`}
          onClick={() => navigate('/home')}
        >
          <FaAngleLeft />
          {navOpen && <span>Alterar Módulo</span>}
        </div>
      </div>
    </div>
  );
}
export default HomeModulesButton;
