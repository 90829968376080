import DefaultLayout from "App/layouts/DefaultLayout/DefaultLayout";
import LoginPage from "pages/shared/Login/LoginPage";
import PrimeiroAcesso from "pages/shared/Login/PrimeiroAcesso/PrimeiroAcesso";
import RedefinirSenha from "pages/shared/Login/RedefinirSenha/RedefinirSenha";
import Root from "pages/Root";

export const routes = [
  {
    key: '/',
    path: '/',
    element: (
      <DefaultLayout button={true} header={false} nav="login">
        <Root />
      </DefaultLayout>
    ),
  },
  {
    key: '*',
    path: '*',
    element: (
      <DefaultLayout button={true} header={false} nav="login">
        <Root />
      </DefaultLayout>
    ),
  },
  {
    key: '/primeiro-acesso/:token',
    path: '/primeiro-acesso/:token',
    element: (
      <DefaultLayout button={true} header={false} nav="login">
        <PrimeiroAcesso />
      </DefaultLayout>
    ),
  },
  {
    key: '/login',
    path: '/login',
    element: (
      <DefaultLayout header={false} nav="login">
        <LoginPage />
      </DefaultLayout>
    ),
  },
  {
    key: '/login/redefinir-senha',
    path: '/login/redefinir-senha',
    element: (
      <DefaultLayout button={true} header={false} nav="login">
        <RedefinirSenha />
      </DefaultLayout>
    ),
  },
];
