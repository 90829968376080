import React from 'react';
import styles from './Card.module.scss';

function Card({ title, children, className }) {
  return (
    <div className={[styles.container, className].join(' ')}>
      <h2>{title}</h2>
      {children}
    </div>
  );
}

export default Card;
