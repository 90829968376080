import React, { useState } from 'react';
import styles from './TabSelector.module.scss';

function TabSelector({ options, bordered, ...props }) {
  const optionsKeys = Object.keys(options).filter(key => !!key);
  const [tab, setTab] = useState(optionsKeys[0]);

  return <div className={styles.container} {...props}>
    <div className={styles.options}>
      {bordered && <div className={styles.bordered} />}
      {optionsKeys.map(option => {
        const classes = [styles.option];
        if (tab === option) {
          classes.push(styles.selectedOption);
        }
        return (
          <div 
            className={classes.join(' ')}
            onClick={() => setTab(option)}
          >
            {option}
          </div>
        )
      })}   
      {bordered && <div className={styles.bordered} />}
    </div>
    {options[tab]}
  </div>;
  }
  
  export default TabSelector;
  