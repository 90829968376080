import Enums from './enums';
// import moment from 'moment';

export const transformUserFormToApi = data => {
  const newTypesArr = data.profile.user_types?.map(type => Enums.profileNameToId[type] || type);
  const newCLinicsArr = data.clinics?.map(clinic => clinic.id);
  return {
    ...data.userData,
    doctor: data.profile.crm ? { crm: data.profile.crm } : null,
    finance: data.profile.approver ? { approver: data.profile.approver } : null,
    clinics: newCLinicsArr,
    user_types: newTypesArr,
  };
};

export const transformSpecialtyFormToApi = data => {
  const transformedData = data.specialtiesPerClinic.reduce((result, item) => {
    const { specialtyId, clinicId } = item;

    const existingIndex = result.findIndex(entry => entry.specialtyId === specialtyId);

    if (existingIndex !== -1) {
      result[existingIndex].clinics.push(clinicId);
    } else {
      result.push({ specialtyId, clinics: [clinicId] });
    }

    return result;
  }, []);

  const finalData = transformedData.map(item => ({
    specialties_type: item.specialtyId,
    clinic: item.clinics,
    rqe: data.specialties.find(f => f.id === item.specialtyId)?.rqe || null,
  }));

  return finalData;
};

export const buildInitialScheduleStructure = () => {
  const weekDays = {
    seg: 'Segunda',
    ter: 'Terça',
    qua: 'Quarta',
    qui: 'Quinta',
    sex: 'Sexta',
    sab: 'Sábado',
    dom: 'Domingo',
  };

  const scheduleStructure = Object.keys(weekDays).reduce(
    (acc, item) => ({
      ...acc,
      [item]: {
        morning: {
          id: null,
          start_time: null,
          end_time: null,
          clinic: null,
        },
        afternoon: {
          id: null,
          start_time: null,
          end_time: null,
          clinic: null,
        },
      },
    }),
    {}
  );

  return scheduleStructure;
};

export const transformUserHoursFormToApi = (user, data) => {
  return Object.keys(data).reduce((acc, itemKey) => {
    const hours = [];
    const item = data[itemKey];
    if (item.morning && item.morning.start_time) {
      hours.push({
        id: item.morning.id,
        start_time: item.morning.start_time,
        end_time: item.morning.end_time,
        day: itemKey,
        user: parseInt(user),
        clinic: item.morning.clinic,
      });
    }
    if (item.afternoon && item.afternoon.start_time) {
      hours.push({
        id: item.afternoon.id,
        start_time: item.afternoon.start_time,
        end_time: item.afternoon.end_time,
        day: itemKey,
        user: parseInt(user),
        clinic: item.afternoon.clinic,
      });
    }

    return [...acc, ...hours];
  }, []);
};

export const parseUserHoursToFormData = (data, initialData) => {
  const result = { ...initialData };
  data.forEach(item => {
    if (!result[item.day]) return;
    if (!result[item.day]?.morning.start_time) {
      result[item.day].morning.start_time = item.start_time;
      result[item.day].morning.end_time = item.end_time;
      result[item.day].morning.clinic = item.clinic;
      result[item.day].morning.id = item.id;
    } else {
      if (result[item.day].morning.start_time > item.start_time) {
        result[item.day].afternoon.start_time = result[item.day].morning.start_time;
        result[item.day].afternoon.end_time = result[item.day].morning.end_time;
        result[item.day].afternoon.clinic = result[item.day].morning.clinic;
        result[item.day].afternoon.id = result[item.day].morning.id;
        result[item.day].morning.start_time = item.start_time;
        result[item.day].morning.end_time = item.end_time;
        result[item.day].morning.clinic = item.clinic;
        result[item.day].morning.id = item.id;
      } else {
        result[item.day].afternoon.start_time = item.start_time;
        result[item.day].afternoon.end_time = item.end_time;
        result[item.day].afternoon.clinic = item.clinic;
        result[item.day].afternoon.id = item.id;
      }
    }
  });
  return result;
};

export const transformClinicDataToApi = data => {
  return {
    ...data.address,
    ...data.clinicData,
    cnpj: data.clinicData.cnpj.replace(/\D/g, ''),
    ...data.times,
  };
};

export const transformApiToClinicData = data => {
  const address = {
    address: data.address,
    cep: data.cep,
    city: data.city,
    complement: data.complement,
    district: data.district,
    number: data.number,
    state: data.state,
  };

  const clinicData = {
    cnpj: data.cnpj,
    email: data.email,
    name: data.name,
    phone_number: data.phone_number,
  };

  const times = {
    closure: data.closure.slice(0, 5),
    opening: data.opening.slice(0, 5),
  };

  return {
    address,
    clinicData,
    times,
  };
};
export const transformApiToDadosBancarios = data => {
  return {
    account: data.account,
    agency: data.agency,
    banks: data.banks,
    banks_name: data.banks_name,
    clinic: data.clinic,
    clinic_name: data.clinic_name,
    origin: data.origin,
  };
};

export const transformApiToPlanoContas = data => {
  return {
    clinic: data.clinic.toString(),
    clinic_name: data.clinic_name,
    type: data.type,
    parent: data.parent,
    parent_name: data.parent_name,
    name: data.name,
    isForSchedule: data.isForSchedule,
    pastOrFutureLaunch: data.pastOrFutureLaunch,
  };
};

export const transformApiToInsumos = data => {
  return {
    name: data.name,
    unit_type: data.unit_type,
    clinic: data.clinic,
    item_code: data.item_code,
    code_item: data.code_item.map(c => ({code: c.code, supplier: c.supplier})),
  };
};

export const transformApiToPaymentMethod = data => {
  return {
    bank_account: data.bank_account,
    tax: data.tax,
    chart_of_account: data.chart_of_account,
    number_release_days: data.number_release_days,
    description: data.description,
  };
};

export const transformApiToSupplier = data => {
  return {
    company_name: data.company_name,
    cnpj: data.cnpj.replace(/\D/g, ''),
    supplier_contacts: data.supplier_contacts,
  };
};

export const transformApiToPatient = data => {
  return {
    name: data.name,
    birthdate: data.birthdate,
    gender: data.gender,
    cpf: data.cpf.replace(/\D/g, ''),
    phone: data.phone,
    address: data.address,
    affiliation: data.affiliation,
    email: data.email,
    clinic: data.clinic,
    observation: data.observation,
    rg: data.rg,
    social_url: data.social_url,
    indication: data.indication,
    patient_indication: data.patient_indication,
  };
};

export const transformApiToMedicalRecords = data => {
  return {
    ...data,
    bmi: data.bmi ? data.bmi.toString() : '',
    height: data.height ? data.height.toString() : '',
    weight: data.weight ? data.weight.toString() : '',
  };
};
