import React, { useState } from 'react';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import styles from './ModalMedicamento.module.scss';
import Input from 'components/Input/Input';
import { validateMedicamentoData } from './validator';
import { useToast } from 'hooks/useToast';

const ModalMedicamento = ({isOpen, onClose, onCreate}) => {
  const [name, setName] = useState('');
  const toast = useToast();

  if (!isOpen && name.length > 0) {
    setName('');
  }

  return (
    <Modal isOpen={isOpen} setModalOpen={onClose}>
      <div className={styles.modalMedi}>
        <h1>Novo Medicamento</h1>
        <Input
          name="name"
          value={name}
          type="text"
          label="Medicamento"
          onChange={e => setName(e.target.value)}
        ></Input>
        <div className={styles.btnMedi}>
          <Button 
            onClick={async () => {
              try {
                const postData = { name };
                await validateMedicamentoData(postData);
                await onCreate(postData);
              } catch (err) {
                toast.error(err.errors?.[0] || err.message);
              }
            }} 
            type="button" 
            label="Salvar" 
            color="secondary" 
          />
        </div>
      </div>
    </Modal>
  )
}

export default ModalMedicamento;