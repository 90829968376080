// hooks
import { useState } from 'react';

// other
import moment from 'moment';

const initialFilters = {
  clinic: '',
  transaction_date_range_after: new Date(moment().startOf('month').subtract(1, 'years').format('YYYY-MM-DD HH:mm')),
  transaction_date_range_before: new Date(moment().endOf('month').format('YYYY-MM-DD HH:mm')),
  bank_account: '',
  income: '',
  limit: 30,
  offset: 0,
};

export default function useFilters() {
  const [filters, setFilters] = useState(initialFilters);

  const onSetFilterList = (prop, selected) => {
    setFilters(prevState => ({
      ...prevState,
      [prop]: selected,
      offset: 0,
    }));
  };

  const handleDateChange = (clearField, newDate) => {
    if (clearField) {
      setFilters(prevState => ({
        ...prevState,
        transaction_date_range_after: '',
        transaction_date_range_before: '',
        offset: 0,
      }));
    } else if (newDate.length >= 2 && newDate[0] !== null && newDate[1] !== null) {
      const [startDate, endDate] = newDate;
      setFilters(prevState => ({
        ...prevState,
        transaction_date_range_after: moment(startDate).format('YYYY-MM-DD'),
        transaction_date_range_before: moment(endDate).format('YYYY-MM-DD'),
        offset: 0,
      }));
    }
  };

  const handleDeleteFilters = () => {
    setFilters({
      ...initialFilters,
      transaction_date_range_after: new Date(moment().startOf('month').subtract(2, 'years').format('YYYY-MM-DD HH:mm')),
      transaction_date_range_before: new Date(moment().startOf('month').add(100, 'years').format('YYYY-MM-DD HH:mm')),
    });
  };

  return {
    // getters
    filters,
    initialFilters,

    // setters
    setFilters,

    // handlers
    handleDateChange,
    handleDeleteFilters,
    onSetFilterList,
  };
}
