import React, { useState, useEffect, useContext } from 'react';
import styles from './RelatorioComissao.module.scss';
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import { Grid } from '@mui/material';
import Table from 'components/Table/Table';
import { CiFilter } from 'react-icons/ci';
import Select from 'components/Select/Select';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import InputDatePickerRange from 'components/InputDatePickerRange/InputDatePickerRange';
import TableFooter from 'components/TableFooter/TableFooter';
import { CommissionRepository } from 'data/commissions';
import { ClinicIdContext } from 'contexts/clinicId';
import { LoaderContext } from 'contexts/loader';
import { formatCurrency } from 'shared/utility';
import Enums from 'transformers/enums';
import { UserRepository } from 'data/users';

function RelatorioComissao() {
  const { clinicId } = useContext(ClinicIdContext);
  const { setLoading } = useContext(LoaderContext);
  const [professional, setProfessional] = useState([]);
  const [list, setList] = useState([]);
  
  useEffect(() => {
    if (clinicId != null) {
      loadCommission();
    }
  }, [clinicId]);
  
  const loadCommission = async () => {
    setLoading(true);
    const responseCommission = await CommissionRepository.list({ clinic: clinicId });
    const responseUsers = await UserRepository.list({ clinic: clinicId, user_type: Enums.profileNameToId.DOCTOR });
    setList(responseCommission?.results ?? []);
    setProfessional(responseUsers?.results ?? []);
    setLoading(false);
  };

  const [chosenProfessional, setChosenProfessional] = useState('');
  const [valueDate, setValueDate] = useState('');

  const handleProfessionalChange = e => {
    setChosenProfessional(e.target.value);
  };

  const handleDateRangeChange = e => {
    setValueDate(e);
  };

  return (
    <Container className={styles.container}>
      <PageTitle>Relatório Comissão</PageTitle>
      <div className={styles.header}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputDatePickerRange label="Período" value={valueDate} onChange={handleDateRangeChange} />
          </Grid>
          <Grid item xs={6}>
            <Select
              options={professional.map(item => ({ value: item.id, name: `${item.first_name} ${item.last_name}` }))}
              value={chosenProfessional}
              onChange={handleProfessionalChange}
              icon={<CiFilter />}
              label="Profissional"
            />
          </Grid>
        </Grid>
        <Table
          columns={['Nome do profissional', 'Valor']}
          lines={list.map(item => [`${item.first_name} ${item.last_name}`, formatCurrency(item.value)])}
        />
        <TableFooter>
          <ButtonVoltar link="/financeiro/home" />
        </TableFooter>
      </div>
    </Container>
  );
}

export default RelatorioComissao;
