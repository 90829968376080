import React from 'react';
import styles from './LoaderScreen.module.scss';
import { CircularProgress } from '@mui/material';

function LoaderScreen({ show }) {
  return (
    show && (
      <div className={styles.container}>
        <CircularProgress color="success" size={75} />
      </div>
    )
  );
}

export default LoaderScreen;
