import { useState, useEffect, useContext } from 'react';
import { useToast } from 'hooks/useToast';
import { ClinicIdContext } from 'contexts/clinicId';
import { PaymentMethodRepository } from 'data/paymentmethod.js';

const initialModalState = {
  isOpen: false,
  success: false,
  error: false,
  msg: '',
};

export function useCobrancas(setLoading) {
  const { clinicId } = useContext(ClinicIdContext);
  const [list, setList] = useState([]);
  const [modalState, setModalState] = useState(initialModalState);

  const toast = useToast();

  async function fetchCobrancas() {
    setLoading(true);
    const response = await PaymentMethodRepository.list({ clinic: clinicId });

    if (response && response.results) {
      setList(response.results);
      setLoading(false);
    } else {
      toast.error(response.error);
      setList([]);
      setLoading(false);
    }
  }

  const handleDelete = async paymentId => {
    setLoading(true);
    const response = await PaymentMethodRepository.delete(paymentId);
    if (response?.error) {
      setModalState({
        isOpen: true,
        error: true,
        msg: response.error,
      });
      setLoading(false);
    } else {
      setList(list.filter(item => item.id !== paymentId));
      setLoading(false);
      setModalState({
        isOpen: true,
        success: true,
      });
    }
  };

  useEffect(() => {
    if (clinicId != null) {
      fetchCobrancas();
    }
  }, [clinicId]);
  return { list, handleDelete, modalState, setModalState };
}
