import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useFeedbackModal() {

  const navigate = useNavigate()
  const [feedbackModal, setFeedbackModal] = useState({
    open: false,
    type: '', // success ou error
    message: '',
  });

  function onClose() {
    const isSuccessModal = feedbackModal.type === 'success';
    setFeedbackModal({
      open: false,
      type: '',
      message: '',
    });
    if (isSuccessModal) {
      navigate('/admin/colaboradores');
    }
  }

  return {
    feedbackModal,
    setFeedbackModal,
    onClose,
  };
}
