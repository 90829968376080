// hooks
import { useNavigate } from 'react-router-dom';

// scss
import styles from '../AdicionarColaborador.module.scss';

// tabs
import AddUserData from '../tabs/data/data';
import AddUserProfile from '../tabs/profile/profile';
import AddUserSpecialties from '../tabs/specialties/specialties';
import AddUserClinic from '../tabs/clinic/clinic';
import AddUserSchedule from '../tabs/schedule/schedule';

export default function useTabs(
  form,
  setForm,
  id,
  edit,
  error,
  serviceNames,
  specialties,
  clinics,
  setControlTabs,
  loading,
  unlockedTabs,
  submitData,
  validateOnBlur
) {
  const navigate = useNavigate();

  const editUserNavigationTabs = {
    data: `/admin/colaboradores/${id}/editar`,
    profile: `/admin/colaboradores/${id}/editar/perfil`,
    clinic: `/admin/colaboradores/${id}/editar/vincularconsultorio`,
    specialties: `/admin/colaboradores/${id}/editar/especialidades`,
    schedule: `/admin/colaboradores/${id}/editar/horarios`,
  };

  const addUserNavigationTabs = {
    data: `/admin/colaboradores/novo`,
    profile: `/admin/colaboradores/novo/perfil`,
    clinic: `/admin/colaboradores/novo/vincularconsultorio`,
    specialties: `/admin/colaboradores/novo/especialidades`,
    schedule: `/admin/colaboradores/novo/horarios`,
  };

  const tabContent = {
    data: <AddUserData props={{ form, setForm, error, validateOnBlur }} />,
    profile: <AddUserProfile props={{ form, setForm, error, serviceNames }} />,
    specialties: <AddUserSpecialties props={{ form, setForm, error, specialties, loading }} />,
    clinic: <AddUserClinic props={{ form, setForm, error, clinics }} />,
    schedule: <AddUserSchedule props={{ form, setForm, error, clinics }} />,
  };

  const Tab = ({ tabKey, label, activeTab, onClick }) => (
    <span className={activeTab === tabKey ? styles.active_tab : styles.default_tab} onClick={() => onClick(tabKey)}>
      {label}
    </span>
  );

  function getTabLabel(tabKey) {
    switch (tabKey) {
      case 'data':
        return 'Dados';
      case 'profile':
        return 'Perfil';
      case 'specialties':
        return 'Especialidades';
      case 'clinic':
        return 'Consultório';
      case 'schedule':
        return 'Horários';
      default:
        return '';
    }
  }

  const handleTabClick = selectedTab => {
    if (edit) {
      navigate(editUserNavigationTabs[selectedTab]);
      setControlTabs(selectedTab);
    } else {
      navigate(addUserNavigationTabs[selectedTab]);
      setControlTabs(selectedTab);
    }
  };

  const handleNextClick = selectedTab => {
    switch (selectedTab) {
      case 'data':
        navigate(addUserNavigationTabs['profile']);
        setControlTabs('profile');
        break;
      case 'profile':
        if (!unlockedTabs.includes('specialties')) {
          navigate(addUserNavigationTabs['clinic']);
          setControlTabs('clinic');
        } else {
          navigate(addUserNavigationTabs['specialties']);
          setControlTabs('specialties');
        }
        break;
      case 'specialties':
        navigate(addUserNavigationTabs['clinic']);
        setControlTabs('clinic');
        break;
      case 'clinic':
        if (form.user_types.includes(1)) {
          navigate(addUserNavigationTabs['schedule']);
          setControlTabs('schedule');
        } else {
          submitData();
        }
        break;
      case 'schedule':
        submitData();
        break;
      default:
        break;
    }
  };

  const handlePreviousClick = selectedTab => {
    switch (selectedTab) {
      case 'data':
        break;
      case 'profile':
        navigate(addUserNavigationTabs['data']);
        setControlTabs('data');
        break;
      case 'specialties':
        navigate(addUserNavigationTabs['profile']);
        setControlTabs(selectedTab);
        break;
      case 'clinic':
        if (!unlockedTabs.includes('specialties')) {
          navigate(addUserNavigationTabs['profile']);
          setControlTabs('profile');
        } else {
          navigate(addUserNavigationTabs['specialties']);
          setControlTabs('specialties');
        }



        break;
      case 'schedule':
        navigate(addUserNavigationTabs['clinic']);
        setControlTabs('clinic');
        break;
      default:
        break;
    }
  };

  return {
    Tab,
    tabContent,
    handleTabClick,
    handleNextClick,
    handlePreviousClick,
    getTabLabel,
  };
}
