import Endpoints from '../config/endpoints';
import http from '../config/httpClient';
import { parseError } from './base';
import { stringifyFilters } from '../shared/utility';

export const BankAccountsRepository = {
  baseUrl: Endpoints.bankaccounts,

  get: async function (id) {
    try {
      const { data } = await http.get(`${this.baseUrl}/${id}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  list: async function (filters = {}) {
    try {
      const queryString = stringifyFilters({ ...filters });
      const { data } = await http.get(`${this.baseUrl}${queryString}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  create: async function (id, payload) {
    try {
      const { data } = await http.post(`${this.baseUrl}?clinic=${id}`, { ...payload });
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  update: async function (id, payload) {
    try {
      const { data } = await http.put(`${this.baseUrl}/${id}`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  delete: async function (id) {
    try {
      const { data } = await http.delete(`${this.baseUrl}/${id}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
};
