// hooks
import { useState } from 'react';

// scss
import styles from './specialties.module.scss';

// components
import Autocomplete from 'components/Autocomplete/Autocomplete';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import Table from 'components/Table/Table';
import { Grid } from '@mui/material';

export default function AddUserSpecialties({ props }) {
  const { form, setForm, error, specialties } = props;

  const [selectedSpecialty, setSelectedSpecialty] = useState({ specialtie_name: '', specialties_type: '', rqe: '' });
  const [addError, setAddError] = useState('');

  return (
    <div className={styles.container}>
      <Grid container spacing={3} className={styles.form_container}>
        <Grid item xs={5}>
          <Autocomplete
            label="Outros serviços"
            className={styles.profile_autocomplete}
            value={specialties
              .map(item => ({ label: item.name, value: item.id }))
              .find(item => item.label === selectedSpecialty.specialtie_name)}
            options={specialties.sort((a, b) => a.name - b.name).map(item => ({ value: item.id, label: item.name }))}
            onChange={(_, item) => {
              if (item != null) {
                setSelectedSpecialty(prevState => ({
                  ...prevState,
                  specialtie_name: item.label,
                  specialties_type: item.value,
                }));
              }
            }}
            error={error['service.service_type']}
          />
          <div className={styles.error}>{addError}</div>
        </Grid>
        <Grid item xs={5}>
          <Input
            placeholder="RQE"
            value={selectedSpecialty.rqe}
            onChange={event => setSelectedSpecialty(prevState => ({ ...prevState, rqe: event.target.value }))}
          />
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            label="+"
            disabled={!selectedSpecialty.specialtie_name || !selectedSpecialty.rqe}
            onClick={() => {
              let hasSpecialty = false;
              form.specialties.forEach(item => {
                if (item.specialtie_name === selectedSpecialty.specialtie_name) {
                  hasSpecialty = true;
                }
              });
              if (!hasSpecialty) {
                setForm(prevState => ({ ...prevState, specialties: [...prevState.specialties, selectedSpecialty] }));
                setSelectedSpecialty({ specialtie_name: '', specialties_type: '', rqe: '' });
              } else {
                setAddError('Esta especialidade já foi adicionada!');
                setTimeout(() => setAddError(''), 2000);
              }
            }}
          />
        </Grid>
      </Grid>
      <div className={styles.list_container}>
        {form.specialties.length > 0 ? (
          <Table
            columns={['Especialidade', 'RQE']}
            lines={form.specialties.map(item => {
              return [item.specialtie_name, item.rqe];
            })}
          />
        ) : (
          <div className={styles.no_data}>Não há dados para exibir</div>
        )}
      </div>
    </div>
  );
}
