import React from 'react';
import styles from './Table.module.scss';
import TableHeaderFilter from 'components/TableHeaderFilter/TableHeaderFilter';
import { BsFunnel } from 'react-icons/bs';
import Checkbox from 'components/Checkbox/Checkbox';

let _lastTimeScrollEnd = null;
export default function Table({
  columns = [],
  lines = [],
  className,
  tableHeight = 'calc(100vh - 382px)',
  onScrollEnd = () => { },
}) {
  const tableStyles = [styles.Table];
  if (className) tableStyles.push(className);

  return (
    <div className={tableStyles.join(' ')}>
      <table>
        <thead>
          <tr>
            {columns.map((element, index) => {
              return typeof element === 'object' ? (
                <th key={index} className={styles.cabecalho}>
                  <TableHeaderFilter
                    placement={element.placement}
                    icon={<BsFunnel color="#C5A500" />}
                    label={element.title}
                  >
                    {element.type === 'checkbox' && (
                      <Checkbox
                        label={element.label}
                        options={element.options}
                        onClick={element.onClick}
                        multiple={element.options.length > 0}
                        allowMultipleSelected={element.allowMultipleSelected}
                      />
                    )}
                  </TableHeaderFilter>
                </th>
              ) : (
                <th key={index} className={styles.cabecalho}>
                  {element}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody
          className={styles.body}
          style={{ maxHeight: tableHeight }}
          onScroll={e => {
            const scrollTop = e?.target?.scrollTop ?? -1;
            const clientHeight = e?.target?.clientHeight ?? -1;
            const scrollHeight = e?.target?.scrollHeight ?? 0;

            const scrollThreshold = 5;
            const scrollCalc = scrollHeight - (scrollTop + clientHeight);
            if (scrollCalc < scrollThreshold) {
              const currentTime = Math.floor(new Date().getTime() / 1000);
              if (_lastTimeScrollEnd !== currentTime) {
                _lastTimeScrollEnd = currentTime;
                onScrollEnd();

              }
            }
          }}
        >
          {lines.map((row, index) => {
            let status = '';

            for (let i = 0; i < row.length; i++) {
              if (row[i] === 'Aguardando' || row[i] === 'Aprovado' || row[i] === 'Reprovado') {
                status = row[i];
              }
            }

            const className = styles[status];
            return (
              <tr key={index} className={className}>
                {row.map((cell, i) => (
                  <td key={i}>
                    <div className={styles.tableCell}>{cell}</div>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
