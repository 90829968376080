import React, { useState, useContext, useEffect } from 'react';
import styles from './Medicamentos.module.scss';
import Button from 'components/Button/Button';
import Table from 'components/Table/Table';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import { MedicinesRepository } from 'data/medicines';
import { apiBadRequestError } from 'exceptions/api';
import { LoaderContext } from 'contexts/loader';
import { useToast } from 'hooks/useToast';
import { RestrictionsRepository } from 'data/restrictions';
import PageTitle from 'components/PageTitle/PageTitle';
import Container from 'components/Container/Container';
import ModalMedicamento from './ModalMedicamento';
import ModalContraindicacao from './ModalContraindicacao';

const initialMedicinesFilter = {
  name: '',
  limit: 30,
  offset: 0,
};
function Medicamentos() {
  const toast = useToast();
  const { loading, setLoading } = useContext(LoaderContext);
  const [medicineList, setMedicineList] = useState([]);
  const [inputSearchValue, setInputSearchValue] = useState(initialMedicinesFilter.name);
  const [medicinesFilter, setMedicinesFilter] = useState(initialMedicinesFilter);
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [modalMedicamentoIsOpen, setModalMedicamentoIsOpen] = useState(false);
  const [modalContraindicacaoIsOpen, setModalContraindicacaoIsOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadMedicines(initialMedicinesFilter, true);
  }, []);

  const loadMedicines = async (filter, cleanPreviousData) => {
    const response = await MedicinesRepository.list(filter);
    if (response?.error) {
      toast.error(response.error);
    } else {
      const previousData = cleanPreviousData ? [] : medicineList;
      setMedicineList([...previousData, ...response.results]);
      if (response.results.length > 0) {
        setSelectedMedicine(response.results[0]);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (medicinesFilter !== initialMedicinesFilter && !loading) {
      setLoading(true);
      loadMedicines(medicinesFilter, false);
    }
  }, [medicinesFilter]);

  if (medicineList.length === 0 && selectedMedicine != null) {
    setSelectedMedicine(null);
  }

  return (
    <Container className={styles.container}>
      <PageTitle>Medicamentos</PageTitle>
      <div className={styles.busca}>
        <div className={styles.search}>
          <input
            className={styles.input}
            type="text"
            value={inputSearchValue}
            onChange={e => setInputSearchValue(e.target.value)}
            placeholder="Medicamento"
          />
          <Button
            label="Pesquisar"
            color="secondary"
            onClick={() => {
              setMedicineList([]);
              setMedicinesFilter({
                ...initialMedicinesFilter,
                name: inputSearchValue,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.tabelas}>
        <div>
          <Table
            columns={['Medicamentos']}
            lines={medicineList.map(item => [
              <div
                onClick={() => setSelectedMedicine(item)}
                className={`${styles.tableItem} ${styles.tableItemHover}`}
                style={
                  selectedMedicine === item
                    ? {
                        fontWeight: 'bold',
                        backgroundColor: '#e2e2e2',
                      }
                    : {}
                }
              >
                {item.name}
              </div>,
            ])}
            onScrollEnd={() => {
              setMedicinesFilter({
                ...medicinesFilter,
                offset: medicinesFilter.offset + medicinesFilter.limit,
              });
            }}
          />
          {medicineList.length === 0 && <div className={styles.noResults}>Nenhum medicamento encontrado</div>}
          <div className={styles.buttonMedi}>
            <ButtonVoltar link="/admin/home" />
            <Button onClick={() => setModalMedicamentoIsOpen(true)} label="Criar Medicamento" color="secondary" />
          </div>
        </div>
        <div>
          <Table
            columns={['Contraindicações']}
            lines={selectedMedicine?.restrictions?.map(restriction => [
              <div className={styles.tableItem}>{restriction.description}</div>,
            ])}
          />
          {selectedMedicine == null && (
            <div className={styles.noResults}>
              Selecione um medicamento para
              <br />
              ver suas contraindicações
            </div>
          )}
          {selectedMedicine != null && (selectedMedicine.restrictions?.length ?? 0) === 0 && (
            <div className={styles.noResults}>Nenhuma contraindicação informada</div>
          )}
          <div className={styles.buttonContr}>
            <Button
              onClick={() => setModalContraindicacaoIsOpen(true)}
              label="Adicionar Contraindicações"
              color={'secondary'}
              disabled={selectedMedicine == null}
            />
          </div>
        </div>
      </div>
      <ModalMedicamento
        isOpen={modalMedicamentoIsOpen}
        onClose={() => setModalMedicamentoIsOpen(false)}
        onCreate={async postData => {
          setLoading(true);
          const response = await MedicinesRepository.post(postData);
          if (response?.error) throw new apiBadRequestError(response.error, response.debug);
          setModalMedicamentoIsOpen(false);
          setInputSearchValue(postData.name);
          const newFilter = {
            ...initialMedicinesFilter,
            name: postData.name,
          };
          setMedicinesFilter(newFilter);
          loadMedicines(newFilter, true);
        }}
      />
      <ModalContraindicacao
        isOpen={modalContraindicacaoIsOpen}
        medicine={selectedMedicine}
        onClose={() => setModalContraindicacaoIsOpen(false)}
        onCreate={async postData => {
          setLoading(true);
          const response = await RestrictionsRepository.post(postData);
          setLoading(false);
          if (response?.error) throw new apiBadRequestError(response.error, response.debug);
          selectedMedicine.restrictions.push(postData);
          setModalContraindicacaoIsOpen(false);
        }}
      />
    </Container>
  );
}

export default Medicamentos;
