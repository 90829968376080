// hooks
import { useState, useEffect, useContext } from 'react';

// scss
import styles from './Pacientes.module.scss';

// contexts
import { LoaderContext } from 'contexts/loader';

// components
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import Input from 'components/InputIcon/InputIcon';
import Table from 'components/Table/Table';
import TableFooter from 'components/TableFooter/TableFooter';
import Button from 'components/Button/Button.js';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import { FaEye } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { Grid } from '@mui/material';
import { TbEdit } from 'react-icons/tb';
import { useToast } from 'hooks/useToast';

// other
import { debounce, formatPhoneNumber } from 'shared/utility';
import { Link } from 'react-router-dom';
import { PatientsRepository } from 'data/patients';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { SchedulesRepository } from 'data/schedules';

const initialPatientsFilter = {
  name: '',
  limit: 8,
  offset: 0,
};
let patientHasNext = false;
export default function Pacientes({path}) {
  const { setLoading } = useContext(LoaderContext);
  const [filter, setFilter] = useState(initialPatientsFilter);
  const [patientsList, setPatientList] = useState([]);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const response = await PatientsRepository.list(filter);
      patientHasNext = response.next != null;
      if ((response?.results?.length ?? 0) > 0) {
        setPatientList([...patientsList, ...response.results]);
      }
      setLoading(false);
    };
    run();
  }, [filter]);

  const isConcierge = path === 'concierge';

  const editButton = id => (
    <div className={styles.editButton}>
      {isConcierge && (
        <Link to={`/${path}/pacientes/editar/${id}`}>
          <TbEdit className={styles.pen} color="#C5A500" title="Editar Paciente" />
        </Link>
      )}
      <Link 
        to={isConcierge ? `/${path}/perfil-paciente/${id}` : null}
        onClick={async () => {
          if (!isConcierge) {
            setLoading(true);
            const responseConsultas = await SchedulesRepository.list({ patient: id, status: 'attended' });
            setLoading(false);
            if (responseConsultas?.results?.length > 0) {
              navigate(`/atendimento/prontuario/${id}/consulta/${responseConsultas.results[0].id}`);
            } else {
              toast.warning('Nenhum atendimento encontrado com este paciente.');
            }
          }
        }}
      >
        <FaEye 
          className={styles.pen} 
          color="#15ab72" 
          title={isConcierge ? "Ver Histórico de Consultas" : "Ver Último Prontuário"} 
        />
      </Link>
    </div>
  );

  const botaoVoltar = <ButtonVoltar link={`/${path}/home`} />;
  const botaoAdd = <Button label="Adicionar Paciente" link={`/${path}/pacientes/novo`} color="secondary" />;

  return (
    <Container>
      <PageTitle>Lista de Pacientes</PageTitle>
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={5}>
          <Input
            placeholder="Pesquisar paciente"
            icon={<FiSearch />}
            iconPosition="right"
            onChange={e => debounce(() => {
              setPatientList([]);
              setFilter({ ...filter, name: e.target.value, offset: 0 });
            })()}
          />
        </Grid>
      </Grid>
      <Table
        columns={['Nome do Paciente', 'Telefone', 'Última Consulta', 'Ações']}
        lines={patientsList.map(item => [
          item.name,
          formatPhoneNumber(item.phone),
          item.last_schedule_date ? moment(item.last_schedule_date).format('DD/MM/YYYY') : '-',
          editButton(item.id),
        ])}
        onScrollEnd={() => {
          if (patientHasNext) {
            setFilter({
              ...filter,
              offset: filter.offset + filter.limit,
            });
          }
        }}
      />
      <TableFooter>
        {botaoVoltar}
        {botaoAdd}
      </TableFooter>
    </Container>
  );
}
