export default class ErrorMessages {
  static serviceUnavailable = 'Serviço indisponível. Tente novamente mais tarde.';

  static credentialsError = 'Não encontramos sua autenticação. Tente realizar login novamente.';

  static noPermissionError = 'Você não possui permissão';

  static planExceeded = 'Sua conta atingiu o limite do plano para esta ação';

  static businessError = 'Não foi possível processar sua solicitação';

  static paramsError = 'Seu formulário possui erro nas informações digitadas';

  static resourceNotFound(resource) {
    return `${resource} não encontrado.`;
  }
}
