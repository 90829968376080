import React from 'react';
import styles from './Toast.module.scss';
import { useEffect, useRef } from 'react';
import { useToast } from 'hooks/useToast';
import { Alert } from '@mui/material';

const Toast = ({ message, type, id }) => {
  const toast = useToast();

  const timerID = useRef(null);

  const handleDismiss = () => {
    toast.remove(id);
  };

  useEffect(() => {
    timerID.current = setTimeout(() => {
      handleDismiss();
    }, 4000);

    return () => {
      clearTimeout(timerID.current);
    };
  });

  return (
    <div className={styles.container}>
      <Alert severity={message}>{type}</Alert>
    </div>
  );
};

export default Toast;
