// hooks
import { useContext, useEffect, useState } from 'react';
import { useToast } from 'hooks/useToast';
import { useNavigate, useParams } from 'react-router-dom';
// scss
import styles from './AddInsumo.module.scss';

// contexts
import { LoaderContext } from 'contexts/loader';
import { ClinicIdContext } from 'contexts/clinicId';

// components
import Autocomplete from 'components/Autocomplete/Autocomplete';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import Input from 'components/Input/Input';
import Card from 'components/Card/Card';
import Button from 'components/Button/Button';
import { Grid } from '@mui/material';

// other
import { apiBadRequestError } from 'exceptions/api';
import { validateInsumo } from './validator';
import { transformApiToInsumos } from 'transformers/userForm';
import { ItemsRepository } from 'data/stockitems';
import TableFooter from 'components/TableFooter/TableFooter';
import { SupplierRepository } from 'data/supplier';
import Table from 'components/Table/Table';

const unit_types = [
  { name: 'ampola', value: 'ampola' },
  { name: 'frasco', value: 'frasco' },
  { name: 'cartela', value: 'cartela' },
  { name: 'pacote', value: 'pacote' },
  { name: 'caixa', value: 'caixa' },
  { name: 'fardo', value: 'fardo' },
  { name: 'unidade/semaglutida', value: 'unidade/semaglutida' },
  { name: 'unidade', value: 'unidade' },
];

function AddInsumo() {
  let navigate = useNavigate();
  const toast = useToast();
  let { id } = useParams();
  const { clinicId } = useContext(ClinicIdContext);
  const { setLoading } = useContext(LoaderContext);
  const [formInsumo, setFormInsumo] = useState({
    name: '',
    item_code: '',
    unit_type: null,
    code_item: [],
  });
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);


  useEffect(() => {
    const run = async () => {
      setLoading(true);
      if (id) {
        const response = await ItemsRepository.get(id);
        if (response?.error) {
          toast.error(response.error);
          setFormInsumo({});
        } else {
          setFormInsumo(transformApiToInsumos(response));
        }
      }
      const responseSupplier = await SupplierRepository.list();
      setLoading(false);
      
      const loadedSuppliers = responseSupplier?.results ?? [];
      setSuppliers(
        loadedSuppliers
          .sort((a, b) => a.company_name - b.company_name)
          .map(item => ({ value: item.id, label: item.company_name }))
      );
    };
    run();
  }, []);

  const handleValidateForm = event => {
    if (['item_code'].includes(event.target.name)) {
      setFormInsumo({
        ...formInsumo,
        [event.target.name]: parseInt(event.target.value),
        clinic: parseInt(clinicId),
      });
    } else {
      setFormInsumo({
        ...formInsumo,
        [event.target.name]: event.target.value,
        clinic: parseInt(clinicId),
      });
    }
  };

  const handleUnitTypeChange = (event, item) => {
    setFormInsumo({ ...formInsumo, unit_type: item.value });
  };

  const handleSubmit = async event => {
    const formData = {
      ...formInsumo,
      item_code: (formInsumo.item_code ?? '').length === 0 ? null : formInsumo.item_code,

    }
    try {
      await validateInsumo(formData);
    } catch (err) {
      toast.error(err.message);
      return;
    }
    try {
      await saveData(formData);
    } catch (err) {
      toast.error(err.errors?.[0] || err.message);
    }
  };

  const saveData = async formData => {
    setLoading(true);
    const response = id ? await ItemsRepository.update(id, formData) : await ItemsRepository.create(formData);
    setLoading(false);
    if (response?.error) throw new apiBadRequestError(response.error, response.debug);
    return navigate('/estoque/insumos');
  };

  const botaoVoltar = <ButtonVoltar link="/estoque/insumos" />;
  const botaoAdd = <Button label="Salvar" onClick={handleSubmit} color="secondary" size="large" />;

  return (
    <Container>
      <PageTitle>Cadastrar Insumo</PageTitle>
      <Card className={styles.card}>
        <Grid container spacing={2} className={styles.gridContainer}>
          <Grid container item spacing={2} xs={4}>
            <Grid item xs={12}>
              <Autocomplete
                label="Unidade"
                value={unit_types
                  .map(item => ({ label: item.name, value: item.value }))
                  .find(item => item.value === formInsumo.unit_type)}
                options={unit_types
                  .sort((a, b) => a.name - b.name)
                  .map(item => ({ value: item.value, label: item.name }))}
                onChange={handleUnitTypeChange}
              />
            </Grid>
            <Grid item xs={12} className={styles.inputNumber}>
              <Input name="name" label="Insumo" value={formInsumo.name} onChange={handleValidateForm} />
            </Grid>
            <Grid item xs={12} className={styles.inputNumber}>
              <Input
                name="item_code"
                label="Código do insumo"
                value={formInsumo.item_code}
                onChange={handleValidateForm}
                type="number"
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2} xs={8}>
            <Grid item xs={7}>
              <Autocomplete
                label="Fornecedor do Insumo"
                value={selectedSupplier}
                options={suppliers}
                onChange={(event, item) => setSelectedSupplier(item)}
              />
            </Grid>
            <Grid item xs={4} className={styles.inputNumber}>
              <Input
                name="item_code"
                label="Código interno do fornecedor"
                value={selectedSupplier?.code ?? ''}
                disabled={selectedSupplier == null}
                onChange={e => setSelectedSupplier({ ...selectedSupplier, code: e.target.value })}
              />
            </Grid>
            <Grid item xs={1}>
              <Button color="secondary" label="+" onClick={() => {
                setFormInsumo({
                  ...formInsumo,
                  code_item: [
                    ...formInsumo.code_item,
                    {
                      code: selectedSupplier.code,
                      supplier: selectedSupplier.value,
                    },
                  ],
                });
              }} />
            </Grid>
            <Grid item xs={12}>
              <Table
                columns={['Fornecedor', 'Código interno', 'Remover']}
                lines={formInsumo.code_item.map(item => {
                  let supplier_name = '';
                  const supplier = suppliers.find(s => s.value === item.supplier);
                  if (supplier) {
                    supplier_name = supplier.label ?? '';
                  }
                  const remove_button = <Button color="secondary" label="-" onClick={() => {
                    const code_item = formInsumo.code_item.filter(c => c !== item);
                    setFormInsumo({
                      ...formInsumo,
                      code_item: code_item,
                    });
                  }} />
                  return [supplier_name, item.code, remove_button];
                })}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <TableFooter>
        {botaoVoltar}
        {botaoAdd}
      </TableFooter>
    </Container>
  );
}

export default AddInsumo;
