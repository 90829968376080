import Toast from './ToastComponent/Toast';
import styles from './ToastContainer.module.scss';

function ToastsContainer({ toasts }) {
  return (
    <div className={styles.container}>
      {toasts.map(toast => (
        <Toast key={toast.id} {...toast} />
      ))}
    </div>
  );
}

export default ToastsContainer;
