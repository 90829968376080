import DefaultLayout from 'App/layouts/DefaultLayout/DefaultLayout';
import Authenticated from 'middleware/authenticated';
import Colaboradores from 'pages/admin/Colaboradores/Colaboradores';
import AdicionarColaborador from 'pages/admin/Colaboradores/AdicionarColaborador/AdicionarColaborador';
import Consultorio from 'pages/admin/Consultorio/Consultorio';
import FormClinic from 'pages/admin/Consultorio/ConsultorioForm/FormClinic';
// import ConsultorioForm from 'pages/admin/Consultorio/ConsultorioForm/addClinica/ConsultorioForm';
import Documentos from 'pages/admin/Documentos/Documentos';
import Medicamentos from 'pages/admin/Medicamentos/Medicamentos';
import Planos from 'pages/_others/Planos/Planos';
import AdminHome from 'pages/admin/AdminHome';
import Professionals from 'pages/admin/Professionals/Professionals';
import AddProfessionals from 'pages/admin/Professionals/AddProfessionals/AddProfessionals';
import Templates from 'pages/admin/Templates/Templates';
import AddTemplates from 'pages/admin/Templates/AddTemplates/AddTemplates';

export const routes = [
  {
    key: '/admin/home',
    path: '/admin/home',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/home">
          <AdminHome />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/colaboradores',
    path: '/admin/colaboradores',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/colaboradores">
          <Colaboradores />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/colaboradores/novo',
    path: '/admin/colaboradores/novo',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/colaboradores">
          <AdicionarColaborador tab="data" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/colaboradores/:id/editar',
    path: '/admin/colaboradores/:id/editar',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/colaboradores">
          <AdicionarColaborador tab="data" edit />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/colaboradores/novo/perfil',
    path: '/admin/colaboradores/novo/perfil',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/colaboradores">
          <AdicionarColaborador tab="profile" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/colaboradores/:id/editar/perfil',
    path: '/admin/colaboradores/:id/editar/perfil',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/colaboradores">
          <AdicionarColaborador tab="profile" edit />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/colaboradores/novo/vincularConsultorio',
    path: '/admin/colaboradores/novo/vincularConsultorio',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/colaboradores">
          <AdicionarColaborador tab="clinic" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/colaboradores/:id/editar/vincularConsultorio',
    path: '/admin/colaboradores/:id/editar/vincularConsultorio',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/colaboradores">
          <AdicionarColaborador tab="clinic" edit />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/colaboradores/novo/especialidades',
    path: '/admin/colaboradores/novo/especialidades',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/colaboradores">
          <AdicionarColaborador tab="specialties" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/colaboradores/:id/editar/especialidades',
    path: '/admin/colaboradores/:id/editar/especialidades',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/colaboradores">
          <AdicionarColaborador tab="specialties" edit />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/colaboradores/novo/horarios',
    path: '/admin/colaboradores/novo/horarios',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/colaboradores">
          <AdicionarColaborador tab="schedule" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/colaboradores/:id/editar/horarios',
    path: '/admin/colaboradores/:id/editar/horarios',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/colaboradores">
          <AdicionarColaborador tab="schedule" edit />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/consultorio',
    path: '/admin/consultorio',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/consultorio">
          <Consultorio />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/consultorio/novo',
    path: '/admin/consultorio/novo',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/consultorio">
          <FormClinic tab="dados" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/consultorio/novo/endereco',
    path: '/admin/consultorio/novo/endereco',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/consultorio">
          <FormClinic tab="endereco" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/consultorio/novo/horarios',
    path: '/admin/consultorio/novo/horarios',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/consultorio">
          <FormClinic tab="horarios" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/consultorio/editar/:id',
    path: '/admin/consultorio/editar/:id',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/consultorio">
          <FormClinic tab="dados" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/consultorio/editar/:id/endereco',
    path: '/admin/consultorio/editar/:id/endereco',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/consultorio">
          <FormClinic tab="endereco" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/consultorio/editar/:id/horarios',
    path: '/admin/consultorio/editar/:id/horarios',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/consultorio">
          <FormClinic tab="horarios" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/documentos',
    path: '/admin/documentos',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/documentos">
          <Documentos tab="pastas" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/documentos/notas',
    path: '/admin/documentos/notas',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/documentos">
          <Documentos tab="notas" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  // {
  //     key: "/admin/especialidades",
  //:     path="/admin/especialidades",
  //:    element={
  //         <Authenticated>
  //             <DefaultLayout nav="admin" tab="/especialidades">
  //                 <Especialidades />
  //             </DefaultLayout>
  //         </Authenticated>
  //     }
  // },
  {
    key: '/admin/medicamentos',
    path: '/admin/medicamentos',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/medicamentos">
          <Medicamentos />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/planos',
    path: '/admin/planos',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/planos">
          <Planos tab="general" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/planos/alterar',
    path: '/admin/planos/alterar',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/planos">
          <Planos tab="change" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/profissionais',
    path: '/admin/profissionais',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/profissionais">
          <Professionals />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/profissionais/novo',
    path: '/admin/profissionais/novo',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/profissionais">
          <AddProfessionals />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/profissionais/editar/:id',
    path: '/admin/profissionais/editar/:id',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/profissionais">
          <AddProfessionals />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/templates',
    path: '/admin/templates',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/templates">
          <Templates />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/templates/novo',
    path: '/admin/templates/novo',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/templates">
          <AddTemplates />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/admin/templates/editar/:id',
    path: '/admin/templates/editar/:id',
    element: (
      <Authenticated>
        <DefaultLayout nav="admin" tab="/admin/templates">
          <AddTemplates />
        </DefaultLayout>
      </Authenticated>
    ),
  },
];
