import React from 'react';
import styles from './CaixaReembolso.module.scss';
import { Link } from 'react-router-dom';
import Caixa from './Caixa/Caixa';
import Reembolso from './Reembolso/Reembolso';
import PageTitle from 'components/PageTitle/PageTitle';
import Container from 'components/Container/Container';

function CaixaReembolso({ tab, user_type }) {
  const contents = {
    caixa: <Caixa user_type={user_type} />,
    reembolso: <Reembolso user_type={user_type} />,
  };

  const titles = {
    caixa: 'Caixa',
    reembolso: 'Reembolso',
  };

  return (
    <Container className={styles.container}>
      <PageTitle>{titles[tab]}</PageTitle>
      <div className={styles.tab}>
        <Link to={user_type === 'finance' ? '/financeiro/caixa' : '/concierge/caixa'}>
          <span className={tab === 'caixa' ? styles.elementActive : styles.element}>Caixa</span>
        </Link>
        <Link to={user_type === 'finance' ? '/financeiro/reembolso' : '/concierge/reembolso'}>
          <span className={tab === 'reembolso' ? styles.elementActive : styles.element}>Reembolso</span>
        </Link>
      </div>
      {contents[tab]}
    </Container>
  );
}

export default CaixaReembolso;
