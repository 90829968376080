// hooks
import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';

// contexts
import { AuthContext } from 'contexts/auth';
import { LoaderProvider } from 'contexts/loader';
import { ClinicIdProvider } from 'contexts/clinicId';
import { ToastContextProvider } from 'contexts/ToastContext';
import { NavBarProvider } from 'contexts/navBar';

// routes
import { routes as publicRoutes } from 'routes/public';
import { routes as authRoutes } from 'routes/auth';
import { routes as adminRoutes } from 'routes/admin';
import { routes as financeRoutes } from 'routes/finance';
import { routes as serviceRoutes } from 'routes/service';
import { routes as conciergeRoutes } from 'routes/concierge';
import { routes as stockRoutes } from 'routes/stock';

// other
import Enums from 'transformers/enums';
import * as Sentry from '@sentry/react';

const SentryRoutes = process.env.REACT_APP_SENTRY_DSN ? Sentry.withSentryReactRouterV6Routing(Routes) : Routes;

export default function AppRoutes() {
  const { user, loadingUser } = useContext(AuthContext);

  if (loadingUser) {
    return '';
  }

  let routes = [
    ...publicRoutes,
    ...(user ? authRoutes : []),
    ...(user?.user_types?.includes(Enums.profileNameToId.ADMIN) ? adminRoutes : []),
    ...(user?.user_types?.includes(Enums.profileNameToId.FINANCE) ? financeRoutes : []),
    ...(user?.user_types?.includes(Enums.profileNameToId.DOCTOR) || user?.user_types?.includes(Enums.profileNameToId.SERVICE) ? serviceRoutes : []),
    ...(user?.user_types?.includes(Enums.profileNameToId.CONCIERGE) ? conciergeRoutes : []),
    ...(user?.user_types?.includes(Enums.profileNameToId.STOCK) ? stockRoutes : []),
  ];

  return (
    <LoaderProvider>
      <ToastContextProvider>
        <ClinicIdProvider>
          <NavBarProvider>
            <SentryRoutes>
              {routes.map(item => (
                <Route {...item} />
              ))}
            </SentryRoutes>
          </NavBarProvider>
        </ClinicIdProvider>
      </ToastContextProvider>
    </LoaderProvider>
  );
}
