// hooks
import useApi from './hooks/useApi';
import useForm from './hooks/useForm';
import useHandlers from './hooks/useHandlers';
import useTabs from './hooks/useTabs';
import useFeedbackModal from './hooks/useFeedbackModal';
import { useParams } from 'react-router-dom';

// scss
import styles from './AdicionarColaborador.module.scss';

// components
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import Button from 'components/Button/Button';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import Modal from 'components/Modal/Modal';

export default function AdicionarColaborador({ tab, edit }) {
  const { id } = useParams();
  const { form, setForm } = useForm();
  const { feedbackModal, setFeedbackModal, onClose } = useFeedbackModal();
  const { serviceNames, specialties, clinics, loading, saveOnEdit, submitData } = useApi(
    tab,
    edit,
    id,
    form,
    setForm,
    setFeedbackModal
  );
  const { error, controlTabs, setControlTabs, unlockedTabs, validateOnBlur } = useHandlers(tab, edit, id, form);
  const { Tab, tabContent, handleTabClick, handleNextClick, handlePreviousClick, getTabLabel } = useTabs(
    form,
    setForm,
    id,
    edit,
    error,
    serviceNames,
    specialties,
    clinics,
    setControlTabs,
    loading,
    unlockedTabs,
    submitData,
    validateOnBlur
  );

  let nextButtonLabel = 'Próximo';
  if (tab === 'schedule' || (tab === 'clinic' && !form.user_types.includes(1))) {
    nextButtonLabel = 'Salvar';
  }

  let disableNextButton = !!error[tab];
  if (tab === 'data' && !disableNextButton) {
    disableNextButton = form.data.first_name.length === 0 || form.data.last_name.length === 0 || form.data.email.length === 0;
  }

  return (
    <Container className={styles.container}>
      <PageTitle>{edit ? 'Editar Colaborador' : 'Adicionar Colaborador'}</PageTitle>
      <div className={edit ? styles.tabs_container_edit : styles.tabs_container}>
        {unlockedTabs.map(tabKey => (
          <Tab
            key={tabKey}
            tabKey={tabKey}
            label={getTabLabel(tabKey)}
            activeTab={controlTabs}
            onClick={handleTabClick}
          />
        ))}
      </div>
      {tabContent[tab]}
      <div className={styles.buttons_container}>
        {edit ? (
          <>
            <ButtonVoltar label="Voltar" link="/admin/colaboradores" />
            <Button
              className={styles.next_button}
              label="Salvar"
              size="large"
              disabled={disableNextButton}
              onClick={() => saveOnEdit(tab)}
            />
          </>
        ) : (
          <>
            <ButtonVoltar label="Voltar" link="/admin/colaboradores" />
            <div className={styles.navigate_buttons}>
              <Button
                className={styles.previous_button}
                disabled={tab === 'data'}
                label="Anterior"
                size="large"
                onClick={() => handlePreviousClick(tab)}
              />
              <Button
                className={styles.next_button}
                disabled={disableNextButton}
                label={nextButtonLabel}
                size="large"
                onClick={() => handleNextClick(tab)}
              />
            </div>
          </>
        )}
      </div>
      <Modal isOpen={feedbackModal.open} setModalOpen={onClose}>
        <div className={styles.header}>
          {feedbackModal.type === 'success' && (
            <div className={styles.body_success}>
              <h1>Sucesso!</h1>
              <span>{feedbackModal.message}</span>
            </div>
          )}
          {feedbackModal.type === 'error' && (
            <div className={styles.body_error}>
              <h1>Erro!</h1>
              <span>{feedbackModal.message}</span>
            </div>
          )}
        </div>
      </Modal>
    </Container>
  );
}
