// hooks
import { useState, useEffect, useContext, useRef } from 'react';
import moment from 'moment';

// contexts
import { ClinicIdContext } from 'contexts/clinicId';
import { LoaderContext } from 'contexts/loader';

// other
import { SchedulesRepository } from 'data/schedules';
import { ProceduresConciergeRepository } from 'data/procedures-concierge';
import { PatientsRepository } from 'data/patients';

export default function useApi(filters) {
  const { clinicId } = useContext(ClinicIdContext);
  const { setLoading } = useContext(LoaderContext);

  // "events" representa todos os horários agendados. se existe algo
  // na agenda, chamamos de "event".
  const [defaultEvents, setDefaultEvents] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [patients, setPatients] = useState([]);
  const lastClinicIdLoaded = useRef(null);

  async function fetchData(filters) {
    setLoading(true);

    // busca todos os dados de agendamentos com filtro aplicado
    const schedulesResponse = await SchedulesRepository.list({
      ...filters,
      clinic: clinicId,
      status_not: 'canceled',
    });

    if (schedulesResponse && schedulesResponse.results) {
      setDefaultEvents(schedulesResponse.results);
    };

    if (lastClinicIdLoaded.current !== clinicId) {
      lastClinicIdLoaded.current = clinicId;

      // busca todos os procedimentos
      const proceduresResponse = await ProceduresConciergeRepository.list({ clinic: clinicId, only_doctor: false, limit: 9999 });

      // busca todos os pacientes
      const patientsResponse = await PatientsRepository.list({ limit: 9999 });

      if (proceduresResponse && proceduresResponse.results) {
        setProcedures(proceduresResponse.results);
      };

      if (patientsResponse && patientsResponse.results) {
        setPatients(patientsResponse.results);
      }
    }
    
    setLoading(false);
  }
  
  async function getScheduleFiles(scheduleId) {
    setLoading(true);
    const filesResponse = await SchedulesRepository.getScheduleFiles(scheduleId);
    setLoading(false);
    return filesResponse?.results ?? []; 
  }

  // faz um post, enviando todos os dados de um agendamento pra api.
  async function addEvent(action, payload, setter) {
    let schedule_date = payload.schedule_date;
    if (typeof schedule_date === 'string' || schedule_date instanceof String) {
      if (payload.hour && schedule_date.includes('T')) {
        schedule_date = schedule_date.split('T')[0];
        schedule_date = `${schedule_date}T${payload.hour}`;
      }
    } else {
      schedule_date = moment(schedule_date).format('YYYY-MM-DD');
    }

    switch (action) {
      case 'add':
      case 'edit':
        try {
          const scheduleData = {
            clinic: clinicId,
            user: payload.user,
            patient: payload.patient,
            schedule_date: schedule_date,
            description: payload.description,
            procedure: payload.procedure,
            file_0: payload.file_0,
            status: 'scheduled',
            repeat_days: payload.repeat_days,
            repeat_total: payload.repeat_total,
          };
          const scheduleResponse = await (
            action === 'add' ? 
              SchedulesRepository.addSchedule(scheduleData) : 
              SchedulesRepository.editSchedule(payload.id, scheduleData)
          );

          if (scheduleResponse && !scheduleResponse.error) {
            setter({
              open: true,
              event: 'success',
            });
            return true;
          } else {
            setter({
              open: true,
              event: 'error',
              message: 'Erro ao adicionar agendamento. Tente novamente mais tarde.',
            });
          }
        } catch (error) {
          console.error(error);
        }
        break;
      case 'reschedule':
        try {
          await SchedulesRepository.editSchedule(payload.id, {
            schedule_date: schedule_date,
            cancellation_reason: payload.cancellation_reason,
            cancellation_date: payload.cancellation_date,
            status: 'scheduled',
          });
          return true;
        } catch (error) {
          setter({
            open: true,
            event: 'error',
            message: error,
          });
        }
        break;
      case 'cancel':
        try {
          await SchedulesRepository.editSchedule(payload.id, {
            cancellation_reason: payload.cancellation_reason,
            cancellation_date: payload.cancellation_date,
            status: 'canceled',
          });
          return true;
        } catch (error) {
          setter({
            open: true,
            event: 'error',
            message: error,
          });
        }
        break;
      case 'didnotattend':
        try {
          await SchedulesRepository.editSchedule(payload.id, { status: 'didnotattend' });
          return true;
        } catch (error) {
          setter({
            open: true,
            event: 'error',
            message: error,
          });
        }
        break;
      case 'waiting':
        try {
          await SchedulesRepository.editSchedule(payload.id, {
            status: 'waiting',
            arrival_time: moment().format('YYYY-MM-DDTHH:mm:ss')
          });
          return true;
        } catch (error) {
          setter({
            open: true,
            event: 'error',
            message: error,
          });
        }
        break;
      default:
    }
    return false;
  }

  // chama a função "fetchData" todas as vezes que o estado de clinicId
  // for alterado.
  useEffect(() => {
    if (clinicId != null) {
      fetchData(filters);
    }
  }, [filters]);

  return {
    defaultEvents,
    procedures,
    patients,
    addEvent,
    getScheduleFiles,
  };
}
