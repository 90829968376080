import React, { useEffect, useState, useContext } from 'react';
import styles from './AgendamentoProtocolos.module.scss';
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import InputIcon from 'components/InputIcon/InputIcon';
import { LoaderContext } from 'contexts/loader';
import { Grid } from '@mui/material';
import { FiSearch } from 'react-icons/fi';
import Button from 'components/Button/Button.js';
import { PatientsRepository } from 'data/patients';
import { PatientsProtocolsRepository } from 'data/patients-protocols';
import Card from 'components/Card/Card';
import { ClinicIdContext } from 'contexts/clinicId';
import ModalAgendar from './ModalAgendar';
import moment from 'moment';

function AgendamentoProtocolos() {
  const { clinicId } = useContext(ClinicIdContext);
  const { setLoading } = useContext(LoaderContext);
  const [patientProtocolsList, setPatietnProtocolsList] = useState([]);
  const [openProtocol, setOpenProtocol] = useState({ open: false, name: '' });
  const [search, setSearch] = useState('');
  const [modalAgendar, setModalAgendar] = useState({ open: false, patient_procedure_id: null, eventData: null });

  useEffect(() => {
    const run = async () => {
      loadData();
    };
    if (clinicId != null) {
      run();
    }
  }, [clinicId]);

  const loadData = async () => {
    setLoading(true);
    const protocolsResponse = await PatientsProtocolsRepository.list({ clinic: clinicId });
    const patientResponse = await PatientsRepository.list();
    setLoading(false);

    let arrayProtocols = protocolsResponse.results;
    let arrayPatient = patientResponse.results;

    if (protocolsResponse.results) {
      const dadosPorPaciente = {};
      const newProtocolData = [];

      arrayPatient.forEach(item => {
        arrayProtocols.forEach(proto => {
          if (item.id === proto.patient) {
            newProtocolData.push({ ...proto, patient_name: item.name });
          }
        });
      });

      newProtocolData.forEach(item => {
        const { patient_name, ...restoDoItem } = item;

        if (!dadosPorPaciente[patient_name]) {
          dadosPorPaciente[patient_name] = [];
        }

        dadosPorPaciente[patient_name].push({ ...restoDoItem, patient_name });
      });

      const arrayMapeada = Object.entries(dadosPorPaciente).map(([patient_name, dados]) => ({
        patient_name,
        dados,
      }));

      setPatietnProtocolsList(arrayMapeada);
    }
  };

  const filteredData = patientProtocolsList.filter(item =>
    item.patient_name.toLowerCase().includes(search.toLowerCase())
  );

  if (filteredData.length > 0 && filteredData[0].patient_name !== '' && openProtocol.name === '') {
    setOpenProtocol({ open: true, name: filteredData[0].patient_name });
  }

  return (
    <Container>
      <PageTitle>Protocolo Médico</PageTitle>
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={5}>
          <InputIcon
            placeholder="Pesquisar paciente"
            icon={<FiSearch />}
            iconPosition="right"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </Grid>
      </Grid>
      <Card className={styles.card}>
        {filteredData.map(item => (
          <div className={styles.cardContainer}>
            <div className={styles.headerPatient}>
              <Button
                size="sm"
                onClick={() => setOpenProtocol({ open: !openProtocol.open, name: item.patient_name })}
                label={item.patient_name === openProtocol.name && openProtocol.open === true ? '-' : '+'}
              />
              <p>Paciente: {item.patient_name}</p>
            </div>
            <div className={styles.procedimentoContainer}>
              {item?.dados.map(data => {
                if (item.patient_name === openProtocol.name) {
                  return openProtocol.open ? (
                    <div>
                      <div className={styles.headerProcedures}>
                        <div>
                          <p>Protocolo: {data.protocol_name}</p>
                        </div>
                      </div>
                      <div>
                        {data?.procedures.map(proce => (
                          <div className={styles.headerInsideProcedures}>
                            <p> - Procedimento: {proce.procedure_name}</p>
                            {proce.schedule_date === null ? (
                              <Button 
                                className={styles.buttonsAgendar} 
                                label="Agendar" 
                                onClick={() => {
                                  setModalAgendar({
                                    open: true,
                                    patient_procedure_id: proce.id,
                                    eventData: {
                                      patient: data.patient,
                                      profile: proce.profile,
                                      service_type: proce.service_type,
                                    },
                                  });
                                }}
                              />
                            ) : moment(proce.schedule_date).format('DD/MM/YYYY HH:mm')}
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <></>
                  );
                } else {
                  return <></>;
                }
              })}
            </div>
          </div>
        ))}
      </Card>
      <ModalAgendar modal={modalAgendar} setModal={setModalAgendar} />
    </Container>
  );
}

export default AgendamentoProtocolos;
