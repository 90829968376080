import React, { useState, useEffect, useContext } from 'react';
import styles from './PrevisaoReceita.module.scss';
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import InputDatePickerRange from 'components/InputDatePickerRange/InputDatePickerRange';
import Table from 'components/Table/Table';
import TableFooter from 'components/TableFooter/TableFooter';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import { SchedulesRepository } from 'data/schedules';
import { LoaderContext } from 'contexts/loader';
import moment from 'moment';
import { ClinicIdContext } from 'contexts/clinicId';
import { formatCurrency } from 'shared/utility';

function PrevisãoReceita() {
  const { clinicId } = useContext(ClinicIdContext);
  const { setLoading } = useContext(LoaderContext);
  const botaoVoltar = <ButtonVoltar link="/financeiro/home" />;
  const [scheduleList, setScheduleList] = useState([]);
  
  const now = new Date();
  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const lastDayofMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  const [filters, setFilters] = useState({ 
    start_date: moment(firstDayOfMonth).format('YYYY-MM-DD'), 
    end_date: moment(lastDayofMonth).format('YYYY-MM-DD') 
  });

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const response = await SchedulesRepository.list({ clinic: clinicId, ...filters });
      setLoading(false);
      setScheduleList(response?.results ?? []);
    };
    if (clinicId != null) {
      run();
    }
  }, [filters, clinicId]);

  const handleDateChange = (clearField, newDate) => {
    if (clearField) {
      setFilters(prevState => ({
        ...prevState,
        start_date: '',
        end_date: '',
        offset: 0,
      }));
    } else if (newDate.length >= 2 && newDate[0] !== null && newDate[1] !== null) {
      const [startDate, endDate] = newDate;
      setFilters(prevState => ({
        ...prevState,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        offset: 0,
      }));
    }
  };

  let totalValue = 0;
  scheduleList.forEach(item => {
    totalValue += (item.value ?? 0);
  });

  return (
    <Container className={styles.container}>
      <div className={styles.header}>
        <div>
          <PageTitle>Previsão de Receita</PageTitle>
          <p>Receita prevista por agendamentos</p>
        </div>
        <div>
          <InputDatePickerRange
            label="Período"
            value={[
              new Date(filters.start_date+'T12:00:00Z'), 
              new Date(filters.end_date+'T12:00:00Z')
            ]}
            onChange={newDate => handleDateChange(false, newDate)}
            className={styles.dateInput}
          />
        </div>
      </div>
      <Table
        columns={['Data', 'Descrição', 'Valor']}
        lines={scheduleList.map(item => [
          moment(item.schedule_date).format('DD/MM/YYYY'),
          item.description,
          item.value ? formatCurrency(item.value) : '-',
        ])}
      />
      <TableFooter>
        {botaoVoltar}
        <div className={styles.footer}>
          <p className={styles.total}>Total:</p>
          <p className={styles.value}>
            {formatCurrency(totalValue)}
          </p>
        </div>
      </TableFooter>
    </Container>
  );
}

export default PrevisãoReceita;
