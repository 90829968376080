import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Planos.module.scss';
import PlanoAtual from './PlanoAtual/PlanoAtual.js';
import AlterarPlano from './Plans/Plans.js';
import PageTitle from 'components/PageTitle/PageTitle';
import Container from 'components/Container/Container';

function Planos({ tab }) {
  const contents = {
    general: <PlanoAtual />,
    change: <AlterarPlano />,
  };

  const titles = {
    general: 'Meu Plano',
    change: 'Planos',
  };

  return (
    <Container>
      <PageTitle>{titles[tab]}</PageTitle>
      <div>
        <div className={styles.navi}>
          <div>
            <Link to="/admin/planos">
              <span className={tab === 'general' ? styles.linkActive : styles.link}>Visão Geral</span>
            </Link>
          </div>
          <div>
            <Link to="/admin/planos/alterar">
              <span className={tab === 'change' ? styles.linkActive : styles.link}>Gerênciar assinaturas</span>
            </Link>
          </div>
        </div>
        <div>{contents[tab]}</div>
      </div>
    </Container>
  );
}

export default Planos;
