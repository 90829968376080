
import '@fullcalendar/react';
import './Calendar.module.scss'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import momentPlugin from '@fullcalendar/moment';

const Calendar = ({
  events,
  onDateClick,
  onEventClick,
  onDrop,
  onEventDrop,
  customButtons,
  customHeaderToolbar,
  customFooterToolbar,
  slotMinTime,
  slotMaxTime 
}) => {
  slotMinTime = slotMinTime ?? '00:00:00';
  slotMaxTime = slotMaxTime ?? '23:59:00';
  const initialHour = parseInt(slotMinTime.split(':')[0]);
  const finalHour = parseInt(slotMaxTime.split(':')[0]);
  let height = 154 + (finalHour - initialHour) * 54;
  if (height < 570) {
    height = 570;
  } else if (height > 800) {
    height = 800;
  }
  return (
    <FullCalendar
      allDaySlot={false}
      locale={ptBrLocale}
      initialView="timeGridWeek"
      slotMinTime={slotMinTime}
      slotMaxTime={slotMaxTime}
      plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin, momentPlugin]}
      views={{
        month: {
          titleFormat: 'MMMM YY',
        },
        week: {
          titleFormat: 'MMM YY',
        },
        day: {
          titleFormat: 'Do MMM YYYY',
        },
      }}
      // validRange={{
      //   start: '2023-12-10',
      //   end: '2023-12-20',
      // }}
      handleWindowResize={true}
      height={height}
      themeSystem="standard"
      buttonText={{
        today: 'Hoje',
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
        list: 'Lista',
        prev: '<<<',
        next: '>>>',
      }}
      eventDisplay="block"
      eventTimeFormat={{
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false,
        hour12: false,
      }}
      displayEventTime={true}
      displayEventEnd={true}
      customButtons={customButtons}
      headerToolbar={
        customHeaderToolbar
          ? customHeaderToolbar
          : {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
          }
      }
      footerToolbar={customFooterToolbar ?? {}}
      editable={true}
      selectable={true}
      droppable={true}
      events={events}
      dateClick={onDateClick}
      eventClick={onEventClick}
      drop={onDrop}
      eventDrop={onEventDrop}
    />
  );
};

export { Calendar };
