import { useState, createContext, useEffect } from 'react';
import { storageClinic } from 'shared/storage';

export const ClinicIdContext = createContext();

export const ClinicIdProvider = ({ children }) => {
  const [clinicId, setClinicId] = useState(null);

  useEffect(() => {
    setClinicId(storageClinic());
  }, []);

  useEffect(() => {
    if (clinicId) storageClinic(clinicId);
  }, [clinicId]);

  return <ClinicIdContext.Provider value={{ clinicId, setClinicId }}>{children}</ClinicIdContext.Provider>;
};
