import { useState, createContext, useEffect } from 'react';
import { navBarOpen } from 'shared/storage';

export const NavBarContext = createContext();

export const NavBarProvider = ({ children }) => {
  const [navOpen, setNavOpen] = useState();

  useEffect(() => {
    const run = async () => {
      const response = JSON.parse(navBarOpen());
      setNavOpen(response);
    };
    run();
  }, []);

  useEffect(() => {
    navBarOpen(navOpen);
  }, [navOpen]);

  return <NavBarContext.Provider value={{ navOpen, setNavOpen }}>{children}</NavBarContext.Provider>;
};
