import React from "react";
import { Avatar } from '@mui/material';


export default function UserAvatar({imageUrl = "", size = 34, ...props}) {   
    return (
        <Avatar 
            alt="User image" 
            src={imageUrl} 
            sx={{ width: size, height: size }}
            {...props}
        />
    )
}