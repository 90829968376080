import React, { useContext, useEffect, useState } from 'react';
import styles from './ConsultorioForm.module.scss';
import Button from 'components/Button/Button';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import { LoaderContext } from 'contexts/loader';
import PageTitle from 'components/PageTitle/PageTitle';
import Container from 'components/Container/Container';
import { FormClinicContext } from 'contexts/formClinic';
import Dados from '../Pages/Dados/Dados';
import Endereco from '../Pages/Endereco/Endereco';
import Horarios from '../Pages/Horario/Horario';
import { validateClinicData, validateClinicAdress, validateClinicTime } from '../validator';
import { transformClinicDataToApi, transformApiToClinicData } from 'transformers/userForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from 'hooks/useToast';
import { AdminClinicsRepository } from 'data/admin-clinics';

function ConsultorioForm({ tab }) {
  let navigate = useNavigate();
  const toast = useToast();
  let { id } = useParams();
  const { setLoading } = useContext(LoaderContext);
  const { dados, setDados } = useContext(FormClinicContext);
  const [inputErrors, setInputErrors] = useState(null);
  const [buttonAvancar, setButtonAvancar] = useState('');
  const [buttonVoltar, setButtonVoltar] = useState('');
  const [showInputErrors, setShowInputErrors] = useState(false);

  const colorButtonAvancar = buttonAvancar !== '' ? 'secondary' : 'inherit';

  const visibleInputErrors = showInputErrors ? inputErrors : null;
  const contents = {
    dados: <Dados error={visibleInputErrors} />,
    endereco: <Endereco error={visibleInputErrors} />,
    horarios: <Horarios error={visibleInputErrors} />,
  };

  useEffect(() => {
    const run = async () => {
      switch (tab) {
        case 'dados':
          if (dados?.clinicData) {
            try {
              await validateClinicData(dados?.clinicData);
              setShowInputErrors(false);
              setInputErrors(null);
              setButtonAvancar(id ? `/admin/consultorio/editar/${id}/endereco` : '/admin/consultorio/novo/endereco');
            } catch (err) {
              const newError = {};
              err.inner.forEach(inputError => {
                newError[inputError.path] = inputError.message;
              });
              setInputErrors(newError);
              setButtonAvancar('');
            }
            setButtonVoltar('/admin/consultorio');
          } else setButtonAvancar('');
          break;
        case 'endereco':
          if (dados?.address) {
            try {
              await validateClinicAdress(dados?.address);
              setShowInputErrors(false);
              setInputErrors(null);
              setButtonAvancar(id ? `/admin/consultorio/editar/${id}/horarios` : '/admin/consultorio/novo/horarios');
            } catch (err) {
              const newError = {};
              err.inner.forEach(inputError => {
                newError[inputError.path] = inputError.message;
              });
              setInputErrors(newError);
              setButtonAvancar('');
            }
            setButtonVoltar(id ? `/admin/consultorio/editar/${id}` : '/admin/consultorio/novo');
          } else setButtonAvancar('');
          break;
        case 'horarios':
          if (dados?.times) {
            try {
              await validateClinicTime(dados?.times);
              setShowInputErrors(false);
              setInputErrors(null);
              setButtonAvancar(id ? `/admin/consultorio/editar/${id}/horarios` : '/admin/consultorio/novo/horarios');
            } catch (err) {
              const newError = {};
              err.inner.forEach(inputError => {
                newError[inputError.path] = inputError.message;
              });
              setInputErrors(newError);
              setButtonAvancar('');
            }
            setButtonVoltar(id ? `/admin/consultorio/editar/${id}/endereco` : '/admin/consultorio/novo/endereco');
          } else setButtonAvancar('');
          break;
        default:
          setButtonAvancar('');
      }
    };
    run();
  }, [tab, dados]); // eslint-disable-line react-hooks/exhaustive-deps

  function removeFormatting(value) {
    return value.replace(/\D/g, ''); // Remove all non-numeric characters
  }

  const saveData = async () => {
    if (inputErrors) {
      return;
    }
    setLoading(true);
    const response = id
      ? await AdminClinicsRepository.update(
        id,
        transformClinicDataToApi({
          ...dados,
          clinicData: {
            ...dados.clinicData,
            cnpj: removeFormatting(dados.clinicData.cnpj),
            phone_number: removeFormatting(dados.clinicData.phone_number),
          },
          address: {
            ...dados.address,
            cep: removeFormatting(dados.address.cep),
          },
        })
      )
      : await AdminClinicsRepository.create(
        transformClinicDataToApi({
          ...dados,
          clinicData: {
            ...dados.clinicData,
            cnpj: removeFormatting(dados.clinicData.cnpj),
            phone_number: removeFormatting(dados.clinicData.phone_number),
          },
          address: {
            ...dados.address,
            cep: removeFormatting(dados.address.cep),
          },
        })
      );
    setLoading(false);
    if (response?.error) {
      toast.error(response.error, response.debug);
    } else {
      return navigate('/admin/consultorio');
    }
  };

  useEffect(() => {
    let ignore = false;
    async function run() {
      setLoading(true);
      const response = await AdminClinicsRepository.get(id);
      if (ignore) return;
      const data = transformApiToClinicData(response);
      if (response?.error) {
        toast.error(response.error);
        setDados({});
      } else {
        setDados(data);
      }
      setLoading(false);
    }
    if (id) {
      run();
    }
    return () => (ignore = true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isLastPage = tab === 'horarios';
  const isValidated = buttonAvancar !== '';
  return (
    <Container>
      <PageTitle>{id ? 'Editar' : 'Criar'} Consultório</PageTitle>
      <div className={styles.tab}>
        <div>
          <span className={tab === 'dados' ? styles.elementActive : styles.element}>Dados</span>
        </div>
        <div>
          <span className={tab === 'endereco' ? styles.elementActive : styles.element}>Endereço</span>
        </div>
        <div>
          <span className={tab === 'horarios' ? styles.elementActive : styles.element}>Horários</span>
        </div>
      </div>
      <>
        {contents[tab]}
        <div className={styles.botoes}>
          <ButtonVoltar link={buttonVoltar} />
          <Button
            color={colorButtonAvancar}
            label={isLastPage ? 'Concluir' : 'Avançar'}
            onClick={() => {
              if (isLastPage && isValidated) {
                saveData();
              } else if (isValidated) {
                navigate(buttonAvancar);
              } else {
                setShowInputErrors(true);
              }
            }}
            size="large"
          />
        </div>
      </>
    </Container>
  );
}

export default ConsultorioForm;
