import React, { useState, useContext } from 'react';
import styles from './PrimeiroAcesso.module.scss';
// import Input from 'components/Input/Input';
import { LoaderContext } from 'contexts/loader';
import { useToast } from 'hooks/useToast';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import Button from 'components/Button/Button';
import lockSVG from 'assets/ico/lock.svg';
import Container from 'components/Container/Container';
import { FirstAccessRepository } from 'data/firstaccess';
import { AuthContext } from 'contexts/auth';

const initialForm = {
  newpassword: '',
  renewpassword: '',
};

function PrimeiroAcesso() {
  let navigate = useNavigate();
  const toast = useToast();
  const { token } = useParams();
  const { setLoading } = useContext(LoaderContext);
  const [formData, setFormData] = useState(initialForm);
  const { user, getHomeMenuItems } = useContext(AuthContext);

  const saveData = async () => {
    setLoading(true);
    const response = await FirstAccessRepository.create(token, { new_password: formData.newpassword });
    setFormData(initialForm);
    setLoading(false);
    if (response?.error) {
      toast.error(response.error);
    } else {
      return navigate('/login');
    }
  };

  const handleChangeForm = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  function handleComparePasswords() {
    const form = { ...formData };
    if (form.newpassword === form.renewpassword && form.newpassword !== '') {
      saveData();
    } else {
      toast.error('Ops, algo errado com a senha. Por favor digite novamente!');
    }
  }

  if (user) {
    const menuItems = getHomeMenuItems();
    if (menuItems.length === 1) {
      return <Navigate to={menuItems[0].link} />;
    } else {
      return <Navigate to={'/home'} />;
    }
  }

  return (
    <Container className={styles.container}>
      <div className={styles.conteudo}>
        <p>Primeiro Acesso</p>
        <form>
          <div className={styles.inputs}>
            <div className={styles.input}>
              <img src={lockSVG} alt="" />
              <input
                type="password"
                placeholder="Nova Senha"
                name="newpassword"
                value={formData.newpassword}
                onChange={e => handleChangeForm(e)}
              />
            </div>
            <div className={styles.input}>
              <img src={lockSVG} alt="" />
              <input
                type="password"
                placeholder="Confirme sua senha"
                name="renewpassword"
                value={formData.renewpassword}
                onChange={e => handleChangeForm(e)}
              />
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              label="Salvar"
              fontColor="white"
              background="#15AB72"
              borderColor="#15AB72"
              widthButton="200px"
              heightButton="60px"
              onClick={() => handleComparePasswords()}
            />
          </div>
        </form>
      </div>
    </Container>
  );
}

export default PrimeiroAcesso;
