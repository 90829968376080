import React from 'react';
import styles from './FinanceHome.module.scss';
import CardHome from 'pages/shared/Home/CardHome/CardHome';
import { ReactComponent as FluxoCaixaSVG } from '../../assets/ico/fluxoCaixa.svg';
import { ReactComponent as ConfiguracoesSVG } from '../../assets/ico/configuracoes.svg';
import { ReactComponent as RelatoriosSVG } from '../../assets/ico/relatorios.svg';
import { HiPlus, HiOutlineMenu } from 'react-icons/hi';
import { ReactComponent as ProtocolosSVG } from '../../assets/ico/protocolos.svg';
import { BsCash } from 'react-icons/bs';

function FinanceHome() {

  return (
    <div className={styles.container}>
      <div className={styles.conteudo}>
        <CardHome
          icon={<ConfiguracoesSVG />}
          title="Dados Bancarios"
          buttons={[
            {
              label: 'Adicionar',
              icon: <HiPlus />,
              link: '/financeiro/dados-bancarios/novo',
              variant: 'contained',
              color: 'secondary',
            },
            {
              label: 'Acessar',
              icon: <HiOutlineMenu />,
              link: '/financeiro/dados-bancarios',
              variant: 'outlined',
              color: 'secondary',
            },
          ]}
        ></CardHome>
        <CardHome
          icon={<ConfiguracoesSVG />}
          title="Plano de Contas"
          buttons={[
            {
              label: 'Adicionar',
              icon: <HiPlus />,
              link: '/financeiro/plano-contas/novo',
              variant: 'contained',
              color: 'secondary',
            },
            {
              label: 'Acessar',
              icon: <HiOutlineMenu />,
              link: '/financeiro/plano-contas',
              variant: 'outlined',
              color: 'secondary',
            },
          ]}
        ></CardHome>
        <CardHome
          icon={<ConfiguracoesSVG />}
          title="Formas de Cobrança"
          buttons={[
            {
              label: 'Adicionar',
              icon: <HiPlus />,
              link: '/financeiro/formas-cobrancas/novo',
              variant: 'contained',
              color: 'secondary',
            },
            {
              label: 'Acessar',
              icon: <HiOutlineMenu />,
              link: '/financeiro/formas-cobrancas',
              variant: 'outlined',
              color: 'secondary',
            },
          ]}
        ></CardHome>
        <CardHome
          icon={<FluxoCaixaSVG />}
          title="Fluxo de Caixa"
          buttons={[
            {
              label: 'Adicionar',
              icon: <HiPlus />,
              link: '/financeiro/fluxo-caixa/add-transacao/',
              variant: 'contained',
              color: 'secondary',
            },
            {
              label: 'Acessar',
              icon: <HiOutlineMenu />,
              link: '/financeiro/fluxo-caixa',
              variant: 'outlined',
              color: 'secondary',
            },
          ]}
        ></CardHome>
        <CardHome
          icon={<ProtocolosSVG />}
          title="Precificação de Protocolos"
          buttons={[
            {
              label: 'Adicionar',
              icon: <HiPlus />,
              link: '/financeiro/protocolos/novo',
              variant: 'contained',
              color: 'secondary',
            },
            {
              label: 'Acessar',
              icon: <HiOutlineMenu />,
              link: '/financeiro/protocolos',
              variant: 'outlined',
              color: 'secondary',
            },
          ]}
        ></CardHome>
        <CardHome
          icon={<BsCash />}
          title="Caixa/Reembolso"
          buttons={[
            {
              label: 'Acessar',
              icon: <HiOutlineMenu />,
              link: '/financeiro/caixa',
              variant: 'outlined',
              color: 'secondary',
            },
          ]}
        ></CardHome>
        <CardHome
          icon={<RelatoriosSVG />}
          title="Comissão"
          buttons={[
            {
              label: 'Acessar',
              icon: <HiOutlineMenu />,
              link: '/financeiro/relatorio-comissao',
              variant: 'outlined',
              color: 'secondary',
            },
          ]}
        ></CardHome>
        {/* <CardHome
          icon={<RelatoriosSVG />}
          title="DRE"
          buttons={[
            {
              label: 'Acessar',
              icon: <HiOutlineMenu />,
              link: '/financeiro/dre',
              variant: 'outlined',
              color: 'secondary',
            },
          ]}
        ></CardHome> */}
        <CardHome
          icon={<RelatoriosSVG />}
          title="Previsão de Receita"
          buttons={[
            {
              label: 'Acessar',
              icon: <HiOutlineMenu />,
              link: '/financeiro/previsao-receita',
              variant: 'outlined',
              color: 'secondary',
            },
          ]}
        ></CardHome>
      </div>
    </div>
  );
}

export default FinanceHome;
