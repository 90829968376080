import Endpoints from '../config/endpoints';
import http from '../config/httpClient';
import { stringifyFilters } from '../shared/utility';
import { parseError } from './base';

export const RepositoryPurchaseOrders = {
  baseUrl: Endpoints.purchase_order,

  getOrders: async function (clinic) {
    try {
      const { data } = await http.get(`${this.baseUrl}${`?clinic=${clinic}`}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  getApprovedOrders: async function (filters = {}) {
    try {
      const queryString = stringifyFilters({ ...filters });
      const { data } = await http.get(`${this.baseUrl}${queryString}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  list: async function (filters = {}) {
    try {
      const queryString = stringifyFilters({ ...filters });
      const { data } = await http.get(`${this.baseUrl}${queryString}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  post: async function (payload) {
    try {
      const { data } = await http.post(`${this.baseUrl}`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  postOrder: async function (payload) {
    try {
      const { data } = await http.post(`${this.baseUrl}`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  patchOrder: async function (id, payload) {
    try {
      const { data } = await http.patch(`${this.baseUrl}/${id}`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  deleteOrder: async function (id) {
    try {
      const { data } = await http.delete(`${this.baseUrl}/${id}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  getItems: async function (id) {
    try {
      const { data } = await http.get(`${this.baseUrl}/${id}/items`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  postItem: async function (id, payload) {
    try {
      const { data } = await http.post(`${this.baseUrl}/${id}/items`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  deleteItem: async function (order, item) {
    try {
      const { data } = await http.delete(`${this.baseUrl}/${order}/items/${item}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  getBudgets: async function (id) {
    try {
      const { data } = await http.get(`${this.baseUrl}/${id}/suppliers `);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  postBudget: async function (id, payload) {
    try {
      const { data } = await http.post(`${this.baseUrl}/${id}/suppliers`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  getSuppliers: async function () {
    try {
      const { data } = await http.get(`/v1/supplier/`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  getSelectedSupplier: async function (id) {
    try {
      const { data } = await http.get(`${this.baseUrl}/${id}/selected-supplier`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  postSelectedSupplier: async function (id, payload) {
    try {
      const { data } = await http.post(`${this.baseUrl}/${id}/selected-supplier`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  deleteSupplier: async function (order, supplier) {
    try {
      const { data } = await http.delete(`${this.baseUrl}/${order}/suppliers/${supplier}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  getCatalog: async function () {
    try {
      const { data } = await http.get(`/v1/stock-items/?limit=9999`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  getItemFromCatalog: async function (id) {
    try {
      const { data } = await http.get(`/v1/stock-items?purchase_order=${id}&limit=9999`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
};
