import React, { useEffect, useState, useContext } from 'react';
import Table from 'components/Table/Table.js';
import styles from './DadosBancarios.module.scss';
import TableFooter from 'components/TableFooter/TableFooter';
import PageTitle from 'components/PageTitle/PageTitle';
import Container from 'components/Container/Container';
import Button from 'components/Button/Button.js';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import { Link } from 'react-router-dom';
import { BankAccountsRepository } from 'data/bankaccounts.js';
import { TbEdit } from 'react-icons/tb';
import Modal from 'components/Modal/Modal.js';
import { LoaderContext } from 'contexts/loader.js';
import confirmedIcon from 'assets/ico/confirmedIcon.svg';
import { ClinicIdContext } from 'contexts/clinicId';
import errorIcon from 'assets/ico/error_icon.svg';
import { MdOutlineDeleteOutline } from 'react-icons/md';

const initialModalState = {
  isOpen: false,
  success: false,
  error: false,
  pending: false,
  msg: '',
  id: '',
};

function DadosBancarios() {
  const { clinicId } = useContext(ClinicIdContext);
  const { setLoading } = useContext(LoaderContext);
  const [list, setList] = useState([]);
  const [modalState, setModalState] = useState(initialModalState);

  const loadDadosBancarios = async () => {
    setLoading(true);
    const response = await BankAccountsRepository.list({ clinic: clinicId });
    if (response?.error == null) {
      setList(response.results);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (clinicId != null) {
      loadDadosBancarios();
    }
  }, [clinicId]);

  const handleDelete = async bankAccountId => {
    setLoading(true);
    const response = await BankAccountsRepository.delete(bankAccountId);
    setLoading(false);
    if (response?.error) {
      setModalState({
        isOpen: true,
        error: true,
        msg: 'Você não pode deletar uma conta bancária que tem transações bancárias vinculadas',
      });
    } else {
      loadDadosBancarios();
      setModalState({
        isOpen: true,
        success: true,
      });
    }
  };

  const onClose = () => {
    setModalState(initialModalState);
  };

  const editButton = id => (
    <div className={styles.editButton}>
      <Link to={`/financeiro/dados-bancarios/editar/${id}`}>
        <TbEdit className={styles.pen} color="#C5A500" title="Editar Dados Bancários" />
      </Link>
      <MdOutlineDeleteOutline
        color="#A31912"
        title="Excluir Dado Bancário"
        onClick={() => {
          setModalState({ isOpen: true, id: id, pending: true });
        }}
      />
    </div>
  );
  const botaoVoltar = <ButtonVoltar link="/financeiro/home" />;
  const botaoAdd = (
    <Button label="Adicionar dados bancários" link="/financeiro/dados-bancarios/novo" color="secondary" />
  );

  return (
    <Container className={styles.container}>
      <PageTitle>Dados Bancários</PageTitle>
      <div className={styles.table}>
        <Table
          columns={['Banco', 'Agência', 'Conta Corrente', 'Ações']}
          lines={list.map(item => [item.banks_name, item.agency, item.account, editButton(item.id)])}
        />
      </div>
      <TableFooter>
        {botaoVoltar}
        {botaoAdd}
      </TableFooter>
      <Modal isOpen={modalState.isOpen && modalState.pending} setModalOpen={onClose} className={styles.modal_container}>
        {modalState.pending ? (
          <div className={styles.modal_inner_container}>
            <p>Tem certeza que deseja excluir ?</p>
            <Button color="secondary" label="Confirmar" onClick={() => handleDelete(modalState.id)} />
          </div>
        ) : (
          <></>
        )}
      </Modal>
      <Modal isOpen={modalState.isOpen && modalState.success} setModalOpen={onClose} className={styles.modal_container}>
        {modalState.success ? (
          <div className={styles.modal_inner_container}>
            <img src={confirmedIcon} alt=""></img>
            <span>Dado Bancário foi excluido</span>
          </div>
        ) : (
          <></>
        )}
      </Modal>
      <Modal isOpen={modalState.isOpen && modalState.error} setModalOpen={onClose} className={styles.modal_container}>
        {modalState.error ? (
          <div className={styles.modal_inner_container}>
            <img src={errorIcon} alt=""></img>
            <span>{modalState.msg}</span>
          </div>
        ) : (
          <></>
        )}
      </Modal>
    </Container>
  );
}

export default DadosBancarios;
