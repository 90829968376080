// hooks
import { useState, useContext } from 'react';
import { useInsumos } from './hooks/insumos';

// scss
import styles from './SolicitacoesInsumos.module.scss';

// contexts
import { ClinicIdContext } from 'contexts/clinicId';
import { AuthContext } from 'contexts/auth';

// components
import ListaSolicitacoes from './ListaSolicitacoes/ListaSolicitacoes';
import VerSolicitacao from './VerSolicitacao/VerSolicitacao';
import PageTitle from 'components/PageTitle/PageTitle';
import Container from 'components/Container/Container';
import Button from 'components/Button/Button.js';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import TableFooter from 'components/TableFooter/TableFooter';
import ModalSolicitarInsumo from './Modal/Modal';
import { CircularProgress } from '@mui/material';
import { FaArrowUp } from 'react-icons/fa';

// other
import moment from 'moment';
import Enums from 'transformers/enums';

function Insumos({ props }) {
  const { nav, tab } = props;

  const [modalState, setModalState] = useState({
    open: false,
    is_edit: false,
  });

  const [orderState, setOrderState] = useState({
    id: null,
    items: [],
  });
  const [tabInfo, setTabInfo] = useState(tab);

  const { clinicId } = useContext(ClinicIdContext);
  const { user } = useContext(AuthContext);
  const {
    pedidos,
    catalogo,
    itens,
    loading,
    setLoading,
    requestApprover,
    hasClinicSelected,
    handleDeleteOrder,
    modalStatus,
    setModalStatus,
  } = useInsumos(clinicId);

  const tableData = {
    headers: ['Data', 'Solicitante', 'Insumos', 'Status', 'Ações'],
    body: pedidos.map(pedido => {
      const filteredItems = [];
      const itemsPerOrder = [];

      for (const orderItems of itens) {
        for (const item of orderItems) {
          if (item.order === pedido.id) {
            const matchingCatalogItem = catalogo.find(catalogItem => catalogItem.id === item.item);
            const itemName = matchingCatalogItem ? matchingCatalogItem.name : '';
            itemsPerOrder.push({
              ...item,
              name: matchingCatalogItem?.name,
            });
            filteredItems.push(itemName);
          }
        }
      }

      const resolvedItemNames = filteredItems.join(', ');

      return {
        order_id: pedido.id,
        items: itemsPerOrder,
        style: '',
        motivo: pedido?.rejection_reason ?? '',
        data: moment(pedido.request_date).format('DD/MM/YYYY'),
        solicitante: pedido.requester_name,
        insumo: resolvedItemNames,
        status: Enums.statusLabels[pedido.status],
      };
    }),
  };

  const contents = {
    lista_solicitacoes: (
      <ListaSolicitacoes
        props={{ setTabInfo, setModalState, setOrderState, tableData, handleDeleteOrder, modalStatus, setModalStatus }}
      />
    ),
    ver_solicitacao: <VerSolicitacao props={{ setTabInfo, orderState, requestApprover, nav }} />,
  };

  return (
    <Container>
      <PageTitle>Solicitação de Insumos</PageTitle>
      {hasClinicSelected ? (
        <>
          <div className={styles.tab}>
            <span
              className={tabInfo === 'lista_solicitacoes' ? styles.elementActive : styles.element}
              onClick={() => setTabInfo('lista_solicitacoes')}
            >
              Lista de Solicitações
            </span>
            {tabInfo === 'ver_solicitacao' && (
              <span
                className={tabInfo === 'ver_solicitacao' ? styles.elementActive : styles.element}
                onClick={() => setTabInfo('ver_solicitacao')}
              >
                Visualizar pedido de insumos
              </span>
            )}
          </div>
          {loading ? (
            <div className={styles.solicitacao_insumos_loader}>
              <CircularProgress color="success" size={75} />
            </div>
          ) : (
            contents[tabInfo]
          )}
          {tabInfo === 'lista_solicitacoes' && (
            <TableFooter>
              <ButtonVoltar link={`/${Enums.navTabToLabelUrl[nav]}/home`} />
              <Button color="secondary" label="+ Solicitar Insumo" onClick={() => setModalState({ ...modalState, open: true })} />
            </TableFooter>
          )}
          <ModalSolicitarInsumo
            modalState={modalState}
            setModalState={setModalState}
            orderState={orderState}
            setOrderState={setOrderState}
            catalogo={catalogo}
            user={user}
            clinic={clinicId}
            loading={loading}
            setLoading={setLoading}
          />
        </>
      ) : (
        <div className={styles.no_clinic_selected}>
          <p>Selecione um consultório</p>
          <FaArrowUp />
        </div>
      )}
    </Container>
  );
}

export default Insumos;
