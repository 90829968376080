// hooks
import { useState, useEffect, useContext } from 'react';

// scss
import styles from './ConsultorioSelectGlobal.module.scss';

// contexts
import { ClinicIdContext } from 'contexts/clinicId';
import { AuthContext } from 'contexts/auth';

import { AdminClinicsRepository } from 'data/admin-clinics';

// components
import Select from 'components/Select/Select';

// other
import { clinicalStorage } from 'shared/storage';

function ConsultorioSelectGlobal() {
  const { clinicId, setClinicId } = useContext(ClinicIdContext);
  const { user } = useContext(AuthContext);

  const [dataList, setDataList] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState(0);

  useEffect(() => {
    const run = async () => {
      const responseClinics = await AdminClinicsRepository.list();
      clinicalStorage(JSON.stringify(responseClinics.results));
      const userClinics = user?.clinics ?? [];
      const clinicsCopy = responseClinics.results.filter(c => userClinics.includes(c.id));
      clinicsCopy.unshift({ id: 0, name: 'Selecione um consultório' });
      setDataList(clinicsCopy);
    };
    run();
  }, []);

  useEffect(() => {
    if (clinicId != null && selectedClinic === 0) {
      setSelectedClinic(clinicId);
    }
  }, [clinicId]);

  const handleChange = event => {
    const newId = event.target.value;
    setSelectedClinic(newId);
    setClinicId(newId !== 0 ? newId : null);
  };

  return (
    <>
      <div className={styles.container}>
        <Select
          className={styles.consultorio_select_global_select}
          label="Consultório"
          options={dataList.map(item => ({ value: item.id, name: item.name }))}
          value={selectedClinic}
          onChange={handleChange}
        />
      </div>
    </>
  );
}

export default ConsultorioSelectGlobal;
