import React from "react"
import styles from './Notas.module.scss'

function Notas() {

    return (
        <div className={styles.container}>
            <div className={styles.conteudo}>
                Notas
            </div>
        </div>
    )
}

export default Notas