import yup from 'shared/validator.js';

let DadosBancariosSchema = yup.object({
  // clinic: yup.number().required('Você deve selecionar um consultório no menu para adicionar uma conta bancária'),
  banks: yup.string().required('Selecione uma conta bancária'),
  agency: yup.string().max(100).required('Agência é um campo obrigatório'),
  account: yup.string().max(100).required('Conta Corrente é um campo obrigatório'),
});

export const validateDadosBancarios = async data => {
  return await DadosBancariosSchema.validate(data);
};
