import Endpoints from '../config/endpoints';
import http from '../config/httpClient';
import { parseError } from './base';
import { stringifyFilters } from '../shared/utility';

export const PaymentMethodRepository = {
  baseUrl: Endpoints.paymentmethod,

  get: async function (id) {
    try {
      const { data } = await http.get(`${this.baseUrl}/${id}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  list: async function (filters = {}) {
    try {
      const queryString = stringifyFilters({ ...filters });
      const { data } = await http.get(`${this.baseUrl}${queryString}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  create: async function (payload) {
    try {
      const { data } = await http.post(`${this.baseUrl}`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  update: async function (id, payload) {
    try {
      const { data } = await http.patch(`${this.baseUrl}/${id}`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  getTypes: async function (id) {
    try {
      const { data } = await http.get(`${this.baseUrl}/${id}/types`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  postType: async function (id, payload) {
    try {
      const { data } = await http.post(`${this.baseUrl}/${id}/types`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  updateType: async function (paymentId, typeId, payload) {
    try {
      const { data } = await http.patch(`${this.baseUrl}/${paymentId}/types/${typeId}`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  deleteType: async function (paymentId, typeId) {
    try {
      const { data } = await http.delete(`${this.baseUrl}/${paymentId}/types/${typeId}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  delete: async function (id) {
    try {
      const { data } = await http.delete(`${this.baseUrl}/${id}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
};
