// hooks
import { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from 'hooks/useToast';

// scss
import styles from './FormPaciente.module.scss';

// contexts
import { LoaderContext } from 'contexts/loader';
import { ClinicIdContext } from 'contexts/clinicId';

// components
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import { Checkbox } from '@mui/material';

// other
import { transformApiToPatient } from 'transformers/userForm';
import { cpfMask } from 'shared/utility';
import { validatePaciente } from './validator';
import { PatientsRepository } from 'data/patients';
import { apiBadRequestError } from 'exceptions/api';
import Select from 'components/Select/Select';
import Enums from 'transformers/enums';

const initialPatientData = {
  name: '',
  birthdate: '1980-01-01',
  gender: '',
  cpf: '',
  address: '',
  social_url: '',
  indication: null,
  patient_indication: null,
  rg: '',
  phone: '',
  observation: '',
  email: '',
  clinic: '',
};

export default function FormPaciente({path}) {
  let navigate = useNavigate();
  let { id } = useParams();
  const [patients, setPatients] = useState([]);
  const { setLoading } = useContext(LoaderContext);
  const [patientData, setPatientData] = useState(initialPatientData);
  const { clinicId } = useContext(ClinicIdContext);
  const toast = useToast();

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const response = await PatientsRepository.get(id);
      setLoading(false);
      const data = transformApiToPatient(response);
      if (response?.error) {
        toast.error(response.error);
        setPatientData({});
      } else {
        setPatientData(data);
        if (data.patient_indication) {
          await loadAllPatients();
        }
      }
      setLoading(false);
    };
    if (id) {
      run();
    }
  }, [id]);

  const loadAllPatients = async () => {
    setLoading(true);
    const patientsResponse = await PatientsRepository.list({ limit: 9999 });
    if (patientsResponse && patientsResponse.results) {
      const patientsMap = [];
      for (const item of patientsResponse.results) {
        if (item.id.toString() !== id) {
          patientsMap.push({ 
            name: `${item.name} - CPF: ${item.cpf.slice(0, 3)}.***.***-**`, 
            value: item.id,
          });
        }
      }
      setPatients(patientsMap);
    }
    setLoading(false);
  }

  const handleChange = event => {
    let value = event.value;
    if (event.name === 'cpf') {
      if (value.length > 14) {
        value = value.replace(/\D/g, '').slice(0, -1);
      } else {
        value = value.replace(/\D/g, '');
      }
    }
    if (event.name === 'phone') {
      value = value.replace(/\D/g, '');
    }
    setPatientData({
      ...patientData,
      [event.name]: value,
    });
  };

  const handleDateChange = event => {
    setPatientData({ ...patientData, birthdate: event.target.value });
  };

  const handleSubmit = async event => {
    try {
      await validatePaciente(patientData);
    } catch (err) {
      toast.error(err.message);
      return;
    }

    try {
      await saveData();
    } catch (err) {
      toast.error(err.errors?.[0] || err.message);
    }
  };

  const saveData = async () => {
    setLoading(true);
    const response = id
      ? await PatientsRepository.update(id, { ...patientData, clinic: parseInt(clinicId) })
      : await PatientsRepository.create({ ...patientData, clinic: parseInt(clinicId) });
    setLoading(false);
    if (response?.error) throw new apiBadRequestError(response.error, response.debug);
    return navigate(`/${path}/pacientes`);
  };

  return (
    <Container>
      <PageTitle>{id ? 'Editar Paciente' : 'Novo Paciente'}</PageTitle>
      <div className={styles.divContainer}>
        <div className={styles.header}>
          <p>Dados Cadastrais</p>
          <Input
            label="Nome do paciente"
            name="name"
            value={patientData.name}
            onChange={event => handleChange({ name: event.target.name, value: event.target.value })}
          />
        </div>
        <div className={styles.divData}>
          <Input
            label="CPF"
            name="cpf"
            value={cpfMask(patientData.cpf)}
            onChange={event => handleChange({ name: event.target.name, value: event.target.value })}
          />
          <Input
            label="RG"
            name="rg"
            value={patientData.rg}
            inputProps={{ maxLength: 10 }}
            onChange={event => handleChange({ name: event.target.name, value: event.target.value })}
          />
          <Input
            type="date"
            name="birthdate"
            value={patientData.birthdate}
            onChange={handleDateChange}
            label="Data de Nascimento"
          />
          <div className={styles.divGender}>
            Sexo:
            <Checkbox
              name="gender"
              color="secondary"
              checked={patientData.gender === 'male'}
              onChange={() => handleChange({ name: 'gender', value: 'male' })}
            />
            Masculino
            <Checkbox
              name="gender"
              color="secondary"
              checked={patientData.gender === 'female'}
              onChange={() => handleChange({ name: 'gender', value: 'female' })}
            />
            Feminino
          </div>
        </div>
        <p>Informações de Contato</p>
        <div className={styles.divData}>
          <Input
            name="phone"
            label="Telefone"
            mask="(99)99999-9999"
            addMask={true}
            lower={true}
            value={patientData.phone}
            onChange={event => handleChange({ name: event.target.name, value: event.target.value })}
          />
          <Input
            label="Email"
            name="email"
            value={patientData.email}
            onChange={event => handleChange({ name: event.target.name, value: event.target.value })}
          />
          <Input
            label="Rede Social"
            name="social_url"
            value={patientData.social_url}
            helper="EX: https://www.instagram.com/drthauansantos/"
            onChange={event => handleChange({ name: event.target.name, value: event.target.value })}
          />
          <div>
            <Select
              label="De onde veio?"
              options={Object.keys(Enums.indicationToLabel).map(value => ({ value, name: Enums.indicationToLabel[value] }))}
              value={patientData.indication}
              onChange={event => {
                setPatientData({
                  ...patientData,
                  indication: event.target.value,
                  patient_indication: null,
                });
                if (event.target.value === 'patient' && patients.length === 0) {
                  loadAllPatients();
                }
              }}
            ></Select>
            {patientData.indication === 'patient' && (
              <Select
                label="Paciente:"
                value={patientData.patient_indication}
                options={patients}
                style={{ marginTop: '10px' }}
                onChange={event => handleChange({ name: 'patient_indication', value: event.target.value })}
              ></Select>
            )}
          </div>
        </div>
        <div className={styles.header}>
          <p>Endereço</p>
          <Input
            name="address"
            value={patientData.address}
            inputProps={{ maxLength: 255 }}
            helper="EX: R. Waldemar Geib, 56 - Hamburgo Velho, Novo Hamburgo - RS, 93540-300"
            onChange={event => handleChange({ name: event.target.name, value: event.target.value })}
          />
        </div>
        <div className={styles.divObs}>
          <p>Observações</p>
          <textarea
            className={styles.textA}
            value={patientData.observation}
            onChange={event => handleChange({ name: 'observation', value: event.target.value })}
          ></textarea>
          <small>EX: Paciente tem dificuldade de fala</small>
        </div>
      </div>
      <div className={styles.footer}>
        <ButtonVoltar link={`/${path}/pacientes`} />
        <Button label="Salvar" size="large" color="secondary" onClick={handleSubmit} />
      </div>
    </Container>
  );
}
