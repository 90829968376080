import React, { useContext, useEffect, useState } from 'react';
import styles from './Consultorio.module.scss';
import Table from 'components/Table/Table.js';
import { TbEdit } from 'react-icons/tb';
import Button from 'components/Button/Button.js';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import { BiTime } from 'react-icons/bi';
import { LoaderContext } from 'contexts/loader';
import { useToast } from 'hooks/useToast';
import { Link } from 'react-router-dom';
import TableFooter from 'components/TableFooter/TableFooter';
import PageTitle from 'components/PageTitle/PageTitle';
import Container from 'components/Container/Container';
import { cnpjMask } from 'shared/utility';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { AdminClinicsRepository } from 'data/admin-clinics';
import errorIcon from 'assets/ico/error_icon.svg';
import Modal from 'components/Modal/Modal.js';
import confirmedIcon from 'assets/ico/confirmedIcon.svg';

const initialModalState = {
  isOpen: false,
  success: false,
  pending: false,
  error: false,
  msg: '',
  id: '',
};

function Consultorio() {
  const { setLoading } = useContext(LoaderContext);
  const toast = useToast();
  const [list, setList] = useState([]);
  const [modalState, setModalState] = useState(initialModalState);

  const deleteRecord = async id => {
    setLoading(true);
    const response = await AdminClinicsRepository.delete(id);
    setLoading(false);
    if (response?.error) {
      setModalState({
        isOpen: true,
        error: true,
        msg: response.error,
      });
    } else {
      setList(list.filter(item => item.id !== id));
      setModalState({
        isOpen: true,
        success: true,
      });
    }
  };

  const handleDelete = id => {
    deleteRecord(id);
  };

  const editButton = item => (
    <div className={styles.editButton}>
      <Link to={`/admin/consultorio/editar/${item.id}`}>
        <TbEdit className={styles.pen} color="#C5A500" title="Editar Consultório" />
      </Link>
      <MdOutlineDeleteOutline
        color="#A31912"
        title="Excluir Consultório"
        onClick={() => {
          setModalState({ isOpen: true, id: item.id, pending: true });
        }}
      />
    </div>
  );
  const time = item => (
    <div className={styles.time}>
      <BiTime />
      {item.opening.slice(0, -3)} às {item.closure.slice(0, -3)}
    </div>
  );
  const botaoVoltar = <ButtonVoltar link="/admin/home" />;
  const botaoAdd = <Button label="Novo Consultório" link="/admin/consultorio/novo" color="secondary" addLink={true} />;

  useEffect(() => {
    let ignore = false;
    async function run() {
      setLoading(true);
      const response = await AdminClinicsRepository.list();
      if (ignore) return;
      setLoading(false);
      if (response?.error) {
        toast.error(response.error);
        setList([]);
      } else setList(response.results);
    }
    run();
    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    if (list.length > 0) {
      setLoading(false);
    }
  }, [list]);

  const onClose = () => {
    setModalState(initialModalState);
  };

  return (
    <Container>
      <PageTitle>Consultórios Vinculados</PageTitle>
      <div className={styles.table}>
        <Table
          columns={['Consultório', 'CNPJ', 'Horário', 'Ações']}
          lines={list.map(item => [item.name, cnpjMask(item.cnpj), time(item), editButton(item)])}
        />
        <TableFooter>
          {botaoVoltar}
          {botaoAdd}
        </TableFooter>
      </div>
      <Modal isOpen={modalState.isOpen && modalState.pending} setModalOpen={onClose} className={styles.modal_container}>
        {modalState.pending ? (
          <div className={styles.modal_inner_container}>
            <p>Tem certeza que deseja excluir este consultório?</p>
            <Button color="secondary" label="Confirmar" onClick={() => handleDelete(modalState.id)} />
          </div>
        ) : (
          <></>
        )}
      </Modal>
      <Modal isOpen={modalState.isOpen && modalState.success} setModalOpen={onClose} className={styles.modal_container}>
        {modalState.success ? (
          <div className={styles.modal_inner_container}>
            <img src={confirmedIcon} alt=""></img>
            <span>Consultório foi excluido</span>
          </div>
        ) : (
          <></>
        )}
      </Modal>
      <Modal isOpen={modalState.isOpen && modalState.error} setModalOpen={onClose} className={styles.modal_container}>
        {modalState.error ? (
          <div className={styles.modal_inner_container}>
            <img src={errorIcon} alt=""></img>
            <span>{modalState.msg}</span>
          </div>
        ) : (
          <></>
        )}
      </Modal>
    </Container>
  );
}

export default Consultorio;
