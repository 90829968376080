// scss
import styles from './profile.module.scss';

// components
import Input from 'components/Input/Input';
import Checkbox from 'components/Checkbox/Checkbox';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import { Grid } from '@mui/material';

// other
import Enums from 'transformers/enums';

export default function AddUserProfile({ props }) {
  const { form, setForm, error, serviceNames } = props;

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Perfis</h2>
      <Grid>
        <Checkbox
          multiple
          allowMultipleSelected
          defaultValue={form.user_types}
          options={Object.keys(Enums.profileNameToLabel).map(label => ({
            label: Enums.profileNameToLabel[label],
            value: Enums.profileNameToId[label],
          }))}
          children={[
            {
              value: 1,
              element: (
                <Input
                  className={styles.selected_input}
                  placeholder="CRM"
                  value={form.profile.doctor != null ? form.profile.doctor.crm : ''}
                  onChange={event =>
                    setForm(prevState => ({
                      ...prevState,
                      profile: {
                        ...prevState.profile,
                        doctor: {
                          crm: event.target.value,
                        },
                      },
                    }))
                  }
                  error={error && error.profile && error.profile['doctor.crm'] ? error.profile['doctor.crm'][0] : null}
                />
              ),
            },
            {
              value: 7,
              element: (
                <Autocomplete
                  label="Outros serviços"
                  className={styles.profile_autocomplete}
                  value={serviceNames
                    .map(item => ({ label: item.name, value: item.id }))
                    .find(item => item.value === form.profile.services?.service_type)}
                  options={serviceNames
                    .sort((a, b) => a.name - b.name)
                    .map(item => ({ value: item.id, label: item.name }))}
                  onChange={(_, item) =>
                    setForm(prevState => ({
                      ...prevState,
                      profile: {
                        ...prevState.profile,
                        services: {
                          ...prevState.profile.services,
                          name: item != null ? item.label : '',
                          service_type: item != null ? item.value : '',
                        },
                      },
                    }))
                  }
                  error={error && error.profile && error.profile.services ? error.profile.services[0] : null}
                />
              ),
            },
          ]}
          onClick={selected => {
            if (!selected.includes(1)) {
              setForm(prevState => ({
                ...prevState,
                profile: {
                  ...prevState.profile,
                  doctor: null,
                },
              }));
            }
            if (!selected.includes(7)) {
              setForm(prevState => ({
                ...prevState,
                profile: {
                  ...prevState.profile,
                  services: null,
                },
              }));
            }
            setForm(prevState => ({
              ...prevState,
              user_types: selected,
            }));
          }}
        />
        {error && error.profile && error.profile.user_types ? (
          <span className={styles.error}>{error.profile.user_types}</span>
        ) : (
          ''
        )}
      </Grid>
    </div>
  );
}
