import yup from 'shared/validator.js';

let FornecedorSchema = yup.object({
  company_name: yup.string().required(),
  cnpj: yup.string().required(),
});

export const validateFornecedor = async data => {
  return await FornecedorSchema.validate(data);
};
