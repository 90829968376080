import React, { useEffect, useState, useContext } from 'react';
import PageTitle from 'components/PageTitle/PageTitle';
import { LoaderContext } from 'contexts/loader';
import { Grid } from '@mui/material';
import Button from 'components/Button/Button.js';
import { ClinicIdContext } from 'contexts/clinicId';
import Modal from 'components/Modal/Modal';
import { PaymentMethodRepository } from 'data/paymentmethod';
import Table from 'components/Table/Table';
import Input from 'components/Input/Input';
import Select from 'components/Select/Select';
import { formatCurrency } from 'shared/utility';
import styles from './ModalPagar.module.scss';
import { PaymentSchedulesRepository } from 'data/paymentprotocols';
import { useToast } from 'hooks/useToast';

function ModalPagar({patientName, procedimentos, onClose}) {
  const { clinicId } = useContext(ClinicIdContext);
  const { setLoading } = useContext(LoaderContext);
  const toast = useToast();

  const [editProce, setEditProce] = useState(procedimentos.map(item => ({ ...item })));
  const [paymentMethodTypeId, setPaymentMethodTypeId] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [discountPercent, setDiscountPercent] = useState('');

  useEffect(() => {
    const fetchCobrancas = async () => {
      setLoading(true);
      const response = await PaymentMethodRepository.list({ clinic: clinicId });
      setPaymentMethods(response.results ?? []);
      setLoading(false);
    }
    if (clinicId != null) {
      fetchCobrancas();
    }
  }, [clinicId]);

  const tableLines = editProce.map(item => [
    `${item.protocolName === '' ? 'Avulso' : item.protocolName}: ${item.name}`, 
    item.scheduleObs ?? '', 
    <Input
      numericInput
      value={item.value}
      className={styles.proceValueInput}
      onChange={e => {
        let newValue = e.target.value.replace(/\D+/g, '');
        newValue = newValue === '' ? 0 : parseInt(newValue) / 100;
        item.value = newValue;
        setEditProce([...editProce]);
      }}
    />
  ]);

  let totalWithDiscount = 0;
  editProce.forEach(proce => totalWithDiscount += proce.value);
  const discountPercentValue = 100 - (discountPercent === '' ? 0 : parseInt(discountPercent));
  totalWithDiscount *= discountPercentValue / 100;
  totalWithDiscount = `Total com desconto: ${formatCurrency(totalWithDiscount)}`;
  tableLines.push(['', '', <span className={styles.totalWithDiscount}>{totalWithDiscount}</span>]);

  const paymentMethodTypes = [];
  paymentMethods.forEach(paymentMethod => {
    if (paymentMethod.types.length === 1) {
      paymentMethodTypes.push({
        id: paymentMethod.types[0].id,
        description: paymentMethod.description,
      });

    } else {
      paymentMethod.types.forEach(pmTypes => {
        paymentMethodTypes.push({
          id: pmTypes.id,
          description: `${paymentMethod.description} (${pmTypes.parcels}x)`,
        });
      });
    }
  });

  return (
    <Modal isOpen={true} setModalOpen={onClose}>
      <PageTitle>Paciente: {patientName}</PageTitle>
      <Table
        columns={['Procedimento', 'Observação do Atendimento', 'Valor']}
        lines={tableLines}
      />
      <Grid container>
        <Grid item xs={2}>
          <Input
            value={discountPercent}
            label="% de Desconto"
            onChange={e => {
              setDiscountPercent(e.target.value.replace(/\D+/g, ''));
            }}
          />              
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={7}>
          <Select
            label="Forma de Pagamento"
            options={paymentMethodTypes.map(item => ({ value: item.id, name: item.description }))}
            value={paymentMethodTypeId}
            onChange={e => setPaymentMethodTypeId(e.target.value)}
          />              
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1}>
          <div align="center">
            <Button
              label="Pagar"
              onClick={async () => {
                setLoading(true);
                const payload = {
                  schedules: editProce.map(proce => ({
                    id: proce.scheduleId,
                    value: proce.value,
                  })),
                  discount: discountPercent === '' ? 0 : parseInt(discountPercent),
                  payment_method_type: paymentMethodTypeId
                };
                const response = await PaymentSchedulesRepository.create(payload);
                setLoading(false);
                if (response?.error) {
                  toast.error(response.error);
                } else {
                  onClose('reload');
                }
              }}
            />              
          </div>
        </Grid>
      </Grid>
    </Modal>    
  );
}

export default ModalPagar;
