import React, { useEffect, useState, useContext } from 'react';
import styles from './DRE.module.scss';
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import Select from 'components/Select/Select';
import Button from 'components/Button/Button';
import Table from 'components/Table/Table';
import TableFooter from 'components/TableFooter/TableFooter';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import { CiExport } from 'react-icons/ci';
import { DreRepository } from 'data/dre';
import { ClinicIdContext } from 'contexts/clinicId';
import { LoaderContext } from 'contexts/loader';
import { FaArrowUp } from 'react-icons/fa';

const selectAgrupar = [
  {
    id: '1',
    name: 'Agrupar por trimestre',
    value: '3',
  },
  {
    id: '2',
    name: 'Agrupar por semestre',
    value: '6',
  },
];

const selectCompetencia = [
  {
    id: '1',
    name: 'competencia 2023',
    value: '2023',
  },
];

function DRE() {
  const { setLoading } = useContext(LoaderContext);
  const [list, setList] = useState([]);
  const { clinicId } = useContext(ClinicIdContext);
  const botaoVoltar = <ButtonVoltar link="/financeiro/home" />;
  const [filterAgrupa, setFilterAgrupa] = useState('3');
  const [filterCompe, setFilterCompe] = useState('2023');
  const [hasClinicSelected, setHasClinicSelected] = useState(false);

  const run = async () => {
    setLoading(true);
    const response = await DreRepository.list({
      agrupamento: filterAgrupa,
      ano: filterCompe,
      clinica: clinicId,
    });
    if (response?.error) {
      setLoading(false);
    } else {
      setList(response.DRE);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (clinicId != null) {
      run();
      setHasClinicSelected(true);
    } else setHasClinicSelected(false);
  }, [filterAgrupa, filterCompe, clinicId]);

  const handleFilter = event => {
    if (event.target.name === 'agrupamento') {
      setFilterAgrupa(event.target.value);
    } else if (event.target.name === 'ano') {
      setFilterCompe(event.target.value);
    }
  };

  return (
    <Container>
      <PageTitle>DRE</PageTitle>
      {hasClinicSelected ? (
        <>
          <div className={styles.header}>
            <div>
              <Select
                label="Selecione o ano"
                name="ano"
                options={selectCompetencia.map(item => ({ value: item.value, name: item.name }))}
                value={filterCompe}
                style={{ width: '271px' }}
                onChange={handleFilter}
              />
            </div>
            <div className={styles.button}>
              <Select
                label="Agrupar por"
                name="agrupamento"
                value={filterAgrupa}
                options={selectAgrupar.map(item => ({ value: item.value, name: item.name }))}
                style={{ width: '271px' }}
                onChange={handleFilter}
              />
              <Button
                label="Exportar"
                type="button"
                variant="contained"
                style={{ backgroundColor: '#fff', border: '1px solid $black', color: '$black' }}
                onClick={() => {}}
                icon={<CiExport className={styles.icon} />}
              />
            </div>
          </div>
          {filterAgrupa === '3' ? (
            <Table
              columns={['Plano de Contas', '1 trimestre', '2 trimestre', '3 trimestre', '4 trimestre', 'Total']}
              lines={list.map(item => [
                <div>{item.chartofaccounts_name}</div>,
                <div>R${item.receita.agrupamento[0]}</div>,
                <div>R${item.receita.agrupamento[1]}</div>,
                <div>R${item.receita.agrupamento[2]}</div>,
                <div>R${item.receita.agrupamento[3]}</div>,
                <div className={styles.total}>R${item.receita.total_geral}</div>,
              ])}
            />
          ) : (
            ''
          )}
          {filterAgrupa === '6' ? (
            <Table
              columns={['Plano de Contas', '1 semestre', '2 semestre', 'Total']}
              lines={list.map(item => [
                <div>{item.chartofaccounts_name}</div>,
                <div>R${item.receita.agrupamento[0]}</div>,
                <div>R${item.receita.agrupamento[1]}</div>,
                <div className={styles.total}>R${item.receita.total_geral}</div>,
              ])}
            />
          ) : (
            ''
          )}
          <TableFooter>{botaoVoltar}</TableFooter>
        </>
      ) : (
        <div className={styles.no_clinic_selected}>
          <p>Selecione um consultório</p>
          <FaArrowUp />
        </div>
      )}
    </Container>
  );
}

export default DRE;
