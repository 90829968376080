export const getStorage = key => {
  return localStorage.getItem(key);
};
export const setStorage = (key, value) => {
  return localStorage.setItem(key, value);
};
export const removeStorage = key => {
  return localStorage.removeItem(key);
};

export const storageClinic = data => {
  if (data !== undefined) setStorage('clinic', data);
  return getStorage('clinic');
};
export const clearClinic = () => {
  return removeStorage('clinic');
};

export const storageUser = data => {
  if (data !== undefined) setStorage('user', data);
  return getStorage('user');
};
export const clearUser = () => {
  return removeStorage('user');
};

export const storageAccessToken = data => {
  if (data !== undefined) setStorage('access_token', data);
  return getStorage('access_token');
};
export const clearAccessToken = () => {
  return removeStorage('access_token');
};

export const storageRefreshToken = data => {
  if (data !== undefined) setStorage('refresh_token', data);
  return getStorage('refresh_token');
};
export const clearRefreshToken = () => {
  return removeStorage('refresh_token');
};

export const clinicalStorage = data => {
  if (data !== undefined) setStorage('clinical_list', data);
  return getStorage('clinical_list');
};
export const clearClinical = () => {
  return removeStorage('clinical_list');
};

export const navBarOpen = data => {
  if (data !== undefined) setStorage('navBarOpen', data);
  return getStorage('navBarOpen');
};
