import { useState, useEffect } from 'react';

import { RepositoryMaterialOrders } from 'data/materialorders';

export function useHandlers(modalState, orderState, catalogo, user, clinic, setLoading) {
  const [selectedInsumo, setSelectedInsumo] = useState({ id: '', amount: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [listInsumos, setListInsumos] = useState([]);
  const [statusModal, setStatusModal] = useState({
    open: false,
    error: '',
    success: '',
  });

  useEffect(() => {
    if (modalState.is_edit) {
      const catalogItems = catalogo
        .map(catalogItem => {
          let customObject;

          orderState.items.some(orderItem => {
            if (orderItem.item === catalogItem.id) {
              customObject = {
                is_edit: true,
                id: orderItem.id,
                catalog_id: catalogItem.id,
                name: catalogItem.name,
                amount: orderItem.quantity,
              };
              return true;
            }
            return false;
          });

          return customObject;
        })
        .filter(orderItem => orderItem !== undefined);

      if (catalogItems.length > 0) setListInsumos(catalogItems);
    }
    if (!modalState.open) {
      setListInsumos([]);
    }
  }, [modalState.open]);

  async function handleOrderRequest() {
    setLoading(true);
    if (modalState.is_edit) {
      try {
        await Promise.all(
          listInsumos
            .filter(item => item.is_edit == null)
            .map(async item => {
              await RepositoryMaterialOrders.postItem(orderState.order_id, {
                item: item.id,
                quantity: item.amount,
              });
            })
        );
        setLoading(false);
        window.location.reload();
      } catch (error) {
        setStatusModal(prevState => ({
          ...prevState,
          open: true,
          error: error.message,
        }));
        setLoading(false);
      }
    } else {
      try {
        const orderResponse = await RepositoryMaterialOrders.postOrder({ requester: user.id, clinic: clinic });

        if (orderResponse.error) {
          setStatusModal(prevState => ({
            ...prevState,
            open: true,
            error: orderResponse.error,
          }));
          setLoading(false);
        } else {
          const itemsResponse = await Promise.all(
            listInsumos.map(async item =>
              RepositoryMaterialOrders.postItem(orderResponse.id, { item: item.id, quantity: item.amount })
            )
          );

          const allItemsSuccessful = itemsResponse.every(item => !item.error);

          if (allItemsSuccessful) {
            setLoading(false);
            window.location.reload();
          } else {
            const firstError = itemsResponse.find(item => item.error);

            setStatusModal(prevState => ({
              ...prevState,
              open: true,
              error: firstError.error,
            }));
            setLoading(false);
          }
        }
      } catch (error) {
        setStatusModal(prevState => ({
          ...prevState,
          open: true,
          error: error.message,
        }));
        setLoading(false);
      }
    }
  }

  function handleAddItem() {
    const itemExists = listInsumos.find(item =>
      modalState.is_edit && item.is_edit ? item.catalog_id === selectedInsumo.id : item.id === selectedInsumo.id
    );
    const amountChecker = selectedInsumo.amount < 1 ? 1 : selectedInsumo.amount;

    catalogo.forEach(item => {
      if (item.id === selectedInsumo.id) {
        if (!itemExists) {
          // adiciona um item na lista caso não exista nenhum item com o mesmo id
          setListInsumos(prevList => [...prevList, ...[{ ...item, amount: amountChecker }]]);
          setSelectedInsumo({ id: '', amount: '' });
        } else {
          setErrorMessage('Este item já foi adicionado!');
          setTimeout(() => setErrorMessage(''), 2000);
          setSelectedInsumo({ id: '', amount: '' });
        }
      }
    });
  }

  async function handleDeleteItem(item) {
    try {
      await RepositoryMaterialOrders.deleteItem(orderState.order_id, item);
      setListInsumos(prevList => prevList.filter(prevItem => prevItem.id !== item));
    } catch (err) {
      setStatusModal(prevState => ({
        ...prevState,
        open: true,
        error: err.message,
      }));
    }
  }

  return {
    selectedInsumo,
    setSelectedInsumo,
    statusModal,
    setStatusModal,
    listInsumos,
    handleOrderRequest,
    handleAddItem,
    handleDeleteItem,
    errorMessage,
  };
}
