// hooks
import { useState, useContext } from 'react';
import { useCobrancasForm } from '../hooks/useCobrancasForm';
import { useParams } from 'react-router-dom';

// scss
import styles from './AdicionarCobranca.module.scss';

// contexts
import { LoaderContext } from 'contexts/loader.js';

// components
import Table from 'components/Table/Table.js';
import TableFooter from 'components/TableFooter/TableFooter';
import PageTitle from 'components/PageTitle/PageTitle';
import Container from 'components/Container/Container';
import Card from 'components/Card/Card';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import ModalAdicionarParcelas from '../modal/modal';
import { Grid } from '@mui/material';

// other
import { validatePaymentMethod } from '../hooks/validator';
import { MdOutlineDeleteOutline } from 'react-icons/md';

export default function AdicionarCobranca() {
  const [validationError, setValidationError] = useState('');
  const [form, setForm] = useState({
    types: [],
  });
  const [modalParcelaInfo, setModalParcelaInfo] = useState({
    open: false,
  });

  const { id } = useParams();
  const { setLoading } = useContext(LoaderContext);
  const { chartAccount, banks, submitForm, deleteParcel } = useCobrancasForm(setLoading, id, form, setForm);

  const handleChange = event => {
    setForm(prevState => ({ ...prevState, [event.target.name]: event.target.value }));
  };

  return (
    <Container className={styles.container}>
      <PageTitle>Formas de Cobrança</PageTitle>
      <Card className={styles.card} title={!!id ? "Editar Forma de Cobrança" : "Adicionar Forma de Cobrança"}>
        <div className={styles.formulario}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Input name="description" label="Forma de Cobrança" value={form.description} onChange={handleChange} />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                label="Plano de Contas"
                value={chartAccount
                  .map(account => ({ value: account.id, label: account.name }))
                  .find(account => account.value === form.chart_of_account)}
                options={chartAccount
                  .sort((a, b) => a.name - b.name)
                  .map(account => ({ value: account.id, label: account.name }))}
                onChange={(_, item) => {
                  setForm(prevState => ({ ...prevState, chart_of_account: item != null ? item.value : '' }));
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                label="Destino"
                value={banks
                  .map(bank => ({ value: bank.id, label: `${bank.banks_name} | Agência: ${bank.agency}` }))
                  .find(bank => bank.value === form.bank_account)}
                options={banks
                  .sort((a, b) => a.banks_name - b.banks_name)
                  .map(bank => ({ value: bank.id, label: `${bank.banks_name} | Agência: ${bank.agency}` }))}
                onChange={(_, item) => {
                  setForm(prevState => ({ ...prevState, bank_account: item != null ? item.value : '' }));
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                style={{ width: '100%' }}
                label="Adicionar parcela(s)"
                color='secondary'
                onClick={async () => {
                  try {
                    await validatePaymentMethod({
                      ...form,
                      bank_account: parseInt(form.bank_account),
                      chart_of_account: parseInt(form.chart_of_account),
                    });
                    setValidationError('');
                    setModalParcelaInfo({ open: true });
                  } catch (err) {
                    setValidationError('Todos os campos devem ser preenchidos');
                    console.error(err);
                    return;
                  }
                }}
              />
            </Grid>
          </Grid>
        </div>
        <span className={styles.validation_error}>{validationError}</span>
      </Card>
      <div className={styles.table}>
        <Table
          columns={['Tipo de Parcela', 'Dias para liberação', 'Taxa', 'Antecipação', '']}
          lines={
            form.types.length > 0
              ? form.types
                .sort((a, b) => a.parcels - b.parcels)
                .map((data, index) => {
                  return [
                    `${data.parcels}x`,
                    `${data.number_release_days} dia(s)`,
                    `${data.tax_per_month}%`,
                    data.is_anticipant ? 'Sim' : 'Não',
                    <div key={index}>
                      {' '}
                      <MdOutlineDeleteOutline
                        color="#A31912"
                        className={styles.pen}
                        title="Excluir"
                        onClick={async () => {
                          await deleteParcel(data.id);
                          const updatedTypes = form.types.filter((_, i) => i !== index);
                          setForm(prevState => ({ ...prevState, types: updatedTypes }));
                        }}
                      />
                    </div>,
                  ];
                })
              : [[<div>Não há informações para exibir</div>]]
          }
        />
      </div>
      <TableFooter>
        <ButtonVoltar link="/financeiro/formas-cobrancas" />
        <Button
          label="Salvar"
          color='secondary'
          disabled={form?.types?.length >= 1 ? false : true}
          onClick={() => {
            submitForm();
          }}
        />
      </TableFooter>
      <ModalAdicionarParcelas
        modalGetter={modalParcelaInfo}
        modalSetter={setModalParcelaInfo}
        form={form}
        setForm={setForm}
      />
    </Container>
  );
}
