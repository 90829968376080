import React, { useContext, useEffect, useState } from 'react';
import styles from './NotificationCount.module.scss';
import { AuthContext } from 'contexts/auth';
import { ClinicIdContext } from 'contexts/clinicId';

let _notificationCountClinicId = 0;
let _notificationTimeout;
function NotificationCount({ object }) {
  const { clinicId } = useContext(ClinicIdContext);
  const { user } = useContext(AuthContext);
  const [count, setCount] = useState(0);

  const loadCount = async () => {
    let newCount = 0;
    if (clinicId != null) {
      newCount = await object.notificationCounter({ clinic: _notificationCountClinicId, user: user.id });
    }
    setCount(newCount);
  }

  if (object.notificationCounter != null) {
    if (_notificationTimeout != null) {
      clearTimeout(_notificationTimeout);
    }
    _notificationTimeout = setTimeout(loadCount, 30000);
  }

  useEffect(() => {
    if (clinicId != null) {
      _notificationCountClinicId = clinicId;
      if (count > 0) {
        setCount(0);
      }
      if (object.notificationCounter != null) {
        loadCount();
      }
    }
  }, [clinicId]);

  if (count === 0) {
    return '';
  }

  return (
    <div className={styles.notificationCount}>{count}</div>
  );
}

export default NotificationCount;
