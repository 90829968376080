import Endpoints from '../config/endpoints';
import http from '../config/httpClient';
import { parseError } from './base';

export const RepositoryMaterialOrders = {
  baseUrl: Endpoints.material_order,

  getOrders: async function (clinic) {
    try {
      const { data } = await http.get(`${this.baseUrl}${`?clinic=${clinic}`}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  getOrder: async function (id) {
    try {
      const { data } = await http.get(`${this.baseUrl}${`/${id}`}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  postOrder: async function (payload) {
    try {
      const { data } = await http.post(`${this.baseUrl}`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  patchOrder: async function (id, payload) {
    try {
      const { data } = await http.patch(`${this.baseUrl}/${id}`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  deleteOrder: async function (id) {
    try {
      const { data } = await http.delete(`${this.baseUrl}/${id}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  getCatalog: async function () {
    // const queryString = clinic != null ? `?clinic=${clinic}` : '';
    try {
      const { data } = await http.get(`/v1/stock-items/`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  getItems: async function (id) {
    try {
      const { data } = await http.get(`${this.baseUrl}/${id}/items`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  postItem: async function (id, payload) {
    try {
      const { data } = await http.post(`${this.baseUrl}/${id}/items`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  patchItem: async function (orderId, itemId, payload) {
    try {
      const { data } = await http.patch(`${this.baseUrl}/${orderId}/items/${itemId}`, payload);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  deleteItem: async function (orderId, itemId) {
    try {
      const { data } = await http.delete(`${this.baseUrl}/${orderId}/items/${itemId}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
};
