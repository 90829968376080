// scss
import styles from './clinic.module.scss';

// components
import Checkbox from 'components/Checkbox/Checkbox';
import { Grid } from '@mui/material';

export default function AddUserClinic({ props }) {
  const { form, setForm, error, clinics } = props;

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Vincular Consultório</h2>
      <Grid>
        <Checkbox
          multiple
          allowMultipleSelected
          defaultValue={form.clinics}
          options={clinics.map(item => ({ label: item.name, value: item.id }))}
          children={clinics.map(item => ({
            value: item.id,
            element: (
              <div className={styles.checkbox_child}>
                <span>
                  {item.address}, {item.number} - {item.district}
                </span>
                <span>
                  {item.city}, {item.state}
                </span>
              </div>
            ),
          }))}
          onClick={selected => {
            setForm(prevState => ({
              ...prevState,
              clinics: selected,
            }));
          }}
        />
        {<span className={styles.error}>{error?.clinic?.clinics ?? ''}</span>}
      </Grid>
    </div>
  );
}
