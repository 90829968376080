import yup from 'shared/validator.js';

let transactionSchema = yup.object({
  recurrence: yup.string().required(),
  transaction_date: yup.string().required(),
  value: yup.number().max(1000000).required(),
  bank_account: yup.string().required(),
  description: yup.string().max(100).required(),
  chart_of_accounts: yup.string().required(),
  recurrence_quantity: yup.number().when('recurrence', {
    is: val => !val.includes('unique'),
    then: val => val.min(1).required(),
    otherwise: val => val.notRequired(),
  }),
  clinic: yup.number().required(),
});

export const validateTransactionList = async data => {
  return await transactionSchema.validate(data);
};
