import React, { useState, useEffect, useContext } from 'react';
import styles from './Colaboradores.module.scss';
import Table from 'components/Table/Table';
import Button from 'components/Button/Button';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import { UserRepository } from 'data/users';
import { UserRepository as AuthRepository } from 'data/auth';
import { ClinicsRepository } from 'data/clinics';
import TableFooter from 'components/TableFooter/TableFooter';
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import Modal from 'components/Modal/Modal';
import { LoaderContext } from 'contexts/loader';
import Enums from 'transformers/enums';
import { MdOutlineLockReset, MdLockOpen, MdLockOutline } from 'react-icons/md';
import { TbEdit } from 'react-icons/tb';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { useNavigate } from 'react-router-dom';

const initialUserFilter = {
  limit: 30,
  offset: 0,
};
const initialResetPasswordModal = {
  isOpen: false,
  success: false,
  loading: false,
  id: '',
};

function Colaboradores() {
  const { loading, setLoading } = useContext(LoaderContext);
  const [clinicsList, setClinicsList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userFilter, setUserFilter] = useState(initialUserFilter);
  const [resetPasswordModal, setResetPasswordModal] = useState(initialResetPasswordModal);

  const navigate = useNavigate();

  let hasNext = false;

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const responses = await Promise.all([
        ClinicsRepository.list({ limit: 9999 }),
        UserRepository.list(initialUserFilter),
      ]);
      const clinicsResponse = responses[0];
      const usersResponse = responses[1];
      if ((clinicsResponse?.results?.length ?? 0) > 0) {
        setClinicsList(clinicsResponse.results);
      }
      if ((usersResponse?.results?.length ?? 0) > 0) {
        setUserList(usersResponse.results);
      }
      setLoading(false);
    };
    run();
  }, [setLoading]);

  useEffect(() => {
    const run = async () => {
      if (userFilter !== initialUserFilter && !loading) {
        setLoading(true);
        const usersResponse = await UserRepository.list(userFilter);
        if (usersResponse.next) {
          hasNext = true;
        }
        if ((usersResponse?.results?.length ?? 0) > 0) {
          setUserList([...userList, ...usersResponse.results]);
        }
        setLoading(false);
      }
    };
    run();
  }, [userFilter]);

  const resetPassword = async id => {
    setResetPasswordModal({
      ...resetPasswordModal,
      loading: true,
    });
    const response = await AuthRepository.resetPassword(id);
    setResetPasswordModal({
      ...resetPasswordModal,
      success: response === 'Ok',
      loading: false,
    });
  };

  const changeUserIsActive = async user => {
    setLoading(true);
    const newIsActive = !user.is_active;
    const response = await UserRepository.update(user.id, { is_active: newIsActive });
    if (response != null) {
      user.is_active = newIsActive;
    }
    setLoading(false);
    return response != null;
  };

  return (
    <Container className={styles.colaboradoresPage}>
      <PageTitle>Colaboradores</PageTitle>
      <Table
        columns={['Colaborador', 'Consultório', 'Perfil', 'Ações']}
        lines={userList.map(user => {
          const clinicsNames = [];
          (user.clinics ?? []).forEach(clinicId => {
            const clinic = clinicsList.find(clinic => clinicId === clinic.id);
            if (clinic?.name) {
              clinicsNames.push(clinic.name);
            }
          });

          const userTypesNames = [];
          (user.user_types ?? []).forEach(userTypeId => {
            if (userTypeId === 7 && user.services && user.services.name) {
              userTypesNames.push(user.services.name);
            } else {
              const userTypeName = Enums.profileIdToLabel[userTypeId];
              if (userTypeName) {
                userTypesNames.push(userTypeName);
              }

            }
          });

          const actions = (
            <div className={styles.tableActions}>
              {user.is_active ? (
                <MdLockOpen onClick={() => changeUserIsActive(user)} title="Bloquear acesso" />
              ) : (
                <MdLockOutline onClick={() => changeUserIsActive(user)} title="Desbloquear acesso" />
              )}
              <TbEdit
                title="Editar Usuário"
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/admin/colaboradores/${user.id}/editar`)}
              />
              <MdOutlineLockReset
                title="Resetar Senha"
                onClick={() =>
                  setResetPasswordModal({
                    isOpen: true,
                    id: user.id,
                  })
                }
              />
            </div>
          );

          const userAvatarName = (
            <div className={styles.tdUser}>
              <UserAvatar imageUrl={user.photo ?? ''} />
              <span>{user.first_name}</span>
            </div>
          );

          return [
            userAvatarName,
            clinicsNames.length === 0 ? '-' : clinicsNames.join(', '),
            userTypesNames.length === 0 ? '-' : userTypesNames.join(', '),
            actions,
          ];
        })}
        onScrollEnd={() => {
          if (hasNext) {
            setUserFilter({
              ...userFilter,
              offset: userFilter.offset + userFilter.limit,
            });
          }
        }}
      />
      <TableFooter>
        <ButtonVoltar link="/admin/home" />
        <Button label="Adicionar Colaborador" link="/admin/colaboradores/novo" color="secondary" addLink={true} />
      </TableFooter>
      <Modal
        isOpen={resetPasswordModal.isOpen}
        setModalOpen={() => setResetPasswordModal(initialResetPasswordModal)}
        className={styles.modal_container}
      >
        {resetPasswordModal.success ? (
          <div className={styles.modal_inner_container}>
            <h1>Successo!</h1>
            <span>Uma nova senha foi enviada para o e-mail cadastrado.</span>
          </div>
        ) : (
          <div className={styles.modal_inner_container}>
            <h1>Redefinir Senha</h1>
            <span>Enviar nova senha para o e-mail cadastrado?</span>
            <Button
              label="Confirmar"
              disabled={resetPasswordModal.loading}
              onClick={() => resetPassword(resetPasswordModal.id)}
            />
          </div>
        )}
      </Modal>
    </Container>
  );
}

export default Colaboradores;
