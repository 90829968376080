import React, { useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './InputDatePickerRange.module.scss';
import { TextField } from '@mui/material';
import ptBR from 'date-fns/locale/pt-BR';

// other
import moment from 'moment';

registerLocale('br', ptBR);

const InputComponent = React.forwardRef((props, ref) => {
  const { component: Component, customRef, ...other } = props;
  return <input {...other} ref={customRef ?? ref} />;
});

const DatepickerComponent = React.forwardRef((props, ref) => {
  const { component: Component, ...other } = props;
  return <DatePicker {...other} locale="br" customInput={<InputComponent customRef={ref} />} />;
});

function InputDatePickerRange({
  name = '',
  label = '',
  placeholder = '',
  id = '',
  value = '',
  onChange = e => {},
  onFocus = () => {},
  onBlur = () => {},
  style,
  className,
  error,
  helper = '',
  disabled = false,
}) {
  const [dateRange, setDateRange] = useState([value[0] ?? null, value[1] ?? null]);
  const [startDate, endDate] = dateRange;

  const inputRef = useRef();
  const handleCalendarClose = () => {
    if (inputRef.current) inputRef.current.focus();
  };
  const onChangeRaw = event => {};

  useEffect(() => {
    let value0 = value[0];
    let value1 = value[1];
    if (typeof value0 === 'string') {
      value0 = moment(value0).toDate();
    }
    if (typeof value1 === 'string') {
      value1 = moment(value1).toDate();
    }
    setDateRange([value0, value1]);
  }, [value]);

  return (
    <div className={styles.container} style={style}>
      <TextField
        disabled={disabled}
        placeholder={placeholder}
        fullWidth={true}
        label={label}
        inputRef={inputRef}
        InputProps={{
          inputComponent: DatepickerComponent,
          inputProps: {
            onKeyDown: e => {
              e.preventDefault();
            },
            dateFormat: 'dd/MM/yyyy',
            startDate: startDate,
            endDate: endDate,
            selectsRange: true,
            onChange: update => {
              setDateRange(update);
              onChange(update);
            },
            onFocus: onFocus,
            onBlur: onBlur,
            className: styles.input,
            wrapperClassName: styles.datePickerWrapper,
            fullWidth: true,
            name: name,
            disabled: disabled,
            placeholder: placeholder,
            onCalendarClose: handleCalendarClose,
            onChangeRaw: onChangeRaw,
          },
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        name={name}
        id={id}
        InputLabelProps={{ shrink: !!startDate || !!endDate }}
        className={[styles.input, className].join(' ')}
        error={!!error}
        helperText={[true, false].includes(error) ? helper : error ?? helper}
      />
    </div>
  );
}

export default InputDatePickerRange;
