// scss
import styles from './VerSolicitacao.module.scss';

// components
import { Estoque, ProfileDefault } from './Profiles/Profiles';

export default function VerSolicitacao({ props }) {
  const { setTabInfo, orderState, requestApprover, nav } = props;

  return (
    <div className={styles.main_container}>
      {nav === 'stock' ? (
        <Estoque props={{ setTabInfo, orderState, requestApprover }} />
      ) : (
        <ProfileDefault props={{ setTabInfo, orderState }} />
      )}
    </div>
  );
}
