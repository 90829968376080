import React, { useContext } from 'react';
import styles from './Endereco.module.scss';
import Input from 'components/Input/Input';
// import Select from 'components/Select/Select';
// import { validateClinicData } from '../validator';
import { Grid } from '@mui/material';
import { FormClinicContext } from 'contexts/formClinic';
import Card from 'components/Card/Card';

function Endereco({ error }) {
  const { dados, setDados } = useContext(FormClinicContext);

  const handleChange = event => {
    setDados({ ...dados, address: { ...dados.address, [event.target.name]: event.target.value } });
  };

  return (
    <div className={styles.container}>
      <Card title="Endereço" className={styles.card}>
        <Grid container spacing={2} mt={0.5}>
          <Grid item xs={4}>
            <Input
              label="CEP"
              name="cep"
              mask="99999-999"
              value={dados.address.cep}
              onChange={handleChange}
              error={error?.cep}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              label="Estado"
              name="state"
              value={dados.address.state}
              onChange={handleChange}
              error={error?.state}
            />
          </Grid>
          <Grid item xs={4}>
            <Input label="Cidade" name="city" value={dados.address.city} onChange={handleChange} error={error?.city} />
          </Grid>
          <Grid item xs={5}>
            <Input
              label="Bairro"
              name="district"
              value={dados.address.district}
              onChange={handleChange}
              error={error?.district}
            />
          </Grid>
          <Grid item xs={7}>
            <Input
              label="Endereço"
              name="address"
              value={dados.address.address}
              onChange={handleChange}
              error={error?.address}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              label="Nº"
              lower={true}
              name="number"
              value={dados.address.number}
              onChange={handleChange}
              error={error?.number}
            />
          </Grid>
          <Grid item xs={9}>
            <Input
              label="Complemento"
              name="complement"
              value={dados.address.complement}
              onChange={handleChange}
              error={error?.complement}
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default Endereco;
