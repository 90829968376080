import styles from './Popover.module.scss';
import Popover from '@mui/material/Popover';

export default function BasicPopover({ open, onClose, anchorEl, children }) {
  return (
    <div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={styles.container}>{children}</div>
      </Popover>
    </div>
  );
}
