import React, { useEffect, useState, useContext } from 'react';
import styles from './Fornecedores.module.scss';
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import Table from 'components/Table/Table';
import { Link } from 'react-router-dom';
import { TbEdit } from 'react-icons/tb';
import TableFooter from 'components/TableFooter/TableFooter';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import Button from 'components/Button/Button';
import { LoaderContext } from 'contexts/loader';
import { FaEye } from 'react-icons/fa';
import { SupplierRepository } from 'data/supplier';
import { cnpjMask, formatPhoneNumber } from 'shared/utility';
import Modal from 'components/Modal/Modal.js';
import confirmedIcon from 'assets/ico/confirmedIcon.svg';
import errorIcon from 'assets/ico/error_icon.svg';
import { MdOutlineDeleteOutline } from 'react-icons/md';

const initialModalState = {
  isOpen: false,
  success: false,
  pending: false,
  error: false,
  msg: '',
  id: '',
};

function Fornecedores() {
  const { setLoading } = useContext(LoaderContext);
  const [list, setList] = useState([]);
  const [modalState, setModalState] = useState(initialModalState);

  useEffect(() => {
    loadFornecedor();
  }, []);

  const loadFornecedor = async () => {
    setLoading(true);
    const response = await SupplierRepository.list();
    setList(response.results);
    setLoading(false);
  };

  async function deleteSupplier(id) {
    const response = await SupplierRepository.delete(id);
    if (response.error) {
      if (response.error === 'Y') {
        setModalState({
          isOpen: true,
          error: true,
          msg: 'Você não pode excluir um Fornecedor com um pedido de compra vinculado.',
        });
      } else {
        setModalState({
          isOpen: true,
          error: true,
          msg: 'Erro ao excluir o fornecedor. Por favor, tente novamente mais tarde.',
        });
      }
    } else {
      const newList = list.filter(item => item.id !== id);
      setList(newList);
      loadFornecedor();
      setModalState({
        isOpen: true,
        success: true,
      });
    }
  }

  const handleDelete = id => {
    deleteSupplier(id);
  };

  const editButton = id => (
    <div className={styles.editButton}>
      <Link to={`/estoque/fornecedores/${id}/editar`}>
        <TbEdit className={styles.pen} color="#C5A500" title="Editar Fornecedor" />
      </Link>
      <Link to={`/estoque/fornecedores/${id}/visualizar`}>
        <FaEye className={styles.pen} color="#15ab72" title="Visualizar Fornecedor" />
      </Link>
      <MdOutlineDeleteOutline
        className={styles.trash}
        color="#A31912"
        title="Excluir Fornecedor"
        onClick={() => setModalState({ isOpen: true, id: id, pending: true })}
      />
    </div>
  );

  const botaoVoltar = <ButtonVoltar link="/estoque/home" />;

  const onClose = () => {
    setModalState(initialModalState);
  };

  return (
    <Container className={styles.container}>
      <div className={styles.header}>
        <PageTitle>Lista de Fornecedores</PageTitle>
        <Button label="Adicionar Fornecedor" link="/estoque/fornecedores/novo" color="secondary" />
      </div>
      <div className={styles.table}>
        <Table
          columns={['CNPJ', 'Razão Social', 'Nome contato', 'Telefone', 'E-mail', 'Ações']}
          lines={list.map(item => {
            let name = '';
            let phone_number = '';
            let email = '';
            if (item.supplier_contacts.length > 0) {
              name = item.supplier_contacts[0].name;
              phone_number = item.supplier_contacts[0].phone_number;
              email = item.supplier_contacts[0].email;
            }
            return [
              cnpjMask(item.cnpj),
              item.company_name,
              name,
              formatPhoneNumber(phone_number),
              email,
              editButton(item.id),
            ];
          })}
        />
      </div>
      <TableFooter>{botaoVoltar}</TableFooter>
      <Modal isOpen={modalState.isOpen && modalState.pending} setModalOpen={onClose} className={styles.modal_container}>
        {modalState.pending ? (
          <div className={styles.modal_inner_container}>
            <p>Tem certeza que deseja excluir este fornecedor?</p>
            <Button color="secondary" label="Confirmar" onClick={() => handleDelete(modalState.id)} />
          </div>
        ) : (
          <></>
        )}
      </Modal>
      <Modal isOpen={modalState.isOpen && modalState.success} setModalOpen={onClose} className={styles.modal_container}>
        {modalState.success ? (
          <div className={styles.modal_inner_container}>
            <img src={confirmedIcon} alt=""></img>
            <span>O Fornecedor foi excluido</span>
          </div>
        ) : (
          <></>
        )}
      </Modal>
      <Modal isOpen={modalState.isOpen && modalState.error} setModalOpen={onClose} className={styles.modal_container}>
        {modalState.error ? (
          <div className={styles.modal_inner_container}>
            <img src={errorIcon} alt=""></img>
            <span>{modalState.msg}</span>
          </div>
        ) : (
          <></>
        )}
      </Modal>
    </Container>
  );
}

export default Fornecedores;
