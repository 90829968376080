export default class Endpoints {
  static auth = '/v1/token/';

  static refreshToken = '/v1/token/refresh/';

  static recoveryPassword = '/v1/recovery-password/';

  static resetPassword = '/v1/reset-password';

  static users = '/v1/users';

  static userHours = user => `/v1/users/${user}/hours`;

  static clinics = '/v1/clinics';

  static adminclinics = '/v1/admin-clinics';

  static medicines = '/v1/medicines';

  static restrictions = '/v1/restrictions';

  static protocols = '/v1/protocols';

  static protocolsProcedures = protocol => `/v1/protocols/${protocol}/procedures`;

  static bankaccounts = '/v1/bankaccounts';

  static banks = '/v1/banks';

  static banktransaction = '/v1/banktransaction';

  static specialties = '/v1/specialties';

  static patients = '/v1/patients';

  static schedules = '/v1/schedules';

  static schedulesPayments = '/v1/schedules-payments';

  static folder = '/v1/folder';

  static chartofaccounts = '/v1/chartofaccounts';

  static dre = '/v1/dre';

  static firstaccess = token => `/v1/first_access/${token}`;

  static check_registration = '/v1/check-registration';

  static stock_items = '/v1/stock-items';

  static purchase_order = '/v1/purchase-order';

  static material_order = '/v1/orders';

  static supplier = '/v1/supplier';

  static paymentmethod = '/v1/payment-method';

  static stock = '/v1/stock';

  static cashier = '/v1/cashier';

  static commission = '/v1/commission';

  static servicenames = '/v1/service-names';

  static templates = '/v1/templates';

  static diseases = '/v1/diseases';

  static procedures_concierge = '/v1/procedures-concierge';

  static patients_protocols = '/v1/patients-protocols';

  static payment_schedules = '/v1/payment-schedules';
}
