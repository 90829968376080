import Endpoints from '../config/endpoints';
import http from '../config/httpClient';
import { parseError } from './base';

export const UserRepository = {
  loginUrl: Endpoints.auth,
  logoutUrl: Endpoints.logout,
  resetPasswordUrl: Endpoints.resetPassword,
  usersUrl: Endpoints.users,

  login: async function ({ email, password }) {
    try {
      const { data } = await http.post(`${this.loginUrl}`, {
        email,
        password,
      });

      return data;
    } catch (err) {
      return parseError(err);
    }
  },
  
  get: async function (userId) {
    try {
      const { data } = await http.get(`${this.usersUrl}/${userId}`);
      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  logout: async function () {
    try {
      const { data } = await http.post(`${this.logoutUrl}`);

      return data;
    } catch (err) {
      return parseError(err);
    }
  },

  resetPassword: async function (id) {
    try {
      const { data } = await http.post(`${this.resetPasswordUrl}`, { user: id });
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
};
