import moment from 'moment';

let timerId;
export const debounce = (fn, delay = 700) => {
  return function (...args) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

export const formatDate = date => {
  if (date == null || date === '') {
    return '';
  }
  return moment(date).format('DD/MM/YYYY');
};

export const getCurrentDate = () => {
  const d = new Date();
  return `${d.getDate()}/${d.getMonth()}/${d.getFullYear()}`;
};

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== '' && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};

export const stringifyFilters = filterObj => {
  let queryString = '';

  if (filterObj) {
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in filterObj) {
      if (
        (Array.isArray(filterObj[prop]) && filterObj[prop].length !== 0) ||
        (!Array.isArray(filterObj[prop]) &&
          typeof filterObj[prop] !== 'undefined' &&
          filterObj[prop] !== null &&
          filterObj[prop] !== '')
      ) {
        queryString += queryString ? '&' : '?';
        queryString += `${prop}=${Array.isArray(filterObj[prop]) ? filterObj[prop].join(',') : filterObj[prop]}`;
      }
    }
  }

  return queryString;
};

export const updatedStringifyFilters = filterObj => {
  let queryString = '';

  if (filterObj) {
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in filterObj) {
      if (
        (Array.isArray(filterObj[prop]) && filterObj[prop].length !== 0) ||
        (!Array.isArray(filterObj[prop]) &&
          typeof filterObj[prop] !== 'undefined' &&
          filterObj[prop] !== null &&
          filterObj[prop] !== '')
      ) {
        queryString += queryString ? '&' : '&';
        queryString += `${prop}=${Array.isArray(filterObj[prop]) ? filterObj[prop].join(',') : filterObj[prop]}`;
      }
    }
  }

  return queryString;
};

export const createAttribute = (name, obj) => {
  const value = obj;
  // eslint-disable-next-line no-prototype-builtins
  if (!value.hasOwnProperty(name)) {
    value[name] = {};
  }

  return value;
};

export const getCookie = key => {
  const b = document.cookie.match(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : '';
};

export const readBase64FromFile = file => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.addEventListener('load', function (e) {
      resolve(e.target.result);
    });
    reader.readAsDataURL(file);
  });
};

export const handleSelectOneFile = (accept, onSuccess) => {
  const imageInput = document.createElement('input');
  imageInput.type = 'file';
  imageInput.multiple = false;
  imageInput.accept = accept;
  imageInput.addEventListener('change', async e => {
    const photo = e.target.files[0];
    const photo64 = await readBase64FromFile(photo);
    onSuccess(photo, photo64);
  });
  imageInput.click();
};

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const cnpjMask = value => {
  return value
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})(\d)/, '$1')
    .slice(0, 19);
};

export const cpfMask = value => {
  return value
    .replace(/\D+/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2')
    .slice(0, 14);
};

export const formatPhoneNumber = phoneNumber => {
  const numbers = phoneNumber.replace(/\D+/g, '');
  if (numbers.length === 11) {
    return numbers.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
  } else if (numbers.length > 11) {
    return numbers.replace(/(\d{1,})(\d{2})(\d{5})(\d{4})/, '+$1 ($2) $3-$4')
  }
  return numbers;
};

export const formatCurrency = number => {
  if (number == null || isNaN(number) || number === '') {
    return '-';
  }

  const [integerPart, decimalPart] = number.toFixed(2).split('.');

  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  const formattedCurrency = `R$ ${formattedIntegerPart},${decimalPart}`;

  return formattedCurrency;
};

export const weekdayDescription = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'];
export const getWeekdayFromDate = date => {
  const weekday = date.getDay();
  return weekdayDescription[weekday];
}