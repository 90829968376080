import { useState } from 'react';

const defaultFilters = {
  user: '',
  patient: '',
  procedure: '',
};

export default function useFilters() {
  const [filters, setFilters] = useState(defaultFilters);

  // limpa todos os filtros, fazendo com que todos os dados sejam retornados
  function clearFilters() {
    setFilters({ ...defaultFilters });
  }

  return {
    filters,
    setFilters,
    clearFilters,
  };
}
