import React, { useEffect, useState, useContext } from 'react';
import styles from './PlanoContasForm.module.scss';
import PageTitle from 'components/PageTitle/PageTitle';
import Container from 'components/Container/Container';
import TableFooter from 'components/TableFooter/TableFooter';
import Button from 'components/Button/Button.js';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import Select from 'components/Select/Select';
import Input from 'components/Input/Input';
import { Checkbox, Grid } from '@mui/material';
import Card from 'components/Card/Card';
import { LoaderContext } from 'contexts/loader';
import { ChartOfAccountsRepository } from 'data/chartofaccounts';
import { apiBadRequestError } from 'exceptions/api';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from 'hooks/useToast';
import { ClinicIdContext } from 'contexts/clinicId';
import { validatePlanAccountList } from './validator';
import { transformApiToPlanoContas } from 'transformers/userForm';

const typeSelect = [
  { id: '1', value: 'income', name: 'Receita' },
  { id: '2', value: 'expense', name: 'Despesa' },
  { id: '2', value: 'passive', name: 'Passivo' },
  { id: '2', value: 'active', name: 'Ativo' },
];

const form = { clinic: '', type: null, parent: null, name: '', pastOrFutureLaunch: false };

function PlanoContasForm() {
  let navigate = useNavigate();
  let { id } = useParams();
  const { clinicId } = useContext(ClinicIdContext);
  const { setLoading } = useContext(LoaderContext);
  const [formData, setFormData] = useState({ ...form });
  const [chartsList, setChartsList] = useState([]);
  const toast = useToast();
  const [chartData, setChartData] = useState();
  const [optionsSelect, setOptionsSelect] = useState([]);
  const [type, setType] = useState('');

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const response = await ChartOfAccountsRepository.list({ type: type, clinic: clinicId });
      setLoading(false);
      setChartsList(response.results);
    };
    if (type !== '' && clinicId != null) {
      run();
    };
  }, [type, clinicId]);

  useEffect(() => {
    const run = async () => {
      const listAllowed = chartData?.allowed_parents.map(item => item);
      const listChart = chartsList.map(item => ({ value: item.id, name: item.name }));
      let list = [];

      listChart.forEach(item => {
        if (listAllowed.includes(item?.value)) {
          list.push(item);
        }
      });

      if (id) {
        setOptionsSelect(list);
      }
    };
    if (chartsList && chartData) {
      run();
    }
  }, [chartsList, chartData]);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const response = await ChartOfAccountsRepository.get(id);
      setLoading(false);
      if (response?.error) {
        toast.error(response.error);
        setFormData({});
      } else {
        setFormData(transformApiToPlanoContas(response));
        setType(response.type);
        setChartData(response);
      }
    };
    if (id) {
      run();
    }
  }, []);

  const handleSubmit = async event => {
    try {
      await validatePlanAccountList(formData);
      await saveData();
      setFormData([]);
    } catch (err) {
      toast.error(err.errors?.[0] || err.message);
    }
  };

  const saveData = async () => {
    setLoading(true);
    try {
      const response = id
        ? await ChartOfAccountsRepository.update(id, formData)
        : await ChartOfAccountsRepository.create(formData);
      if (response?.error) {
        throw new apiBadRequestError(response.error, response.debug);
      }
      navigate('/financeiro/plano-contas');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = event => {
    let value = event.target.value;
    if (['pastOrFutureLaunch'].includes(event.target.name)) {
      value = event.target.checked;
    }
    if (['type'].includes(event.target.name)) {
      setType(value);
    }
    setFormData({ ...formData, [event.target.name]: value, clinic: parseInt(clinicId) });
  };

  const botaoVoltar = <ButtonVoltar link="/financeiro/plano-contas" />;
  const botaoAdd = <Button label="Salvar" color="secondary" onClick={handleSubmit} size="large" />;

  return (
    <Container className={styles.container}>
      <PageTitle>Adicionar Plano de Contas</PageTitle>
      <Card className={styles.card} title="Dados Bancarios">
        <div className={styles.form}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Select
                name="type"
                label="Categoria"
                value={formData.type}
                options={typeSelect}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                name="parent"
                label="Item Pai"
                value={formData.parent}
                options={
                  formData.type === null
                    ? [{ name: 'Selecione uma categoria', value: '' }]
                    : id
                    ? optionsSelect.map(options => ({ value: options.value, name: options.name }))
                    : chartsList.map(chart => ({ value: chart.id, name: chart.name }))
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Input name="name" label="Nome" value={formData.name} onChange={handleChange} />
            </Grid>
          </Grid>
        </div>
      </Card>
      <div className={styles.checkboxes}>
        <span className={styles.checkbox}>
          <Checkbox
            name="pastOrFutureLaunch"
            color="secondary"
            checked={formData.pastOrFutureLaunch}
            onChange={handleChange}
          />
          Lançamento passado ou futuro
        </span>
        {/* <span className={styles.checkbox}>
          <Checkbox name="pastOrFutureLaunch" value={formData.pastOrFutureLaunch} onChange={handleChange} />
          Lançamento passado ou futuro
        </span> */}
      </div>
      <TableFooter>
        {botaoVoltar}
        {botaoAdd}
      </TableFooter>
    </Container>
  );
}

export default PlanoContasForm;
