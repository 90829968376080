import { useState, createContext } from 'react';

export const FormClinicContext = createContext([]);

export const FormClinicProvider = ({ children }) => {
  const [dados, setDados] = useState({
    clinicData: {
      cnpj: '',
      name: '',
      email: '',
      phone_number: '',
    },
    address: {
      state: '',
      city: '',
      district: '',
      address: '',
      cep: '',
      complement: '',
      number: '',
    },
    times: {
      opening: '',
      closure: '',
    },
  });

  return <FormClinicContext.Provider value={{ dados, setDados }}>{children}</FormClinicContext.Provider>;
};
