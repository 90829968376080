import Button from 'components/Button/Button';
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import styles from './ListaDeEspera.module.scss';
import Table from 'components/Table/Table';
import { LoaderContext } from 'contexts/loader';
import { SchedulesRepository } from 'data/schedules';
import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import TableFooter from 'components/TableFooter/TableFooter';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import { ClinicIdContext } from 'contexts/clinicId';
import { AuthContext } from 'contexts/auth';

function ListaDeEspera() {
  const { clinicId } = useContext(ClinicIdContext);
  const { user } = useContext(AuthContext);
  const { setLoading } = useContext(LoaderContext);
  const [list, setList] = useState([]);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const response = await SchedulesRepository.list({ status: 'waiting', clinic: clinicId, user: user.id });
      setLoading(false);
      setList(response.results);
    };
    if (clinicId != null) {
      run();
    }
  }, [clinicId]);

  const editButton1 = (patient_id, schedule_id) => (
    <Button label="Prontuario" link={`/atendimento/prontuario/${patient_id}/consulta/${schedule_id}`} />
  );

  return (
    <Container>
      <div className={styles.title}>
        <PageTitle>Lista De Espera</PageTitle>
        {/* <div className={styles.countItems}>{list?.length}</div> */}
      </div>
      <div>
        <Table
          columns={['Paciente', 'Horário marcado', 'Horário chegada', '']}
          lines={list?.map(item => [
            item.patient_name,
            moment(item.schedule_date).format('DD/MM/YY HH:mm'),
            moment(item.arrival_time).format('DD/MM/YY HH:mm'),
            editButton1(item.patient, item.id),
          ])}
        />
        <TableFooter>
          <ButtonVoltar link="/atendimento/home" />
        </TableFooter>
      </div>
    </Container>
  );
}

export default ListaDeEspera;
