import DefaultLayout from 'App/layouts/DefaultLayout/DefaultLayout';
import Authenticated from 'middleware/authenticated';
import Home from 'pages/shared/Home/Home';

export const routes = [
  {
    key: '/home',
    path: '/home',
    element: (
      <Authenticated>
        <DefaultLayout tab="/home">
          <Home />
        </DefaultLayout>
      </Authenticated>
    ),
  },
];
