import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@mui/material';
import Button from 'components/Button/Button';
import Container from 'components/Container/Container';
import Input from 'components/InputIcon/InputIcon';
import Modal from 'components/Modal/Modal';
import PageTitle from 'components/PageTitle/PageTitle';
import { ReactComponent as Calendar } from 'assets/ico/calendar.svg';
import { LoaderContext } from 'contexts/loader';
import { useToast } from 'hooks/useToast';
import { UserRepository } from 'data/users';
// import { SpecialtiesRepository } from 'data/specialties';
import Select from 'components/Select/Select';
import styles from './AgendaSemanal.module.scss';
import { GrSearch } from 'react-icons/gr';
import { WiTime2 } from 'react-icons/wi';

export default function AgendaSemanal() {
  const toast = useToast();
  const [openModal, setOpenModal] = useState(false);
  const { setLoading } = useContext(LoaderContext);
  const [doctorList, setDoctorList] = useState([]);
  const [specialtiesList, setSpecialtiesList] = useState([]);
  const [doctor, setDoctor] = useState('');
  const [specialty, setSpecialty] = useState('');

  const handleDoctorSelect = param => {
    setDoctor(param);
    // specialtiesRecord(doctor.id);
  };

  const handleSpecialtySelect = param => {
    setSpecialty(param);
  };

  useEffect(() => {
    const run = async () => {
      const response = await UserRepository.list();
      if (response?.error) {
      } else {
        setDoctorList(response.results);
      }
    };
    run();
  }, []);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const response = await UserRepository.listSpecialties(doctor.id);
      setLoading(false);
      if (response?.error) {
        toast.error(response.error);
      } else {
        setSpecialtiesList(response.results);
      }
    };
    run();
  }, [doctor]);

  return (
    <Container>
      <PageTitle>Agenda da Semana</PageTitle>
      <Grid container>
        <Grid item mt={5}>
          <Button
            label="Novo Paciente"
            size="medium"
            color="secondary"
            onClick={() => {
              setOpenModal(true);
            }}
          />
        </Grid>
      </Grid>
      <Modal
        isOpen={openModal}
        setModalOpen={() => {
          setOpenModal(!openModal);
        }}
      >
        <h1>Criar Agendamento</h1>
        <Grid container className={styles.gridContainer} display="inline-block">
          <Grid item mt={2} ml={2}>
            <div className={styles.modal}>
              <Input placeholder="Nome do paciente" icon={<GrSearch />} iconPosition="right" />
              <Button label="Novo" className={styles.button} color="secondary" />
            </div>
          </Grid>
          <Grid item mt={2} ml={2}>
            <Select
              label="Nome do Médico"
              options={doctorList
                .map(user => (user.user_types.includes('doctor') ? { value: user.id, name: user.first_name } : ''))
                .filter(item => item)}
              value={doctor.id}
              onChange={e => handleDoctorSelect(e.target.value)}
            />
          </Grid>
          <Grid item mt={2} ml={2}>
            <Select
              label="Especialidade do Médico"
              options={specialtiesList.map(item => ({ value: item.id, name: item.id }))}
              value={specialty.id}
              onChange={e => handleSpecialtySelect(e.target.value)}
            />
          </Grid>
          <Grid item mt={2} ml={2}>
            <Input placeholder="Data do agendamento" icon={<Calendar />} iconPosition="right" />
          </Grid>
          <Grid item mt={2} ml={2}>
            <Input placeholder="Horario do agendamento" icon={<WiTime2 />} iconPosition="right" />
          </Grid>
          <Grid item mt={2} ml={2}>
            <div className={styles.confirmButton}>
              <Button label="Confirmar Agendamento" color="secondary" />
            </div>
          </Grid>
        </Grid>
      </Modal>
    </Container>
  );
}
