import React from 'react';
import styles from './Button.module.scss';
import { Link } from 'react-router-dom';
import { Button as MuiButton } from '@mui/material';

const variantsHelper = {
  default: 'contained',
  contained: 'contained',
  contain: 'contained',
  fill: 'contained',
  normal: 'contained',
  regular: 'contained',
  outlined: 'outlined',
  outline: 'outlined',
  line: 'outlined',
  border: 'outlined',
  light: 'outlined',
  text: 'text',
  clean: 'text',
  empty: 'text',
  link: 'text',
  normalize: 'text',
  normalized: 'text',
  unstyle: 'text',
  unstyled: 'text',
};

function Button({
  className = '',
  label = '',
  type = 'button',
  color = 'primary', // primary, secondary, light, dark
  variant = 'contained', // text, outlined, contained
  icon = null,
  style = {},
  link = null,
  size = 'medium',
  onClick = () => {},
  disabled,
  ...props
}) {
  const buttonContent = (
    <MuiButton
      type={type}
      color={color}
      size={size}
      className={styles.container + ' ' + className}
      style={style}
      variant={variantsHelper[variant]}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {icon}
      {label}
    </MuiButton>
  );

  return link ? <Link to={link}>{buttonContent}</Link> : buttonContent;
}

export default Button;
