import Endpoints from '../config/endpoints';
import http from '../config/httpClient';
import { parseError } from './base';

export const FirstAccessRepository = {
  baseUrl: Endpoints.firstaccess,

  create: async function (token, payload) {
    try {
      const { data } = await http.post(`${this.baseUrl(token)}`, { ...payload });
      return data;
    } catch (err) {
      return parseError(err);
    }
  },
};
