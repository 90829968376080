// hooks
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// repositories
import { ChartOfAccountsRepository } from 'data/chartofaccounts';
import { BankAccountsRepository } from 'data/bankaccounts';
import { PaymentMethodRepository } from 'data/paymentmethod.js';
import { ClinicIdContext } from 'contexts/clinicId';

// other
import { apiBadRequestError } from 'exceptions/api';

export function useCobrancasForm(setLoading, id, form, setForm) {
  const { clinicId } = useContext(ClinicIdContext);
  const [chartAccount, setChartAccount] = useState([]);
  const [banks, setBanks] = useState([]);

  const navigate = useNavigate();

  const fetchData = async () => {
    setLoading(true);

    const chartsResponse = await ChartOfAccountsRepository.list({ type: 'income', clinic: clinicId });
    const bankAccountsResponse = await BankAccountsRepository.list({ clinic: clinicId });

    if (id) {
      const paymentmethodResponse = await PaymentMethodRepository.get(id);
      const typesResponse = await PaymentMethodRepository.getTypes(id);

      if (!paymentmethodResponse.error) {
        setForm(paymentmethodResponse);
      }
      if (!typesResponse.error) {
        setForm(prevState => ({ ...prevState, types: typesResponse.results }));
      }
    }

    if (chartsResponse && chartsResponse.results) {
      setChartAccount(chartsResponse.results);
      setLoading(false);
    }

    if (bankAccountsResponse && bankAccountsResponse.results) {
      setBanks(bankAccountsResponse.results);
      setLoading(false);
    }
  };

  const submitForm = async () => {
    setLoading(true);
    try {
      if (id) {
        const paymentMethodResponse = await PaymentMethodRepository.update(id, form);
        const typesResponse = await PaymentMethodRepository.getTypes(id);

        if (!paymentMethodResponse?.error) {
          for (const formType of form.types) {
            const existingType = typesResponse.results.find(type => type.id === formType.id);

            if (!existingType) {
              await PaymentMethodRepository.postType(id, formType);
            }
          }
        } else {
          throw new apiBadRequestError(paymentMethodResponse.error, paymentMethodResponse.debug);
        }
      } else {
        await PaymentMethodRepository.create(form);
      }
    } finally {
      setLoading(false);
      navigate('/financeiro/formas-cobrancas');
    }
  };

  const deleteParcel = async parcelId => {
    PaymentMethodRepository.deleteType(id, parcelId);
  };

  useEffect(() => {
    if (clinicId != null) {
      fetchData();
    }
  }, [clinicId]);

  return { chartAccount, banks, submitForm, deleteParcel };
}
