import { ReactComponent as ColaboradoresSVG } from 'assets/ico/colaboradores.svg';
import { ReactComponent as ConfiguracoesSVG } from 'assets/ico/configuracoes.svg';
import { ReactComponent as ProtocolosSVG } from 'assets/ico/protocolos.svg';
import { ReactComponent as RelatoriosSVG } from 'assets/ico/relatorios.svg';
import { BiDollar, BiSolidPurchaseTagAlt } from 'react-icons/bi';
import { BsArrowReturnRight, BsBuildingFillUp, BsCalendar3, BsListCheck } from 'react-icons/bs';
import { FaWarehouse, FaHandHoldingMedical, FaClinicMedical } from 'react-icons/fa';
import { AiFillMedicineBox } from 'react-icons/ai';
import { GiPriceTag, GiMedicalDrip } from 'react-icons/gi';
import { BsCash } from 'react-icons/bs';
import { AiOutlineSchedule } from 'react-icons/ai';
import { SchedulesRepository } from 'data/schedules';
import { PiScroll } from "react-icons/pi";

const admin = [
  {
    icon: <ColaboradoresSVG />,
    title: 'Colaboradores',
    link: '/admin/colaboradores',
  },
  {
    icon: <FaClinicMedical />,
    title: 'Consultorios',
    link: '/admin/consultorio',
  },
  {
    icon: <AiFillMedicineBox />,
    title: 'Medicamentos',
    link: '/admin/medicamentos',
  },
  {
    icon: <ColaboradoresSVG />,
    title: 'Profissionais',
    link: '/admin/profissionais',
  },
  {
    icon: <PiScroll />,
    title: 'Templates',
    link: '/admin/templates',
  },
];

const stock = [
  {
    icon: <BsBuildingFillUp />,
    title: 'Fornecedores',
    link: '/estoque/fornecedores',
  },
  {
    icon: <GiMedicalDrip />,
    title: 'Insumos',
    link: '/estoque/insumos',
  },
  {
    icon: <FaWarehouse />,
    title: 'Estoque',
    link: '/estoque/estoque',
  },
  {
    icon: <BiSolidPurchaseTagAlt />,
    title: 'Solicitação de Insumos',
    link: '/estoque/solicitacao-insumos',
  },
  {
    icon: <GiPriceTag />,
    title: 'Solicitação de Compras',
    link: '/estoque/solicitacoes-compras',
  },
  // {
  //   icon: <BiSolidReport />,
  //   title: 'Relatório',
  //   link: '/estoque/relatorio',
  // },
];

const finance = [
  {
    icon: <ConfiguracoesSVG />,
    title: 'Configurações',
    link: '',
    children: [
      {
        icon: <BsArrowReturnRight />,
        title: 'Dados Bancários',
        link: '/financeiro/dados-bancarios',
        is_children: true,
      },
      {
        icon: <BsArrowReturnRight />,
        title: 'Plano de Contas',
        link: '/financeiro/plano-contas',
        is_children: true,
      },
      {
        icon: <BsArrowReturnRight />,
        title: 'Formas de Cobranças',
        link: '/financeiro/formas-cobrancas',
        is_children: true,
      },
    ],
  },
  {
    icon: <BiDollar />,
    title: 'Fluxo de Caixa',
    link: '/financeiro/fluxo-caixa',
  },
  {
    icon: <ProtocolosSVG />,
    title: 'Precificação de Protocolos',
    link: '/financeiro/protocolos',
  },
  {
    icon: <BsCash />,
    title: 'Caixa/Reembolso',
    link: '/financeiro/caixa',
    disabled: false,
  },
  {
    icon: <BiSolidPurchaseTagAlt />,
    title: 'Solicitação de Insumos',
    link: '/financeiro/solicitacao-insumos',
    disabled: false,
  },
  {
    icon: <GiPriceTag />,
    title: 'Solicitação de Compras',
    link: '/financeiro/solicitacao-compras',
    disabled: false,
  },
  {
    icon: <RelatoriosSVG />,
    title: 'Relatórios',
    link: '',
    children: [
      {
        icon: <BsArrowReturnRight />,
        title: 'Comissão',
        link: '/financeiro/relatorio-comissao',
        is_children: true,
      },
      // {
      //   icon: <BsArrowReturnRight />,
      //   title: 'DRE',
      //   link: '/financeiro/dre',
      //   is_children: true,
      // },
      {
        icon: <BsArrowReturnRight />,
        title: 'Previsão de Receita',
        link: '/financeiro/previsao-receita',
        is_children: true,
      },
    ],
  },
];

const service = [
  {
    icon: <BsCalendar3 />,
    title: 'Agenda',
    link: '/atendimento/home',
    disabled: false,
  },
  {
    icon: <FaHandHoldingMedical />,
    title: 'Pacientes',
    link: '/atendimento/pacientes',
  },
  {
    icon: <BsListCheck />,
    title: 'Lista de Espera',
    link: '/atendimento/lista-de-espera',
    disabled: false,
    notificationCounter: props => SchedulesRepository.countWaiting(props.clinic, props.user)
  },
  {
    icon: <BiSolidPurchaseTagAlt />,
    title: 'Solicitar Insumos',
    link: '/atendimento/solicitacao-insumos',
    disabled: false,
  },
];

const concierge = [
  {
    icon: <BsCalendar3 />,
    title: 'Agenda',
    link: '/concierge/home',
    disabled: false,
  },
  {
    icon: <FaHandHoldingMedical />,
    title: 'Pacientes',
    link: '/concierge/pacientes',
  },
  {
    icon: <BiSolidPurchaseTagAlt />,
    title: 'Solicitação de Insumos',
    link: '/concierge/solicitacao-insumos',
  },
  {
    icon: <BiSolidPurchaseTagAlt />,
    title: 'Caixa/Reembolso',
    link: '/concierge/caixa',
  },
  {
    icon: <AiOutlineSchedule />,
    title: 'Protocolo Médico',
    link: '/concierge/agendamento-protocolos',
  },
  {
    icon: <AiOutlineSchedule />,
    title: 'Pagamentos',
    link: '/concierge/pagamentos',
  },
];

export const navItems = { admin, stock, finance, service, concierge };
