import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './InputIcon.module.scss';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

Input.defaultProps = {
  inputClassName: '',
  containerClassName: '',
  type: '',
  label: null,
  containerStyle: {},
  inputStyle: {},
  icon: null,
  iconPosition: 'left',
  hasError: false,
  placeholder: null,
};

Input.propTypes = {
  inputClassName: PropTypes.string,
  label: PropTypes.any,
  containerClassName: PropTypes.string,
  type: PropTypes.string,
  containerStyle: PropTypes.instanceOf(Object),
  inputStyle: PropTypes.instanceOf(Object),
  iconPosition: PropTypes.oneOf(['left', 'right']),
  icon: PropTypes.node,
  hasError: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default function Input({
  inputClassName,
  containerClassName,
  type,
  label,
  icon,
  iconPosition,
  containerStyle,
  inputStyle,
  hasError,
  placeholder,
  ...props
}) {
  const [hidePassword, setHidePassword] = useState(true);

  const [state, setState] = useState({ icon, iconPosition });

  useEffect(() => {
    setState({ icon, iconPosition });
  }, [icon, iconPosition]);

  useEffect(() => {
    if (type === 'password') {
      // setState({ ...state, iconPosition: 'right' })

      if (hidePassword) {
        setState({
          ...state,
          icon: (
            <FaEye
              onClick={() => {
                setHidePassword(false);
              }}
            />
          ),
        });
      } else {
        setState({
          ...state,
          icon: (
            <FaEyeSlash
              onClick={() => {
                setHidePassword(true);
              }}
            />
          ),
        });
      }
    }
  }, [hidePassword]); // eslint-disable-line react-hooks/exhaustive-deps

  const inputClasses = [styles.input];
  const inputContainerClasses = [styles.inputContainer];

  if (inputClassName) inputClasses.push(inputClassName);

  if (state.icon) {
    inputClasses.push(state.iconPosition === 'left' ? styles.hasIconLeft : styles.hasIconRight);
    inputContainerClasses.push(state.iconPosition === 'left' ? styles.hasIconLeft : styles.hasIconRight);
  }
  if (hasError) inputClasses.push(styles.hasError);

  return (
    <label style={containerStyle} className={[styles.container, containerClassName].join(' ')}>
      {label}
      <div className={inputContainerClasses.join(' ')}>
        <input
          style={inputStyle}
          className={inputClasses.join(' ')}
          type={type === 'password' && !hidePassword ? 'text' : type}
          placeholder={placeholder}
          {...props}
        />
        {state.icon}
      </div>
    </label>
  );
}
