import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import styles from './PerfilPaciente.module.scss';
import { LoaderContext } from 'contexts/loader';
import { useToast } from 'hooks/useToast';
import Container from 'components/Container/Container';
import { transformApiToPatient } from 'transformers/userForm';
import PageTitle from 'components/PageTitle/PageTitle';
import Table from 'components/Table/Table';
import moment from 'moment';
import { PatientsRepository } from 'data/patients';
import { SchedulesRepository } from 'data/schedules';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import { Grid } from '@mui/material';
import { cpfMask, formatPhoneNumber } from 'shared/utility';
import Enums from 'transformers/enums';
import { Link } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';

export default function PerfilPaciente({path}) {
  let { id } = useParams();
  const { setLoading } = useContext(LoaderContext);
  const [patientData, setPatientData] = useState();
  const [consultasPaciente, setConsultasPaciente] = useState([]);
  const toast = useToast();
  const isConcierge = path === 'concierge';

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const responsePatient = await PatientsRepository.get(id);
      const responseConsultas = await SchedulesRepository.list({ patient: id, status: isConcierge ? null : 'attended' });
      setLoading(false);
      const data = await transformApiToPatient(responsePatient);
      if (responsePatient?.error) {
        toast.error(responsePatient.error);
        setPatientData({});
      } else {
        setPatientData(data);
        setConsultasPaciente(responseConsultas.results);
      }
      setLoading(false);
    };
    run();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const translateGender = value => {
    if (value === 'male') {
      return 'Masculino';
    } else if (value === 'female') {
      return 'Feminino';
    } else {
      return value;
    }
  };

  return (
    <Container>
      <PageTitle>Perfil do Paciente</PageTitle>
      <Grid
        container
        className={styles.gridContainer}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={4.5}>
          <div className={styles.item}>
            <p>Nome Completo: {patientData?.name}</p>
            <p>Data de Nascimento: {moment(patientData?.birthdate).format('DD/MM/YYYY')}</p>
            <p>Telefone: {formatPhoneNumber(patientData?.phone)}</p>
            <p>Email: {patientData?.email}</p>
            <p>Endereço: {patientData?.address}</p>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={styles.item}>
            <p>CPF: {patientData?.cpf ? cpfMask(patientData?.cpf) : ''}</p>
            <p>Rede Social: {patientData?.social_url}</p>
            <p>Gênero: {patientData?.gender ? translateGender(patientData?.gender) : ''} </p>
            <p>De onde veio: {Enums.indicationToLabel[patientData?.indication] ?? ''} </p>
          </div>
        </Grid>
      </Grid>
      <div className={styles.title}>
        <p>Histórico de Consultas</p>
      </div>
      {consultasPaciente && consultasPaciente.length > 0 ? (
        <Table
          columns={isConcierge 
            ? ['Status', 'Data', 'Especialista', 'Observações'] 
            : ['Status', 'Data', 'Especialista', 'Observações', 'Ver Prontuário']}
          lines={consultasPaciente.map(item => {
            const arr = [
              `${Enums.statusSchedule[item.status]}`,
              moment(item.schedule_date).format('DD/MM/YYYY'),
              item.specialties_name,
              item.description,
            ];
            if (!isConcierge) {
              arr.push(
                <div>
                  <Link to={`/atendimento/prontuario/${id}/consulta/${item.id}`}>
                    <FaEye className={styles.viewIcon} color="#15ab72" title="Ver Prontuário" />
                  </Link>
                </div>
              );
            }
            return arr;
          })}
        />
      ) : (
        <div className={styles.error_placeholder}>Nenhum histórico encontrado.</div>
      )}

      <ButtonVoltar link={`/${path}/pacientes`} />
    </Container>
  );
}
