// hooks
import { useState, useContext, useEffect } from 'react';
import useFilters from './hooks/useFilters';
import useApi from './hooks/useApi';
import useScheduleHanlders from './hooks/useScheduleHanlders';
import useModalController from './hooks/useModalController';
import { UserRepository } from 'data/users';

// scss
import styles from './Schudule.module.scss';

// components
import Container from 'components/Container/Container';
import Button from 'components/Button/Button';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import EventModal from './EventModal/EventModal';
import { Calendar } from 'components/Calendar/Calender';
import { ClinicIdContext } from 'contexts/clinicId';
import { UserHoursRepository } from 'data/usersHour';
import { getWeekdayFromDate } from 'shared/utility';
import { useToast } from 'hooks/useToast';
import { clinicalStorage } from 'shared/storage';

export default function ConciergeSchedule() {
  const { clinicId } = useContext(ClinicIdContext);
  const clinicsList = JSON.parse(clinicalStorage());
  const toast = useToast();
  const { filters, setFilters, clearFilters } = useFilters();
  const { modalController, setModalController, onClose } = useModalController();
  const { defaultEvents, procedures, patients, addEvent, getScheduleFiles } = useApi(filters);
  const { events, onDateClick, onEventClick, onDrop, onEventDrop } = useScheduleHanlders(
    defaultEvents,
    setModalController,
    getScheduleFiles,
    filters
  );

  const clinicData = clinicsList.find(c => c.id.toString() === clinicId?.toString());
  
  const [professionals, setProfessionals] = useState([]);
  const [professionalWorkingHours, setProfessionalWorkingHours] = useState([]);

  useEffect(() => {
    if (clinicId != null) {
      if (filters.procedure) {
        setProfessionalWorkingHours([]);
        onClose(); //reseta os atributos do modal
      }
      loadProfessionals({});
      clearFilters(); //reseta os filtros, atualizando inclusive o clinicId
    }
  }, [clinicId]);

  const onModalClose = () => {
    setFilters({ ...filters });
    if (filters.procedure && filters.user) {
      onClose({ procedure: filters.procedure, user: filters.user });
    } else {
      onClose();
    }
  }

  const onCalendarDateClick = (arg) => {
    if (filters.procedure && filters.user) {
      const weekday = getWeekdayFromDate(arg.date);
      const professionalDayHours = professionalWorkingHours.filter(hours => hours.day === weekday);
      const ignore = professionalWorkingHours.length === 0; //ignora pois não tem definição, fica a critério do usuário controlar a agenda
      if (ignore || professionalDayHours.length > 0) {
        arg.professionalDayHours = ignore ? null : professionalDayHours;
        onDateClick(arg);
      } else {
        toast.warning('Este profissional não trabalha no consultório neste dia');
      }
    } else {
      toast.warning('Você deve selecionar um procedimento e profissional para agendar');
    }
  }

  const loadProfessionals = async selectedProcedure => {
    const filters = { clinic: clinicId };
    if (selectedProcedure.profile === 'doctor') {
      filters.user_type = '1';
    }
    if (selectedProcedure.profile === 'other' && selectedProcedure.service_type != null) {
      filters.user_type = '7';
      filters.service_type = selectedProcedure.service_type;
    }
    const userResponse = await UserRepository.list(filters);
    setProfessionals(userResponse?.results ?? []);
  }

  const loadProfessionalWorkingHours = async professional => {
    const response = await UserHoursRepository.list(professional, clinicId);
    setProfessionalWorkingHours(response?.results ?? []);
  }

  return (
    <Container className={styles.container}>
      <div className={styles.action_bar}>
        <Autocomplete
          className={styles.autocomplete}
          label="Procedimento"
          value={procedures
            .map(item => ({ label: item.first_name, value: item.id }))
            .find(item => item.value === filters.procedure)}
          options={procedures.map(item => ({ label: item.name, value: item.id }))}
          onChange={(_, item) => {
            if (item != null) {
              const selectedProcedure = procedures.find(procedure => procedure.id === item.value);
              loadProfessionals(selectedProcedure);
              setModalController(prevState => ({ ...prevState, eventData: { ...prevState.eventData, procedure: item.value } }));
              setFilters(prevFilters => ({ ...prevFilters, procedure: item.value }));
            }
          }}
        />
        <Autocomplete
          className={styles.autocomplete}
          label="Profissional"
          value={professionals
            .map(user => ({ label: user.first_name, value: user.id }))
            .find(user => user.value === filters.user)}
          options={professionals
            .map(user => {
              const label = `${user.first_name} ${user.last_name}`
              return { label: label, value: user.id };
            })
            .filter(option => option !== null)}
          onChange={(_, item) => {
            if (item != null) {
              loadProfessionalWorkingHours(item.value);
              setFilters(prevFilters => ({ ...prevFilters, user: item.value }));
              setModalController(prevState => ({ ...prevState, eventData: { ...prevState.eventData, user: item.value } }));
            }
          }}
        />
        <Button
          label="Limpar Filtros"
          onClick={() => {
            loadProfessionals({});
            setProfessionalWorkingHours([]);
            clearFilters();
          }}
        />
      </div>
      <div className={styles.calendar_container}>
        <Calendar
          events={events}
          onDateClick={onCalendarDateClick}
          onEventClick={onEventClick}
          onDrop={onDrop}
          onEventDrop={onEventDrop}
          customHeaderToolbar={{
            left: 'today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
          }}
          customFooterToolbar={{
            left: '',
            center: '',
            right: 'prev next',
          }}
          slotMinTime={clinicData?.opening}
          slotMaxTime={clinicData?.closure}
        />
      </div>
      <EventModal props={{ modalController, setModalController, onModalClose, filters, addEvent, patients, procedures, professionals }} />
    </Container>
  );
}
