import React, { useEffect, useState, useContext } from 'react';
import styles from './Pagamentos.module.scss';
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import InputIcon from 'components/InputIcon/InputIcon';
import { LoaderContext } from 'contexts/loader';
import { Grid } from '@mui/material';
import { FiSearch } from 'react-icons/fi';
import Button from 'components/Button/Button.js';
import Card from 'components/Card/Card';
import { ClinicIdContext } from 'contexts/clinicId';
import { SchedulesRepository } from 'data/schedules';
import { formatCurrency } from 'shared/utility';
import Checkbox from 'components/Checkbox/Checkbox';
import ModalPagar from './ModalPagar';

function Pagamentos() {
  const { clinicId } = useContext(ClinicIdContext);
  const { setLoading } = useContext(LoaderContext);
  const [patientProtocolsList, setPatientProtocolsList] = useState({});
  const [openedPatient, setOpenedPatient] = useState(null);
  const [search, setSearch] = useState('');
  const [schedulesSelected, setSchedulesSelected] = useState([]);
  const [showModalPagar, setShowModalPagar] = useState(false);

  useEffect(() => {
    const run = async () => {
      loadData();
    };
    if (clinicId != null) {
      run();
    }
  }, [clinicId]);

  const loadData = async () => {
    setLoading(true);
    const schedulesResponse = await SchedulesRepository.listPayments({ clinic: clinicId });
    setLoading(false);

    const schedules = schedulesResponse?.results ?? [];
    const dadosPorPaciente = {};
    let firstPatient;
    schedules.forEach(schedule => {
      if (schedule.procedure != null) {
        const patientName = schedule.patient_name;
        if (firstPatient == null) {
          firstPatient = patientName;
        }
        if (dadosPorPaciente[patientName] == null) {
          dadosPorPaciente[patientName] = {};
        }
        const patientData = dadosPorPaciente[patientName];
        let protocolName = '';
        if (schedule.protocol != null) {
          protocolName = schedule.protocol.name;
        }
        if (patientData[protocolName] == null) {
          patientData[protocolName] = [];
        }
        patientData[protocolName].push({
          ...schedule.procedure,
          scheduleId: schedule.id,
          scheduleObs: schedule.user_obs,
          scheduleIsPayed: schedule.is_payed,
          protocolName: protocolName,
        });
      }
    });
    setOpenedPatient(firstPatient);
    setPatientProtocolsList(dadosPorPaciente);
  };

  const selectedProcedimentos = [];
  if (showModalPagar) {
    const patientProtocols = patientProtocolsList[openedPatient];
    Object.keys(patientProtocols).forEach(protocolName => {
      const protocolSelectedProce = patientProtocols[protocolName].filter(proce => schedulesSelected.includes(proce.scheduleId));
      selectedProcedimentos.push(...protocolSelectedProce);
    });
  }

  return (
    <Container>
      <PageTitle>Pagamentos</PageTitle>
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={5}>
          <InputIcon
            placeholder="Pesquisar paciente"
            icon={<FiSearch />}
            iconPosition="right"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </Grid>
      </Grid>
      <Card className={styles.card}>
        {Object.keys(patientProtocolsList).map(patientName => {
          const protocols = patientProtocolsList[patientName];
          const isSelectedPatient = patientName === openedPatient;
          return (
            <div className={styles.cardContainer}>
              <div className={styles.headerPatient}>
                <Button
                  onClick={() => {
                    setSchedulesSelected([]);
                    if (isSelectedPatient) {
                      setOpenedPatient(null);
                    } else {
                      setOpenedPatient(patientName);
                    }
                  }}
                  label={isSelectedPatient ? '-' : '+'}
                  size="sm"
                />
                <p>Paciente: {patientName}</p>
              </div>
              <div className={styles.procedimentoContainer}>
                {isSelectedPatient && Object.keys(protocols).map(protocolName => {
                  const procedures = protocols[protocolName];
                  return (
                    <div>
                      <div className={styles.headerProcedures}>
                        <div>
                          <p>{protocolName === '' ? 'Atendimentos Avulsos' : `Protocolo: ${protocolName}`}</p>
                        </div>
                      </div>
                      <div>
                        {procedures.map(proce => {
                          const index = schedulesSelected.indexOf(proce.scheduleId);
                          return (
                            <div className={styles.headerInsideProcedures}>
                              <p> - Procedimento: {proce.name}</p>
                              <div className={styles.priceAndBtn}>
                                {proce.scheduleIsPayed ? (
                                  <p className={styles.scheduleIsPayed}>PAGO!</p>  
                                ) : (
                                  <>
                                    <p>{formatCurrency(proce.value)}</p>
                                    <Checkbox
                                      single
                                      selected={index >= 0}
                                      onClick={() => {
                                        if (index >= 0) {
                                          const newschedulesSelected = [...schedulesSelected];
                                          newschedulesSelected.splice(index, 1);
                                          setSchedulesSelected(newschedulesSelected);
    
                                        } else {
                                          setSchedulesSelected([...schedulesSelected, proce.scheduleId]);
                                        }
                                      }} 
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </Card>
      <Button
        label="Pagar"
        className={styles.paymentButton}
        disabled={schedulesSelected.length === 0}
        onClick={() => setShowModalPagar(true)}
      />
      {showModalPagar && (
        <ModalPagar 
          patientName={openedPatient}
          procedimentos={selectedProcedimentos} 
          onClose={res => {
            setShowModalPagar(false);
            if (res === 'reload') {
              loadData();
            }
          }} 
        />
      )}
    </Container>
  );
}

export default Pagamentos;
