import React, { useState, useEffect, useContext, useRef } from 'react';
import styles from './AddTemplates.module.scss';
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import Card from 'components/Card/Card';
import { useToast } from 'hooks/useToast';
import Input from 'components/Input/Input';
import { LoaderContext } from 'contexts/loader';
import Button from 'components/Button/Button';
import TableFooter from 'components/TableFooter/TableFooter';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import { validateTemplate } from './validator';
import { apiBadRequestError } from 'exceptions/api';
import { useNavigate, useParams } from 'react-router-dom';
import { TemplatesRepository } from 'data/templates';
import Select from 'components/Select/Select';
import { AdminClinicsRepository } from 'data/admin-clinics';
import { Grid } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { TEMPLATE_TEXT_TYPE } from '../consts';

function AddTemplates() {
  const toast = useToast();
  let navigate = useNavigate();
  const { id } = useParams();
  const { setLoading } = useContext(LoaderContext);
  const [formData, setFormData] = useState({ name: '', text_type: '', text: '' });
  const [clinics, setClinics] = useState([]);
  const editorRef = useRef(null);

  useEffect(() => {
    const run = async () => {
      setLoading(true);
      const responseClinics = await AdminClinicsRepository.list();
      setClinics(responseClinics.results);
      if (id) {
        const response = await TemplatesRepository.get(id);
        if (response?.error) {
          toast.error(response.error);
        } else {
          setFormData(response);
        }
      }
      setLoading(false);
    };
    run();
  }, []);

  const handleSubmit = async () => {
    const payload = { ...formData, text: editorRef.current?.getContent() };
    try {
      await validateTemplate(payload);
      await saveData(payload);
    } catch (error) {
      if (error.error) {
        toast.error(error.error);
      } else if (error.errors) {
        toast.error(error.errors[0]);
      }
    }
  };

  const saveData = async (payload) => {
    setLoading(true);
    const response = id
      ? await TemplatesRepository.update(id, payload)
      : await TemplatesRepository.create(payload);
    setLoading(false);
    if (response?.error) throw new apiBadRequestError(response.error, response.debug);
    return navigate('/admin/templates');
  };

  return (
    <Container className={styles.container}>
      <PageTitle>Adicionar um template</PageTitle>
      <Card className={styles.card}>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={3}>
            <Select
              className={styles.consultorio_select_global_select}
              label="Consultório"
              options={clinics.map(item => ({ value: item.id, name: item.name }))}
              value={formData.clinic}
              onChange={e => setFormData({ ...formData, clinic: e.target.value })} 
            />
          </Grid>
          <Grid item xs={3}>
            <Select
              label="Tipo do Template"
              options={TEMPLATE_TEXT_TYPE}
              value={formData.text_type}
              onChange={e => setFormData({ ...formData, text_type: e.target.value })} 
            />  
          </Grid>
          <Grid item xs={6}>
            <Input 
              label="Nome do template" 
              value={formData.name} 
              onChange={e => setFormData({ ...formData, name: e.target.value })} 
            />
          </Grid>
        </Grid>
        <Editor
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={formData.text ?? ''}
          init={{
            language: 'pt_BR',
            language_url: 'https://cdn.jsdelivr.net/npm/tinymce-lang/langs/pt_BR.js',
            statusbar: false,
            height: 500,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste imagetools wordcount',
            ],
            toolbar:
              'insertfile undo redo | styleselect | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            image_title: true,
            automatic_uploads: true,
            file_picker_types: 'image',
            file_picker_callback: function (cb, value, meta) {
              var input = document.createElement('input');
              input.setAttribute('type', 'file');
              input.setAttribute('accept', 'image/*');
              input.onchange = function () {
                var file = this.files[0];
                var reader = new FileReader();
                reader.onload = function () {
                  var id = 'blobid' + new Date().getTime();
                  var blobCache = editorRef.current.editorUpload.blobCache;
                  var base64 = reader.result.split(',')[1];
                  var blobInfo = blobCache.create(id, file, base64);
                  blobCache.add(blobInfo);
                  cb(blobInfo.blobUri(), { title: file.name });
                };
                reader.readAsDataURL(file);
              };

              input.click();
            },
          }}
        />
      </Card>
      <TableFooter className={styles.footer}>
        <ButtonVoltar link="/admin/templates" />
        <Button label="Salvar" onClick={handleSubmit} />
      </TableFooter>
    </Container>
  );
}

export default AddTemplates;
