// hooks
import { useContext, useState, useEffect } from 'react';
import { useToast } from 'hooks/useToast';
import { useNavigate } from 'react-router-dom';

// scss
import styles from './AddTransacao.module.scss';

// contexts
import { ClinicIdContext } from 'contexts/clinicId';
import { LoaderContext } from 'contexts/loader';

// components
import Container from 'components/Container/Container';
import PageTitle from 'components/PageTitle/PageTitle';
import TableFooter from 'components/TableFooter/TableFooter';
import Select from 'components/Select/Select';
import Button from 'components/Button/Button';
import ButtonVoltar from 'components/ButtonVoltar/ButtonVoltar';
import Table from 'components/Table/Table';
import Formulario from './Formulario/Formulario';
import Input from 'components/Input/Input';
import { AiOutlineUpload } from 'react-icons/ai';
import { Grid } from '@mui/material';

// other
import { BankTransactionsRepository } from 'data/banktransactions';
import { validateTransactionList } from './validator';
import { apiBadRequestError } from 'exceptions/api';
import { BankAccountsRepository } from 'data/bankaccounts';
import { ChartOfAccountsRepository } from 'data/chartofaccounts';
import moment from 'moment';
import { MdOutlineDeleteOutline } from 'react-icons/md';

const listRecorrencia = [
  {
    id: '1',
    name: 'Única',
    value: 'unique',
  },
  {
    id: '2',
    name: 'Recorrente Semanal',
    value: 'weekly_recurrence',
  },
  {
    id: '3',
    name: 'Recorrente Mensal',
    value: 'monthly_recurrence',
  },
];

const min = 0;
const max = 100;

const listForm = {
  recurrence: '',
  transaction_date: '',
  value: '',
  recurrence_quantity: 0,
  chart_of_accounts: null,
  chart_of_accounts_name: '',
  bank_account: null,
  bank_account_name: '',
  description: '',
  clinic: null,
};

function AddTransacao() {
  let navigate = useNavigate();
  const toast = useToast();
  const { setLoading } = useContext(LoaderContext);
  const { clinicId } = useContext(ClinicIdContext);
  const [recurrence, setRecurrence] = useState('');
  const [bank_account, setBankAccount] = useState('');
  const [chart_account, setChartAccount] = useState('');
  const [formData, setFormData] = useState({ ...listForm });
  const [list, setList] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [inputDate, setInputDate] = useState('');
  const [type, setType] = useState('');

  useEffect(() => {
    const run = async () => {
      const response = await ChartOfAccountsRepository.get(chart_account);
      setFormData({ ...formData, chart_of_accounts_name: response.name });
    };
    run();
  }, [chart_account]);

  useEffect(() => {
    const run = async () => {
      const response = await BankAccountsRepository.get(bank_account);
      setFormData({
        ...formData,
        agency: response.agency,
        account: response.account,
        bank_account_name: response.banks_name,
      });
    };
    run();
  }, [bank_account]);

  const handleChange = event => {
    let value = event.value;
    if (event.name === 'type') {
      setInputValue('');
    }
    if (event.name === 'bank_account') {
      setBankAccount(value);
    }
    if (event.name === 'chart_of_accounts') {
      setChartAccount(value);
    }
    setFormData({
      ...formData,
      [event.name]: value,
      clinic: parseInt(clinicId),
    });
  };

  const handleChangeValue = (event, value, maskedValue) => {
    if (type === 'expense') {
      setFormData({ ...formData, value: value * -1 });
      setInputValue(maskedValue);
    } else {
      setFormData({ ...formData, value: value });
      setInputValue(maskedValue);
    }
  };

  const handleSubmit = async event => {
    const newList = [...list];
    try {
      await validateTransactionList({ ...formData });
      const qtd = parseInt(formData?.recurrence_quantity);
      const rec = formData?.recurrence;
      const desc = formData?.description;
      if (qtd > 1) {
        for (let i = 0; i < qtd; i++) {
          if (rec === 'monthly_recurrence') {
            if (i === 0) {
              newList.push({
                ...formData,
                description: `${desc} - ${i + 1}/${qtd}`,
                transaction_date: moment(inputDate).format('YYYY-MM-DD'),
              });
            } else {
              newList.push({
                ...formData,
                description: `${desc} - ${i + 1}/${qtd}`,
                transaction_date: moment(inputDate).add(i, 'months').format('YYYY-MM-DD'),
              });
            }
          } else if (rec === 'weekly_recurrence') {
            if (i === 0) {
              newList.push({
                ...formData,
                description: `${desc} - ${i + 1}/${qtd}`,
                transaction_date: moment(inputDate).format('YYYY-MM-DD'),
              });
            } else {
              newList.push({
                ...formData,
                description: `${desc} - ${i + 1}/${qtd}`,
                transaction_date: moment(inputDate).add(i, 'weeks').format('YYYY-MM-DD'),
              });
            }
          }
        }
        setList(newList);
        setFormData({ ...listForm });
      } else {
        setList([...list, formData]);
        setFormData({ ...listForm });
      }
      setRecurrence('');
      setInputDate('');
      setInputValue('');
      setBankAccount('');
      setChartAccount('');
      setType('');
    } catch (err) {
      toast.error('Preencha todos os campos corretamente');
    }
  };

  const handleChangeDate = event => {
    setFormData({ ...formData, transaction_date: event.target.value });
    setInputDate(event.target.value);
  };

  const handleChangeRecorrencia = value => {
    setRecurrence(value);
    setFormData({ ...formData, recurrence: value });
  };

  const saveData = async () => {
    setLoading(true);
    const response = await BankTransactionsRepository.create(list);
    setList([]);
    setLoading(false);
    if (response?.error) throw new apiBadRequestError(response.error, response.debug);
    return navigate('/financeiro/fluxo-caixa');
  };

  const botaoVoltar = <ButtonVoltar link="/financeiro/fluxo-caixa" />;
  const botaoAdd = (
    <Button
      label="Salvar"
      link="/financeiro/fluxo-caixa/add-transacao"
      color="secondary"
      onClick={saveData}
      size="large"
      disabled={list.length === 0}
    />
  );

  const handleDelete = id => {
    const updatedItems = [...list];
    updatedItems.splice(id, 1);
    setList(updatedItems);
  };

  const editButton = id => (
    <div className={styles.editButton}>
      <MdOutlineDeleteOutline
        color="#A31912"
        title="Excluir Consultório"
        onClick={() => {
          handleDelete(id);
        }}
      />
    </div>
  );

  const handleCategory = async event => {
    const value = event.value;
    setType(value);
  };

  return (
    <Container className={styles.container}>
      <PageTitle>Nova Transação</PageTitle>
      <div className={styles.formulario}>
        <Formulario handleChange={handleChange} formData={formData} handleCategory={handleCategory} type={type} />
      </div>
      <Grid container spacing={1} className={styles.filters}>
        {type !== '' && (
          <Grid item xs={3}>
            <Select
              label="Recorrência"
              name={listRecorrencia.name}
              value={recurrence}
              onChange={e => {
                handleChangeRecorrencia(e.target.value);
              }}
              options={listRecorrencia}
            />
          </Grid>
        )}
        {recurrence === 'unique' && (
          <>
            <Grid item xs={3}>
              <Input type="date" name="date" value={formData.transaction_date} onChange={handleChangeDate} />
            </Grid>
            <Grid item xs={3}>
              <Input
                numericInput
                name="value"
                value={inputValue}
                className={type === 'expense' ? styles.estiloDespesa : styles.estiloReceita}
                label={type === 'expense' ? 'Débito' : 'Credito'}
                onChange={handleChangeValue}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                label="Adicionar"
                type="button"
                variant="contained"
                style={{ width: '100%' }}
                color="secondary"
                icon={AiOutlineUpload}
                onClick={handleSubmit}
              />
            </Grid>
          </>
        )}
        {recurrence !== 'unique' && recurrence !== '' && (
          <>
            <Grid item xs={2}>
              <Input type="date" name="date" value={formData.transaction_date} onChange={handleChangeDate} />
            </Grid>
            <Grid item xs={2}>
              <Input
                label="Quantidade de recorrências"
                name="recurrence_quantity"
                type="number"
                inputProps={{ min, max }}
                value={formData.recurrence_quantity}
                onChange={e => {
                  var value = parseInt(e.target.value, 10);
                  if (value > max) value = max;
                  if (value < min) value = min;
                  handleChange({ name: e.target.name, value: value });
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Input
                numericInput
                name="value"
                value={inputValue}
                className={type === 'expense' ? styles.estiloDespesa : styles.estiloReceita}
                label={type === 'expense' ? 'Débito' : 'Credito'}
                onChange={handleChangeValue}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                label="Adicionar"
                type="button"
                variant="contained"
                style={{ width: '100%' }}
                color="secondary"
                icon={AiOutlineUpload}
                onClick={handleSubmit}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Table
        columns={['Data', 'Banco', 'Descrição', 'Plano de Contas', 'Valor', 'Ações']}
        tableHeight="250px"
        lines={list.map(item => [
          moment(item.transaction_date).format('DD/MM/YYYY'),
          [`${item.bank_account_name}${item.agency && item.account ? ` - ${item.agency} - ${item.account}` : ''}`],
          item.description,
          item.chart_of_accounts_name,
          item.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
          editButton(item.id),
        ])}
      />
      <TableFooter>
        {botaoVoltar}
        {botaoAdd}
      </TableFooter>
    </Container>
  );
}

export default AddTransacao;
