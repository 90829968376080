import DefaultLayout from 'App/layouts/DefaultLayout/DefaultLayout';
import Authenticated from 'middleware/authenticated';
import AgendaSemanal from 'pages/shared/AgendaSemanal/AgendaSemanal';
import FormPaciente from 'pages/shared/Pacientes/FormPaciente/FormPaciente';
import Pacientes from 'pages/shared/Pacientes/Pacientes';
import PerfilPaciente from 'pages/shared/Pacientes/PerfilPaciente/PerfilPaciente';
import ConciergeHome from 'pages/concierge/ConciergeHome';
import SolicitacoesInsumos from 'pages/concierge/SolicitacoesInsumos/SolicitacoesInsumos';
import CaixaReembolso from 'pages/finance/CaixaReembolso/CaixaReembolso';
import AgendamentoProtocolos from 'pages/concierge/AgendamentoProtocolos/AgendamentoProtocolos';
import Pagamentos from 'pages/concierge/Pagamentos/Pagamentos';

export const routes = [
  {
    key: '/concierge/home',
    path: '/concierge/home',
    element: (
      <Authenticated>
        <DefaultLayout nav="concierge" tab="">
          <ConciergeHome />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/concierge/pacientes',
    path: '/concierge/pacientes',
    element: (
      <Authenticated>
        <DefaultLayout nav="concierge" tab="/pacientes">
          <Pacientes path="concierge" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/concierge/perfil-paciente/:id',
    path: '/concierge/perfil-paciente/:id',
    element: (
      <Authenticated>
        <DefaultLayout nav="concierge" tab="/perfil-paciente">
          <PerfilPaciente path="concierge" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/concierge/pacientes/novo',
    path: '/concierge/pacientes/novo',
    element: (
      <Authenticated>
        <DefaultLayout nav="concierge" tab="/pacientes">
          <FormPaciente path="concierge" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/concierge/pacientes/editar/:id',
    path: '/concierge/pacientes/editar/:id',
    element: (
      <Authenticated>
        <DefaultLayout nav="concierge" tab="/pacientes">
          <FormPaciente path="concierge" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/concierge/agenda-semanal',
    path: '/concierge/agenda-semanal',
    element: (
      <Authenticated>
        <DefaultLayout nav="concierge" tab="/agenda-semanal">
          <AgendaSemanal />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/concierge/solicitacao-insumos',
    path: '/concierge/solicitacao-insumos',
    element: (
      <Authenticated>
        <DefaultLayout nav="concierge" tab="/solicitacao-insumos">
          <SolicitacoesInsumos props={{ nav: 'concierge', tab: 'lista_solicitacoes' }} />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/concierge/caixa',
    path: '/concierge/caixa',
    element: (
      <Authenticated>
        <DefaultLayout nav="concierge" tab="/caixa">
          <CaixaReembolso tab="caixa" user_type="concierge" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/concierge/reembolso',
    path: '/concierge/reembolso',
    element: (
      <Authenticated>
        <DefaultLayout nav="concierge" tab="/caixa">
          <CaixaReembolso tab="reembolso" user_type="concierge" />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/concierge/agendamento-protocolos',
    path: '/concierge/agendamento-protocolos',
    element: (
      <Authenticated>
        <DefaultLayout nav="concierge" tab="/agendamento-protocolos">
          <AgendamentoProtocolos />
        </DefaultLayout>
      </Authenticated>
    ),
  },
  {
    key: '/concierge/pagamentos',
    path: '/concierge/pagamentos',
    element: (
      <Authenticated>
        <DefaultLayout nav="concierge" tab="/pagamentos">
          <Pagamentos />
        </DefaultLayout>
      </Authenticated>
    ),
  },
];
